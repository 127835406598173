import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";
import api from "../../../api";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import Colors from "../../../assets/styles/Colors";
import { AppState } from "../../../store/appSlice";


//
//
const AdminPolicyDeactivationReasons = () => {
  //
  const { slug } = useParams();
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );  
  //
  //
  const { data: productCategories, isLoading: isProductCategoryLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);
  //
  const categoryId = productCategories?.find((x) => x.slug === slug)?.id || 0;
  //
  // get policy deactive reason
  const {
    data: deactiveReasonArr,
    isLoading: isDeaactiveReasonArrLoading,
    refetch,
  } = api.useGetAdminPolicyDeactiveReasonQuery({
    categoryId,
  });
  //
  //
  useEffect(() => {
    refetch();
    //
    return () => {};
  }, [refetch]);

  //
  // main loader
  const MainLoader = isDeaactiveReasonArrLoading || isProductCategoryLoading;
  //
  //
  return MainLoader ? (
    <Loader />
  ) : (
    <Wrapper>
      <HeadDiv>
        <Button
          btnStyleType="primary"
          text={t("createDeactivationReason")}
          onClick={() => navigate(`/${slug}/policy-deactivation-reason-detail/0`)}
          size="medium"
        />
        <AdminLangSwicher />
      </HeadDiv>

      <br />
      <ReasonBlock>
        {deactiveReasonArr &&
          deactiveReasonArr.map((item, index) => (
            <ReasonItem
              $isActive={item.isActive}
              key={index}
              onClick={() => navigate(`/${slug}/policy-deactivation-reason-detail/${item.id}`)}
            >
              <p>{item.translations?.[field_Language]?.title || t("textNotFound")}</p>
            </ReasonItem>
          ))}
      </ReasonBlock>
    </Wrapper>
  );
};
//
const HeadDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ReasonItem = styled.div<{ $isActive: boolean }>`
  border: ${({ $isActive }) =>
    $isActive
      ? `1px solid ${Colors.primaryGreen}`
      : `1px solid ${Colors.sunsetOrange}`};
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  transition: all 200ms ease;

  p {
    margin: 0;
  }

  &:hover {
    transform: scale(1.02);
    transition: all 200ms ease;
  }
`;
const ReasonBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  text-align: center;
`;
const Wrapper = styled.div`
  /*  */
`;
//
export default AdminPolicyDeactivationReasons;
