import React from 'react'

function PetSmallIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      data-name="Component 127 – 1"
      viewBox="0 0 40 40"
      {...props}
    >
      <defs>
        <clipPath id="PetSmallIcon">
          <path
            fill="none"
            stroke="#707070"
            strokeWidth="1"
            d="M0 0H26V26H0z"
            data-name="Rectangle 8630"
            transform="translate(209 1618)"
          ></path>
        </clipPath>
      </defs>
      <rect width="40" height="40" fill="#ffefed" data-name="Rectangle 2345" rx="20"></rect>
      <g fill="#263238" clipPath="url(#clip-path)" data-name="Mask Group 810" transform="translate(-202 -1611)">
        <path
          d="M5.194.02A5.269 5.269 0 00.071 3.694 4.11 4.11 0 001.308 8.12 9.3 9.3 0 015.194 7.1 9.3 9.3 0 019.08 8.12a4.11 4.11 0 001.237-4.426A5.269 5.269 0 005.194.02m0-1.2c6 0 9 7.364 4 10.725a8.817 8.817 0 00-4-1.248 8.817 8.817 0 00-4 1.248c-5-3.361-2-10.725 4-10.725z"
          data-name="Path 88851"
          transform="translate(216.504 1630.457)"
        ></path>
        <path
          d="M2.492 1.2a1.119 1.119 0 00-.8.357A1.9 1.9 0 001.2 2.93a1.825 1.825 0 001.509 1.9h.025a1.119 1.119 0 00.8-.357A1.9 1.9 0 004.025 3.1a1.825 1.825 0 00-1.509-1.9h-.024m0-1.2h.051a3 3 0 012.682 3.074 2.686 2.686 0 01-2.542 2.958A3 3 0 010 2.958 2.7 2.7 0 012.492 0z"
          data-name="Path 88852"
          transform="rotate(-45 2073.21 563.168)"
        ></path>
        <path
          d="M2.492 4.832h.024a1.825 1.825 0 001.508-1.9 1.9 1.9 0 00-.488-1.374 1.111 1.111 0 00-.827-.358A1.825 1.825 0 001.2 3.1a1.9 1.9 0 00.488 1.373 1.12 1.12 0 00.8.357m0 1.2A2.7 2.7 0 010 3.074 3 3 0 012.683 0a2.686 2.686 0 012.542 2.958 3 3 0 01-2.682 3.074z"
          data-name="Path 91609"
          transform="rotate(-135 453.423 769.755)"
        ></path>
        <path
          d="M2.785 1.2a1.335 1.335 0 00-.942.425A2.454 2.454 0 001.2 3.379c.012 1.221.79 2.263 1.7 2.274h.015a1.335 1.335 0 00.942-.425A2.454 2.454 0 004.5 3.475C4.486 2.253 3.709 1.212 2.8 1.2h-.015m0-1.2h.031A3.265 3.265 0 015.7 3.463a3.093 3.093 0 01-2.815 3.39A3.265 3.265 0 010 3.391 3.1 3.1 0 012.785 0z"
          data-name="Path 88853"
          transform="rotate(-18 5233.195 135.193)"
        ></path>
        <path
          d="M2.785 5.653H2.8c.909-.011 1.686-1.053 1.7-2.274a2.454 2.454 0 00-.642-1.753 1.335 1.335 0 00-.945-.426H2.9c-.908.012-1.686 1.053-1.7 2.274a2.454 2.454 0 00.642 1.753 1.335 1.335 0 00.942.425m0 1.2A3.1 3.1 0 010 3.463 3.265 3.265 0 012.883 0C4.456-.02 5.717 1.5 5.7 3.391a3.265 3.265 0 01-2.885 3.462z"
          data-name="Path 91608"
          transform="rotate(-162 242.566 797.17)"
        ></path>
      </g>
    </svg>
  )
}

export default PetSmallIcon
