const Fonts = {
  Helvetica_Neue_LT_GEO_55_roman: 'Helvetica_Neue_LT_GEO_55_Roman',
  helvetica_Neue_regular: 'helvetica_neueregular',

  // helvetica_Neue_medium: 'helvetica_neuemedium',
  // helvetica_Neue_bold: 'helvetica_neuebold',
  // Helvetica_Neue_LT_GEO_65: 'Helvetica_Neue_LT_GEO_65',
  // Helvetica_Neue_LT_GEO_75_roman: 'Helvetica_Neue_LT_GEO_75_Roman'
}

export default Fonts
