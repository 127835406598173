import React from "react";
import styled from "styled-components";
import Responsive from "../assets/config/Responsive";
import Colors from "../assets/styles/Colors";
import { ScheduleType, currencySymbol } from "../domain/types";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import TransactionArrow from "../assets/img/JSX/TransactionArrow";
//
//
const AdminPaymentSchedule: React.FC<{
  scheduleData?: ScheduleType;
}> = ({ scheduleData }) => {
  //
  const { t } = useTranslation();
  //
  //
  return scheduleData ? (
    <TransactionsStyle>
      <PaymentAmountWrapper>
        {scheduleData.schedule?.map(
          (month, index) =>
            month.active && (
              <div key={index}>
                <span>{month?.name} : </span>
                <span>{month?.amount}</span>
              </div>
            )
        )}
      </PaymentAmountWrapper>

      <TransactionWrapper>
        {scheduleData.schedule?.map((month, index) => (
          <PaymentAmount $isMonthActive={month?.active} key={index}>
            {month?.amount + currencySymbol(scheduleData.currency)}
          </PaymentAmount>
        ))}
      </TransactionWrapper>

      <TransactionWrapper>
        {scheduleData.schedule?.map((month, index) => (
          <TransactionStatus key={index}>
            <TransactionArrowStyle
              $isMonthActive={month?.active}
              $isMonthPassed={month?.passed}
            />
            <ArrowWrapper />
            {index !== 11 && <Line $isMonthPassed={month.passed} />}
          </TransactionStatus>
        ))}

        {scheduleData.schedule?.map((month, index) => (
          <MonthName
            key={index}
            $isMonthPassed={month?.passed}
            $isMonthActive={month.active}
          >
            {month?.name}
          </MonthName>
        ))}
      </TransactionWrapper>

      <PaymentInfoWrapper>
        <PaymentInfo>
          <PaymentTitle>{t("nextPayment")}:</PaymentTitle>
          <PaymentNumber>
            {dayjs(scheduleData?.nextPaymentDate).format("DD.MM.YYYY")}{" "}
          </PaymentNumber>
        </PaymentInfo>
        <PaymentInfo>
          <PaymentTitle>{t("haveToPayment")}:</PaymentTitle>
          <PaymentNumber>
            {scheduleData?.nextPaymentAmount}{" "}
            {currencySymbol(scheduleData.currency)}{" "}
          </PaymentNumber>
        </PaymentInfo>
        <TotalAmount>
          <PaymentTitle>{t("totalPayment")}:</PaymentTitle>
          <PaymentNumber>
            {scheduleData?.totalPaymentAmount}{" "}
            {currencySymbol(scheduleData.currency)}{" "}
          </PaymentNumber>
        </TotalAmount>
      </PaymentInfoWrapper>
    </TransactionsStyle>
  ) : null;
};
//
const TransactionsStyle = styled.div`
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-shadow: 0px 0px 15px 3px #88888811;
  box-shadow: 0px 0px 15px 3px #88888811;
  background-color: ${Colors.white};
  border-radius: 8px;
  overflow: hidden;
  &:first-child {
    margin-bottom: 55px;
  }
  ${Responsive.mobile} {
    padding-inline: 24px;
    &:first-child {
      margin-bottom: 26px;
    }
  }
`;
//
const PaymentAmountWrapper = styled.div`
  display: none;
  text-transform: capitalize;
  font-size: 14px;
  color: ${Colors.primaryBlack};
  ${Responsive.mobile} {
    display: flex;
  }
`;
//
const TransactionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(11, 1fr) min-content;
  grid-template-rows: 1fr;
  row-gap: 10px;
  padding-inline: 50px;
  padding-top: 10px;
  ${Responsive.mobile} {
    padding-inline: 0px;
  }
`;
//
const TransactionStatus = styled.div`
  display: flex;
  align-items: center;
`;
//
const PaymentAmount = styled.div<{ $isMonthActive: boolean }>`
  visibility: ${({ $isMonthActive }) =>
    $isMonthActive ? "visible" : "hidden"};
  font-size: 14px;
  color: ${({ $isMonthActive }) =>
    $isMonthActive ? `${Colors.primaryBlack}` : `${Colors.secondaryGrey}`};
  ${Responsive.mobile} {
    display: none;
  }
`;
//
const TransactionArrowStyle = styled(TransactionArrow)<{
  $isMonthPassed: boolean;
  $isMonthActive: boolean;
}>`
  user-select: none;
  pointer-events: none;
  color: ${({ $isMonthPassed, $isMonthActive }) =>
    $isMonthActive || $isMonthPassed
      ? `${Colors.primaryRed}`
      : `${Colors.secondaryGrey}`};
  width: ${({ $isMonthActive }) => ($isMonthActive ? "25px" : "15px")};
  height: ${({ $isMonthActive }) => ($isMonthActive ? "25px" : "15px")};
  /* color: ${({ $index }) => $index === 11 && "red"}; */
  ${Responsive.mobile} {
    width: ${({ $isMonthActive }) => ($isMonthActive ? "20px" : "12px")};
  }
`;
//
const ArrowWrapper = styled.div`
  width: 0;
  height: 0;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid white;
  position: relative;
  right: -2px;
`;
//
const MonthName = styled.div<{
  $isMonthPassed: boolean;
  $isMonthActive: boolean;
}>`
  color: ${({ $isMonthPassed, $isMonthActive }) =>
    $isMonthActive || $isMonthPassed
      ? `${Colors.primaryBlack}`
      : `${Colors.secondaryGrey}`};
  text-transform: capitalize;
  font-size: 14px;
  ${Responsive.mobile} {
    display: none;
  }
`;
//
const PaymentInfoWrapper = styled.div`
  padding-inline: 50px;
  display: grid;
  grid-template-columns: repeat(2, max-content) 1fr;
  align-items: center;
  gap: 50px;
  padding-top: 40px;
  padding-bottom: 20px;
  ${Responsive.mobile} {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    row-gap: 6px;
    padding: 20px 0px 0px 0px;
  }
`;
//
const PaymentInfo = styled.div`
  display: flex;
  gap: 7px;
  font-size: 14px;
  ${Responsive.mobile} {
    justify-content: space-between;
  }
`;
//
const PaymentTitle = styled.div`
  color: ${Colors.nevadaGrey};
`;
//
const PaymentNumber = styled.div`
  color: ${Colors.primaryBlack};
`;
//
const TotalAmount = styled.div`
  display: flex;
  gap: 7px;
  font-size: 14px;
  justify-self: end;
  ${Responsive.mobile} {
    display: none;
  }
`;
//
const Line = styled.div<{ $isMonthPassed: boolean }>`
  width: calc(100% - 17px);
  height: 2px;
  background-color: ${({ $isMonthPassed }) =>
    $isMonthPassed ? `${Colors.primaryRed}` : `${Colors.secondaryGrey}`};
  ${Responsive.mobile} {
    width: calc(100% - 14px);
  }
`;
//
export default AdminPaymentSchedule;
