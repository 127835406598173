import * as React from "react"

function RightArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={5.001}
      height={10.001}
      viewBox="0 0 5.001 10.001"
      {...props}
    >
      <path
        data-name="Subtraction 34"
        d="M5 5l5-5H8.093L5 3.054 1.944 0H0z"
        transform="rotate(-90 5.001 5)"
        fill="CurrentColor"
      />
    </svg>
  )
}

export default RightArrow
