import React from 'react'
import styled from 'styled-components'
import api from '../../../../api'
import Loader from '../../../../components/Loader'
//
//
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useSelector } from 'react-redux'
import Colors from '../../../../assets/styles/Colors'
import { RootState } from '../../../../store'
import { AuthState } from '../../../../store/authSlice'
//
//
const Admin_travel_reports = () => {
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth)
  //
  // for frani admin
  const { data: adminAbroadReports, isLoading: isAdminAbroadReportsLoading } = api.useGetAdminTravelReportListQuery(
    undefined,
    { skip: auth?.user?.companyUsers?.[0]?.companyId ? true : false },
  )
  //
  //
  // for insurance company
  const { data: insuranceCompanyAbroadReports, isLoading: isInsuranceCompanyAbroadReportsLoading } =
    api.useGetAdminTravelIncuranceCompanyReportListQuery(auth?.user?.companyUsers?.[0]?.companyId as number, {
      skip: !auth?.user?.companyUsers?.[0]?.companyId && true,
    })
  //
  //
  // for frani admin
  const { data: adminAbroadReportPdf, isLoading: isAdminAbroadReportPdfLoading } = api.useGetAdminTravelReportPdfQuery(
    undefined,
    { skip: auth?.user?.companyUsers?.[0]?.companyId ? true : false },
  )
  //
  //
  // for insurance company pdf
  const { data: adminAbroadInsuranceCompanyReportPdf, isLoading: isAdminAbroadInsuranceCompanyReportPdfLoading } =
    api.useGetAdminTravelInsuranceCompanyReportPdfQuery(auth?.user?.companyUsers?.[0]?.companyId as number, {
      skip: !auth?.user?.companyUsers?.[0]?.companyId && true,
    })
  //
  //
  const reports = adminAbroadReports || insuranceCompanyAbroadReports
  //
  //
  const mainLoading =
    isAdminAbroadReportsLoading ||
    isAdminAbroadReportPdfLoading ||
    isInsuranceCompanyAbroadReportsLoading ||
    isAdminAbroadInsuranceCompanyReportPdfLoading
  //
  //
  const reportTitle = reports && Object.entries(reports[0])?.map((item) => item[0])
  //
  //
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      {auth && auth?.user?.userType === 'Frani-Product-Owner' ? null : (
        <ATagStyle href={adminAbroadReportPdf?.url || adminAbroadInsuranceCompanyReportPdf?.url}>
          რეპორტების გადმოწერა
        </ATagStyle>
      )}
      <br />
      <br />
      <Content>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {reportTitle?.map((item, index) => (
                    <TableCell sx={{ minWidth: 260 }} key={index}>
                      {item.replace(/_/g, ' ')}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reports &&
                  reports?.map((item, index) => (
                    <TableRow key={index}>
                      {Object.entries(item)?.map((x, index) => (
                        <TableCell key={index}>{x[1]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <br />
        <br />
        <br />
      </Content>
    </Wrapper>
  )
}
//
//  styles
const ATagStyle = styled.a`
  font-family: inherit;
  font-size: 18px;
  color: ${Colors.primaryRed};
  text-transform: capitalize;
  &:hover {
    color: ${Colors.primaryBlack};
  }
`
const Content = styled.div`
  overflow: hidden;
`
const Wrapper = styled.div`
  .MuiTableCell-stickyHeader {
    font-family: inherit;
    background-color: ${Colors.grey};
    color: ${Colors.primaryRed};
    white-space: nowrap;
    opacity: unset !important;
  }
  .MuiTableCell-body {
    font-family: inherit;
    background-color: ${Colors.grey};
    color: ${Colors.primaryRed};
    white-space: nowrap;
  }
`
//
export default Admin_travel_reports
