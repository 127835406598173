import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";
import NotFound404 from "../../../../../components/NotFound404";
import {
  Currency,
  TplCurrencies,
  TplLimitList,
  TravelFranchise,
  WelcomerAddon,
  WelcomerProduct,
  WelcomerRisk,
} from "../../../../../domain/types";
import BaseModal from "../../../../../components/modals/BaseModal";
import WelcomerPackageEditor from "./WelcomerPackageEditor";
import api from "../../../../../api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
//
//
//
//
const EditWelcomerPackagesTab: React.FC<{
  welcomerProduct?: WelcomerProduct;
  welcomerFranchise: TravelFranchise[];
  welcomerRisks: WelcomerRisk[];
  welcomerAddon: WelcomerAddon[];
  isLoading: boolean;
  welcomerLimits: TplLimitList[] | undefined;
  welcomerCurrencies: TplCurrencies[] | undefined;
  refetchExistingWelcomerProduct: () => void;
  /** setTravelProductField is not used for saving packages, but only to receive price combination-related updates */
  setWelcomerProductField: (
    fieldName: keyof WelcomerProduct,
    fieldValue: any
  ) => void;
  setUsedCurrencies: (welcomerPackageId: number, _: Currency[]) => void;
  setUsedFranchises: (welcomerPackageId: number, _: number[]) => void;
  refetchWelcomerPackagePrices: () => void;
}> = ({
  welcomerProduct,
  welcomerFranchise,
  welcomerRisks,
  welcomerLimits,
  welcomerCurrencies,
  welcomerAddon,
  isLoading,
  refetchExistingWelcomerProduct,
  setWelcomerProductField,
  setUsedCurrencies,
  setUsedFranchises,
  refetchWelcomerPackagePrices
}) => {
  //
  const { t } = useTranslation()
  //
  const [
    createWelcomerPackage, //{ isLoading: isCreateTravelPackageLoading }
  ] = api.useCreateWelcomerPackageMutation();
  //
  const [isAddPackageModalOpen, setIsAddPackageModalOpen] =
    useState<boolean>(false);
  const _onCloseAddModal = () => setIsAddPackageModalOpen(false);
  //
  //
  //
  return isLoading ? (
    <Loader />
  ) : !welcomerProduct ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <Block>
        <Button
          btnStyleType="primary"
          icon={false}
          onClick={() => setIsAddPackageModalOpen(true)}
          text={t('addPackage')}
        />
      </Block>
      <br />
      {[...welcomerProduct?.mainProduct.packages]
        .sort((pkg1, pkg2) => (pkg1.id > pkg2.id ? 1 : -1))
        .map((pkg) =>
          pkg.targetType ? ( 
            <WelcomerPackageEditor
              key={pkg.id}
              welcomerPackageId={pkg.targetId}
              welcomerFranchise={welcomerFranchise}
              welcomerRisks={welcomerRisks}
              welcomerAddon={welcomerAddon}
              refetchWelcomerProduct={refetchExistingWelcomerProduct}
              welcomerProduct={welcomerProduct}
              setWelcomerProductField={setWelcomerProductField}
              setUsedCurrencies={setUsedCurrencies}
              setUsedFranchises={setUsedFranchises}
              welcomerLimits={welcomerLimits}
              welcomerCurrencies={welcomerCurrencies}
              refetchWelcomerPackagePrices={refetchWelcomerPackagePrices}
            />
          ) : null
        )}
      {/* add package modal */}
      <BaseModal onClose={_onCloseAddModal} isOpen={isAddPackageModalOpen}>
        <div>
          <Block>
            <Text>{t('addPackage')}</Text>
          </Block>
          {/* {selectedProductCategoryID === undefined ? null : ( */}
          <Button
            btnStyleType="primary"
            icon={false}
            fullWidth={true}
            disabled={/* 'validation' == */ false}
            onClick={async () => {
              try {
                const title = `${welcomerProduct.mainProduct.company.title}, ${
                  welcomerProduct.mainProduct.productCategory.title
                } დაზღვევა, პაკეტი ${
                  welcomerProduct.mainProduct.packages.length + 1
                }`;
                await createWelcomerPackage({
                  productId: welcomerProduct.mainProduct.id,
                  translations: {
                    ge: {
                      title,
                    },
                    en: {
                      title,
                    }
                  },
                  limitId:
                    welcomerLimits
                      ?.filter((limit) => limit.isActive)
                      .map((limit) => limit.id)[0] || 1,
                  currencyId:
                    welcomerCurrencies
                      ?.filter((currency) => currency.isActive)
                      .map((currency) => currency.id)[0] || 1,
                }).unwrap();
                //
                // refetch product list by id
                refetchExistingWelcomerProduct();
                _onCloseAddModal();
                toast.success(`${t('package')} ${t('addedSuccessfully')}`);
              } catch (error) {
                if (error?.data?.errors?.constructor === Array) {
                  const firstError = error.data.errors[0];
                  const errorMessage = firstError.message;
                  toast.error(errorMessage);
                } else {
                  toast.warning(`${t('fillAllFields')}`);
                }
              }
            }}
            text={t('create')}
          />
          {/* )} */}
        </div>
        {/*  */}
      </BaseModal>
    </Wrapper>
  );
};
//
//
// styles
// const Text = styled.div``
const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /*  */
  > div {
    flex-basis: 50%;
  }
`;
const Wrapper = styled.div`
  padding: 20px 15px;
`;

const Text = styled.div`
  margin-bottom: 10px;
`;

export default EditWelcomerPackagesTab;
