import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Button from "../../../components/Button";
import CustomSelect from "../../../components/CustomSelect";
import Loader from "../../../components/Loader";
import {
  AdminWebDocument,
  TranslationsField,
} from "../../../domain/types";
import { RootState, useAppSelector } from "../../../store";
import { AuthState } from "../../../store/authSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import Editor from "../../admin_components/admin_editor/Editor";
import { AppState } from "../../../store/appSlice";
//
//
const Admin_web_documents = () => {
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const { t } = useTranslation();
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  // edited state
  const [editedDocument, setEditedDocument] =
    useState<Partial<AdminWebDocument> | null>(null);
  //
  // page slugs
  const pageSlugs: {
    id: number;
    slug: string;
    text: string;
    translations: TranslationsField<{ text: string }>;
  }[] = [
    {
      id: 1,
      slug: "terms-and-conditions",
      text: "წესები და პირობები",
      translations: {
        en: {
          text: "Terms and conditions",
        },
        ge: {
          text: "წესები და პირობები",
        },
      },
    },
    {
      id: 2,
      slug: "privacy",
      text: "კონფიდენციალურობა",
      translations: {
        en: {
          text: "Confidentiality",
        },
        ge: {
          text: "კონფიდენციალურობა",
        },
      },
    },
    {
      id: 3,
      slug: "consent",
      text: "თანხმობა",
      translations: {
        en: {
          text: "Consent",
        },
        ge: {
          text: "თანხმობა",
        },
      },
    },
  ];
  //
  // selected slug
  const [selectedSlug, setSelectedSlug] = useState<string | null>(null);
  //
  //
  const {
    data: webDocuments,
    isLoading: isWebDocumentsLoading,
    refetch: refetchWebDocuments,
  } = api.useGetAdminWebDocumentsQuery(selectedSlug as string, {
    skip: !selectedSlug && true,
  });
  //
  //
  // add travel addon
  const [storeDocument, { isLoading: isStoreDocumentLoading }] =
    api.useStoreWebDocumentMutation();
  //
  //
  // store web document function
  const StoreWebDocumentFun = async () => {
    //
    try {
      await storeDocument(editedDocument as Partial<AdminWebDocument>).unwrap();
      //
      // refetch travel Addons
      refetchWebDocuments();
      //f
      toast.success(`${t('document')} ${t('addedSuccessfully')}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  // existing document
  useEffect(() => {
    if (webDocuments && selectedSlug && webDocuments.slug === selectedSlug) {
      setEditedDocument(webDocuments);
    }
  }, [webDocuments, selectedSlug]);
  //
  //
  const mainLoading = isWebDocumentsLoading || isStoreDocumentLoading;
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <div>
      {auth && auth.user?.userType !== "Frani-Admin" ? (
        "თქვენ არ გაქვთ წვდომა მიმდინარე გვერდზე..."
      ) : (
        <>
          <CustomSelect
            defaultInputValue={
              pageSlugs.find((x) => x.slug === selectedSlug)?.text || ""
            }
            placeholder=""
            headText={t('chooseDocument')}
            onChange={(event) => [
              setEditedDocument(null),
              setSelectedSlug(event.value),
            ]}
            data={[
              ...pageSlugs.map((item) => ({
                value: item.slug,
                label: item.translations?.[lang]?.text,
              })),
            ]}
          />
          <br />
          <br />
          <br />
          {webDocuments && (
            <DivStyle>
              <AdminLangSwicher />
              {/* <Input
                id="version"
                label=""
                type="number"
                placeholder="versin example 2.0"
                value={editedDocument.version || ""}
                name="version"
                onChange={(value) => setDocumentsFields("version", value)}
                width={"default"}
              /> */}
              <Button text={`${t("submit")}`} onClick={StoreWebDocumentFun} />
            </DivStyle>
          )}
          <br />
          <br />
          <br />

          {webDocuments && (
            <Editor
              value={editedDocument?.translations?.[field_Language]?.body}
              onChange={(value) => {
                if (webDocuments && editedDocument) {
                  setEditedDocument({
                    ...webDocuments,
                    translations: {
                      ...webDocuments.translations,
                      [field_Language]: {
                        ...webDocuments.translations?.[field_Language],
                        body: value,
                      },
                    },
                  });
                }
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
//
const DivStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
//
export default Admin_web_documents;
