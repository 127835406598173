import * as React from "react"

function LineArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={9}
      viewBox="0 0 16 9"
      {...props}
    >
      <path d="M10.866 7.884l2.523-2.595H0V3.711h13.383l-2.517-2.595L11.95 0l3.827 3.942a.81.81 0 010 1.116L11.95 9z" />
    </svg>
  )
}

export default LineArrow