import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AdminDeactivePolicyReason, EnabledLanguage } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import api from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import CustomLink from "../../../components/CustomLink";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import Button from "../../../components/Button";
import Colors from "../../../assets/styles/Colors";
import { AppState } from "../../../store/appSlice";

//
//
const AdminPolicyDeactivationReasonsDetails = () => {
  //
  //
  const { id: idStr = "0" } = useParams();
  const itemId = parseInt(idStr);
  //
  const { slug } = useParams()
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation();
  //
  // existing reason
  const [existingReason, setExistingReason] = useState<
    Partial<AdminDeactivePolicyReason>
  >({});
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );  
  //
  //
  const { data: productCategories, isLoading: isProductCategoryLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);
  //
  // get policy deactive reason
  const {
    data: deactiveReasonDetail,
    isLoading: isDeaactiveReasonDetailLoading,
    refetch: refetchPolicyReason,
  } = api.useGetAdminPolicyDeactiveReasonDetailQuery(itemId, {
    skip: !itemId && true,
  });
  //
  //
  const [storePolicyReason, { isLoading: isStorePolicyReason }] =
    api.useStorePolicyReasonMutation();
  //
  //
  //  remove reason
  const [removeReason, { isLoading: isRemoveReasonLoading }] =
    api.useRemoveReasonMutation();
  //
  //
  const setEditReasonTranslationField = (
    field: keyof AdminDeactivePolicyReason,
    language: EnabledLanguage,
    value: any
  ) => {
    setExistingReason({
      ...existingReason,
      translations: {
        ...existingReason.translations,
        [language]: {
          ...existingReason?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  useEffect(() => {
    if (deactiveReasonDetail) {
      setExistingReason(deactiveReasonDetail);
    }
  }, [deactiveReasonDetail]);
  //
  //
  useEffect(() => {
    if (itemId !== 0) {
      refetchPolicyReason();
    }
    //
    return () => {};
  }, [refetchPolicyReason, itemId]);
  //
  //
  // store reason
  const storeReasonFun = async (isActive?: boolean) => {
    //
    try {
      if (itemId === 0) {
        await storePolicyReason({
          productCategoryId:
            productCategories?.find((x) => x.slug === slug)?.id || 0,
          translations: existingReason.translations,
        }).unwrap();
      } else {
        await storePolicyReason({
          ...existingReason,
          isActive: isActive
        }).unwrap();
      }
      //
      toast.success(
        itemId === 0 ? `${t('createdSuccessfully')}` : `${t('updatedSuccessfully')}`
      );
      //
      navigate(`/${slug}/policy-deactivation-reasons`);
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  const RemoveReasonFunction = async () => {
    try {
      if (itemId !== 0 && itemId) {
        window.confirm(`${t('certainToDeleteIt')}`) &&
          (await removeReason(itemId).unwrap());
        //
        navigate(`/${slug}/policy-deactivation-reasons`);
      }
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(error);
      }
    }
  };
  //
  //
  const MainLoader =
    isDeaactiveReasonDetailLoading ||
    isProductCategoryLoading ||
    isStorePolicyReason ||
    isRemoveReasonLoading;
  //
  //
  return MainLoader ? (
    <Loader
     />
  ) : (
    <Wrapper>
      <Head>
        <CustomLink
          text={t('prevPage')}
          leftLineIcon={true}
          onClick={() => navigate(-1)}
        />

        {/* {itemId !== 0 && ( */}
        <AdminLangSwicher />
        {/* )} */}
      </Head>

      <br />

      <div>
        <TextAreaTitle>{t('deactivationReason')}</TextAreaTitle>
        <TextAreaStyle
          value={existingReason.translations?.[field_Language]?.title || ""}
          onChange={(event) =>
            setEditReasonTranslationField("title", field_Language, event.target.value)
          }
        ></TextAreaStyle>
      </div>

      <br />

      <Button
        fullWidth={true}
        btnStyleType="primary"
        text={itemId ? t('update') : t('create')}
        onClick={() => storeReasonFun()}
      />

      <br />

      {itemId !== 0 && (
        <ButtonDiv>
          <Button
            fullWidth={true}
            btnStyleType="transparent"
            border={true}
            text={
              existingReason.isActive === true ? t('deactivate') : t('activate')
            }
            onClick={() => {
              //
              if (existingReason.isActive === true) {
                storeReasonFun(false);
              } else {
                storeReasonFun(true);
              }

              //
            }}
          />

          <Button
            fullWidth={true}
            btnStyleType="transparent"
            border={true}
            text={t('delete')}
            onClick={RemoveReasonFunction}
          />
        </ButtonDiv>
      )}
    </Wrapper>
  );
};
//
//
const ButtonDiv = styled.div`
  display: flex;

  button {
    :first-of-type {
      margin-right: 20px;
    }
  }
`;
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TextAreaTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid ${Colors.primaryRed};
  padding: 10px;
  &:focus {
    outline-color: ${Colors.primaryRed};
  }
`;
const Wrapper = styled.div`
  /*  */
`;
//
export default AdminPolicyDeactivationReasonsDetails;
