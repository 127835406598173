import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../assets/styles/Colors";
import { WelcomerCurrencies } from "../../../../domain/types";
import api from "../../../../api";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import Button from '../../../admin_components/AdminButton'
import CloseIcon from "../../../../assets/icons/JSX/CloseIcon";
import BaseModal from "../../../../components/modals/BaseModal";
import Input from "../../../../components/Input";
import { useTranslation } from "react-i18next";

const AdminWelcomerCurrencies = () => {
  //
  const { t } = useTranslation()
  //
  const [openWelcomerAddModal, setOpenWelcomerAddModal] =
    useState<boolean>(false);
  //
  const [editedCurrency, setEditedCurrency] =
    useState<Partial<WelcomerCurrencies> | null>(null);
  //
  // set currency fields
  const setCurrencyFields = (field: keyof WelcomerCurrencies, value: any) => {
    setEditedCurrency({
      ...editedCurrency,
      [field]: value,
    });
  };
  //
  //
  const {
    data: welcomerCurrencies,
    isLoading: isWelcomerCurrenciesLoading,
    refetch: refetchWelcomerCurrencies,
  } = api.useGetWelcomerCurrenciesQuery(undefined);
  //
  //
  const [saveWelcomerCurrency, { isLoading: isSaveWelcomerCurrencyLoading }] =
    api.useAddWelcomerCurrencyMutation();
  //
  //
  const [
    removeWelcomerCurrency,
    {
      isLoading: isRemoveWelcomerCurrencyLoading,
      isSuccess: removeWelcomerCurrencySucces,
    },
  ] = api.useRemoveWelcomerCurrencyMutation();
  //
  //
  const [
    updateWelcomerCurrencyStatus,
    {
      isLoading: isUpdateWelcomerCurrencyStatusLoading,
      isSuccess: isUpdateWelcomerCurrencySuccess,
    },
  ] = api.useUpdateWelcomerCurrencyStatusMutation();
  //
  //
  const updateWelcomerCurrencyStatusFun = async (
    id: number,
    isActive: boolean
  ) => {
    if (id && isActive !== undefined) {
      try {
        await updateWelcomerCurrencyStatus({ id, isActive }).unwrap();
        // refetch franchise list
        refetchWelcomerCurrencies();
        //
        toast.success(`${t('status')} ${t('updatedSuccessfully')}`);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    }
  };
  //
  //
  const _onAddWelcomerCurrencyCloseModal = () => {
    setOpenWelcomerAddModal(false);
    setEditedCurrency(null);
  };
  //
  //
  const saveCurrencyFunction = async () => {
    if (editedCurrency) {
      try {
        await saveWelcomerCurrency(editedCurrency).unwrap();
        //
        // refetch franchise list
        refetchWelcomerCurrencies();
        //
        // message for user
        toast.success(`${t('currency')} ${t('addedSuccessfully')}`);
        //
        // close modal
        _onAddWelcomerCurrencyCloseModal();
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    } else {
      toast.error(`${t('enterCurrency')} ...`);
    }
  };
  //
  //
  useEffect(() => {
    if (removeWelcomerCurrencySucces || isUpdateWelcomerCurrencySuccess) {
      refetchWelcomerCurrencies();
    }
  }, [
    removeWelcomerCurrencySucces,
    isUpdateWelcomerCurrencySuccess,
    refetchWelcomerCurrencies,
  ]);
  //
  //
  const MainLoading =
    isWelcomerCurrenciesLoading ||
    isSaveWelcomerCurrencyLoading ||
    isRemoveWelcomerCurrencyLoading ||
    isUpdateWelcomerCurrencyStatusLoading;
  //

  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        btnStyleType="primary"
        text={t('addCurrency')}
        onClick={() => setOpenWelcomerAddModal(!openWelcomerAddModal)}
      />
      <br />
      <br />
      <RisksContent>
        {welcomerCurrencies && welcomerCurrencies.length >= 1 ? (
          welcomerCurrencies.map((item, index) => (
            <Block key={index} $isActive={item.isActive}>
              <RemoveBtn
                onClick={() =>
                  window.confirm(`${t('certainToDeleteIt')}`) &&
                  removeWelcomerCurrency(item.id)
                }
              >
                <CloseIcon />
              </RemoveBtn>
              <div>
                <div>
                  {t('currency')}: <b>{item.currencyCode}</b>
                </div>
                <div>
                  {t('symbol')}: <b>{item.symbolOrName}</b>
                </div>
                <div>
                  {t('status')}:
                  <b> {item.isActive === true ? t('active') : t('inActive')}</b>
                </div>

                <br />

                <Button
                  fullWidth={false}
                  btnStyleType="secondary"
                  text={item.isActive === true ? t('deactivate') : t('activate')}
                  onClick={() => {
                    const newStatus = item.isActive;
                    return updateWelcomerCurrencyStatusFun(item.id, !newStatus);
                  }}
                />
              </div>
            </Block>
          ))
        ) : (
          <h4>{t('noCurrenciesFound')}</h4>
        )}
      </RisksContent>
      <BaseModal
        onClose={_onAddWelcomerCurrencyCloseModal}
        isOpen={openWelcomerAddModal}
      >
        <div>
          <ModalHead>
            <h4>{t('createCurrency')}</h4>
          </ModalHead>

          <FieldsContent>
            <Input
              id="currency-name"
              label={t('currency')}
              type="text"
              placeholder=""
              value={editedCurrency?.currencyCode || ""}
              name="currency-name"
              onChange={(value) => setCurrencyFields("currencyCode", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="currency-symbol"
              label={t('currencySymbol')}
              type="text"
              placeholder=""
              value={editedCurrency?.symbolOrName || ""}
              name="currency-symbol"
              onChange={(value) => setCurrencyFields("symbolOrName", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <ButtonsBlock>
              <Button
                disabled={
                  editedCurrency?.currencyCode && editedCurrency.symbolOrName
                    ? false
                    : true
                }
                fullWidth={true}
                btnStyleType="primary"
                text={t('create')}
                onClick={saveCurrencyFunction}
              />
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
    </Wrapper>
  );
};
//
//
//styles
const RemoveBtn = styled.button`
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: transparent;
  border: 0px;
  outline: none;
  height: 20px;
  width: 20px;
  padding: 0px;
  display: grid;
  place-items: center;
  svg {
    height: 13px;
    color: ${Colors.primaryRed};
  }
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-top: 1px solid ${Colors.grey};
  padding-top: 40px;
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.grey};
`;
const RisksContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  /*  */
  img {
    width: 50px;
  }
`;
const Block = styled.div<{ $isActive: boolean }>`
  position: relative;
  height: 200px;
  width: 100%;
  border: ${({ $isActive }) =>
    $isActive
      ? `1px solid ${Colors.primaryGreen}`
      : `1px solid ${Colors.sunsetOrange}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
`;
const Wrapper = styled.div``;
//

export default AdminWelcomerCurrencies;
