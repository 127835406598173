import React, { useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import Responsive from "../assets/config/Responsive";
import CheckmarkIcon from "../assets/icons/JSX/CheckmarkIcon";
import RightArrow from "../assets/icons/JSX/RightArrow";
import Colors from "../assets/styles/Colors";
//
//
const CustomDropdown: React.FC<{
  style?: React.CSSProperties;
  isDisabled?: boolean;
  menuIsOpen?: boolean | undefined;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  options?: Array<{ label: any; value: any }>;
  value?: any;
  className?: string;
  onChange: (_) => void;
  title?: string;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
}> = ({
  style,
  isDisabled = false,
  menuIsOpen = undefined,
  isLoading = false,
  isClearable = false,
  isSearchable = false,
  placeholder = "",
  options,
  value,
  className = "base-dropdown",
  onChange,
  title = "",
  isMulti = false,
  closeMenuOnSelect = true,
}) => {
  //
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  //
  //
  const singleValue =
    isMulti === false &&
    value &&
    options?.find(
      (x) =>
        x?.value?.toString()?.toLowerCase() === value?.toString()?.toLowerCase()
    );
  //
  //
  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };
  //
  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };
  //
  //
  //
  // add checkmark icon
  const getOptionLabel = (option: { value: any; label: any }) => {
    // @ts-ignore
    if (option.value === singleValue?.value) {
      return (
        <GetOptionLabelSpanStyle>
          <span>{option?.label}</span>
          <span className="icon-class">
            <CheckmarkIconStyle />
          </span>
        </GetOptionLabelSpanStyle>
      );
    } else {
      return option.label;
    }
  };
  //
  //
  const customDropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <span>
          <RightArrowStyle $isMenuOpen={isMenuOpen} />
        </span>
      </components.DropdownIndicator>
    );
  };
  //
  //
  //
  //
  return (
    <Wrapper $isMenuOpen={isMenuOpen} style={style}>
      {title ? <Title>{title}</Title> : null}
      <Select
        placeholder={placeholder}
        className={`${className}`}
        classNamePrefix="v2-select" // don't change this className
        value={isMulti ? value : singleValue}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={false}
        menuIsOpen={menuIsOpen}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={isSearchable}
        onChange={(data) => onChange(data)}
        options={options}
        getOptionLabel={getOptionLabel}
        components={{ DropdownIndicator: customDropdownIndicator }}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
      />
    </Wrapper>
  );
};
//
const Title = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${Colors.nevadaGrey};
`;
const GetOptionLabelSpanStyle = styled.span`
  display: flex;
  justify-content: space-between;
  align-content: center;
  /*  */
  &:not(:first-of-type) {
    font-weight: bold;
  }
`;
const CheckmarkIconStyle = styled(CheckmarkIcon)`
  width: 16px;
  height: 10px;
  margin-top: -5px;
`;
const RightArrowStyle = styled(RightArrow)<{ $isMenuOpen: boolean }>`
  transform: ${({ $isMenuOpen }) =>
    $isMenuOpen ? "rotate(-90deg)" : "rotate(90deg)"};
  width: 14px;
  height: 14px;
  margin-right: 16px;
  margin-top: ${({ $isMenuOpen }) => ($isMenuOpen ? "-7px" : "-5px")};
`;
const Wrapper = styled.div<{ $isMenuOpen: boolean }>`
  /*  */
  .v2-select__placeholder {
    font-size: 14px;
  }
  /*  */
  .v2-select__dropdown-indicator {
    /*  */
    ${Responsive.mobile} {
      pointer-events: none;
    }
  }
  /*  */
  .v2-select__menu-list {
    border: 1px solid ${Colors.nevadaGrey};
    border-radius: 4px !important;
    padding: 12px;
    /*  */
    ${Responsive.mobile} {
      padding-block: 20px;
      position: ${({ $isMenuOpen }) => ($isMenuOpen ? "fixed" : "unset")};
      top: 0;
      left: 0;
      bottom: 0;
      overflow: hidden;
      background-color: ${Colors.white};
      margin: 0;
      width: 100%;
      height: fit-content;
      max-height: 70vh;
      overflow-y: scroll;
      margin-top: auto;
      z-index: 99999999999999999999;
      border-radius: 0px !important;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border: unset;
    }
  }
  /*  */
  .v2-select__option--is-selected {
    background-color: transparent;
    color: ${Colors.primaryBlack};
  }
  /*  */
  .v2-select__option--is-focused {
    border-radius: 4px;
    background-color: ${Colors.lightGrey};
    color: ${Colors.primaryBlack};
  }
  /*  */
  .v2-select__menu {
    margin-block: 12px;
    z-index: 5;
    /*  */
    ${Responsive.mobile} {
      position: ${({ $isMenuOpen }) => ($isMenuOpen ? "fixed" : "unset")};
      top: 0;
      left: 0;
      bottom: 0;
      background-color: ${({ $isMenuOpen }) =>
        $isMenuOpen ? Colors.grey : "transparent"};
      z-index: 99999999999999999999;
      width: 100%;
      height: 100%;
      margin: ${({ $isMenuOpen }) => ($isMenuOpen ? "0px" : "12px 0px")};
      border-radius: ${({ $isMenuOpen }) => ($isMenuOpen ? "0px" : "4px")};
    }
    /*  */
  }
  /*  */
  .v2-select__option {
    font-size: 14px;
    padding: 12px;
    /*  */
    &:active {
      background-color: ${Colors.lightGrey};
      color: ${Colors.primaryBlack};
    }
  }
  /*  */
  .v2-select__value-container {
    padding-inline: 24px;
  }
  /*  */
  .v2-select__control {
    /* height: 56px; */
    padding-block: 7px;
    outline: none;
    box-shadow: none;
    border-color: ${Colors.secondaryGrey};
    ${Responsive.mobile} {
      /* height: 48px; */
      padding-block: 3px;
    }
  }
  /*  */
  .v2-select__control--is-focused {
    /*  */
  }
  .v2-select__control--menu-is-open {
    border-color: ${Colors.nevadaGrey};
  }
  /*  */
  .v2-select__control:hover {
    border-color: ${Colors.nevadaGrey};
  }
  /* hide arrow indicator */
  .v2-select__indicator-separator {
    display: none;
  }
  /*  */
  /* hide custom icon in head field */
  .v2-select__single-value {
    font-size: 14px;
    .icon-class {
      display: none;
    }
  }
  /*  */
`;
//
export default CustomDropdown;
