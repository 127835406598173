import dayjs from "dayjs";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../../../api";
import Responsive from "../../../../assets/config/Responsive";
import DoubleRightIcon from "../../../../assets/icons/JSX/DoubleRightIcon";
import Colors from "../../../../assets/styles/Colors";
import Button from "../../../../components/Button";
import CustomDropdown from "../../../../components/CustomDropdown";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import { RootState } from "../../../../store";
import { AuthState } from "../../../../store/authSlice";
import { useTranslation } from "react-i18next";

type FilterFieldStateTypes = {
  companyName: string;
  idNumber: string;
  productType: string;
  pageIndex: number;
  pageSize: number;
};

type InputFieldTypes = {
  companyName: string;
  idNumber: string;
  productType: string;
};

const AdminBusinessModule = () => {
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const isFraniAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";
  //
  //
  const [filterFieldState, setFilterFieldState] =
    useState<FilterFieldStateTypes>({
      companyName: "",
      idNumber: "",
      productType: "",
      pageIndex: 1,
      pageSize: 20,
    });
  //

  const [inputFields, setInputFields] = useState<InputFieldTypes>({
    companyName: "",
    idNumber: "",
    productType: "",
  });
  //
  const query = `pageIndex=${filterFieldState.pageIndex}&pageSize=${filterFieldState.pageSize}&name=${filterFieldState.companyName}&idNumber=${filterFieldState.idNumber}&type=${filterFieldState.productType}`;
  //
  const { data: corporateModuleData, isLoading: corporateModuleLoading } =
    api.useGetAllBusinessModulesQuery(query);
  //
  const corporateModule = corporateModuleData?.data
  // 
  const pageCount = corporateModuleData && Math.ceil(corporateModuleData.totalCount / 20)


  // functions
  const setInputField = (fieldName: keyof InputFieldTypes, fieldValue: any) => {
    setInputFields({
      ...inputFields,
      [fieldName]: fieldValue,
    });
  };
  //
  //
  const setFilterField = (
    fieldName: keyof FilterFieldStateTypes,
    fieldValue: any
  ) => {
    setFilterFieldState({
      ...filterFieldState,
      [fieldName]: fieldValue,
    });
  };
  //
  //
  const handlePageClick = (event: any) => {
    setFilterField("pageIndex", event.selected + 1);
  };
  //
  //
  const handleFilterButton = () => {
    setFilterFieldState({
      companyName: inputFields.companyName || "",
      idNumber: inputFields.idNumber || "",
      productType: inputFields.productType || "",
      pageIndex: 1,
      pageSize: 20,
    });
  };
  //
  //
  //
  const productTypeArr = [
    { id: 1, label: `${t("healthInsurance")}`, value: "health" },
    { id: 2, label: `${t("carInsurance")}`, value: "car" },
    { id: 3, label: `${t("propertyInsurance")}`, value: "property" },
    { id: 4, label: `${t("other")}`, value: "other" },
  ];

  return corporateModuleLoading ? (
    <Loader />
  ) : (
    <div>
      <CompanyItemsWrapper>
        <FilterWrapper>
          <Input
            id="companyName"
            label={t('companyName')}
            value={inputFields?.companyName || ""}
            onChange={(value) => setInputField("companyName", value)}
            width="fullWidth"
          />
          <Input
            id="idNumber"
            label={t('companyID')}
            value={inputFields?.idNumber || ""}
            onChange={(value) => setInputField("idNumber", value)}
            width="fullWidth"
          />
          <CustomDropdown
            title={t('productType')}
            options={productTypeArr}
            value={inputFields?.productType || ""}
            onChange={(value) => setInputField("productType", value.value)}
          />

          <Button
            style={{
              minWidth: "unset",
            }}
            fullWidth={true}
            text={t('clear')}
            btnStyleType="white"
            border={true}
            onClick={() =>
              setInputFields({
                companyName: "",
                idNumber: "",
                productType: "",
              })
            }
          />

          <Button
            style={{
              minWidth: "unset",
            }}
            fullWidth={true}
            text={t('filter')}
            btnStyleType="primary"
            onClick={handleFilterButton}
          />
        </FilterWrapper>
        <>

            <TotalCount>
              {t('searched')} <b>{corporateModule?.length}</b> {t('statement')}
            </TotalCount>
        </>
        <hr />
        {isFraniAdmin &&
          corporateModule?.map((item, index) => (
            <CompanyItem key={index}>
              <ItemBlock
                onClick={() => navigate(`/business-module/${item.id}`)}
              >
                <Item>
                  <div>
                    {item.type === "health"
                      ? t("healthInsurance")
                      : item.type === "car"
                      ? t("carInsurance")
                      : item.type === "property"
                      ? t("propertyInsurance")
                      : item.type === "other"
                      ? t("other")
                      : ""}
                  </div>
                  <div></div>
                </Item>

                <VerticalLine />

                <Item>
                  <div>{item.companyName}</div>
                  <div>{item.idNumber}</div>
                </Item>

                <VerticalLine />

                <Item>
                  <div>{item.firstname + " " + item.lastname}</div>
                  <div>{item.mobileNumber}</div>
                </Item>

                <VerticalLine />

                <Item>
                  <div>{item.email}</div>
                  <div></div>
                </Item>

                <VerticalLine />

                <Item>
                  <div>{t('createdDate')}:</div>
                  <div>
                    {dayjs(item?.createdAt).format("DD MMM YYYY | HH:mm")}
                  </div>
                </Item>
              </ItemBlock>
            </CompanyItem>
          ))}
      </CompanyItemsWrapper>
      {corporateModule && corporateModule.length >= 1 ? (
        <CustomPaginationWrapper>
          <ReactPaginate
            breakLabel={"..."}
            forcePage={0}
            nextLabel={<RightIcon />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount || 0}
            previousLabel={<LeftIcon />}
            // renderOnZeroPageCount={null}
          />
        </CustomPaginationWrapper>
      ) : null}
    </div>
  );
};

export default AdminBusinessModule;

const CompanyItemsWrapper = styled.div`
  margin-block: 50px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px 3px #64646411;
  box-shadow: 0px 0px 15px 3px #64646411;
  min-height: 400px;
`;

const CompanyItem = styled.div`
  display: flex;
  align-items: center;
`;
const VerticalLine = styled.div`
  width: 2px;
  min-width: 2px;
  height: 40px;
  background-color: ${Colors.primaryGrey};
  margin-inline: 20px;
  ${Responsive.mobile} {
    height: 2px;
    width: 100%;
    margin: 10px 0px;
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  white-space: nowrap;
  width: 25%;

  div {
    &:first-child {
      color: ${Colors.primaryBlack};
    }

    &:last-child {
      color: ${Colors.primaryGrey};
    }
    &:hover {
      color: ${Colors.white};
    }
  }

  &:last-child {
    color: ${Colors.primaryGrey};
    text-align: right;
    ${Responsive.mobile} {
      text-align: left;
    }
  }
`;

const ItemBlock = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  width: 100%;
  margin-block: 15px;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    background-color: ${Colors.primaryRed};
    transition: all 200ms ease;

    div {
      color: white;
    }

    ${VerticalLine} {
      background-color: ${Colors.white};
    }
  }

  ${Responsive.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CustomPaginationWrapper = styled.div`
  margin-block: 20px;
  display: grid;
  place-items: center;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-bottom: 0px;
    li {
      user-select: none;
      font-size: 14px;
      line-height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background-color: transparent;
      color: var(--text-color);
      height: 30px;
      width: 30px;
      cursor: pointer;
      margin-inline: 1px;

      a {
        width: 100%;
        text-align: center;
        display: grid;
        place-items: center;
      }

      &.disabled {
        opacity: 0.6;
      }

      &.break {
        a {
          height: 100%;
          margin-top: -8px;
          color: ${Colors.primaryRed};
        }
      }
      &.selected {
        background-color: ${Colors.primaryRed};
        color: ${Colors.white};
      }
    }
  }

  ${Responsive.mobile} {
    ul {
      li {
        margin: 0 5px;
        font-size: 14px;
        height: 24px;
        width: 24px;
      }
    }
  }
`;
const RightIcon = styled(DoubleRightIcon)`
  color: ${Colors.primaryRed};
  height: 15px;
`;
const LeftIcon = styled(DoubleRightIcon)`
  transform: rotate(180deg);
  color: ${Colors.primaryRed};
  height: 15px;
`;

const TotalCount = styled.div`
  font-size: 18px;
  color: ${Colors.secondaryBlack};
  text-align: center;
  margin-block: 20px;
  margin-inline: auto;
  padding-inline: 5px;
  border-bottom: 2px dashed ${Colors.primaryRed};
  width: fit-content;
  user-select: none;
  pointer-events: none;
  b {
    color: ${Colors.primaryRed};
  }
`;

const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  gap: 20px;
  grid-template-rows: 1fr 1fr;

  ${Responsive.mobile} {
    grid-template-columns: 1fr;
  }
`;
