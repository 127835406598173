import React from 'react'

const TransactionArrow = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.799" height="19.799" viewBox="0 0 19.799 19.799" {...props}>
      <g data-name="arrow more click" transform="rotate(135 7.85 9.05)">
        <g data-name="Group 52068">
          <path fill="CurrentColor" d="M0 14V0h14L9.785 4.215h-5.57v5.57L0 14z" data-name="Subtraction 3"></path>
        </g>
        <path fill="CurrentColor" d="M0 0h6.341L0 6.341z" data-name="Path 64577" transform="translate(6.403 6.403)"></path>
      </g>
    </svg>
  )
}

export default TransactionArrow
