import React from 'react'

function FraniIconWIthLine(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="988.083" height="691.492" viewBox="0 0 988.083 691.492" {...props}>
      <g data-name="Group 57034" transform="translate(-199.51 -284.666)">
        <g data-name="Group 57025" transform="rotate(175 590.068 279.784)">
          <path
            fill="#263238"
            d="M83.307 0c.025.051-10.623 13.294-16.652 19.3a69.258 69.258 0 01-13.6 10.763C49.32 32.252 45.635 35 43.381 38.8s-2.963 11.688 1.026 14.765C48.788 56.958 55.8 56.78 60.97 56.6a159.464 159.464 0 0019.31-2.162c6.141-.95 12.283-2.051 18.488-2.621 4.457-.418 9.56-.861 13.853.772a8.636 8.636 0 013.723 2.533 10.129 10.129 0 011.266 2.393 8.7 8.7 0 01.266 1.1c.3.886.57-3.52 0 .392a27.377 27.377 0 01-3.533 10.5c-5.191 8.61-14.663 13.929-23.552 17.943-12.941 5.838-27.77 11.4-42.142 11.308-13.663-.152-27.618-5.179-37.343-14.968a38.382 38.382 0 01-9.852-16.537 33.646 33.646 0 01-.962-4.622 27.734 27.734 0 01-.279-2.9c.089 2.6.139-.709.317-1.937a43.506 43.506 0 013.229-11.017c.608-1.418-.6-3.584 0-4.977C-2.9 57.426-.914 76.433 11.356 88.754a51.918 51.918 0 0026.592 13.853c12.422 2.533 24.059.1 35.9-3.8 15.4-5.065 37.14-12.334 42.889-29.454A34.672 34.672 0 00118 53.982c-1.19-7.433-9.877-7.6-15.841-7.37-11.634.557-23.059 3.444-34.643 4.588-6.521.646-24.756 2.9-25.68-7.205V48.377c.431-4.255 3.6-7.724 6.787-10.308 4.761-3.8 10.13-6.433 14.765-10.612A156.215 156.215 0 0083.332 5.014c.81-1.089 0-4.964 0-4.964z"
            data-name="Path 68845"
            transform="rotate(-158 123.032 50.472)"
          ></path>
          <path
            fill="#eceff1"
            d="M69.78.563s-8.342 17.8-14.246 25.655c-2.659 3.6-8.1 7.6-8.623 12.258-.139 1.178-.507 3.989.3 5.065 1.33 1.684 5.774 1.14 7.6 1.127 13.182-.1 26.288-1.874 39.5-1.266 7.6.355 21.527 1.089 22.35 11.245v-2.533c0 4.571-2.647 10.839-4.394 15.119a49.259 49.259 0 01-10.953 16.842 37.014 37.014 0 01-19.77 9.915c-9.763 1.633-19.463-.747-29.125-1.874-8.509-.988-17.108-1.545-25.541-3.1-5.85-1.089-12.106-2.71-16.6-6.838s-6.56-10.854-6.484-16.92c.152-10.751 2.381-21.59 3.558-32.278S9.657 10.9.248 3.666c.772.6-.836 4.331 0 4.964a18.994 18.994 0 016.331 7.6 20.631 20.631 0 011.14 4.609 14.659 14.659 0 01.139 1.661c-.089-1.925 0-.494 0 0-.1 1.52-.241 3.039-.393 4.559-1.266 12.334-3.66 24.655-3.8 37.09-.063 7.737 0 16.322 5.774 22.274 6.192 6.433 16.31 7.6 24.693 8.712 6.078.76 12.182 1.266 18.26 2.013 6.977.81 13.929 2.368 21.008 2.533a38.66 38.66 0 0029.24-11.896c6.04-6.471 9.9-14.74 12.232-23.211 1.418-5.154 3.28-12.473 1.266-17.728-6.825-17.411-68.481-1.19-69.089-9v4.977c.19-1.735 1.836-3.28 2.887-4.533 2.355-2.8 4.723-5.559 6.838-8.56C62.157 22.3 71.753 0 71.753 0z"
            data-name="Path 68846"
            transform="rotate(-158 116.583 48.051)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M23.457 117.58l56.744-3.678.04.002 37.18-2.405.028-.003 2.485-.17.013.005 8.33-.54.011.005 1.476-.101 5.442-.355.983-.068 3.747-.249.753-.037 1.656-.113-.496.755L94.662 182.8l-14.254-.514-24.976-.914-.085.007-26.727-.984-.072.012-18.402-.67 2.758-12.842h0l.07-.409 4.137-19.265.011-.063 4.548-21.23z"
            data-name="Path 2072"
          ></path>
          <path
            fill="#fe5f55"
            d="M50.669 0l32.557 29.488L0 26.585z"
            data-name="Path 68843"
            transform="translate(10.78 152.917)"
          ></path>
          <path
            fill="#fe5f55"
            d="M37.183 41.9L0 7.61 115.919 0z"
            data-name="Path 68844"
            transform="translate(24.485 110.127)"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M0 0L90.451 33.797"
            data-name="Line 256"
            transform="rotate(-158 65.097 82.199)"
          ></path>
          <path
            fill="#eceff1"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M96.402 0L0 114.295"
            data-name="Line 257"
            transform="rotate(-158 70.741 98.252)"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinejoin="round"
          strokeWidth="1"
          d="M1095.839 334.238c-185.976-67.809-364.942-96.1-316.891 122.722S4.748 255.06 243.622 976"
          data-name="Path 17174"
          opacity="0.3"
        ></path>
      </g>
    </svg>
  )
}

export default FraniIconWIthLine
