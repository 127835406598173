import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import Colors from "../../../assets/styles/Colors";
// @ts-ignore
import testImg from "../../../assets/img/frani-editor-background.png";

const QuillEditor: React.FC<{
  value?: string;
  label?: string;
  onChange: (value: string) => void;
  error?: boolean;
  errorText?: string;
}> = ({ value, label, onChange, error = false, errorText }) => {
  //
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      ["code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ size: [] }], // Font size controller
      // [{ font: [] }], // Font familyr
      [{ align: [] }],
      ["link", "image"],
      [{ color: [] }, { background: [] }],
      ["clean"],
      ["code-block"],
      ["formula"],
      ["video"],
    ],
  };
  //
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "script",
    "size",
    "font",
    "align",
    "link",
    "image",
    "color",
    "background",
    "clean",
    "code-block",
    "formula",
    "video",
  ];

  return (
    <Wrapper>
      {label ? <LabelStyle $error={error}>{label}</LabelStyle> : null}

      <Content>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={value}
          // onChange={(content) => onChange(content)}
          onChange={(content, delta, source, editor) => {
            if (source === "user") {
              onChange(content);
            }
          }}
        />
      </Content>

      {error === true ? (
        <ErrorMessage $error={error}>{errorText}</ErrorMessage>
      ) : null}
    </Wrapper>
  );
};
//
const ErrorMessage = styled.div<{ $error: boolean }>`
  width: inherit;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: ${({ $error }) =>
    $error === true ? `${Colors.primaryRed}` : `${Colors.primaryGrey}`};
  margin-top: 5px;
`;
const LabelStyle = styled.label<{ $error: boolean }>`
  width: inherit;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  text-align: left;
  padding-right: 25px;
  color: ${({ $error }) =>
    $error === true ? `${Colors.primaryRed}` : `${Colors.primaryGrey}`};
`;
const Content = styled.div`
  background-color: ${Colors.white};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .quill {
    .ql-formats {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border-radius: 10px;
      padding: 5px;
      margin-block: 3px;
    }

    .ql-container.ql-snow {
      background-image: url(${testImg});
      background-repeat: space;
    }

    .ql-toolbar.ql-snow {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border: unset;
      box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
        rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    }

    .ql-container.ql-snow {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border: unset;
      box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
        rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    }

    .ql-editor.ql-blank {
      min-height: 200px;
      height: 100%;
    }
    .ql-editor {
      min-height: 200px;
      height: 100%;
    }
  }
`;
const Wrapper = styled.div``;
//
export default QuillEditor;
