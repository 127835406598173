import React from 'react'

function CarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
      <g data-name="Group 57065" transform="translate(-576 -614)">
        <g data-name="Group 57068">
          <path
            fill="#ffefed"
            d="M28 0A28 28 0 110 28 28 28 0 0128 0z"
            data-name="Path 68860"
            transform="translate(576 614)"
          ></path>
        </g>
        <g data-name="Group 57066" transform="translate(-959 -690)">
          <path
            fill="#263238"
            d="M-.6-.6h4.911v1.808a2.455 2.455 0 01-4.911 0zM3.111.6H.6v.608a1.255 1.255 0 002.511 0z"
            data-name="Rectangle 8631"
            transform="translate(1567.289 1336.929)"
          ></path>
          <path
            fill="none"
            d="M8.3 7.547H.355a3.5 3.5 0 00-.763.195 3.025 3.025 0 00-1.61 2.778v3.037c0 1.217.7 1.443 1.308 1.443h9.8"
            data-name="Path 67990"
            transform="translate(1555.018 1321.93)"
          ></path>
          <path
            fill="none"
            d="M-2.018 7.547h8.734a3.2 3.2 0 012.373 2.973v3.037c0 1.217-.7 1.443-1.308 1.443h-9.8"
            data-name="Path 67991"
            transform="translate(1563.911 1321.93)"
          ></path>
          <path
            fill="#263238"
            d="M9.089 14.174H-2.618V9.212A3.331 3.331 0 01-1.172 6.33a3.921 3.921 0 011.031-.5l.979-3.96C1.157.667 2.116.057 3.689.057h5.4v1.2h-5.4c-1.267 0-1.562.44-1.69.917L.842 6.847l-.391.063a2.681 2.681 0 00-.974.43 2.166 2.166 0 00-.895 1.872v3.762H9.089z"
            data-name="Path 67983"
            transform="translate(1555.018 1323.343)"
          ></path>
          <path
            fill="#263238"
            d="M9.689 14.174H-2.018v-1.2H8.489V9.212A2.535 2.535 0 006.567 6.9l-.349-.088-.084-.349-1.062-4.289c-.128-.477-.422-.917-1.69-.917h-5.4v-1.2h5.4c1.572 0 2.531.61 2.851 1.813v.01l.987 3.974a4.518 4.518 0 011.039.568 3.356 3.356 0 011.43 2.791z"
            data-name="Path 67984"
            transform="translate(1563.911 1323.343)"
          ></path>
          <path
            fill="#263238"
            d="M15.867 10.32H-.018v-1.2h15.885z"
            data-name="Path 67985"
            transform="translate(1555.059 1319.957)"
          ></path>
          <path
            fill="#263238"
            d="M4.065 1.257H-.018v-1.2h4.083z"
            data-name="Path 67986"
            transform="translate(1555.078 1332.942)"
          ></path>
          <path
            fill="#263238"
            d="M4.065 1.257H-.018v-1.2h4.083z"
            data-name="Path 67987"
            transform="translate(1566.86 1332.942)"
          ></path>
          <path
            fill="#263238"
            d="M-.6-.6h4.911v1.808a2.455 2.455 0 01-4.911 0zM3.111.6H.6v.608a1.255 1.255 0 002.511 0z"
            data-name="Rectangle 8632"
            transform="translate(1555 1336.937)"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default CarIcon
