import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import { toast } from "react-toastify";
import styled from "styled-components";
import Responsive from "../../../../../assets/config/Responsive";
import CloseIcon from "../../../../../assets/icons/JSX/CloseIcon";
import Colors from "../../../../../assets/styles/Colors";
import Checkbox from "../../../../../components/Checkbox";
import Loader from "../../../../../components/Loader";
import NotFound404 from "../../../../../components/NotFound404";
import {
  EnabledLanguage,
  Product,
  TravelAbroadAreaOptions,
  TravelProduct,
} from "../../../../../domain/types";
import { useAppSelector } from "../../../../../store";
import {
  default as AdminButton,
  default as Button,
} from "../../../../admin_components/AdminButton";
import Input from "../../../../admin_components/AdminInput";
import BaseModal from "../../../../../components/modals/BaseModal";
import { useTranslation } from "react-i18next";
dayjs.locale("ka");
//
//
//
//
const EditAbroadProductFieldsTab: React.FC<{
  travelProduct?: TravelProduct;
  language: EnabledLanguage;
  isLoading: boolean;
  setTranslatedFieldOnProduct: any;
  setTravelProductField: (
    fieldName: keyof TravelProduct,
    fieldValue: any
  ) => void;
  setMainProductField: (fieldName: keyof Product, fieldValue: any) => void;
  saveTab: any;
  approveProductChanges: any;
  rejectProductChanges: any;
}> = ({
  travelProduct,
  language,
  setTranslatedFieldOnProduct,
  setTravelProductField,
  setMainProductField,
  isLoading,
  saveTab,
  approveProductChanges,
  rejectProductChanges,
}) => {
  //
  console.log("travelProduct", travelProduct);
  //
  const { t } = useTranslation();
  //
  const auth = useAppSelector((data) => data.auth);
  const isAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";
  //
  const [warningAlertRange, setWarningAlertRange] = useState<string | number>(
    ""
  );
  //
  const [emailsOfCompanyUsers, setEmailsOfCompanyUsers] = useState<
    string[] | []
  >(travelProduct?.mainProduct?.emails || []);
  //
  const [enteredEmail, setEnteredEmail] = useState<string | undefined>("");
  //
  //
  // set exist company user

  const removeLeadingZeros = (input: string) => {
    const trimmedString = input.replace(/^0+/, "");
    const numberValue = parseInt(trimmedString, 10);

    return numberValue;
  };

  useEffect(() => {
    if (travelProduct?.mainProduct?.emails) {
      setEmailsOfCompanyUsers(travelProduct?.mainProduct?.emails);
    }
    //
    return () => {};
  }, [travelProduct?.mainProduct?.emails]);
  //
  //
  useEffect(() => {
    if (emailsOfCompanyUsers) {
      setMainProductField("emails", emailsOfCompanyUsers);
    }
    //
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsOfCompanyUsers]);
  //
  //
  const [openWarningRangeModal, setOpenWarningRangeModal] = useState<{
    isOpen: boolean;
    index: number;
  }>({ isOpen: false, index: 0 });
  //
  //
  const _CloseWarningRangeModal = (isOpen: boolean, index: number) => {
    setOpenWarningRangeModal({ isOpen, index });
  };
  //
  //
  return isLoading ? (
    <Loader />
  ) : !travelProduct ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <ContentBox>
        <Column style={{ padding: "20px 10px 20px 20px" }} $widthPercent={70}>
          <div>
            <h6>{t("companyUserEmails")}</h6>
            <div
              style={{
                position: "relative",
              }}
            >
              <Input
                id="email"
                label={""}
                type="email"
                placeholder="example@gmail.com"
                value={enteredEmail}
                name="email"
                onChange={(value) => setEnteredEmail(value)}
                width={"fullWidth"}
                style={{ marginBottom: "24px" }}
              />

              <AdminButton
                style={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
                size="medium"
                text={t("add")}
                fullWidth={false}
                btnStyleType="primary"
                onClick={() => {
                  if (
                    enteredEmail &&
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      enteredEmail
                    )
                  ) {
                    setEmailsOfCompanyUsers((prevNames) => [
                      ...prevNames,
                      enteredEmail,
                    ]);
                    //
                    setEnteredEmail("");
                    //
                  } else {
                    toast.error(`${t('validEmail')}`);
                  }
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {emailsOfCompanyUsers?.map(
                (email_item: string, email_index: number) => {
                  return (
                    <div
                      style={{
                        padding: "12px",
                        margin: "10px",
                        backgroundColor: Colors.Sazerac,
                        borderRadius: "6px",
                        position: "relative",
                      }}
                      key={email_index}
                    >
                      <span>{email_item}</span>
                      <CloseIcon
                        onClick={() =>
                          emailsOfCompanyUsers && [
                            setEmailsOfCompanyUsers(
                              emailsOfCompanyUsers.filter(
                                (x) => x !== email_item
                              )
                            ),
                          ]
                        }
                        style={{
                          color: Colors.primaryRed,
                          height: "17px",
                          width: "17px",
                          position: "absolute",
                          top: "-6px",
                          right: "-6px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  );
                }
              )}
            </div>
            <br />
          </div>
          <br />

          <Checkbox
            id="integrate-product"
            label={t("integrated")}
            defaultChecked={travelProduct?.mainProduct?.integrated}
            onChange={(checked) => {
              if (checked === true) {
                setMainProductField("integrated", true);
              } else {
                setMainProductField("integrated", false);
              }
            }}
          />

          <br />
          <Row>
            <Column $widthPercent={50} style={{ padding: "0px" }}>
              <Block>
                {/* <Text>დასახელება</Text> */}
                <Input
                  id=""
                  label={t("title")}
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  value={
                    travelProduct.mainProduct.translations?.[language]?.title ||
                    ""
                  }
                  required={false}
                  error={false}
                  onChange={(value) =>
                    setTranslatedFieldOnProduct("title", language, value)
                  }
                />
              </Block>
            </Column>
            <Column $widthPercent={50} style={{ padding: "0px" }}>
              <Block>
                {/* <Text>პროდუქტის ტიპი</Text> */}
                <Input
                  id=""
                  label={t("productType")}
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  value={t("travelInsurance")}
                  required={false}
                  error={false}
                  onChange={(value) => {}}
                  disabled={true}
                />
              </Block>
            </Column>
          </Row>
          <Block>
            <Title>{t("ageGroups")}</Title>
            <AgeGroupDiv>
              <Row>
                <Col>
                  <Text>{t("fromAge")}</Text>
                </Col>
                <Col>
                  <Text>{t("upToAge")}</Text>
                </Col>
                <Col>
                  <Text>{t("minPremiumInsured")}</Text>
                </Col>
              </Row>
              <AgeGroupScrollView>
                {travelProduct.minTotalPremiumInfos?.map((info, infoIndex) => (
                  <AgeGroupBlock
                    // key={`${ageGroup.minAge}-${ageGroup.maxAge}`}
                    key={infoIndex}
                  >
                    <Row>
                      <Col>
                        <Input
                          id={`minTotalPremiumInfos-${infoIndex}-minAge`}
                          width="fullWidth"
                          length={3}
                          // label="წლის ასაკიდან (ჩათვლით)"
                          label=""
                          value={info.minAge}
                          // style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                          onChange={(value) => {
                            const listWithUpdatedMinAge =
                              travelProduct.minTotalPremiumInfos.map((g, i) =>
                                i === infoIndex ? { ...g, minAge: value } : g
                              );
                            setTravelProductField(
                              "minTotalPremiumInfos",
                              listWithUpdatedMinAge
                            );
                          }}
                        />
                      </Col>
                      <Col>
                        <Input
                          id={`minTotalPremiumInfos-${infoIndex}-maxAge`}
                          width="fullWidth"
                          length={3}
                          // label="წლამდე (ჩათვლით)"
                          label=""
                          value={info.maxAge}
                          // style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                          onChange={(value) => {
                            const listWithUpdatedMaxAge =
                              travelProduct.minTotalPremiumInfos.map((g, i) =>
                                i === infoIndex ? { ...g, maxAge: value } : g
                              );
                            setTravelProductField(
                              "minTotalPremiumInfos",
                              listWithUpdatedMaxAge
                            );
                          }}
                        />
                      </Col>
                      <Col>
                        <div style={{ display: "flex", paddingRight: "5px" }}>
                          <Input
                            id={`minTotalPremiumInfos-${infoIndex}-minTotalPremium`}
                            width="fullWidth"
                            length={3}
                            // label="GEL: მინიმალური პრემია 1 დაზღვეულზე"
                            label=""
                            value={info.minTotalPremium}
                            // style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                            onChange={(value) => {
                              const listWithUpdatedMinTotalPremium =
                                travelProduct.minTotalPremiumInfos.map((g, i) =>
                                  i === infoIndex
                                    ? { ...g, minTotalPremium: value }
                                    : g
                                );
                              setTravelProductField(
                                "minTotalPremiumInfos",
                                listWithUpdatedMinTotalPremium
                              );
                            }}
                          />

                          <Button
                            btnStyleType="secondary"
                            icon={false}
                            fullWidth={false}
                            disabled={false}
                            // style={{ display: 'inline-block' }}
                            onClick={() => {
                              setTravelProductField(
                                "minTotalPremiumInfos",
                                travelProduct.minTotalPremiumInfos.filter(
                                  (g, i) => i !== infoIndex
                                )
                              );
                            }}
                            text={t("delete")}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <AgeInputBlock></AgeInputBlock> */}
                  </AgeGroupBlock>
                ))}
              </AgeGroupScrollView>
              <br />
              <Button
                btnStyleType="secondary"
                size="medium"
                icon={false}
                fullWidth={true}
                disabled={false}
                onClick={() => {
                  const maxUsedAge = travelProduct.minTotalPremiumInfos.reduce(
                    (c, n) => Math.max(c, n.maxAge),
                    0
                  );
                  const newMinAge = maxUsedAge + 1;
                  const listWithAddedItem = [
                    ...travelProduct.minTotalPremiumInfos,
                    {
                      minAge: newMinAge,
                      maxAge: newMinAge + 1,
                      minTotalPremium: 0,
                      coefficientsByTravelArea: TravelAbroadAreaOptions.map(
                        (area) => ({
                          area,
                          coefficient: 1,
                        })
                      ),
                    },
                  ];
                  setTravelProductField(
                    "minTotalPremiumInfos",
                    listWithAddedItem
                  );
                }}
                text={t("add")}
              />
            </AgeGroupDiv>
          </Block>
          {/* <Block>
            <Text>ასაკობრივი ჯგუფები</Text>
            <div>
              {ageGroups.map((ageGroup, ageGroupIndex) => (
                <div
                  // key={`${ageGroup.minAge}-${ageGroup.maxAge}`}
                  key={ageGroupIndex}
                  style={{ flexDirection: 'row', alignItems: 'center' }}
                  style={{ padding: 10, borderRadius: 6, border: '1px solid #cacaca', marginBottom: 5 }}
                >
                  <Input
                    width="auto"
                    length={3}
                    label="წლის ასაკიდან (ჩათვლით)"
                    value={ageGroup.minAge}
                    style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                    onChange={(value) => {
                      setAgeGroups(ageGroups.map((g, i) => (i === ageGroupIndex ? { ...g, minAge: value } : g)))
                    }}
                  />
                  <Input
                    width="auto"
                    length={3}
                    label="წლამდე (ჩათვლით)"
                    value={ageGroup.maxAge}
                    style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                    onChange={(value) => {
                      setAgeGroups(ageGroups.map((g, i) => (i === ageGroupIndex ? { ...g, maxAge: value } : g)))
                    }}
                  />
                  <Input
                    width="auto"
                    length={3}
                    label="GEL: მინიმალური პრემია 1 დაზღვეულზე"
                    value={ageGroup.maxAge}
                    style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setAgeGroups(ageGroups.filter((g, i) => i !== ageGroupIndex))
                    }}
                  >
                    წაშლა
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => {
                  const maxUsedAge = ageGroups.reduce((c, n) => Math.max(c, n.maxAge), 0)
                  const newMinAge = maxUsedAge + 1
                  setAgeGroups([
                    ...ageGroups,
                    {
                      minAge: newMinAge,
                      maxAge: newMinAge + 1,
                    },
                  ])
                }}
              >
                + დაამატე
              </button>
            </div>
          </Block> */}
          {/* <Block>
            <Text>მინიმალური პრემია 1 დაზღვეულზე</Text>
            <div>
              {travelProduct.minTotalPremiumInfos.map((info, infoIndex) => (
                <div key={`${info.minAge}-${info.maxAge}`} style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Input
                    width="auto"
                    length={3}
                    label="წლის ასაკიდან"
                    value={info.minAge}
                    style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                  />
                  <Input
                    width="auto"
                    length={3}
                    label="წლის ჩათვლით"
                    value={info.maxAge}
                    style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                  />{' '}
                  = &nbsp;
                  <Input
                    width="auto"
                    length={3}
                    label="GEL"
                    value={info.minTotalPremium}
                    style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                  />
                  <button type="button">ჩასწორება</button>
                </div>
              ))}
              <button type="button">+ დაამატე</button>
            </div>
          </Block> */}
          <Block>
            <Title>{t("policyNumber")}</Title>
            <PolicyNumberGroupDiv>
              <Row>
                <Col>
                  <Text>{`(${t("prefix")})`}</Text>
                </Col>
                <Col>
                  <Text>{t("from")}</Text>
                </Col>
                <Col>
                  <Text>{t("to")}</Text>
                </Col>
                <Col>
                  <Text>{`(${t("suffix")})`}</Text>
                </Col>
                <Col>
                  <Text>{t("utilized")}</Text>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <PolicyNumberGroupScrollView>
                {travelProduct.mainProduct.policyNumberRanges.map(
                  (range, rangeIndex) => (
                    <PolicyNumberBlock key={range.id}>
                      {/*  alert range modal */}
                      <BaseModal
                        isOpen={
                          openWarningRangeModal.isOpen === true &&
                          openWarningRangeModal.index === rangeIndex
                            ? true
                            : false
                        }
                        onClose={() =>
                          _CloseWarningRangeModal(false, rangeIndex)
                        }
                      >
                        {/*  */}
                        <WarningAlertRanges>
                          {range?.warningAlertRanges?.map(
                            (warningRange, warningRangeIndex) => (
                              <WarningRangeDiv key={warningRangeIndex}>
                                <span>{warningRange}</span>
                                <button
                                  onClick={() => {
                                    const listWithUpdatedPolicyWarningAlertRange =
                                      travelProduct.mainProduct.policyNumberRanges.map(
                                        (g, i) =>
                                          i === rangeIndex
                                            ? {
                                                ...g,
                                                warningAlertRanges:
                                                  g.warningAlertRanges.filter(
                                                    (x) => x !== warningRange
                                                  ),
                                              }
                                            : g
                                      );
                                    setMainProductField(
                                      "policyNumberRanges",
                                      listWithUpdatedPolicyWarningAlertRange
                                    );
                                  }}
                                >
                                  X
                                </button>
                              </WarningRangeDiv>
                            )
                          )}
                        </WarningAlertRanges>
                        <hr />

                        <Input
                          width="fullWidth"
                          id="email-warning-range-input"
                          label={t("numbersOfEmails")}
                          value={warningAlertRange || ""}
                          onChange={(value) =>
                            setWarningAlertRange(parseInt(value))
                          }
                        />

                        <br />

                        <AdminButton
                          fullWidth={true}
                          text={t("add")}
                          onClick={() => {
                            const listWithUpdatedPolicyWarningAlertRange =
                              travelProduct.mainProduct.policyNumberRanges.map(
                                (g, i) => {
                                  //
                                  if (i === rangeIndex) {
                                    //
                                    const currentRanges =
                                      g.warningAlertRanges === null
                                        ? []
                                        : [...g.warningAlertRanges];

                                    const editedRange = [
                                      ...currentRanges,
                                      warningAlertRange,
                                    ];
                                    //
                                    return {
                                      ...g,
                                      warningAlertRanges:
                                        editedRange || g.warningAlertRanges,
                                    };
                                    //
                                  } else {
                                    //
                                    return g;
                                    //
                                  }
                                  //
                                }
                              );
                            //
                            setWarningAlertRange("");
                            //
                            setMainProductField(
                              "policyNumberRanges",
                              listWithUpdatedPolicyWarningAlertRange
                            );
                          }}
                        />
                        {/*  */}
                      </BaseModal>
                      {/*  alert range modal */}

                      <Row>
                        <Col>
                          <Input
                            id={`policy-numbers-prefix`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.policyPrefix}
                            onChange={(value) => {
                              const listWithUpdatedPolicyPrefix =
                                travelProduct.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, policyPrefix: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedPolicyPrefix
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            id={`policy-numbers-minPolicyNumber-${rangeIndex}`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.minPolicyNumber}
                            onChange={(value) => {
                              const listWithUpdatedMinPolicyNumber =
                                travelProduct.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, minPolicyNumber: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedMinPolicyNumber
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            id={`policy-numbers-maxPolicyNumber-${rangeIndex}`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.maxPolicyNumber}
                            onChange={(value) => {
                              const listWithUpdatedMaxPolicyNumber =
                                travelProduct.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, maxPolicyNumber: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedMaxPolicyNumber
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            id={`policy-numbers-suffix`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.policySuffix}
                            onChange={(value) => {
                              const listWithUpdatedPolicySuffix =
                                travelProduct.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, policySuffix: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedPolicySuffix
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {range.usedPolicyNumber
                              ? `${range.policyPrefix ?? ""}${
                                  range.usedPolicyNumber
                                }${range.policySuffix ?? ""}`
                              : `${t("none")}`}
                          </div>
                        </Col>

                        <Col>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            <Button
                              btnStyleType="transparent"
                              size="medium"
                              border={true}
                              icon={false}
                              fullWidth={false}
                              disabled={false}
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                              }}
                              text={t("ranges")}
                              onClick={() =>
                                setOpenWarningRangeModal({
                                  isOpen: true,
                                  index: rangeIndex,
                                })
                              }
                            />

                            <Button
                              btnStyleType="secondary"
                              size="medium"
                              icon={false}
                              fullWidth={false}
                              disabled={false}
                              style={{ display: "inline-block" }}
                              onClick={() => {
                                setMainProductField(
                                  "policyNumberRanges",
                                  travelProduct.mainProduct.policyNumberRanges.filter(
                                    (g, i) => i !== rangeIndex
                                  )
                                );
                              }}
                              text={t("delete")}
                            />
                          </div>
                        </Col>
                      </Row>
                    </PolicyNumberBlock>
                  )
                )}
              </PolicyNumberGroupScrollView>
              <br />
              <Button
                btnStyleType="secondary"
                size="medium"
                icon={false}
                fullWidth={true}
                disabled={false}
                onClick={() => {
                  const maxUsedPolicyNumber =
                    travelProduct.mainProduct.policyNumberRanges.reduce(
                      (c, n) =>
                        Math.max(c, removeLeadingZeros(n.maxPolicyNumber)),
                      0
                    );
                  const lastUsedPolicyPrefix = [
                    ...travelProduct.mainProduct.policyNumberRanges,
                  ].reverse()[0]?.policyPrefix;

                  const lastUsedPolicySuffix = [
                    ...travelProduct.mainProduct.policyNumberRanges,
                  ].reverse()[0]?.policySuffix;

                  const newMinPolicyNumber = maxUsedPolicyNumber + 1;

                  setMainProductField("policyNumberRanges", [
                    ...travelProduct.mainProduct.policyNumberRanges,
                    {
                      id:
                        -1 *
                        (travelProduct.mainProduct.policyNumberRanges.length +
                          1),
                      minPolicyNumber: newMinPolicyNumber.toLocaleString(),
                      maxPolicyNumber: (
                        newMinPolicyNumber + 1
                      ).toLocaleString(),
                      policyPrefix: lastUsedPolicyPrefix,
                      policySuffix: lastUsedPolicySuffix,
                    },
                  ]);
                }}
                text={t("addPolicyNumber")}
              />
            </PolicyNumberGroupDiv>
          </Block>
          <Block>
            <Title>{t("daysRange")}</Title>
            <PolicyNumberGroupDiv>
              <Row>
                <Col>
                  <Text>{t("fromDay")}</Text>
                </Col>
                <Col>
                  <Text>{t("uptToDay")}</Text>
                </Col>
                <Col></Col>
              </Row>
              <PolicyNumberGroupScrollView>
                {travelProduct.dayGroupInfos?.map((dayGroup, groupIndex) => (
                  <DayRangeBlock
                    // key={`${dayGroup.minDay}-${dayGroup.maxDay}`}
                    key={groupIndex}
                  >
                    <Row>
                      <Col>
                        <Input
                          id={`dayGroups-${groupIndex}-minDay`}
                          width="auto"
                          length={3}
                          // label="დღიდან (ჩათვლით)"
                          label=""
                          value={dayGroup.minDay}
                          style={{
                            display: "inline-flex",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                          }}
                          onChange={(value) => {
                            const listWithUpdatedMinDay =
                              travelProduct.dayGroupInfos.map((g, i) =>
                                i === groupIndex ? { ...g, minDay: value } : g
                              );
                            setTravelProductField(
                              "dayGroupInfos",
                              listWithUpdatedMinDay
                            );
                          }}
                        />
                      </Col>

                      <Col>
                        <Input
                          id={`dayGroups-${groupIndex}-minDay`}
                          width="auto"
                          length={3}
                          // label="დღემდე (ჩათვლით)"
                          label=""
                          value={dayGroup.maxDay}
                          style={{
                            display: "inline-flex",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                          }}
                          onChange={(value) => {
                            const listWithUpdatedMaxDay =
                              travelProduct.dayGroupInfos.map((g, i) =>
                                i === groupIndex ? { ...g, maxDay: value } : g
                              );
                            setTravelProductField(
                              "dayGroupInfos",
                              listWithUpdatedMaxDay
                            );
                          }}
                        />
                      </Col>

                      <Col>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "5px",
                          }}
                        >
                          <Button
                            btnStyleType="secondary"
                            size="medium"
                            icon={false}
                            fullWidth={false}
                            disabled={false}
                            style={{ display: "inline-block" }}
                            onClick={() => {
                              setTravelProductField(
                                "dayGroupInfos",
                                travelProduct.dayGroupInfos.filter(
                                  (g, i) => i !== groupIndex
                                )
                              );
                            }}
                            text={t("delete")}
                          />
                        </div>
                      </Col>
                    </Row>
                  </DayRangeBlock>
                ))}
              </PolicyNumberGroupScrollView>
              <br />
              <Button
                btnStyleType="secondary"
                size="medium"
                icon={false}
                fullWidth={true}
                disabled={false}
                onClick={() => {
                  const maxUsedDay = travelProduct.dayGroupInfos.reduce(
                    (c, n) => Math.max(c, n.maxDay),
                    0
                  );
                  const newMinDay = maxUsedDay + 1;
                  const listWithAddedItem = [
                    ...travelProduct.dayGroupInfos,
                    {
                      minDay: newMinDay,
                      maxDay: newMinDay + 1,
                      coefficient: 0,
                    },
                  ];
                  setTravelProductField("dayGroupInfos", listWithAddedItem);
                }}
                text={t("add")}
              />
            </PolicyNumberGroupDiv>
          </Block>
        </Column>
        <Column $widthPercent={30} style={{ padding: "20px 20px 20px 10px" }}>
          <Block>
            <Text>{t("productMarketingPrice")}</Text>
            <Input
              id=""
              label=""
              placeholder=""
              type="text"
              width="fullWidth"
              value={travelProduct.productMinPremium || ""}
              required={false}
              error={false}
              onChange={(value) =>
                setTravelProductField("productMinPremium", value)
              }
            />
          </Block>
          <h6
            style={{
              color: Colors.primaryBlack,
              borderLeft: `1px solid ${Colors.primaryGrey}`,
              paddingLeft: "5px",
            }}
          >
            {t("multipleMarketingPrice")}
          </h6>
          <br />
          <Block>
            <Text>{t("singleMarketingPrice")}</Text>
            <Input
              id=""
              label=""
              type="text"
              placeholder=""
              value={travelProduct.singleTravelMinPremium}
              onChange={(value) =>
                setTravelProductField("singleTravelMinPremium", value)
              }
              width={"fullWidth"}
            />
          </Block>
          <Block>
            <Text>{t("multiMarketingPrice")}</Text>
            <Input
              id=""
              label=""
              type="text"
              placeholder=""
              value={travelProduct.multiTravelMinPremium}
              onChange={(value) =>
                setTravelProductField("multiTravelMinPremium", value)
              }
              width={"fullWidth"}
            />
          </Block>

          <Block>
            <Text>{t("productRealizationDate")}</Text>
            <Input
              id=""
              label=""
              type="text"
              placeholder=""
              value={travelProduct.limitBeforeRealization}
              onChange={(value) =>
                setTravelProductField("limitBeforeRealization", value)
              }
              width={"fullWidth"}
            />
          </Block>

          <Block>
            <Text>{t("minimumInsuranceDays")}</Text>
            <Input
              id=""
              label=""
              type="text"
              placeholder=""
              value={travelProduct.minimumInsuranceDays}
              onChange={(value) =>
                setTravelProductField("minimumInsuranceDays", value)
              }
              width={"fullWidth"}
            />
          </Block>

          <Block>
            <Text>{t("maximumInsuranceDays")}</Text>
            <Input
              id=""
              label=""
              type="text"
              placeholder=""
              value={travelProduct.maximumInsuranceDays}
              onChange={(value) =>
                setTravelProductField("maximumInsuranceDays", value)
              }
              width={"fullWidth"}
            />
          </Block>

          <Block>
            <Text>{t("companyFee")}</Text>
            <Input
              id=""
              label=""
              type="number"
              placeholder=""
              value={travelProduct?.mainProduct?.commission}
              onChange={(value) => setMainProductField("commission", value)}
              width={"fullWidth"}
            />
          </Block>

          <Block>
            <Text>{t("createdDate")}</Text>
            <div>
              {dayjs(travelProduct.createdAt).format("DD MMMM YYYY HH:mm:ss")}
            </div>
          </Block>
          <Block>
            <Text>{t("lastUpdatedAt")}</Text>
            <div>
              {dayjs(travelProduct.updatedAt).format("DD MMMM YYYY HH:mm:ss")}
            </div>
          </Block>
          <Block>
            <Text>{t("status")}</Text>
            <div>{travelProduct.mainProduct.status}</div>
          </Block>
          {travelProduct.mainProduct.activationDate && (
            <Block>
              <Text>{t("lastActivationDate")}:</Text>
              <div>
                {dayjs(travelProduct.mainProduct.activationDate).format(
                  "DD MMMM YYYY HH:mm:ss"
                )}
              </div>
            </Block>
          )}
          {travelProduct.mainProduct.deactivationDate && (
            <Block>
              <Text>{t("deactivationDate")}</Text>
              <div>
                {dayjs(travelProduct.mainProduct.deactivationDate).format(
                  "DD MMMM YYYY HH:mm:ss"
                )}
              </div>
            </Block>
          )}
          <Button
            btnStyleType="primary"
            icon={false}
            fullWidth={true}
            style={{ marginBottom: 16 }}
            disabled={false}
            onClick={saveTab}
            text={t("save")}
          />
          {isAdmin && travelProduct.mainProduct.status === "draft" ? (
            <Button
              btnStyleType="primary"
              icon={false}
              fullWidth={true}
              style={{ marginBottom: 16 }}
              disabled={false}
              onClick={approveProductChanges}
              text={t("publish")}
            />
          ) : null}
          {isAdmin ? (
            <Button
              btnStyleType="primary"
              icon={false}
              fullWidth={true}
              style={{ marginBottom: 16 }}
              disabled={false}
              onClick={rejectProductChanges}
              text={
                travelProduct.mainProduct.status === "draft"
                  ? `${t("rejectChanges")}`
                  : `${t("moveToDraft")}`
              }
            />
          ) : null}
        </Column>
      </ContentBox>
    </Wrapper>
  );
};
//
//
// styles
const WarningAlertRanges = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const WarningRangeDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  width: fit-content;
  background-color: ${Colors.Sazerac};
  margin-right: 10px;
  margin-bottom: 10px;

  button {
    color: ${Colors.primaryRed};
    background-color: unset;
    margin-left: 10px;
    outline: none;
    line-height: none;
    border: none;
    padding: 2px 5px;

    :hover {
      color: ${Colors.Black};
    }
  }
`;
const PolicyNumberGroupScrollView = styled.div`
  min-height: 150px;
  max-height: 290px;
  overflow-y: scroll;
  /*  */

  .policy-number-class {
    input {
      min-width: 53px !important;
      width: 70% !important;
    }
  }
`;
const AgeGroupScrollView = styled.div`
  min-height: 150px;
  max-height: 290px;
  overflow-y: scroll;
`;
const AgeGroupDiv = styled.div`
  input {
    text-align: center;
  }
  /* border: 1px solid red; */
  box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  -webkit-box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  -moz-box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  padding: 10px;
`;
const PolicyNumberGroupDiv = styled.div`
  input {
    text-align: center;
  }
  /* border: 1px solid red; */
  box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  -webkit-box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  -moz-box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  padding: 10px;
`;
const DayRangeBlock = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 5px;
  padding-block: 5px;
`;
const PolicyNumberBlock = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 5px;
  padding-block: 5px;
`;
const AgeGroupBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-block: 5px;
  padding-block: 5px;
`;
const Text = styled.div`
  margin-bottom: 10px;
  color: ${Colors.primaryGrey};
  font-size: 14px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 10px;
  /*  */
  > div {
    flex-basis: 50%;
  }
`;
const Title = styled.div`
  margin-bottom: 10px;
  color: ${Colors.primaryRed};
  font-size: 16px;
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  ${Responsive.tablet} {
    flex-direction: column;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const Column = styled.div<{ $widthPercent?: number }>`
  padding: 20px 15px;
  ${({ $widthPercent = 50 }) =>
    $widthPercent ? `width: calc(${$widthPercent}% - 15px);` : ""}

  ${Responsive.tablet} {
    width: 100%;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export default EditAbroadProductFieldsTab;
