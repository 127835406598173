import React from "react";
import { EnabledLanguage, supportedLocales } from "../../domain/types";
import styled from "styled-components";
import Colors from "../../assets/styles/Colors";
import { useAppDispatch, useAppSelector } from "../../store";
import { AppState, setEditedlanguage } from "../../store/appSlice";
//
//
const AdminLangSwicher: React.FC<{
  onChange?: (value: any) => void;
  style?: React.CSSProperties;
}> = ({ onChange, style }) => {
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const dispatch = useAppDispatch();
  //
  return (
    <Select
      style={style}
      value={field_Language}
      onChange={(event) => {
        onChange?.(event.target.value as EnabledLanguage);
        dispatch(setEditedlanguage(event.target.value as EnabledLanguage));
      }}
    >
      {supportedLocales.map((locale) => (
        <Option value={locale} key={locale}>
          {locale}
        </Option>
      ))}
    </Select>
  );
};
//
const Select = styled.select`
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  outline: none;
  border-radius: 6px;
  transition: 300ms ease-in-out;
  font-size: 16px;
  text-transform: uppercase;
  color: ${Colors.primaryGrey};
  &:hover {
    box-shadow: ${Colors.primaryGrey} 0px 0px 0px 1px;
    cursor: pointer;
    transition: 300ms ease-in-out;
    color: ${Colors.primaryBlack};
  }
`;
const Option = styled.option`
  background-color: ${Colors.backgroundGrey};
`;
//
export default AdminLangSwicher;
