import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../assets/styles/Colors";
import api from "../../../../api";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import CloseIcon from "../../../../assets/icons/JSX/CloseIcon";
import Button from "../../../admin_components/AdminButton";
import Input from "../../../../components/Input";
import BaseModal from "../../../../components/modals/BaseModal";
import { useTranslation } from "react-i18next";

const AdminWelcomerLimits = () => {
  //
  const { t } = useTranslation()
  //
  const [openWelcomerAddModal, setOpenWelcomerAddModal] =
    useState<boolean>(false);
  //
  const [editedLimit, setEditedLimit] = useState<string | null>(null);
  //
  // limits array
  const {
    data: welcomerLimits,
    isLoading: isWelcomerLimitsLoading,
    refetch: refetchWelcomerLimits,
  } = api.useGetWelcomerLimitsQuery({});
  //
  // add welcomer limits
  const [saveWelcomerLimit, { isLoading: isSaveLimitLoading }] =
    api.useAddWelcomerLimitMutation();
  //
  //
  const [
    removeLimit,
    { isLoading: isRemoveLimitLoading, isSuccess: removeLimitSucces },
  ] = api.useRemoveWelcomerLimitMutation();
  //
  //
  const [
    updateWelcomerLimitsStatus,
    {
      isLoading: isUpdateWelcomerLimitStatusLoading,
      isSuccess: isUpdateWelcomerLimitSuccess,
    },
  ] = api.useUpdateWelcomerLimitStatusMutation();
  //
  //
  const updateWelcomerLimitStatusFun = async (
    id: number,
    isActive: boolean
  ) => {
    if (id && isActive !== undefined) {
      try {
        await updateWelcomerLimitsStatus({ id, isActive }).unwrap();
        // refetch franchise list
        refetchWelcomerLimits();
        //
        toast.success(`${t('status')} ${t('updatedSuccessfully')}`);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    }
  };
  //
  //
  //close add trave franchise modal
  const _onAddWelcomerLimitCloseModal = () => {
    setOpenWelcomerAddModal(false);
    setEditedLimit(null);
  };
  //
  //
  const saveLimitFunction = async () => {
    if (editedLimit) {
      try {
        await saveWelcomerLimit({ value: parseInt(editedLimit) }).unwrap();
        //
        // refetch franchise list
        refetchWelcomerLimits();
        //
        // message for user
        toast.success(`${t('limit')} ${t('addedSuccessfully')}`);
        //
        // close modal
        _onAddWelcomerLimitCloseModal();
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    } else {
      toast.error(`${t('enterLimit')} ...`);
    }
  };
  //
  //
  useEffect(() => {
    if (removeLimitSucces || isUpdateWelcomerLimitSuccess) {
      refetchWelcomerLimits();
    }
  }, [removeLimitSucces, isUpdateWelcomerLimitSuccess, refetchWelcomerLimits]);
  //
  //
  const MainLoading =
    isWelcomerLimitsLoading ||
    isSaveLimitLoading ||
    isRemoveLimitLoading ||
    isUpdateWelcomerLimitStatusLoading;
  //

  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        btnStyleType="primary"
        text={t('addLimit')}
        onClick={() => setOpenWelcomerAddModal(!openWelcomerAddModal)}
      />
      <br />
      <br />
      <RisksContent>
        {welcomerLimits && welcomerLimits.length >= 1 ? (
          welcomerLimits.map((item, index) => (
            <Block key={index} $isActive={item.isActive}>
              <RemoveBtn
                onClick={() =>
                  window.confirm(`${t('certainToDeleteIt')}`) &&
                  removeLimit(item.id)
                }
              >
                <CloseIcon />
              </RemoveBtn>
              <div>{t('limit')}: {item.value}</div>

              <div>
                {t('status')}:
                <b> {item.isActive === true ? t('active') : t('inActive')}</b>
              </div>

              <Button
                fullWidth={false}
                btnStyleType="secondary"
                text={item.isActive === true ? t('deactivate') : t('Activate')}
                onClick={() => {
                  const newStatus = item.isActive;
                  return updateWelcomerLimitStatusFun(item.id, !newStatus);
                }}
              />
            </Block>
          ))
        ) : (
          <h4>{t('noLimitsFound')}</h4>
        )}
      </RisksContent>
      <BaseModal
        onClose={_onAddWelcomerLimitCloseModal}
        isOpen={openWelcomerAddModal}
      >
        <div>
          <ModalHead>
            <h4>{t('createLimit')}</h4>
          </ModalHead>

          <FieldsContent>
            <Input
              id="limit-name"
              label={t('limit')}
              type="number"
              placeholder=""
              value={editedLimit || ""}
              name="limit-value"
              onChange={(value) => setEditedLimit(value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <ButtonsBlock>
              <Button
                fullWidth={true}
                btnStyleType="primary"
                text={t('create')}
                onClick={saveLimitFunction}
              />
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
    </Wrapper>
  );
};
//
//
//
//styles
const RemoveBtn = styled.button`
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: transparent;
  border: 0px;
  outline: none;
  height: 20px;
  width: 20px;
  padding: 0px;
  display: grid;
  place-items: center;
  svg {
    height: 13px;
    color: ${Colors.primaryRed};
  }
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-top: 1px solid ${Colors.grey};
  padding-top: 40px;
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.grey};
`;
const RisksContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  /*  */
  img {
    width: 50px;
  }
`;
const Block = styled.div<{ $isActive: boolean }>`
  position: relative;
  height: 200px;
  width: 100%;
  border: ${({ $isActive }) =>
    $isActive
      ? `1px solid ${Colors.primaryGreen}`
      : `1px solid ${Colors.sunsetOrange}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
`;
const Wrapper = styled.div``;
//
export default AdminWelcomerLimits;
