import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../../../admin_components/AdminButton";
import Loader from "../../../../../components/Loader";
import NotFound404 from "../../../../../components/NotFound404";
import {
  Currency,
  EnabledLanguage,
  MinTotalPremiumAgeGroupWithCoefficient,
  SingleMultiOptions,
  TravelAbroadArea,
  TravelAbroadPriceCombination,
  TravelFranchise,
  TravelProduct,
} from "../../../../../domain/types";
import AdminDropdown from "../../../../admin_components/AdminDropdown";
import Input from "../../../../admin_components/AdminInput";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Col from "react-bootstrap/esm/Col";
import Logo from "../../../../../assets/icons/JSX/Logo";
import Colors from "../../../../../assets/styles/Colors";
import { explainTravelCombination } from "../../../../../domain/travelAbroad";
import { useTranslation } from "react-i18next";

const EditAbroadPricesTab: React.FC<{
  travelProductId: number;
  travelProduct?: TravelProduct;
  setTravelProductField: (
    fieldName: keyof TravelProduct,
    fieldValue: any
  ) => void;
  language: EnabledLanguage;
  isLoading: boolean;
  travelFranchises: TravelFranchise[];
  saveTab: any;
  usedCurrencies: Currency[];
  usedAreas: TravelAbroadArea[];
  usedFranchises: number[];
}> = ({
  travelProduct,
  travelProductId,
  language,
  isLoading,
  travelFranchises,
  setTravelProductField,
  saveTab,
  usedCurrencies,
  usedAreas,
  usedFranchises,
}) => {
  const emptyAddException: Partial<TravelAbroadPriceCombination> = {
    travelProductId: travelProductId,
    isAutoGenerated: false,
  };
  const [addException, setAddException] =
    useState<Partial<TravelAbroadPriceCombination>>(emptyAddException);
  const setAddExceptionField = (
    fieldName: keyof TravelAbroadPriceCombination,
    fieldValue: any
  ) => {
    setAddException({
      ...addException,
      [fieldName]: fieldValue,
    });
  };

  const [priceListFilter, setPriceListFilter] = useState<
    Partial<TravelAbroadPriceCombination>
  >({
    isAutoGenerated: false,
  });
  const setPriceListFilterField = (
    fieldName: keyof TravelAbroadPriceCombination,
    fieldValue: any
  ) => {
    setPriceListFilter({
      ...priceListFilter,
      [fieldName]: fieldValue,
    });
  };

  const [priceCombinationState, setPriceCombinationState] = useState<
    TravelAbroadPriceCombination[] | []
  >(travelProduct?.priceCombinations || []);

  const isSet = (_: any) => typeof _ !== "undefined" && _?.toString() !== "";

  const unfilteredPrices = travelProduct ? travelProduct.priceCombinations : [];
  const filteredPrices = unfilteredPrices.filter((comb) => {
    let showThisPrice = true;
    if (showThisPrice && isSet(priceListFilter.travelPackageId)) {
      showThisPrice =
        comb.travelPackageId.toString() ===
        priceListFilter.travelPackageId?.toString();
    }
    if (showThisPrice && isSet(priceListFilter.travelType)) {
      showThisPrice = comb.travelType === priceListFilter.travelType;
    }
    if (showThisPrice && isSet(priceListFilter.area)) {
      showThisPrice = comb.area === priceListFilter.area;
    }
    if (showThisPrice && isSet(priceListFilter.currency)) {
      showThisPrice = comb.currency === priceListFilter.currency;
    }
    if (showThisPrice && isSet(priceListFilter.franchise)) {
      showThisPrice =
        comb.franchise.toString() === priceListFilter.franchise?.toString();
    }
    if (showThisPrice && isSet(priceListFilter.minDay)) {
      showThisPrice =
        comb.minDay.toString() === priceListFilter.minDay?.toString();
    }
    if (showThisPrice && isSet(priceListFilter.maxDay)) {
      showThisPrice =
        comb.maxDay.toString() === priceListFilter.maxDay?.toString();
    }
    if (showThisPrice && isSet(priceListFilter.minAge)) {
      showThisPrice =
        comb.minAge.toString() === priceListFilter.minAge?.toString();
    }
    if (showThisPrice && isSet(priceListFilter.maxAge)) {
      showThisPrice =
        comb.maxAge.toString() === priceListFilter.maxAge?.toString();
    }
    if (showThisPrice && isSet(priceListFilter.isAutoGenerated)) {
      showThisPrice = comb.isAutoGenerated === priceListFilter.isAutoGenerated;
    }
    return showThisPrice;
  });

  const FranoHorizontalLine: React.FC<{ title?: string }> = ({ title }) => {
    return (
      <CustomHorizontalLine>
        <div></div>
        {title ? <span>{title}</span> : <Logo />}
        <div></div>
      </CustomHorizontalLine>
    );
  };

  const removePriceCombinationItemById = (id: number) => {
    if (id && priceCombinationState) {
      setPriceCombinationState(
        priceCombinationState.filter((x) => x.id !== id)
      );
      setTravelProductField(
        "priceCombinations",
        priceCombinationState.filter((x) => x.id !== id)
      );
    }
  };

  const { t } = useTranslation()

  return isLoading ? (
    <Loader />
  ) : !travelProduct ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridTemplateRows: "1fr",
          gridColumnGap: "20px",
          gridRowGap: "20px",
        }}
      >
        <Input
          width={"fullWidth"}
          id={"travelProductPrice-basePrice"}
          label={t('basicDailyPrice')}
          value={travelProduct.priceCoefficients?.basePrice}
          onChange={(value) =>
            setTravelProductField("priceCoefficients", {
              ...travelProduct.priceCoefficients,
              basePrice: value,
            })
          }
        />

        <Input
          width={"fullWidth"}
          id={"travelProductPrice-singlePolicyCoefficient"}
          label={t('singlePolicyRate')}
          value={travelProduct.priceCoefficients?.singlePolicyCoefficient}
          onChange={(value) =>
            setTravelProductField("priceCoefficients", {
              ...travelProduct.priceCoefficients,
              singlePolicyCoefficient: value,
            })
          }
        />

        <Input
          width={"fullWidth"}
          id={"travelProductPrice-multiPolicyCoefficient"}
          label={t('multiPolicyRate')}
          value={travelProduct.priceCoefficients?.multiPolicyCoefficient}
          onChange={(value) =>
            setTravelProductField("priceCoefficients", {
              ...travelProduct.priceCoefficients,
              multiPolicyCoefficient: value,
            })
          }
        />
      </div>
      <FranoHorizontalLine title={t('ageGroupRate')}/>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridTemplateRows: "1fr",
          gridColumnGap: "20px",
          gridRowGap: "20px",
        }}
      >
        {usedAreas.map((area) => (
          <AgePriceBox key={area}>
            <div
              style={{
                fontSize: "14px",
                lineHeight: "15px",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              {area} {t('ratesByAgeGroup')}
              <hr
                style={{
                  color: Colors.primaryRed,
                }}
              />
            </div>
            <Row>
              <Col>
                <InnerText>{t('from')}</InnerText>
              </Col>
              <Col>
                <InnerText>{t('including')}</InnerText>
              </Col>
              <Col>
                <InnerText>{t('rate')}</InnerText>
              </Col>
            </Row>
            <AgeGroupPriceDiv>
              {travelProduct.minTotalPremiumInfos.map(
                (kAgeGroup, iKAgeGroup) => (
                  <Row
                    key={`${kAgeGroup.minAge}-${kAgeGroup.maxAge}`}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Col>
                      <Input
                        id={`kAgeGroup-${kAgeGroup}-minAgeDisplay`}
                        width="auto"
                        length={3}
                        label=""
                        value={kAgeGroup.minAge}
                        style={{
                          display: "inline-flex",
                          flexDirection: "row-reverse",
                          alignItems: "center",
                        }}
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Input
                        id={`kAgeGroup-${kAgeGroup}-maxAgeDisplay`}
                        width="auto"
                        length={3}
                        label=""
                        value={kAgeGroup.maxAge}
                        style={{
                          display: "inline-flex",
                          flexDirection: "row-reverse",
                          alignItems: "center",
                        }}
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Input
                        id={`kAgeGroup-${kAgeGroup}-coefficient`}
                        width="auto"
                        length={3}
                        label=""
                        value={
                          kAgeGroup.coefficientsByTravelArea?.find(
                            (k) => k.area === area
                          )?.coefficient
                        }
                        style={{
                          display: "inline-flex",
                          flexDirection: "row-reverse",
                          alignItems: "center",
                        }}
                        onChange={(value) => {
                          const ageGroupNewCoeff = value;
                          setTravelProductField(
                            "minTotalPremiumInfos",
                            travelProduct.minTotalPremiumInfos.map(
                              (
                                kg,
                                iKg
                              ): MinTotalPremiumAgeGroupWithCoefficient =>
                                iKg === iKAgeGroup
                                  ? {
                                      ...kg,
                                      coefficientsByTravelArea: [
                                        ...(kg.coefficientsByTravelArea
                                          ? kg.coefficientsByTravelArea.filter(
                                              (kByArea) => kByArea.area !== area
                                            )
                                          : []),
                                        { area, coefficient: ageGroupNewCoeff },
                                      ],
                                    }
                                  : kg
                            )
                          );
                        }}
                      />
                    </Col>
                  </Row>
                )
              )}
            </AgeGroupPriceDiv>
          </AgePriceBox>
        ))}
      </div>

      <FranoHorizontalLine title={t('dayRangeRate')} />
      <div>
        <Row>
          <Col>
            <InnerText>{t('fromDays')}</InnerText>
          </Col>
          <Col>
            <InnerText>{t('upToDays')}</InnerText>
          </Col>
          <Col>
            <InnerText>{t('rate')}</InnerText>
          </Col>
        </Row>
        <div>
          {travelProduct.dayGroupInfos.map((kDayGroup, iKDayGroup) => (
            <Row
              key={`${kDayGroup.minDay}-${kDayGroup.maxDay}`}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Col>
                <Input
                  id={`kDayGroup-${kDayGroup}-minDisplay`}
                  width="auto"
                  length={3}
                  label=""
                  value={kDayGroup.minDay}
                  style={{
                    display: "inline-flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                  }}
                  disabled={true}
                />
              </Col>

              <Col>
                <Input
                  id={`kDayGroup-${kDayGroup}-maxDisplay`}
                  width="auto"
                  length={3}
                  label=""
                  value={kDayGroup.maxDay}
                  style={{
                    display: "inline-flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                  }}
                  disabled={true}
                />
              </Col>

              <Col>
                <Input
                  id={`kDayGroup-${kDayGroup}-coefficient`}
                  width="auto"
                  length={3}
                  label=""
                  value={kDayGroup.coefficient}
                  style={{
                    display: "inline-flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                  }}
                  onChange={(value) => {
                    const ageGroupNewCoeff = value;
                    setTravelProductField(
                      "dayGroupInfos",
                      travelProduct.dayGroupInfos.map((kg, iKg) =>
                        iKg === iKDayGroup
                          ? { ...kg, coefficient: ageGroupNewCoeff }
                          : kg
                      )
                    );
                  }}
                />
              </Col>
            </Row>
          ))}
        </div>
      </div>

      <FranoHorizontalLine title={t('deductibleRates')} />
      <Row>
        {usedFranchises.map((franchise) => (
          <Col
            style={{
              padding: "5px",
            }}
            key={franchise}
          >
            <Input
              width={"fullWidth"}
              id={`travelProductPrice-${franchise}-franchise`}
              label={language === 'ge' ? `${franchise}${t('for')}` : `${t('for')}${franchise}`}
              value={
                travelProduct.priceCoefficients?.franchiseCoefficients?.find(
                  (fc) => fc.franchise === franchise
                )?.coefficient
              }
              onChange={(value) => {
                const newCoefficients: TravelProduct["priceCoefficients"] = {
                  ...travelProduct.priceCoefficients,
                  franchiseCoefficients: [
                    ...(
                      travelProduct.priceCoefficients?.franchiseCoefficients ??
                      []
                    ).filter((fc) => fc.franchise !== franchise),
                    {
                      franchise: franchise,
                      coefficient: value,
                    },
                  ],
                };
                setTravelProductField("priceCoefficients", newCoefficients);
              }}
            />
          </Col>
        ))}
      </Row>

      <FranoHorizontalLine title={t('travelAreaRate')}/>
      <Row>
        {usedAreas.map((area) => (
          <Col
            key={area}
            style={{
              padding: "5px",
            }}
          >
            <Input
              width={"fullWidth"}
              id={`travelProductPrice-${area}-area`}
              label={area}
              value={
                travelProduct.priceCoefficients?.areaCoefficients?.find(
                  (fc) => fc.area === area
                )?.coefficient
              }
              onChange={(value) => {
                const newCoefficients: TravelProduct["priceCoefficients"] = {
                  ...travelProduct.priceCoefficients,
                  areaCoefficients: [
                    ...(
                      travelProduct.priceCoefficients?.areaCoefficients ?? []
                    ).filter((fc) => fc.area !== area),
                    {
                      area: area,
                      coefficient: value,
                    },
                  ],
                };
                setTravelProductField("priceCoefficients", newCoefficients);
              }}
            />
          </Col>
        ))}
      </Row>

      <FranoHorizontalLine title={t('currencyRate')} />
      <Row>
        {usedCurrencies.map((currency) => (
          <Col
            key={currency}
            style={{
              padding: "5px",
            }}
          >
            <Input
              width={"fullWidth"}
              id={`travelProductPrice-${currency}-currency`}
              label={currency}
              value={
                travelProduct.priceCoefficients?.currencyCoefficients?.find(
                  (fc) => fc.currency === currency
                )?.coefficient
              }
              onChange={(value) => {
                const newCoefficients: TravelProduct["priceCoefficients"] = {
                  ...travelProduct.priceCoefficients,
                  currencyCoefficients: [
                    ...(
                      travelProduct.priceCoefficients?.currencyCoefficients ??
                      []
                    ).filter((fc) => fc.currency !== currency),
                    {
                      currency: currency,
                      coefficient: value,
                    },
                  ],
                };
                setTravelProductField("priceCoefficients", newCoefficients);
              }}
            />
          </Col>
        ))}
      </Row>
      <FranoHorizontalLine title={t('packageRate')}/>
      <Row>
        {travelProduct.mainProduct.packages
          .map((p) => p.target)
          .map((pkg) => (
            <Col
              key={pkg?.id}
              style={{
                padding: "5px",
              }}
            >
              <Input
                width={"fullWidth"}
                id={`travelProductPrice-${pkg?.id}-travelPackageId`}
                label={pkg?.mainPackage.title || ""}
                value={
                  travelProduct.priceCoefficients?.packageCoefficients?.find(
                    (fc) => fc.packageId === pkg?.id
                  )?.coefficient
                }
                onChange={(value) => {
                  const newCoefficients: TravelProduct["priceCoefficients"] = {
                    ...travelProduct.priceCoefficients,
                    packageCoefficients: [
                      ...(
                        travelProduct.priceCoefficients?.packageCoefficients ??
                        []
                      ).filter((fc) => fc.packageId !== pkg!.id),
                      {
                        packageId: pkg!.id,
                        coefficient: value,
                      },
                    ],
                  };
                  setTravelProductField("priceCoefficients", newCoefficients);
                }}
              />
            </Col>
          ))}
      </Row>

      <FranoHorizontalLine title={t('priceCombinations')}/>
      <Block
        style={{
          overflowY: "scroll",
          maxHeight: "600px",
          height: "100%",
        }}
      >
        <TableContainer component={Paper} style={{ marginBottom: 20 }}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="price combinations and exceptions"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <AdminDropdown
                    id="priceListFilter-travelPackageId"
                    label={t('package')}
                    options={travelProduct.mainProduct.packages
                      .map((pkg) => pkg.target)
                      .map((tPkg) => ({
                        label: tPkg?.mainPackage.title ?? "No label specified",
                        value: tPkg?.id,
                      }))}
                    selectedValue={priceListFilter?.travelPackageId}
                    onChange={(event) =>
                      setPriceListFilterField(
                        "travelPackageId",
                        event.target.value
                      )
                    }
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="priceListFilter-travelType"
                    label={t('multiplicity')}
                    options={SingleMultiOptions.map((opt) => ({
                      label: opt,
                      value: opt,
                    }))}
                    selectedValue={priceListFilter?.travelType}
                    onChange={(event) =>
                      setPriceListFilterField("travelType", event.target.value)
                    }
                    minWidth={120}
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="priceListFilter-area"
                    label={t('area')}
                    options={usedAreas.map((opt) => ({
                      label: opt,
                      value: opt,
                    }))}
                    selectedValue={priceListFilter?.area}
                    onChange={(event) =>
                      setPriceListFilterField("area", event.target.value)
                    }
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="priceListFilter-currency"
                    label={t('currency')}
                    options={usedCurrencies.map((opt) => ({
                      label: opt,
                      value: opt,
                    }))}
                    selectedValue={priceListFilter?.currency}
                    onChange={(event) =>
                      setPriceListFilterField("currency", event.target.value)
                    }
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="priceListFilter-franchise"
                    label={t('deductible')}
                    options={usedFranchises.map((opt) => ({
                      label: opt.toString(),
                      value: opt.toString(),
                    }))}
                    selectedValue={priceListFilter?.franchise?.toString()}
                    onChange={(event) =>
                      setPriceListFilterField("franchise", event.target.value)
                    }
                    minWidth={120}
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="priceListFilter-dayGroup"
                    label={t('days')}
                    options={travelProduct.dayGroupInfos.map((dayGroup) => ({
                      label: `${dayGroup.minDay}-${dayGroup.maxDay}`,
                      value: `${dayGroup.minDay}-${dayGroup.maxDay}`,
                    }))}
                    selectedValue={`${priceListFilter.minDay}-${priceListFilter.maxDay}`}
                    onChange={(event) => {
                      const [minDay, maxDay] = event.target.value.split("-");
                      setPriceListFilter({
                        ...priceListFilter,
                        minDay,
                        maxDay,
                      });
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="priceListFilter-ageGroup"
                    label={t('age')}
                    options={travelProduct.minTotalPremiumInfos.map(
                      (ageGroup) => ({
                        label: `${ageGroup.minAge}-${ageGroup.maxAge}`,
                        value: `${ageGroup.minAge}-${ageGroup.maxAge}`,
                      })
                    )}
                    selectedValue={`${priceListFilter.minAge}-${priceListFilter.maxAge}`}
                    onChange={(event) => {
                      const [minAge, maxAge] = event.target.value.split("-");
                      setPriceListFilter({
                        ...priceListFilter,
                        minAge,
                        maxAge,
                      });
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={priceListFilter.isAutoGenerated}
                          onChange={(event) => {
                            setPriceListFilterField(
                              "isAutoGenerated",
                              event.target.checked
                            );
                            if (event.target.checked === true) {
                              setPriceCombinationState(
                                travelProduct?.priceCombinations
                              );
                            } else {
                              setPriceCombinationState([]);
                            }
                          }}
                        />
                      }
                      label={t('generated')}
                    />
                  </FormGroup>
                </TableCell>
                <TableCell align="left">{t('premium')}</TableCell>
                <TableCell align="left">{t('operation')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPrices.map((combination, iCombination) => (
                <TableRow
                  key={combination.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {
                      travelProduct.mainProduct.packages.find(
                        (p) => p.targetId === combination.travelPackageId
                      )?.title
                    }
                  </TableCell>
                  <TableCell align="left">{combination.travelType}</TableCell>
                  <TableCell align="left">{combination.area}</TableCell>
                  <TableCell align="left">{combination.currency}</TableCell>
                  <TableCell align="left">{combination.franchise}</TableCell>
                  <TableCell align="left">
                    {combination.minDay}-{combination.maxDay}
                  </TableCell>
                  <TableCell align="left">
                    {combination.minAge}-{combination.maxAge}
                  </TableCell>
                  <TableCell align="left">
                    {combination.isAutoGenerated ? `${t('yes')}` : `${t('no')}`}
                  </TableCell>
                  <TableCell align="left">
                    {combination.premium}
                    <sup
                      title={explainTravelCombination({
                        travelProduct,
                        priceCombinationToExplain: combination,
                      })}
                    >
                      <span> ?</span>
                    </sup>
                  </TableCell>

                  <TableCell align="left">
                    <Button
                      btnStyleType="primary"
                      icon={false}
                      fullWidth={false}
                      disabled={false}
                      onClick={() =>
                        removePriceCombinationItemById(combination.id)
                      }
                      text={t('delete')}
                    />
                  </TableCell>
                </TableRow>
              ))}

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <AdminDropdown
                    id="addException-travelPackageId"
                    label={t('package')}
                    options={travelProduct.mainProduct.packages
                      .map((pkg) => pkg.target)
                      .map((tPkg) => ({
                        label: tPkg?.mainPackage.title ?? "No label specified",
                        value: tPkg?.id,
                      }))}
                    selectedValue={addException?.travelPackageId ?? ""}
                    onChange={(event) =>
                      setAddExceptionField(
                        "travelPackageId",
                        event.target.value
                      )
                    }
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="addException-travelType"
                    label={t('multiplicity')}
                    options={SingleMultiOptions.map((opt) => ({
                      label: opt,
                      value: opt,
                    }))}
                    selectedValue={addException?.travelType ?? ""}
                    onChange={(event) =>
                      setAddExceptionField("travelType", event.target.value)
                    }
                    minWidth={120}
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="addException-area"
                    label={t('area')}
                    options={usedAreas.map((opt) => ({
                      label: opt,
                      value: opt,
                    }))}
                    selectedValue={addException?.area ?? ""}
                    onChange={(event) =>
                      setAddExceptionField("area", event.target.value)
                    }
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="addException-currency"
                    label={t('currency')}
                    options={usedCurrencies.map((opt) => ({
                      label: opt,
                      value: opt,
                    }))}
                    selectedValue={addException?.currency ?? ""}
                    onChange={(event) =>
                      setAddExceptionField("currency", event.target.value)
                    }
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="addException-franchise"
                    label={t('deductible')}
                    options={usedFranchises.map((opt) => ({
                      label: opt.toString(),
                      value: opt.toString(),
                    }))}
                    selectedValue={addException?.franchise?.toString() || ""}
                    onChange={(event) =>
                      setAddExceptionField("franchise", event.target.value)
                    }
                    minWidth={120}
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="addException-dayGroup"
                    label={t('days')}
                    options={travelProduct.dayGroupInfos.map((dayGroup) => ({
                      label: `${dayGroup.minDay}-${dayGroup.maxDay}`,
                      value: `${dayGroup.minDay}-${dayGroup.maxDay}`,
                    }))}
                    selectedValue={`${addException.minDay}-${addException.maxDay}`}
                    onChange={(event) => {
                      const [minDay, maxDay] = event.target.value.split("-");
                      setAddException({
                        ...addException,
                        minDay,
                        maxDay,
                      });
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <AdminDropdown
                    id="addException-ageGroup"
                    label={t('age')}
                    options={travelProduct.minTotalPremiumInfos.map(
                      (ageGroup) => ({
                        label: `${ageGroup.minAge}-${ageGroup.maxAge}`,
                        value: `${ageGroup.minAge}-${ageGroup.maxAge}`,
                      })
                    )}
                    selectedValue={`${addException.minAge}-${addException.maxAge}`}
                    onChange={(event) => {
                      const [minAge, maxAge] = event.target.value.split("-");
                      setAddException({
                        ...addException,
                        minAge,
                        maxAge,
                      });
                    }}
                  />
                </TableCell>
                <TableCell align="left">{t('no')}</TableCell>
                <TableCell align="left">
                  <Input
                    id={`addException-premium`}
                    width="auto"
                    length={3}
                    label=""
                    value={addException?.premium ?? ""}
                    style={{
                      display: "inline-flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                    }}
                    onChange={(value) => {
                      setAddExceptionField("premium", value);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Button
                    btnStyleType="secondary"
                    icon={false}
                    fullWidth={false}
                    disabled={false}
                    onClick={() => {
                      let fields = {
                        travelProductId: "პროდუქტი",
                        travelPackageId: "პაკეტი",
                        travelType: "ჯერადობა",
                        area: "არეალი",
                        currency: "ვალუტა",
                        franchise: "ფრანშიზა",
                        minDay: "მინ. დღეები",
                        maxDay: "მაქს. დღეები",
                        minAge: "მინ. ასაკი",
                        maxAge: "მაქს. ასაკი",
                        premium: "პრემია",
                      };
                      for (const field of Object.keys(fields)) {
                        if (
                          typeof addException[field] === "undefined" ||
                          (typeof addException[field] === "string" &&
                            addException[field] === "")
                        ) {
                          alert(`${t('pleaseSelect')}` + fields[field]);
                          return;
                        }
                      }
                      setTravelProductField("priceCombinations", [
                        ...travelProduct.priceCombinations,
                        {
                          ...addException,
                          id: -1 * travelProduct.priceCombinations.length,
                        },
                      ]);

                      setAddException(emptyAddException);
                    }}
                    text={t('addException')}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Block>
      <Button
        btnStyleType="primary"
        icon={false}
        fullWidth={false}
        disabled={false}
        onClick={saveTab}
        text={t('savePrice')}
      />
    </Wrapper>
  );
};

const AgePriceBox = styled.div`
  padding: 10px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  &:hover {
    box-shadow: ${Colors.lightRed} 0px 10px 20px, ${Colors.lightRed} 0px 6px 6px;
  }
`;
const InnerText = styled.div`
  margin-bottom: 10px;
  color: ${Colors.primaryGrey};
  font-size: 14px;
`;
const AgeGroupPriceDiv = styled.div`
  input {
    text-align: center;
  }
`;
const CustomHorizontalLine = styled.div`
  margin-block: 20px;
  padding-block: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: all 200ms ease;
    height: 25px;
    width: 55px;
    min-width: 55px;
  }
  span {
    white-space: nowrap;
    font-weight: bold;
    color: ${Colors.primaryBlack};
  }
  div {
    height: 2px;
    width: 100%;
    background-color: ${Colors.primaryRed};
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
  &:hover {
    svg {
      transition: all 200ms ease;
      transform: scale(1.05);
    }
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid #dadada;
  margin-bottom: 20px;
  > div {
    flex-basis: 50%;
  }
`;
const Wrapper = styled.div`
  padding: 20px 15px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default EditAbroadPricesTab;
