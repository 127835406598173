import React from "react";
import styled from "styled-components";
import api from "../../../api";
import Loader from "../../../components/Loader";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import { useDispatch } from "react-redux";
import { AppState, setEditedlanguage } from "../../../store/appSlice";
import Colors from "../../../assets/styles/Colors";
import { useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";
import AdminButton from "../../admin_components/AdminButton";
import { useTranslation } from "react-i18next";
//
const AdminPartners = () => {
  //
  const dispatch = useDispatch();
  //
  const { t } = useTranslation();
  // 
  const navigate = useNavigate();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const { data: franiPartners, isLoading: isFraniPartnersLoading } =
    api.useGetFraniPartnersQuery(undefined, {
      refetchOnMountOrArgChange: true,
    });
  //
  const MainLoading = isFraniPartnersLoading;
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <HeadStyle>
        <AdminButton
          btnStyleType="primary"
          text={t('addPartner')}
          onClick={() => navigate("/frani-partner-detail/0")}
        />

        <AdminLangSwicher
          onChange={(value) => dispatch(setEditedlanguage(value))}
        />
      </HeadStyle>

      <hr />

      <Partners>
        {franiPartners?.map((item, index) => (
          <PartnerItem
            key={index}
            onClick={() => navigate(`/frani-partner-detail/${item.id}`)}
          >
            <img src={item?.imageUrl} alt={`partner-icon-${item.id}`} />
            <div>{item?.translations?.[field_Language]?.title}</div>
          </PartnerItem>
        ))}
      </Partners>
    </Wrapper>
  );
};
//
const Partners = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
`;
const PartnerItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${Colors.primaryGrey};
  border-radius: 8px;
  padding: 15px;
  transition: all 200ms ease;
  cursor: pointer;

  &:hover {
    border-color: ${Colors.primaryBlack};
    transition: all 200ms ease;
    transform: scale(1.01);
  }

  img {
    height: 50px;
  }
`;
const HeadStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Wrapper = styled.div`
  /*  */
`;
//
export default AdminPartners;
