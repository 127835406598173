import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={23.194}
            height={16.604}
            viewBox="0 0 23.194 16.604"
            {...props}
        >
            <g data-name="Group 52715">
                <g data-name="Group 52715">
                    <path
                        data-name="Path 17173"
                        d="M8.367 0H0v1.473h8.367z"
                        transform="translate(7.318 6.475) rotate(90 1.626 3.389)"
                        fill="CurrentColor"
                    />
                    <g data-name="Group 52706">
                        <path
                            data-name="Subtraction 152"
                            d="M6.052.059L0 0l.059 6.051 1.46-1.46-.03-3.1 3.1.03L6.051.06z"
                            fill="CurrentColor"
                            transform="translate(7.318 6.475) rotate(45 2.14 5.165)"
                        />
                    </g>
                </g>
                <path
                    data-name="Path 65711"
                    d="M-25.331-66.962v-1.445h1.891c1.9 0 3.285-.659 3.912-1.856a3.514 3.514 0 00-.346-3.63 3.242 3.242 0 00-3.247-1.276l-.853.126v-.845c.011-2.069-1.531-4.38-4.943-4.566a5.639 5.639 0 00-3.58.958 4.672 4.672 0 00-1.894 3.422l-.083.76-.771-.123a3.462 3.462 0 00-3.507 1.163 3.673 3.673 0 00-.247 3.819 4.079 4.079 0 003.872 2.047h1.811v1.446h-1.811a5.559 5.559 0 01-5.19-2.839 5.124 5.124 0 01.372-5.327 4.762 4.762 0 014.2-1.815 5.947 5.947 0 012.38-3.723 7.14 7.14 0 014.534-1.234c2.937.16 5.9 2 6.294 5.237a4.661 4.661 0 013.863 1.924 4.9 4.9 0 01.461 5.134c-.517.987-1.883 2.642-5.227 2.642h-.012c-.177.004-1.879.001-1.879.001z"
                    transform="translate(40.885 81.911)"
                    fill="CurrentColor"
                />
            </g>
        </svg>
    )
}

export default SvgComponent
