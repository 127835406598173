import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import DoubleRightIcon from "../../../assets/icons/JSX/DoubleRightIcon";
import Colors from "../../../assets/styles/Colors";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import CustomDropdown from "../../../components/CustomDropdown";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import Message from "../../../components/Message";
import BaseModal from "../../../components/modals/BaseModal";
import { AdminSoldPolicyFilterFields } from "../../../domain/types";
import { RootState } from "../../../store";
import { AuthState } from "../../../store/authSlice";
import CustomSelecte from "../../admin_components/CustomSelecte";
import { useTranslation } from "react-i18next";

//
//
// filter field type
type FilterFieldsType = {
  reportDownload: boolean;
  page: number | string;
  productCategory: "TRAVEL" | "TPL" | string;
  firstName: string;
  lastName: string;
  personalNumber: string;
  companyIds: { value: any; label: any }[] | number[] | [];
  registerDateFrom: string;
  registerDateTo: string;
  paymentDateFrom: string;
  paymentDateTo: string;
  policyStatus: string[] | [];
  // | "ACTIVE"
  // | "PENDING"
  // | "CLOSED"
  // | "DEACTIVATED"
  // | "PENDING-DELETED"
  // | string;
  paymentStatus: "PAID" | "UNPAID" | string;
  policyNumber: string;
  tplRegistrationNumber: string;
  policyDateFrom: string;
  accountId: string;
  orderId: string;
};
//
//
//
const AdminCompanySoldPolicies = () => {
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  //
  const insuranceUserCompanyId = auth?.user?.companyUsers?.[0]?.companyId;
  //
  //
  const { data: authorizedCompanies, isLoading: isAuthorizedCompaniesLoading } =
    api.useGetAuthorizedCompaniesQuery(undefined);
  //
  //
  const [reportType, setReportType] = useState<
    "TRAVEL" | "TPL" | "WELCOMER" | "PET" | "COMBINED" | "BORDERO" | string
  >("");
  //
  //
  const [openReportModal, setOpenReportModal] = useState<boolean>(false);
  //
  //
  const isFraniAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";
  //x`
  //
  const [selectedCompanyCheckbox, setSelectedCompanyCheckbox] = useState<
    number[]
  >([]);
  //
  //
  const [showGeneratePolicyFiles, setShowGeneratePolicyFiles] =
    useState<boolean>(false);
  //
  const [targetTypeState, setTargetTypeState] = useState<string | undefined>(
    undefined
  );
  //
  //

  //
  const companyIdArr = authorizedCompanies?.map((c) => ({
    value: c.id,
    label: c.title,
  }));
  //
  //
  //
  //
  // current page filter fields
  const [filterFieldsState, setFilterFieldsState] = useState<FilterFieldsType>({
    reportDownload: false,
    page: 1,
    productCategory: "COMBINED",
    firstName: "",
    lastName: "",
    personalNumber: "",
    companyIds: [],
    registerDateFrom: "",
    registerDateTo: "",
    paymentDateFrom: "",
    paymentDateTo: "",
    policyStatus: [],
    paymentStatus: "",
    policyNumber: "",
    tplRegistrationNumber: "",
    policyDateFrom: "",
    orderId: "",
    accountId: "",
  });
  //
  //
  const setFilterField = (
    fieldName: keyof AdminSoldPolicyFilterFields,
    fieldValue: any
  ) => {
    setFilterFieldsState({
      ...filterFieldsState,
      [fieldName]: fieldValue,
    });
  };
  //
  // set company ids firs load
  useEffect(() => {
    // frani user
    if (authorizedCompanies) {
      //
      const companyArr = authorizedCompanies?.map((c) => ({
        value: c.id,
        label: c.title,
      }));
      //
      if (companyArr) {
        setFilterFieldsState({
          ...filterFieldsState,
          companyIds: companyArr,
        });
      }
    }
    //
    // company user
    if (insuranceUserCompanyId && auth.user?.userType === "Insurance") {
      setFilterFieldsState({
        ...filterFieldsState,
        companyIds: [insuranceUserCompanyId],
      });
    }
    //
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizedCompanies, insuranceUserCompanyId]);
  //
  //
  const [currentPage, setCurrentPage] = useState<number>(1);
  //
  //
  //
  //
  // post filter fields
  const [
    sendFilterFields,
    { isLoading: isFilterLoading, data: filteredPolicies },
  ] = api.useGetSoldSearchedPoliciesMutation();
  //
  //
  //
  // post filter report
  const [
    sendFilterReport,
    { isLoading: isFilterReportLoading, data: filteredPolicyReport },
  ] = api.useGetSoldSearchedPoliciesMutation();
  //
  // download report
  useEffect(() => {
    if (filteredPolicyReport) {
      document.location.href = filteredPolicyReport?.url || "";
    }
    //
    return () => {};
  }, [filteredPolicyReport]);
  //
  //
  //
  // send filter fields fun
  const sendFilterData = async () => {
    //
    const companyIDS =
      insuranceUserCompanyId && auth.user?.userType === "Insurance"
        ? filterFieldsState.companyIds
        : filterFieldsState.companyIds.map((x) => x.value);
    //
    try {
      await sendFilterFields({
        companyIds: companyIDS,
        firstName: filterFieldsState.firstName,
        lastName: filterFieldsState.lastName,
        page: filterFieldsState.page,
        paymentDateFrom: filterFieldsState.paymentDateFrom,
        paymentDateTo: filterFieldsState.paymentDateTo,
        paymentStatus: filterFieldsState.paymentStatus,
        personalNumber: filterFieldsState.personalNumber,
        policyNumber: filterFieldsState.policyNumber,
        policyStatus: filterFieldsState.policyStatus,
        productCategory: filterFieldsState.productCategory,
        registerDateFrom: filterFieldsState.registerDateFrom,
        registerDateTo: filterFieldsState.registerDateTo,
        reportDownload: filterFieldsState.reportDownload,
        tplRegistrationNumber: filterFieldsState.tplRegistrationNumber,
        policyDateFrom: filterFieldsState.policyDateFrom,
        accountId: filterFieldsState.accountId,
        orderId: filterFieldsState.orderId,
      }).unwrap();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  //
  // send filter report fun
  const sendFilterReportFun = async () => {
    //
    const companyIDS =
      insuranceUserCompanyId && auth.user?.userType === "Insurance"
        ? filterFieldsState.companyIds
        : filterFieldsState.companyIds.map((x) => x.value);
    //
    if (reportType) {
      try {
        await sendFilterReport({
          reportType: reportType,
          companyIds: companyIDS,
          firstName: filterFieldsState.firstName,
          lastName: filterFieldsState.lastName,
          page: filterFieldsState.page,
          paymentDateFrom: filterFieldsState.paymentDateFrom,
          paymentDateTo: filterFieldsState.paymentDateTo,
          paymentStatus: filterFieldsState.paymentStatus,
          personalNumber: filterFieldsState.personalNumber,
          policyNumber: filterFieldsState.policyNumber,
          policyStatus: filterFieldsState.policyStatus,
          productCategory: filterFieldsState.productCategory,
          registerDateFrom: filterFieldsState.registerDateFrom,
          registerDateTo: filterFieldsState.registerDateTo,
          reportDownload: true,
          tplRegistrationNumber: filterFieldsState.tplRegistrationNumber,
          policyDateFrom: filterFieldsState.policyDateFrom,
          accountId: filterFieldsState.accountId,
          orderId: filterFieldsState.orderId,
        }).unwrap();
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
      //
    } else {
      toast.info(`${t("chooseReportType")}`);
    }
    //
  };
  //
  //
  //
  // add company id
  const addCompanyId = (companyID: number) => {
    setSelectedCompanyCheckbox([...selectedCompanyCheckbox, companyID]);
  };
  // remove company id
  const removeCompanyId = (companyID: number) => {
    setSelectedCompanyCheckbox([
      ...selectedCompanyCheckbox.filter((x) => x !== companyID),
    ]);
  };
  //
  //
  //
  // pagination page count
  const pageCount =
    filteredPolicies && Math.ceil(filteredPolicies?.meta?.total / 20);
  //
  //
  // generate policy file
  const [generatePolicyFile, { isLoading: IsGeneratePolicyFileLoading }] =
    api.useGeneratePolicyFilesByPolicyIdsMutation();
  //
  //
  // Send Email/Sms To Policy Holder mutatuon
  const [
    sendEmailAndSmsToPolicyHolder,
    { isLoading: IsSendEmailAndSmsToPolicyHolderLoading },
  ] = api.useSendEmailAndSmsToPolicyHolderMutation();
  //
  //
  // generate policy files fun
  const GeneratePolicyFilesFun = async () => {
    //
    if (targetTypeState && selectedCompanyCheckbox) {
      try {
        await generatePolicyFile({
          policyType: targetTypeState,
          policyIds: selectedCompanyCheckbox,
        }).unwrap();
        //
        toast.success(`${t("generatedSuccessfully")}`);
        //
        window.location.reload();
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };
  //
  //
  // Send Email/Sms To Policy Holder
  const SendEmailAndSmsToPolicyHolderFun = async () => {
    //
    if (targetTypeState && selectedCompanyCheckbox) {
      try {
        await sendEmailAndSmsToPolicyHolder({
          policyType: targetTypeState,
          policyIds: selectedCompanyCheckbox,
        }).unwrap();
        //
        toast.success(`${t("sendedSuccessfully")}`);
        //
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };
  //
  //
  //
  //
  useEffect(() => {
    const sendFilterData = async () => {
      //
      const companyIDS =
        insuranceUserCompanyId && auth.user?.userType === "Insurance"
          ? filterFieldsState.companyIds
          : filterFieldsState.companyIds.map((x) => x.value);
      //
      try {
        await sendFilterFields({
          companyIds: companyIDS,
          firstName: filterFieldsState.firstName,
          lastName: filterFieldsState.lastName,
          page: filterFieldsState.page,
          paymentDateFrom: filterFieldsState.paymentDateFrom,
          paymentDateTo: filterFieldsState.paymentDateTo,
          paymentStatus: filterFieldsState.paymentStatus,
          personalNumber: filterFieldsState.personalNumber,
          policyNumber: filterFieldsState.policyNumber,
          policyStatus: filterFieldsState.policyStatus,
          productCategory: filterFieldsState.productCategory,
          registerDateFrom: filterFieldsState.registerDateFrom,
          registerDateTo: filterFieldsState.registerDateTo,
          reportDownload: filterFieldsState.reportDownload,
          tplRegistrationNumber: filterFieldsState.tplRegistrationNumber,
          policyDateFrom: filterFieldsState.policyDateFrom,
          accountId: filterFieldsState.accountId,
          orderId: filterFieldsState.orderId,
        }).unwrap();
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    };
    //
    sendFilterData();
    //
    return () => {};
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  //
  //
  //
  //
  // pagination onClick
  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected + 1);
    //
    setFilterField("page", event.selected + 1);
  };
  //
  //
  const _closeReportModal = () => {
    setReportType("");
    setOpenReportModal(false);
  };
  //
  //
  const policyStatusArr =
    insuranceUserCompanyId && auth.user?.userType === "Insurance"
      ? ["ACTIVE", "PENDING", "CLOSED"]
      : ["ACTIVE", "PENDING", "CLOSED", "DEACTIVATED", "PENDING-DELETED"];
  //
  //
  const mainLoading =
    isAuthorizedCompaniesLoading ||
    isFilterLoading ||
    IsGeneratePolicyFileLoading ||
    IsSendEmailAndSmsToPolicyHolderLoading ||
    isFilterReportLoading;
  //
  return (
    <div>
      {/* <pre>{JSON.stringify(filterFieldsState, undefined, 2)}</pre> */}
      {/* reports base modal */}
      <BaseModal onClose={_closeReportModal} isOpen={openReportModal}>
        <CustomDropdown
          title={t("chooseReportType")}
          isMulti={false}
          value={reportType || ""}
          isClearable={false}
          onChange={(obj) => setReportType(obj.value)}
          options={[
            ...["TRAVEL", "TPL", "WELCOMER", "PET", "COMBINED", "BORDERO"].map(
              (item) => ({
                value: item,
                label: item,
              })
            ),
          ]}
        />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Button
          border={true}
          btnStyleType="white"
          text={t("downloadReports")}
          fullWidth={true}
          onClick={sendFilterReportFun} // false send filter data info & true send report url
          // (document.location.href = adminReportByFilterFields?.url || "")
        />
        <br />
        <br />
      </BaseModal>
      {/* reports base modal */}
      {/*  */}
      <AllPoliciesWrapper>
        {filteredPolicies && filteredPolicies?.data?.length ? <></> : null}
        {/*  */}
        {/*  */}
        <Row>
          <Col>
            <Button
              border={true}
              btnStyleType="white"
              text={t("generateFiles")}
              fullWidth={true}
              onClick={() =>
                setShowGeneratePolicyFiles(!showGeneratePolicyFiles)
              }
            />
          </Col>
          <Col>
            <Button
              border={true}
              btnStyleType="white"
              text={t("downloadReports")}
              fullWidth={true}
              onClick={() => setOpenReportModal(true)}
            />
          </Col>
        </Row>

        {showGeneratePolicyFiles && isFraniAdmin && (
          <CustomGeneretPolicyFilesBlock>
            <Message variant="gray" text={t("selectUsersForGenerateFiles")} />

            <Message
              variant="light-red"
              text={`${t("selectedUserNumber")} ${
                selectedCompanyCheckbox?.length
              }`}
            />

            <Button
              disabled={selectedCompanyCheckbox?.length >= 1 ? false : true}
              fullWidth={true}
              border={true}
              btnStyleType="transparent"
              text={t("generateFilesBtn")}
              onClick={() =>
                window.confirm(`${t("certainToGenerateFiles")}`) &&
                GeneratePolicyFilesFun()
              }
            />

            <Button
              disabled={selectedCompanyCheckbox?.length >= 1 ? false : true}
              fullWidth={true}
              border={true}
              btnStyleType="transparent"
              text={t("sendSmsAndEmails")}
              onClick={() =>
                window.confirm(`${t("certainToSendSMS")}`) &&
                SendEmailAndSmsToPolicyHolderFun()
              }
            />
          </CustomGeneretPolicyFilesBlock>
        )}

        <hr />

        <FilterGridBlock>
          <CustomDropdown
            title={t("product")}
            isMulti={false}
            value={filterFieldsState.productCategory}
            isClearable={false}
            onChange={(obj) => setFilterField("productCategory", obj.value)}
            options={[
              ...["TRAVEL", "TPL", "WELCOMER", "PET", "COMBINED"].map(
                (item) => ({
                  value: item,
                  label: item,
                })
              ),
            ]}
          />

          <Input
            id="from-register-date"
            name="from-register-date"
            label={t("fromRegistrationDate")}
            type="date"
            placeholder=""
            value={filterFieldsState?.registerDateFrom || ""}
            onChange={(value) => setFilterField("registerDateFrom", value)}
            width={"fullWidth"}
          />

          <Input
            id="end-register-date"
            name="end-register-date"
            label={t("upToRegistrationDate")}
            type="date"
            placeholder=""
            value={filterFieldsState?.registerDateTo || ""}
            onChange={(value) => setFilterField("registerDateTo", value)}
            width={"fullWidth"}
          />

          <Input
            id="from-policy-start"
            name="from-policy-start"
            label={t("fromPolicyStartDate")}
            type="date"
            placeholder=""
            value={filterFieldsState?.policyDateFrom || ""}
            onChange={(value) => setFilterField("policyDateFrom", value)}
            width={"fullWidth"}
          />

          <CustomSelecte
            closeMenuOnSelect={false}
            onChange={(data) =>
              setFilterField(
                "policyStatus",
                data.map((item) => item.value)
              )
            }
            isMulti={true}
            title={t("policyStatus")}
            placeholder={""}
            showOptionCheckbox={true}
            value={filterFieldsState?.policyStatus?.map((s) => ({
              label: s,
              value: s,
            }))}
            options={
              policyStatusArr?.map((item) => ({
                value: item,
                label: item,
              })) || []
            }
          />

          {/* <CustomDropdown
            title="პოლისის სტატუსი"
            isMulti={false}
            value={filterFieldsState.policyStatus}
            isClearable={false}
            onChange={(obj) => setFilterField("policyStatus", obj.value)}
            options={
              insuranceUserCompanyId && auth.user?.userType === "Insurance"
                ? [
                    ...["ACTIVE", "PENDING", "CLOSED"].map((item) => ({
                      value: item,
                      label: item,
                    })),
                  ]
                : [
                    ...[
                      "ACTIVE",
                      "PENDING",
                      "CLOSED",
                      "DEACTIVATED",
                      "PENDING-DELETED",
                    ].map((item) => ({
                      value: item,
                      label: item,
                    })),
                  ]
            }
          /> */}

          <Input
            id="account-id"
            name="account-id"
            label={"Account ID"}
            type="text"
            placeholder=""
            value={filterFieldsState?.accountId || ""}
            onChange={(value) => setFilterField("accountId", value)}
            width={"fullWidth"}
          />

          <Input
            id="order-id"
            name="order-id"
            label={"Order ID"}
            type="number"
            placeholder=""
            value={filterFieldsState?.orderId || ""}
            onChange={(value) => setFilterField("orderId", value)}
            width={"fullWidth"}
          />

          <CustomDropdown
            title={t("paymentStatus")}
            isMulti={false}
            value={filterFieldsState.paymentStatus}
            isClearable={false}
            onChange={(obj) => setFilterField("paymentStatus", obj.value)}
            options={[
              ...["PAID", "UNPAID"].map((item) => ({
                value: item,
                label: item,
              })),
            ]}
          />

          <Input
            id="policy-number"
            name="policy-number"
            label={t("policyNumber")}
            type="text"
            placeholder=""
            value={filterFieldsState?.policyNumber || ""}
            onChange={(value) => setFilterField("policyNumber", value)}
            width={"fullWidth"}
          />

          <Input
            id="insurer-personal-number"
            name="insurer-personal-number"
            label={t("insurerIdNumber")}
            type="text"
            placeholder=""
            value={filterFieldsState?.personalNumber || ""}
            onChange={(value) => setFilterField("personalNumber", value)}
            width={"fullWidth"}
          />

          <Input
            id="search-name"
            name="search-name"
            label={t("isurerName")}
            type="text"
            placeholder=""
            value={filterFieldsState?.firstName || ""}
            onChange={(value) => setFilterField("firstName", value)}
            width={"fullWidth"}
          />

          <Input
            id="search-lastName"
            name="search-lastName"
            label={t("insurerSurname")}
            type="text"
            placeholder=""
            value={filterFieldsState?.lastName || ""}
            onChange={(value) => setFilterField("lastName", value)}
            width={"fullWidth"}
          />

          <Input
            id="from-payment-date"
            name="from-payment-date"
            label={t("fromPaymentsDate")}
            type="date"
            placeholder=""
            value={filterFieldsState?.paymentDateFrom || ""}
            onChange={(value) => setFilterField("paymentDateFrom", value)}
            width={"fullWidth"}
          />

          <Input
            id="end-payment-date"
            name="end-payment-date"
            label={t("uptoPaymentDate")}
            type="date"
            placeholder=""
            value={filterFieldsState?.paymentDateTo || ""}
            onChange={(value) => setFilterField("paymentDateTo", value)}
            width={"fullWidth"}
          />

          {filterFieldsState.productCategory === "TPL" && (
            <Input
              id="car-registration-numner"
              name="car-registration-numner"
              label={t("carRegistrationNumber")}
              type="text"
              placeholder=""
              value={filterFieldsState?.tplRegistrationNumber || ""}
              onChange={(value) =>
                setFilterField("tplRegistrationNumber", value)
              }
              width={"fullWidth"}
            />
          )}
        </FilterGridBlock>

        <hr />

        {authorizedCompanies && isFraniAdmin && (
          <CustomDropdown
            title={t("insuranceCompanies")}
            isMulti={true}
            value={filterFieldsState.companyIds}
            isClearable={true}
            options={companyIdArr}
            onChange={(obj) => setFilterField("companyIds", obj)}
          />
        )}

        <div>
          <br />
          <div>
            <Row>
              <Col>
                <Button
                  border={true}
                  btnStyleType="white"
                  text={t("clear")}
                  fullWidth={true}
                  onClick={() =>
                    setFilterFieldsState({
                      reportDownload: false,
                      page: 1,
                      productCategory: "",
                      firstName: "",
                      lastName: "",
                      personalNumber: "",
                      companyIds: [],
                      registerDateFrom: "",
                      registerDateTo: "",
                      paymentDateFrom: "",
                      paymentDateTo: "",
                      policyStatus: [],
                      paymentStatus: "",
                      policyNumber: "",
                      tplRegistrationNumber: "",
                      policyDateFrom: "",
                      accountId: "",
                      orderId: "",
                    })
                  }
                />
              </Col>
              <Col>
                <Button
                  border={false}
                  btnStyleType="primary"
                  text={t("filter")}
                  fullWidth={true}
                  onClick={sendFilterData} // false send filter data info & true send report url
                />
              </Col>
            </Row>
          </div>
        </div>

        {mainLoading ? (
          <Loader />
        ) : (
          <>
            <TotalCount>
              {t("searched")} <b>{filteredPolicies?.meta?.total || 0}</b>{" "}
              {t("policy")}{" "}
            </TotalCount>
            {/*  */}
            {filterFieldsState.productCategory === "TPL" && (
              <div>{t("soldPolicyNotFound")}</div>
            )}
            <hr />
            {/* searched policies */}
            {!isFilterLoading && (
              <SearchedItemsWrapper>
                {filteredPolicies && filteredPolicies?.data?.length ? (
                  <>
                    {filteredPolicies &&
                      filteredPolicies?.data?.map((item, index) => {
                        //
                        const paymentsWithoutInit =
                          item.orderItems?.[0]?.order?.payments?.filter(
                            (x) => x.status !== "init"
                          );
                        //
                        const lastPayment =
                          paymentsWithoutInit && paymentsWithoutInit.length > 0
                            ? paymentsWithoutInit[
                                paymentsWithoutInit.length - 1
                              ]
                            : null;
                        //
                        return (
                          <SearchedItemStyle key={index}>
                            {showGeneratePolicyFiles && isFraniAdmin && (
                              <div>
                                <Checkbox
                                  id="some-id"
                                  label={``}
                                  defaultChecked={
                                    selectedCompanyCheckbox.find(
                                      (x) => x === item.id
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(checked) => {
                                    if (checked === true) {
                                      addCompanyId(item.id);
                                      setTargetTypeState(item.targetType);
                                    } else {
                                      removeCompanyId(item.id);
                                      setTargetTypeState(item.targetType);
                                    }
                                  }}
                                />
                              </div>
                            )}

                            <ItemBlock
                              onClick={() => {
                                if (item?.targetType === "TravelPolicy") {
                                  // navigate travel detail page
                                  navigate(`/company-sold-policy/${item.id}`);
                                  //
                                } else if (item?.targetType === "TplPolicy") {
                                  // navigate tpl detail page
                                  navigate(
                                    `/tpl-sold-policy-detail/${item.id}`
                                  );
                                } else if (
                                  item?.targetType === "WelcomerPolicy"
                                ) {
                                  navigate(
                                    `/welcomer-sold-policy-detail/${item.id}`
                                  );
                                } else if (item?.targetType === "PetPolicy") {
                                  navigate(
                                    `/pet-sold-policy-detail/${item.id}`
                                  );
                                }
                              }}
                            >
                              <Item>
                                <div>
                                  {String(
                                    item?.targetType?.match(/[A-Z][a-z]+/g)
                                  )?.replace(",", " ")}
                                </div>
                                <div>
                                  {item?.package?.product?.company?.slug?.replace(
                                    "-",
                                    " "
                                  )}
                                </div>
                              </Item>

                              <VerticalLine />

                              <Item>
                                <div>
                                  {item?.translations?.en?.firstName}{" "}
                                  {item?.translations?.en?.lastName}
                                </div>
                                <div>
                                  {t("policyN")}
                                  {item.policyNumber}
                                </div>
                              </Item>

                              <VerticalLine />

                              <Item>
                                <div>
                                  {t("from")}{" "}
                                  {dayjs(item?.startDate).format(
                                    "DD  MMM YYYY"
                                  )}
                                </div>
                                <div>
                                  {t("up")}{" "}
                                  {dayjs(item?.endDate).format("DD MMM YYYY")}
                                </div>
                              </Item>

                              <VerticalLine />

                              <Item>
                                <div>
                                  {t("policyStatus")}: {item?.status}
                                </div>
                                <div>
                                  {t("paymentStatus")}:{" "}
                                  {lastPayment?.status || "unpaid"}
                                  {/* {lastElement?.status ?? "unpaid"} */}
                                </div>
                              </Item>

                              <VerticalLine />

                              <Item>
                                <div>{t("registrationDate")}:</div>
                                <div>
                                  {dayjs(item.createdAt).format(
                                    "DD  MMM YYYY | HH:mm"
                                  )}
                                </div>
                              </Item>
                            </ItemBlock>
                          </SearchedItemStyle>
                        );
                      })}
                  </>
                ) : null}
              </SearchedItemsWrapper>
            )}
            {/* searched policies */}

            {filteredPolicies &&
            filteredPolicies.data &&
            filteredPolicies.data.length >= 1 ? (
              <CustomPaginationWrapper>
                <ReactPaginate
                  breakLabel={"..."}
                  forcePage={(filterFieldsState.page as number) - 1}
                  nextLabel={<RightIcon />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  pageCount={pageCount || 0}
                  previousLabel={<LeftIcon />}
                  // renderOnZeroPageCount={null}
                />
              </CustomPaginationWrapper>
            ) : null}
          </>
        )}
      </AllPoliciesWrapper>
    </div>
  );
};
//
//
// styles
const FilterGridBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px 20px;
`;
const VerticalLine = styled.div`
  width: 2px;
  min-width: 2px;
  height: 40px;
  background-color: ${Colors.primaryGrey};
  margin-inline: 20px;
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  white-space: nowrap;
  width: 25%;

  div {
    &:first-child {
      color: ${Colors.primaryBlack};
    }

    &:last-child {
      color: ${Colors.primaryGrey};
    }
  }

  &:last-child {
    color: ${Colors.primaryGrey};
    text-align: right;
  }
`;
const ItemBlock = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  width: 100%;
  margin-block: 15px;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    background-color: ${Colors.primaryRed};
    transition: all 200ms ease;

    div {
      color: ${Colors.white};
    }
    ${VerticalLine} {
      background-color: ${Colors.white};
    }
  }
`;

const CustomGeneretPolicyFilesBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  align-items: center;

  margin-block: 20px;
  padding-block: 20px;
`;

const TotalCount = styled.div`
  font-size: 18px;
  color: ${Colors.secondaryBlack};
  text-align: center;
  margin-block: 20px;
  margin-inline: auto;
  padding-inline: 5px;
  border-bottom: 2px dashed ${Colors.primaryRed};
  width: fit-content;
  user-select: none;
  pointer-events: none;
  b {
    color: ${Colors.primaryRed};
  }
`;

const SearchedItemStyle = styled.div`
  display: flex;
  align-items: center;
`;
const SearchedItemsWrapper = styled.div``;

const AllPoliciesWrapper = styled.div`
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px 3px #64646411;
  box-shadow: 0px 0px 15px 3px #64646411;
  min-height: 400px;
`;
const RightIcon = styled(DoubleRightIcon)`
  color: ${Colors.primaryRed};
  height: 15px;
`;
const LeftIcon = styled(DoubleRightIcon)`
  transform: rotate(180deg);
  color: ${Colors.primaryRed};
  height: 15px;
`;
const CustomPaginationWrapper = styled.div`
  margin-block: 20px;
  display: grid;
  place-items: center;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-bottom: 0px;
    li {
      user-select: none;
      font-size: 14px;
      line-height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background-color: transparent;
      color: var(--text-color);
      height: 30px;
      width: 30px;
      cursor: pointer;
      margin-inline: 1px;

      a {
        width: 100%;
        text-align: center;
        display: grid;
        place-items: center;
      }

      &.disabled {
        opacity: 0.6;
      }

      &.break {
        a {
          height: 100%;
          margin-top: -8px;
          color: ${Colors.primaryRed};
        }
      }
      &.selected {
        background-color: ${Colors.primaryRed};
        color: ${Colors.white};
      }
    }
  }

  ${Responsive.mobile} {
    ul {
      li {
        margin: 0 5px;
        font-size: 14px;
        height: 24px;
        width: 24px;
      }
    }
  }
`;
//
//
export default AdminCompanySoldPolicies;
