import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import {
  AdminPaymentScheduleType,
} from "../../../domain/types";
import { useAppSelector } from "../../../store";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import { toast } from "react-toastify";
import CustomLink from "../../../components/CustomLink";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store/appSlice";

const AdminPaymentScheduleTypeDetail = () => {
  const { id: idStr = "0" } = useParams();
  const itemId = parseInt(idStr);
  const navigate = useNavigate();

  const [existingPaymentType, setExistingPaymentScheduleType] = useState<
    Partial<AdminPaymentScheduleType>
  >({});
  // 
  const { t } = useTranslation();
  // 
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  // 
  const {
    data: paymentScheduleTypeDetail,
    isLoading: isDeaactivePaymentScheduleTypeDetailLoading,
    refetch: refetchPolicyPaymentScheduleType,
  } = api.useGetAdminPaymentScheduleTypeDetailQuery(itemId, {
    skip: !itemId && true,
  });
  //
  //
  const [storePaymentScheduleType, { isLoading: isStorePaymentScheduleType }] =
    api.useStorePaymentScheduleTypeMutation();
  //
  //
  //  remove paymentScheduleType
  const [
    removePaymentScheduleType,
    { isLoading: isRemovePaymentScheduleTypeLoading },
  ] = api.useRemovePaymentScheduleTypeMutation();
  //
  // commented for ci/cd
  // const setEditPaymentScheduleTypeTranslationField = (
  //   field: keyof AdminPaymentScheduleType,
  //   language: EnabledLanguage,
  //   value: any
  // ) => {
  //   setExistingPaymentScheduleType({
  //     ...existingPaymentType,
  //     translations: {
  //       ...existingPaymentType.translations,
  //       [language]: {
  //         ...existingPaymentType?.translations?.[language],
  //         [field]: value,
  //       },
  //     },
  //   });
  // };
  //
  //
  useEffect(() => {
    if (paymentScheduleTypeDetail) {
      setExistingPaymentScheduleType(paymentScheduleTypeDetail);
    }
  }, [paymentScheduleTypeDetail]);
  //
  //
  useEffect(() => {
    if (itemId !== 0) {
      refetchPolicyPaymentScheduleType();
    }
    //
    return () => {};
  }, [refetchPolicyPaymentScheduleType, itemId]);
  //
  //
  // store paymentScheduleType
  const storePaymentScheduleTypeFun = async (isActive?: boolean) => {
    //
    try {
      if (itemId === 0) {
        await storePaymentScheduleType({
          translations: existingPaymentType.translations,
          number: existingPaymentType.number,
          slug: existingPaymentType.slug
        }).unwrap();
      } else {
        await storePaymentScheduleType({
          ...existingPaymentType,
          isActive: isActive,
        }).unwrap();
      }
      //
      toast.success(
        itemId === 0 ? `${t('createdSuccessfully')}` : `${t('updatedSuccessfully')}`
      );
      //
      navigate("/payment-schedule-types");
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        console.log(error);
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  const RemovePaymentScheduleTypeHandler = async () => {
    try {
      if (itemId !== 0 && itemId) {
        window.confirm(`${t('certainToDeleteIt')}`) &&
          (await removePaymentScheduleType(itemId).unwrap());
        //
        navigate("/payment-schedule-types");
      }
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        console.log(error);

        const message =
          error.data.message ===
          "PAYMENT_SCHEDULE_TYPE_REFERENCED_BY_TPL_PRICES"
            ? "გადახდის ჯერადობა გამოიყენება ფასის ლიმიტის მიერ. წაშლა ვერ მოხერხდა."
            : error;
        toast.error(message);
      }
    }
  };

  const paymentTitleChangeHandler = (language: string, value: string) => {
    if (language && value.length < 35) {
      setExistingPaymentScheduleType({
        ...existingPaymentType,
        translations: {
          ...existingPaymentType.translations,
          [language]: {
            ...existingPaymentType?.translations?.[language],
            title: value,
          },
        },
      });
    }
  };

  const paymentNumberChangeHandler = (language: string, value: number) => {
    if (language) {
      setExistingPaymentScheduleType({
        ...existingPaymentType,
        number: value,
      });
    }
  };

  const paymentSlugChangeHandler = (language: string, value: string) => {
    if (language) {
      setExistingPaymentScheduleType({
        ...existingPaymentType,
        slug: value,
      });
    }
  };

  const paymentDescriptionChangeHandler = (language: string, value: string) => {
    if (language) {
      setExistingPaymentScheduleType({
        ...existingPaymentType,
        translations: {
          ...existingPaymentType.translations,
          [language]: {
            ...existingPaymentType?.translations?.[language],
            description: value,
          },
        },
      });
    }
  };

  const MainLoader =
    isDeaactivePaymentScheduleTypeDetailLoading ||
    isStorePaymentScheduleType ||
    isRemovePaymentScheduleTypeLoading;
  //
  //
  return MainLoader ? (
    <Loader />
  ) : (
    <Wrapper>
      <Head>
        <CustomLink
          text={t('prevPage')}
          leftLineIcon={true}
          onClick={() => navigate(-1)}
        />

        {/* {itemId !== 0 && ( */}
        <AdminLangSwicher />
        {/* )} */}
      </Head>

      <br />

      <div>
        <InputTitle>{t('title')}</InputTitle>
        <InputStyle
          value={existingPaymentType.translations?.[field_Language]?.title || ""}
          onChange={(e) => paymentTitleChangeHandler(field_Language, e.target.value)}
        ></InputStyle>
      </div>

      <div>
        <InputTitle>{t('paymentsNumber')}</InputTitle>
        <InputStyle
          value={existingPaymentType.number || ""}
          onChange={(e) =>
            paymentNumberChangeHandler(field_Language, parseInt(e.target.value))
          }
        ></InputStyle>
      </div>

      <div>
        <InputTitle>{t('paymentSlug')}</InputTitle>
        <InputStyle
          value={existingPaymentType.slug || ""}
          onChange={(e) =>
            paymentSlugChangeHandler(field_Language, e.target.value)
          }
        ></InputStyle>
      </div>

      <div>
        <TextAreaTitle>{t('paymentScheduleDescription')}</TextAreaTitle>
        <TextAreaStyle
          value={
            existingPaymentType.translations?.[field_Language]?.description || ""
          }
          onChange={(e) =>
            paymentDescriptionChangeHandler(field_Language, e.target.value)
          }
        ></TextAreaStyle>
      </div>

      <br />

      <Button
        fullWidth={true}
        btnStyleType="primary"
        text={itemId ? t('update') : t('create')}
        onClick={() => storePaymentScheduleTypeFun()}
      />

      <br />

      {itemId !== 0 && (
        <ButtonDiv>
          <Button
            fullWidth={true}
            btnStyleType="transparent"
            border={true}
            text={
              existingPaymentType.isActive === true
                ? t('deactivate')
                : t('activate')
            }
            onClick={() => {
              //
              if (existingPaymentType.isActive === true) {
                storePaymentScheduleTypeFun(false);
              } else {
                storePaymentScheduleTypeFun(true);
              }

              //
            }}
          />

          <Button
            fullWidth={true}
            btnStyleType="transparent"
            border={true}
            text={t('delete')}
            onClick={RemovePaymentScheduleTypeHandler}
          />
        </ButtonDiv>
      )}
    </Wrapper>
  );
};
//
//
const ButtonDiv = styled.div`
  display: flex;

  button {
    :first-of-type {
      margin-right: 20px;
    }
  }
`;
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TextAreaTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid ${Colors.primaryRed};
  padding: 10px;
  &:focus {
    outline-color: ${Colors.primaryRed};
  }
`;
const InputTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;
const InputStyle = styled.input`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${Colors.primaryRed};
  padding: 10px;
  margin-bottom: 10px;
  &:focus {
    outline-color: ${Colors.primaryRed};
  }
`;
const Wrapper = styled.div`
  /*  */
`;
//
export default AdminPaymentScheduleTypeDetail;
