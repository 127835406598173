import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const NotFound404: React.FC = () => {
  //
  const navigate = useNavigate();
  //
  return (
    <Wrapper>
      <Result
        status="404"
        title="404"
        subTitle={"Sorry, the page you visited does not exist"}
        extra={
          <Button type="primary" onClick={() => navigate("/dashboard")}>
            Dashboard
          </Button>
        }
      />
    </Wrapper>
  );
};

// style
const Wrapper = styled.div`
  display: grid;
  place-items: center;
  /* min-height: 100vh; */
  height: 100%;
  overflow: hidden;
`;

export default NotFound404;
