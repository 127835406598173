import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { AuthState } from '../../../store/authSlice'

const Admin_profile: React.FC = () => {
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth)
  //
  return (
    <div>
      TODO: {auth.user?.firstName} {auth.user?.lastName}
    </div>
  )
}

export default Admin_profile
