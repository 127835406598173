import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../api";
import Responsive from "../../assets/config/Responsive";
import Colors from "../../assets/styles/Colors";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

const DocumentFieldUploaderBlock = ({
  url,
  onChange,
  width = 300,
}: {
  url: string;
  onChange: (newUrl: string) => void;
  width?: number;
}) => {
  //
  const [uploadFileMutation, { isLoading: isUploadFileLoading }] =
    api.useUploadFileMutation();
  //
  const { t } = useTranslation();
  //
  return isUploadFileLoading ? (
    <Loader />
  ) : (
    <div>
      <IframeDiv
        style={{
          width,
          height: Math.round((width * 428) / 300),
          border: "1px solid #E6E6E6",
          textAlign: "center",
          borderRadius: 6,
          display: "grid",
          placeItems: "center",
        }}
      >
        {url && url !== "file-goes-here.pdf" ? (
          <iframe
            src={url}
            title={url}
            style={{
              width: "100%",
              height: "100%",
              boxSizing: "border-box",
              objectFit: "cover",
            }}
          ></iframe>
        ) : (
          <b>{t("noFileChosen")}</b>
        )}
      </IframeDiv>
      <div style={{ marginBlock: "20px" }}>
        <a
          href={url}
          style={{ color: Colors.primaryRed }}
          target="_blank"
          rel="noreferrer"
        >
          {t("documentLink")}
        </a>
      </div>
      <div>
        <input
          type="file"
          onChange={async (event) => {
            const file = event?.target?.files?.[0];
            if (!file) {
              toast.error(`${t("pleaseChooseFile")}`);
              return;
            }
            const result = await uploadFileMutation({ file }).unwrap();
            console.log("upload file result", result);
            onChange(result.url);
          }}
        />
      </div>
    </div>
  );
};

const IframeDiv = styled.div`
  width: 300px;
  height: 428px;
  ${Responsive.tablet} {
    margin-inline: auto;
  }
`;

export default DocumentFieldUploaderBlock;
