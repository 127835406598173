import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import UploadFile from "../../../components/UploadFile";
import BaseModal from "../../../components/modals/BaseModal";
import { AddTravelRisk, EnabledLanguage } from "../../../domain/types";
import { useAppDispatch, useAppSelector } from "../../../store";
import { AppState, setEditedlanguage } from "../../../store/appSlice";
import Button from "../../admin_components/AdminButton";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";
//
//
type AddTravelRiskTranslatedFields = {
  riskName: string;
  icon: string;
};
//
//
const TravelRisk = () => {
  //
  const dispatch = useAppDispatch();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const {lang} = useAppSelector((root) => root.app)
  // 
  const { t } = useTranslation();
  // selected risk id
  const [travelRiskId, setTravelRiskId] = useState<number | null>(null);
  //
  // states
  const [openTravelAddModal, setOpenTravelAddModal] = useState<boolean>(false);
  //
  // add travel risk state
  const [addTravelRiskState, setAddTravelRiskState] = useState<
    Partial<AddTravelRisk>
  >({});
  //
  // get all travel risks list
  const {
    data: travelRisks,
    isLoading: isTravelRisksLoading,
    refetch: refetchTravelRisks,
  } = api.useGetAdminTravelRiskListQuery(undefined);
  //
  //
  // add travel risk
  const [saveTravelRisk, { isLoading: isAddTravelRiskLoading }] =
    api.useSaveTravelRiskMutation();
  //
  // remvoe travel risk
  const [
    removeTravelRisk,
    { isLoading: isRemoveTravelRiskLoading, isSuccess: removeTravelRiskSucces },
  ] = api.useRemoveTravelRiskMutation();
  //
  //
  //close add trave risk modal
  const _onAddTravelRiskCloseModal = () => {
    setOpenTravelAddModal(false);
    setTravelRiskId(null);
  };
  //
  // add travel risk fields
  const setAddTravelRisk = (field: keyof AddTravelRisk, value: any) => {
    setAddTravelRiskState({
      ...addTravelRiskState,
      [field]: value,
    });
  };
  //
  //
  const { data: existingTravelRisk, isLoading: isExistingTravelRiskLoading } =
    api.useGetAdminTravelRiskByIdQuery(travelRiskId || 0);
  //
  //
  // add travel translation fields
  const setAddTravelRiskTranslatedField = (
    field: keyof AddTravelRiskTranslatedFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddTravelRiskState({
      ...addTravelRiskState,
      translations: {
        ...addTravelRiskState.translations,
        [language]: {
          ...addTravelRiskState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  // add travel risk function
  const AddTravelRiskFunction = async () => {
    //
    try {
      await saveTravelRisk(addTravelRiskState).unwrap();
      //
      // refetch travel risks
      refetchTravelRisks();
      // close modal
      _onAddTravelRiskCloseModal();
      //
      toast.success(`${t('risk')} ${t('addedSuccessfully')}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  // auto refetch travel risks when user remove risk
  useEffect(() => {
    if (removeTravelRiskSucces) {
      refetchTravelRisks();
      _onAddTravelRiskCloseModal();
    }
  }, [removeTravelRisk, removeTravelRiskSucces, refetchTravelRisks]);
  //
  //
  //
  useEffect(() => {
    if (existingTravelRisk) {
      setAddTravelRiskState(existingTravelRisk);
    }
  }, [existingTravelRisk]);
  //
  //
  // main loading
  const MainLoding =
    isTravelRisksLoading ||
    isAddTravelRiskLoading ||
    isRemoveTravelRiskLoading ||
    isExistingTravelRiskLoading;
  //
  //
  return MainLoding ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        btnStyleType="primary"
        text={t('createRisk')}
        onClick={() => [
          setOpenTravelAddModal(!openTravelAddModal),
          setAddTravelRiskState({}),
        ]}
      />
      <br />
      <br />
      <RisksContent>
        {travelRisks &&
          travelRisks.map((item, index) => (
            <RiskBox
              key={index}
              onClick={() => [
                setTravelRiskId(item.id),
                setOpenTravelAddModal(!openTravelAddModal),
              ]}
            >
              <img src={item.icon} alt={`risk icon`} />
              <div>{item.translations[lang]?.riskName}</div>
            </RiskBox>
          ))}
      </RisksContent>
      {/*  */}
      {/* add new travel risk */}
      <BaseModal
        onClose={_onAddTravelRiskCloseModal}
        isOpen={openTravelAddModal}
      >
        <div>
          <ModalHead>
            <h4>{travelRiskId ? t('update') : t('create')}</h4>
            <AdminLangSwicher
              onChange={(value) => dispatch(setEditedlanguage(value))}
            />
          </ModalHead>

          <FieldsContent>
            <Input
              id="risk-name"
              label={t('riskTitle')}
              type="text"
              placeholder=""
              value={
                addTravelRiskState?.translations?.[field_Language]?.riskName ||
                ""
              }
              name="risk-name"
              onChange={(value) =>
                setAddTravelRiskTranslatedField(
                  "riskName",
                  field_Language,
                  value
                )
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <UploadFile
              label={t('riskIcon')}
              onChange={(props) => setAddTravelRisk("icon", props.url)}
              defaultFileName={addTravelRiskState?.icon || ""}
            />

            <ButtonsBlock>
              {/* add travel risk */}
              <Button
                fullWidth={true}
                btnStyleType="primary"
                text={travelRiskId ? t('update') : t('create')}
                onClick={AddTravelRiskFunction}
              />
              {/* add travel risk */}
              <br />
              {/* remove travel risk */}
              {travelRiskId && (
                <Button
                  fullWidth={true}
                  btnStyleType="transparent"
                  border={true}
                  text={t('removeRisk')}
                  onClick={() =>
                    window.confirm(`${t('certainToDeleteIt')}`) &&
                    removeTravelRisk(travelRiskId)
                  }
                />
              )}
              {/* remove travel risk */}
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
      {/* add new travel risk  */}
      {/*  */}
    </Wrapper>
  );
};
//
//styles
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 40px;
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.grey};
`;
const RisksContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  /*  */
  img {
    width: 50px;
  }
`;
const RiskBox = styled.div`
  height: 150px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
`;
const Wrapper = styled.div``;
//
export default TravelRisk;
