import React, { useState } from "react";
// import { useNavigate } from 'react-router-dom'
import styled from "styled-components";
// import Accordion from '../../../../../components/Accordion'
// import Input from '../../../../admin_components/AdminInput'
import Button from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";
import NotFound404 from "../../../../../components/NotFound404";
import {
  Country,
  Currency,
  TravelAbroadArea,
  TravelAddon,
  TravelFranchise,
  TravelMultiDayOption,
  TravelProduct,
  TravelRisk,
} from "../../../../../domain/types";
// import { Checkbox, InputLabel } from '@mui/material'
import { toast } from "react-toastify";
import api from "../../../../../api";
import BaseModal from "../../../../../components/modals/BaseModal";
import TravelPackageEditor from "./TravelPackageEditor";
import { useTranslation } from "react-i18next";
//
//

//
//
const EditAbroadPackagesTab: React.FC<{
  travelProduct?: TravelProduct;
  countries: Country[];
  travelFranchises: TravelFranchise[];
  travelRisks: TravelRisk[];
  travelMultiDays: TravelMultiDayOption[];
  travelAbroadAddons: TravelAddon[];
  isLoading: boolean;
  // setTranslatedFieldOnProduct: any
  // setTravelProductField: any
  refetchExistingTravelProduct: () => void;
  /** setTravelProductField is not used for saving packages, but only to receive price combination-related updates */
  setTravelProductField: (
    fieldName: keyof TravelProduct,
    fieldValue: any
  ) => void;
  setUsedCurrencies: (travelPackageId: number, _: Currency[]) => void;
  setUsedAreas: (travelPackageId: number, _: TravelAbroadArea[]) => void;
  setUsedFranchises: (travelPackageId: number, _: number[]) => void;
}> = ({
  travelProduct,
  countries,
  travelFranchises,
  travelRisks,
  travelMultiDays,
  travelAbroadAddons,
  // setTranslatedFieldOnProduct,
  isLoading,
  refetchExistingTravelProduct,
  setTravelProductField,
  setUsedCurrencies,
  setUsedAreas,
  setUsedFranchises,
}) => {
  //
  //
  const [
    createTravelPackage, //{ isLoading: isCreateTravelPackageLoading }
  ] = api.useCreateTravelPackageMutation();
  //
  const [isAddPackageModalOpen, setIsAddPackageModalOpen] =
    useState<boolean>(false);
  const _onCloseAddModal = () => setIsAddPackageModalOpen(false);
  //
  const { t } = useTranslation()
  //
  return isLoading ? (
    <Loader />
  ) : !travelProduct ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <Block>
        <Button
          btnStyleType="primary"
          icon={false}
          onClick={() => setIsAddPackageModalOpen(true)}
          text={t('addPackage')}
        />
      </Block>
      <br />
      {[...travelProduct.mainProduct.packages]
        .sort((pkg1, pkg2) => (pkg1.id > pkg2.id ? 1 : -1))
        .map((pkg) =>
          pkg.target ? ( //
            <TravelPackageEditor
              key={pkg.id}
              travelPackageId={pkg.target.id}
              countries={countries}
              travelFranchises={travelFranchises}
              travelRisks={travelRisks}
              travelMultiDays={travelMultiDays}
              travelAbroadAddons={travelAbroadAddons}
              refetchTravelProduct={refetchExistingTravelProduct}
              travelProduct={travelProduct}
              setTravelProductField={setTravelProductField}
              setUsedCurrencies={setUsedCurrencies}
              setUsedAreas={setUsedAreas}
              setUsedFranchises={setUsedFranchises}
            />
          ) : null
        )}
      {/* add package modal */}
      <BaseModal onClose={_onCloseAddModal} isOpen={isAddPackageModalOpen}>
        <div>
          <Block>
          <Text>{t('addPackage')}</Text>
            {/* <Text>დასახელება</Text>
            <Input
              id=""
              label=""
              type="text"
              placeholder=""
              value={editedPackage.mainPackage.title}
              onChange={(value) => setMainPackageField('title', value)}
              width={'fullWidth'}
            /> */}
          </Block>
          {/* {selectedProductCategoryID === undefined ? null : ( */}
          <Button
            btnStyleType="primary"
            icon={false}
            fullWidth={true}
            disabled={/* 'validation' == */ false}
            onClick={async () => {
              try {
                const title = `${travelProduct.mainProduct.company.title}, ${
                  travelProduct.mainProduct.productCategory.title
                } დაზღვევა, პაკეტი ${
                  travelProduct.mainProduct.packages.length + 1
                }`;
                await createTravelPackage({
                  productId: travelProduct.mainProduct.id,
                  translations: {
                    ge: {
                      title,
                    },
                    en: {
                      title,
                    }
                  },
                }).unwrap();
                //
                // refetch product list by id
                refetchExistingTravelProduct();
                _onCloseAddModal();
                toast.success(`${t('package')} ${t('addedSuccessfully')}`);
              } catch (error) {
                if (error?.data?.errors?.constructor === Array) {
                  const firstError = error.data.errors[0];
                  const errorMessage = firstError.message;
                  toast.error(errorMessage);
                } else {
                  toast.warning(`${t('fillAllFields')}`);
                }
              }
            }}
            text={t('create')}
          />
          {/* )} */}
        </div>
        {/*  */}
      </BaseModal>
    </Wrapper>
  );
};
//
//
// styles
// const Text = styled.div``
const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /*  */
  > div {
    flex-basis: 50%;
  }
`;
const Wrapper = styled.div`
  padding: 20px 15px;
`;

const Text = styled.div`
  margin-bottom: 10px;
`;

export default EditAbroadPackagesTab;
