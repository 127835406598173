import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Drawer,
  Flex,
  Image,
  Input,
  Popconfirm,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdDeleteForever } from "react-icons/md";
import { TiPlus } from "react-icons/ti";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import api, { apiUrl } from "../../../api";
import Colors from "../../../assets/styles/Colors";
import Loader from "../../../components/Loader";
import { EnabledLanguage, LandingSlide } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import { getAuthToken } from "../../../store/tokenStorage";

const AdminLandingSlider = () => {
  //
  const { field_Language } = useAppSelector(
    (root: { app: AppState }) => root.app
  );
  //
  const [existingSlideId, setExistingSlideId] = useState<number | undefined>(
    undefined
  );
  //
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  //
  const [imageUploadLoading, setImageUploadLoading] = useState<boolean>(false);
  //
  const [slide, setSlide] = useState<Partial<LandingSlide> | null>(null);
  //
  const { t } = useTranslation();
  //
  const _closeCreatePromoCodeDrawer = () => {
    setDrawerStatus(false);
    setSlide(null);
    setExistingSlideId(undefined);
  };
  //
  const setSlideField = (field: keyof LandingSlide, value: any) => {
    setSlide({
      ...slide,
      [field]: value,
    });
  };
  //
  const setSlideTranslationField = (
    field: keyof { title: string; description: string; btnText: string },
    language: EnabledLanguage,
    value: any
  ) => {
    setSlide({
      ...slide,
      translations: {
        ...slide?.translations,
        [language]: {
          ...slide?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  const [debouncedPromoCodeName] = useDebounce(slide?.promoCodeId, 1000);
  //
  const query = `${
    debouncedPromoCodeName !== undefined
      ? `name=${debouncedPromoCodeName}&`
      : ``
  }`;
  //
  const { data: PromoCodeList, isLoading: isPromoCodeListLoading } =
    api.useGetPromoCodesListQuery({
      query: query,
    });
  //
  const { data: prodcutsList, isLoading: isProdcutsListLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);
  //
  const [
    removeSlideById,
    { isLoading: isRemoveSlideByIdLoading, isSuccess: removeSlideByIdSucces },
  ] = api.useDeleteSlideByIdMutation();
  //
  const { data: slideById, isLoading: isSlideByIdLoading } =
    api.useGetLandingSlideByIdQuery(
      { id: existingSlideId as number },
      {
        skip: existingSlideId ? false : true,
        refetchOnMountOrArgChange: true,
      }
    );
  //
  const { data: slidesArray, refetch: refetchSlides } =
    api.useGetLandingSlidesQuery(undefined);
  //
  const [createSlide] = api.useCreateLandingSlideMutation();
  //
  const [updateSlide] = api.useUpdateLandingSlideMutation();
  //
  const _createSlide = async () => {
    //
    if (slide) {
      try {
        await createSlide(slide).unwrap();
        //
        setDrawerStatus(false);
        refetchSlides();
        toast.success(`${t("createdSuccessfully")}`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    } else {
      toast.error("empty");
    }
  };
  //
  const _updateSlide = async () => {
    //
    if (slide) {
      try {
        await updateSlide(slide).unwrap();
        //
        setDrawerStatus(false);
        refetchSlides();
        toast.success(`${t("updated")}`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    } else {
      toast.error("empty");
    }
  };
  //
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setImageUploadLoading(true);
      return;
    }

    if (info.file.status === "done") {
      const response = info.file.response;
      setSlide({
        ...slide,
        photoUrl: response?.photoUrl,
        fileKey: response?.fileKey,
      });
      setImageUploadLoading(false);
    }
  };
  //
  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {imageUploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      {imageUploadLoading ? (
        <div>{t("loading")}</div>
      ) : (
        <div style={{ marginTop: 8 }}>{t("uploadImage")}</div>
      )}
    </button>
  );
  // set existing slide
  useEffect(() => {
    if (existingSlideId && slideById) {
      setSlide(slideById);
    }
    return () => {};
  }, [slideById, existingSlideId]);
  // when user remove slide & auto refetch
  useEffect(() => {
    if (removeSlideByIdSucces) {
      refetchSlides();
      _closeCreatePromoCodeDrawer();
    }
    return () => {};
  }, [removeSlideByIdSucces, refetchSlides]);
  //
  const MainLoading = isSlideByIdLoading || isRemoveSlideByIdLoading;
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        type="default"
        size="large"
        style={{
          display: "flex",
          placeItems: "center",
          gap: "10px",
        }}
        onClick={() => setDrawerStatus(true)}
      >
        <TiPlus /> {t("add")}
      </Button>

      <Divider />

      <SlideDiv>
        {slidesArray?.data.map((item, index) => (
          <SlideItem key={index}>
            <Image height={200} src={item.photoUrl} />

            <TextsDiv>
              <Divider />
              <Typography.Text>
                {item?.translations?.[field_Language]?.title}
              </Typography.Text>

              <Divider />

              <Typography.Text>
                {item?.translations?.[field_Language]?.description}
              </Typography.Text>

              <Divider />

              <Typography.Text>
                {item?.translations?.[field_Language]?.btnText}
              </Typography.Text>

              <Divider />

              <Button
                type="primary"
                onClick={() => {
                  if (item.id) {
                    return [setExistingSlideId(item.id), setDrawerStatus(true)];
                  } else {
                    toast.error("slide ID not fount !");
                  }
                }}
              >
                {t("update")}
              </Button>
            </TextsDiv>
          </SlideItem>
        ))}
      </SlideDiv>

      {/* drawer */}
      <Drawer
        width={500}
        title={existingSlideId ? t("update") : t("generate")}
        onClose={_closeCreatePromoCodeDrawer}
        open={drawerStatus}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: "100%",
          }}
        >
          <div>
            <Typography.Text>{t("promoCodes")}</Typography.Text>
            <Select
              size="large"
              loading={isPromoCodeListLoading}
              showSearch
              style={{ width: "100%", display: "block" }}
              placeholder={t("promoCodes")}
              optionFilterProp="label"
              allowClear
              value={slide?.promoCodeId || undefined}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={(value) => setSlideField("promoCodeId", value)}
              options={PromoCodeList?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </div>

          <div>
            <Typography.Text>{t("applyProduct")}</Typography.Text>
            <Select
              loading={isProdcutsListLoading}
              size="large"
              style={{ width: "100%", minWidth: "200px" }}
              placeholder={`${t("applyProduct")}`}
              value={slide?.productCategoryId || undefined}
              onChange={(value: number) => {
                setSlideField("productCategoryId", value);
              }}
              options={[
                ...(prodcutsList || []).map((product) => ({
                  label: product?.slug?.replace(/-/g, " "),
                  value: product?.id,
                })),
              ]}
            />
          </div>

          <div>
            <Typography.Text>{t("link")}</Typography.Text>
            <Input
              size="large"
              placeholder={`${t("sliderTitle")}`}
              value={slide?.redirectUrl || ""}
              onChange={(e) => setSlideField("redirectUrl", e.target.value)}
            />
            <Alert
              style={{
                marginTop: "10px",
              }}
              message="მიუთითეთ მხოლოდ გვერდის მისამართი საიტის დომეინის გარეშე, მაგ: category | არ მიუთითოთ მსგავსად: https://frani.com/category"
              type="warning"
              closable
            />
          </div>

          <div>
            <Typography.Text>{t("sliderTitle")}</Typography.Text>
            <Input
              size="large"
              placeholder={`${t("sliderTitle")}`}
              value={slide?.translations?.[field_Language]?.title || ""}
              onChange={(e) =>
                setSlideTranslationField(
                  "title",
                  field_Language,
                  e.target.value
                )
              }
            />
          </div>

          <div>
            <Typography.Text>{t("description")}</Typography.Text>
            <Input
              size="large"
              placeholder={`${t("description")}`}
              value={slide?.translations?.[field_Language]?.description || ""}
              onChange={(e) =>
                setSlideTranslationField(
                  "description",
                  field_Language,
                  e.target.value
                )
              }
            />
          </div>

          <div>
            <Typography.Text>{t("button")}</Typography.Text>
            <Input
              size="large"
              placeholder={`${t("button")}`}
              value={slide?.translations?.[field_Language]?.btnText || ""}
              onChange={(e) =>
                setSlideTranslationField(
                  "btnText",
                  field_Language,
                  e.target.value
                )
              }
            />
          </div>

          {/*  */}

          <Flex justify="space-evenly" align="flex-start">
            <div>
              <Typography.Text>{t("image")}</Typography.Text>
              <Upload
                name="file"
                action={`${apiUrl}admin/slides/store-file`}
                headers={{ authorization: `Bearer ${getAuthToken()}` }}
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                onChange={handleChange}
              >
                {slide && slide.photoUrl ? (
                  <img
                    src={slide.photoUrl}
                    alt="file"
                    style={{
                      width: "100%",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>

            <Checkbox
              style={{
                marginTop: "10px",
              }}
              checked={slide?.published || false}
              onChange={(e) => {
                if (e.target.checked) {
                  setSlideField("published", true);
                } else {
                  setSlideField("published", false);
                }
              }}
            >
              {t("publishe")}
            </Checkbox>
          </Flex>
        </Space>

        <Divider />

        <Flex justify="space-between" align="center" gap={20}>
          <Button
            type="default"
            size="large"
            block
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={() => {
              if (existingSlideId) {
                _updateSlide();
              } else {
                _createSlide();
              }
            }}
          >
            {existingSlideId ? t("update") : t("create")}
          </Button>

          <Popconfirm
            title={t("certainToDeleteIt")}
            onConfirm={() => removeSlideById(existingSlideId as number)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              type="dashed"
              size="large"
              block
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <MdDeleteForever /> {t("delete")}
            </Button>
          </Popconfirm>
        </Flex>
      </Drawer>
      {/* drawer */}
    </Wrapper>
  );
};
//
const TextsDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const SlideItem = styled.div`
  border: 1px solid ${Colors.nevadaGrey};
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  img {
    height: 200px;
    object-fit: cover;
  }
`;
const SlideDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;
const Wrapper = styled.div`
  overflow: hidden;
  /*  */

  .ant-carousel .slick-dots {
    background-color: ${Colors.primaryRed} !important;
    width: fit-content;
    height: 28px;
    margin-inline: auto;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    border-radius: 6px;
    padding-top: 8px;
  }
`;
//
export default AdminLandingSlider;
