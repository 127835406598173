import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Colors from "../../../assets/styles/Colors";
import { useTranslation } from "react-i18next";
//
//
const Admin_manage_pages = () => {
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation();
  //
  return (
    <Wrapper>
      <GridBlock>
        <Box onClick={() => navigate("/landing-manage-texts")}>
          {t("mainPage")}
        </Box>
        <Box onClick={() => navigate("/manage-faq")}>{t("FAQpage")}</Box>
        <Box onClick={() => navigate("/about-us-edit")}>{t("aboutUsPage")}</Box>
        <Box onClick={() => navigate("/edit-product-detail")}>
          {t("productDetailPage")}
        </Box>
        <Box onClick={() => navigate("/frani-partners")}>
          {t("partnersPage")}
        </Box>
        <Box onClick={() => navigate("/landing-slider")}>
          {t("landingSlider")}
        </Box>
      </GridBlock>
    </Wrapper>
  );
};
//
const Box = styled.div`
  height: 200px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
  /*  */
  img {
    width: 60px;
  }
  /*  */
  div {
    font-size: 18px;
    margin-block: 10px;
    text-align: center;
  }
  /*  */
  p {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
  }
`;
const GridBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  /*  */
`;
const Wrapper = styled.div``;
//
export default Admin_manage_pages;
