import React from 'react'
import styled from 'styled-components'
import Responsive from '../../assets/config/Responsive'
import PlusIcon from '../../assets/icons/JSX/PlusIcon'
import Colors from '../../assets/styles/Colors'
import ButtonPresets from '../../components/Button.presets'

const AdminButton: React.FC<{
  fullWidth?: boolean
  size?: 'large' | 'medium'
  border?: boolean
  btnStyleType?: 'transparent' | 'primary' | 'secondary' | 'white'
  disabled?: boolean
  text?: string
  icon?: boolean
  type?: 'submit' | 'button' | 'reset'
  onClick?: () => void
  style?: React.CSSProperties
}> = ({
  fullWidth = false,
  size = 'medium',
  border = false,
  btnStyleType = 'primary',
  disabled = false,
  text = 'No text specified',
  icon = false,
  type,
  onClick,
  style,
}) => {
  return (
    <Btn
      style={{ ...style, opacity: disabled ? '0.6' : 'unset' }}
      disabled={disabled}
      $size={size}
      $fullWidth={fullWidth}
      $border={border}
      $btnStyleType={btnStyleType}
      onClick={onClick}
      type={type ?? 'button'}
    >
      {icon === true ? <PlusIcon /> : null}
      <span>{text}</span>
    </Btn>
  )
}
//
//
// styles levan madurashvili
const Btn = styled.button<{ $fullWidth: boolean; $size: string; $border: boolean; $btnStyleType: string }>`
  /* styles with props */
  width: ${({ $fullWidth }) => ($fullWidth === true ? '100%' : 'fit-content')};
  height: ${({ $size }) => ($size === 'large' ? '64px' : '56px')};
  border: ${({ $border }) => ($border === true ? `1px solid ${Colors.primaryBlack}` : 'unset')};
  font-size: ${({ $size }) => ($size === 'large' ? '18px' : '14px')};

  /* color preset */
  ${({ $btnStyleType }) => ButtonPresets[$btnStyleType]}

  /* styles */
  padding-left: 20px;
  padding-right: 20px;
  min-width: ${({ $size }) => ($size === 'large' ? '260px' : 'unset')};
  border-radius: 4px;
  outline: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
  /* btn text */
  span {
    color: inherit;
  }

  /* btn icon */
  svg {
    color: inherit;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    /* margin-bottom: -2px; */
  }

  /* responsive */
  ${Responsive.mobile} {
    min-width: unset;
  }
`

export default AdminButton
