import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api";
import styled from "styled-components";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import { EnabledLanguage, FraniPartnerType } from "../../../domain/types";
import { AppState, setEditedlanguage } from "../../../store/appSlice";
import { useAppSelector } from "../../../store";
import Input from "../../../components/Input";
import UploadFile from "../../../components/UploadFile";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import { useDispatch } from "react-redux";
import AdminButton from "../../admin_components/AdminButton";
import Editor from "../../admin_components/admin_editor/Editor";
import { useTranslation } from "react-i18next";
//
type TranslationFields = {
  title: string;
  body: string;
  secondBody: string;
  thirdBody: string;
};
//
const AdminPartnerDetail = () => {
  const { id: partneridStr = "0" } = useParams();
  const partnerId = parseInt(partneridStr);
  //
  const dispatch = useDispatch();
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const [existingPartner, setExistingPartner] = useState<
    Partial<FraniPartnerType>
  >({});
  //
  //
  const { data: franiPartner, isLoading: isFraniPartnerLoading } =
    api.useGetFraniPartnersByidQuery(partnerId, {
      skip: partnerId && partnerId !== 0 ? false : true,
    });
  //
  const [createPartner, { isLoading: isCreatePartnerLoading }] =
    api.useCreatePartnerMutation();
  //
  const [updatePartner, { isLoading: isUpdatePartnerLoading }] =
    api.useUpdatePartnerMutation();
  //
  const [deletePartner, { isLoading: isDeletePartnerLoading }] =
    api.useDeletePartnerMutation();
  //
  //
  const setPartnerField = (
    field: keyof Partial<FraniPartnerType>,
    value: any
  ) => {
    setExistingPartner({
      ...existingPartner,
      [field]: value,
    });
  };
  //
  //
  const setFraniTranslationField = (
    field: keyof TranslationFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setExistingPartner({
      ...existingPartner,
      translations: {
        ...existingPartner.translations,
        [language]: {
          ...existingPartner?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  const _createPartner = async () => {
    //
    try {
      await createPartner(existingPartner).unwrap();
      //
      navigate("/frani-partners");
      //
      toast.success(`${t("partnerCompany")} ${t("addedSuccessfully")}`);
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  const _updatePartner = async () => {
    //
    try {
      await updatePartner(existingPartner).unwrap();
      //
      navigate("/frani-partners");
      //
      toast.success(`${t("partnerCompany")} ${t("updatedSuccessfully")}`);
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  const _deletePartner = async () => {
    //
    try {
      await deletePartner(partnerId).unwrap();
      //
      navigate("/frani-partners");
      //
      toast.success(`${t("partnerCompany")} ${t("deletedSuccessfully")}`);
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  //
  useEffect(() => {
    // set existing partner
    if (partnerId && franiPartner) {
      setExistingPartner(franiPartner);
    }
    return () => {};
  }, [franiPartner, partnerId]);

  //
  //
  const MainLoading =
    isCreatePartnerLoading ||
    isFraniPartnerLoading ||
    isUpdatePartnerLoading ||
    isDeletePartnerLoading;
  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <HeadStyle>
        <h6>
          {partnerId && partnerId !== 0
            ? `${t("updatePartner")}`
            : `${t("addNewParnter")}`}
        </h6>

        <AdminLangSwicher
          onChange={(value) => dispatch(setEditedlanguage(value))}
        />
      </HeadStyle>

      <hr />

      <Block>
        <Inputs>
          <Input
            id="partner-title"
            label={t("title")}
            type="text"
            placeholder=""
            value={existingPartner?.translations?.[field_Language]?.title || ""}
            name="addon-title"
            onChange={(value) =>
              setFraniTranslationField("title", field_Language, value)
            }
            width={"fullWidth"}
          />

          <Input
            id="partner-title"
            label={`${t("siteAddress")} (https://frani.com)`}
            type="text"
            placeholder=""
            value={existingPartner?.webAddress || ""}
            name="addon-title"
            onChange={(value) => setPartnerField("webAddress", value)}
            width={"fullWidth"}
          />

          <UploadFile
            label={t("imageIcon")}
            onChange={(props) => setPartnerField("imageUrl", props.url)}
            defaultFileName={existingPartner?.imageUrl || ""}
          />
        </Inputs>

        <hr />

        <Inputs>
          <Editor
            label={t("companyBriefDescription")}
            value={existingPartner.translations?.[field_Language]?.body}
            onChange={(value) => {
              if (existingPartner) {
                setFraniTranslationField("body", field_Language, value);
              }
            }}
          />

          <Editor
            label={t("companyDescription")}
            value={existingPartner.translations?.[field_Language]?.secondBody}
            onChange={(value) => {
              if (existingPartner) {
                setFraniTranslationField("secondBody", field_Language, value);
              }
            }}
          />
        </Inputs>
      </Block>

      <Buttons>
        <AdminButton
          fullWidth={true}
          btnStyleType="primary"
          text={
            partnerId && partnerId !== 0 ? `${t("update")}` : `${t("create")}`
          }
          onClick={() => {
            if (partnerId && partnerId !== 0) {
              _updatePartner();
            } else {
              _createPartner();
            }
          }}
        />

        {partnerId && partnerId !== 0 ? (
          <AdminButton
            fullWidth={true}
            btnStyleType="secondary"
            text={t("deletePartner")}
            onClick={() => {
              if (window.confirm(`${t("certainToDeleteIt")}`)) {
                _deletePartner();
              }
            }}
          />
        ) : null}
      </Buttons>
    </Wrapper>
  );
};
//
const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
`;
const HeadStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Block = styled.div`
  /*  */
`;
const Wrapper = styled.div`
  /*  */
`;
//
export default AdminPartnerDetail;
