import * as React from "react"

function PlusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={10}
      viewBox="0 0 10 10"
      {...props}
    >
      <path
        data-name="+"
        d="M24573.33 13478.475v-4.334h-4.33v-1.332h4.328v-4.334h1.338v4.334h4.334v1.332h-4.334v4.334z"
        transform="translate(-24569.002 -13468.475)"
        fill="CurrentColor"
      />
    </svg>
  )
}

export default PlusIcon
