import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import { MediumScreenTitle } from "../../../assets/styles/GlobalStyle";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import BaseModal from "../../../components/modals/BaseModal";
import UploadFile from "../../../components/UploadFile";
import { AboutUsType, EnabledLanguage, OurTeam } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store/appSlice";
//
//
//
const Admin_about_us = () => {
  //
  const { t } = useTranslation()
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );  
  //
  //
  const [selectedMemberID, setSelectedMemberID] = useState<
    number | null | undefined
  >(null);
  //
  const [selectedMember, setSelectedMember] = useState<Partial<AboutUsType>>(
    {}
  );
  //
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  //
  const [editedTexts, setEditedTexts] = useState<Partial<AboutUsType>>({});
  //
  const {
    data: aboutUsArray,
    isLoading: isOurTeamLoading,
    refetch: refetchMember,
  } = api.useGetWebPageQuery("about-us");
  const ourTeam: AboutUsType = aboutUsArray as AboutUsType;
  //
  //
  const {
    data: aboutUsTexts,
    isLoading: isAboutUsTextsLoading,
    refetch: isAbouSTextsRefetch,
  } = api.useGetAdminAboutUsTextsQuery(undefined);
  //
  //
  //close base modal
  const _onCloseModal = () => {
    setOpenEditModal(false);
    setSelectedMemberID(null);
  };
  //
  //
  const setMemberFields = (field: keyof OurTeam, value: any) => {
    setSelectedMember({
      ...selectedMember,
      [field]: value,
    });
  };
  //
  //
  const setEditAboutTextsField = (
    field: keyof AboutUsType,
    language: EnabledLanguage,
    value: any
  ) => {
    setEditedTexts({
      ...editedTexts,
      translations: {
        ...editedTexts.translations,
        [language]: {
          ...editedTexts?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  const setEditMemberTranslatedField = (
    field: keyof OurTeam,
    language: EnabledLanguage,
    value: any
  ) => {
    setSelectedMember({
      ...selectedMember,
      translations: {
        ...selectedMember.translations,
        [language]: {
          ...selectedMember?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  const { data: currentMemberData, isLoading: isCurrentMemberDataLoading } =
    api.useGetCurrentTeamMemberQuery(selectedMemberID as number, {
      skip: !selectedMemberID && true,
    });
  //
  const [updateAboutUsTexts, { isLoading: isUpdateAboutUsTextsLoading }] =
    api.useUpdateAboutUsTextsMutation();
  //
  // update about us texts mutation
  const [storeTeamMember, { isLoading: isStoreTeamMemberLoading }] =
    api.useStoreTeamMemberMutation();
  //
  //
  const UpdateAbouUsTexts = async () => {
    //
    try {
      await updateAboutUsTexts(editedTexts).unwrap();
      //
      // refetch
      isAbouSTextsRefetch();
      //
      toast.success(`${('information')} ${t('updatedSuccessfully')}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  // update & store
  const StoreMember = async () => {
    //
    try {
      await storeTeamMember(selectedMember).unwrap();
      //
      // refetch
      refetchMember();
      // close modal
      _onCloseModal();
      //
      toast.success(
        selectedMemberID ? `${t('user')} ${('updatedSuccessfully')}` : `${t('createdSuccessfully')}`
      );
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  useEffect(() => {
    if (currentMemberData) {
      setSelectedMember(currentMemberData);
    }
  }, [currentMemberData]);
  //
  //
  useEffect(() => {
    if (aboutUsTexts) {
      setEditedTexts(aboutUsTexts);
    }
  }, [aboutUsTexts]);
  //
  //
  const MainLoader =
    isOurTeamLoading ||
    isStoreTeamMemberLoading ||
    isCurrentMemberDataLoading ||
    isOurTeamLoading ||
    isUpdateAboutUsTextsLoading ||
    isAboutUsTextsLoading;
  //
  return MainLoader ? (
    <Loader />
  ) : (
    <Wrapper>
      <MediumScreenTitle>{t('manageAboutUs')}</MediumScreenTitle>
      <Button
        btnStyleType="primary"
        text={t('addMember')}
        onClick={() => [
          setOpenEditModal(!openEditModal),
          setSelectedMember({}),
        ]}
      />

      <br />
      <br />

      <AdminLangSwicher />
      <br />
      <br />
      <br />
      {/*  */}
      {/*  */}
      <MediumScreenTitle>{t('manageTexts')}</MediumScreenTitle>

      <EditAboutTextBox>
        <TextBlock>
          <TextAreaTitle>{t('title')}</TextAreaTitle>
          <TextAreaStyle
            value={editedTexts.translations?.[field_Language]?.title}
            onChange={(event) =>
              setEditAboutTextsField("title", field_Language, event.target.value)
            }
          ></TextAreaStyle>
        </TextBlock>

        <TextBlock>
          <TextAreaTitle>{t('description')}</TextAreaTitle>
          <TextAreaStyle
            value={editedTexts.translations?.[field_Language]?.excerpt}
            onChange={(event) =>
              setEditAboutTextsField("excerpt", field_Language, event.target.value)
            }
          ></TextAreaStyle>
        </TextBlock>

        <TextBlock>
          <TextAreaTitle>{t('sliderTitle')}</TextAreaTitle>
          <TextAreaStyle
            value={editedTexts.translations?.[field_Language]?.body}
            onChange={(event) =>
              setEditAboutTextsField("body", field_Language, event.target.value)
            }
          ></TextAreaStyle>
        </TextBlock>

        <Button
          fullWidth={true}
          btnStyleType="primary"
          text={t('update')}
          onClick={UpdateAbouUsTexts}
        />
      </EditAboutTextBox>
      {/*  */}
      {/*  */}
      <br />
      <br />
      <MediumScreenTitle>{t('manageMembers')}</MediumScreenTitle>
      <Grid>
        {ourTeam &&
          ourTeam?.children?.map((item, index) => (
            <TeamMember
              key={index}
              onClick={() => [
                setOpenEditModal(!openEditModal),
                setSelectedMemberID(item?.id),
              ]}
            >
              <img src={item.image} alt="team memer img" />
              <div>{item?.translations?.[lang]?.title}</div>
              <div>{item?.translations?.[lang]?.excerpt}</div>
              <div>{item.url}</div>
            </TeamMember>
          ))}
      </Grid>

      <br />
      <br />
      <BaseModal onClose={_onCloseModal} isOpen={openEditModal}>
        <div>
          <ModalHead>
            <h4>{selectedMemberID ? `${t('update')}` : `${t('create')}`}</h4>
            <AdminLangSwicher />
          </ModalHead>

          <FieldsContent>
            <Input
              id="title"
              label={t('title')}
              type="text"
              placeholder=""
              value={selectedMember?.translations?.[field_Language]?.title || ""}
              name="title"
              onChange={(value) =>
                setEditMemberTranslatedField("title", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="body"
              label={t('description')}
              type="text"
              placeholder=""
              value={selectedMember?.translations?.[field_Language]?.excerpt || ""}
              name="body"
              onChange={(value) =>
                setEditMemberTranslatedField("excerpt", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="url"
              name="url"
              label={t('linkedinLink')}
              type="text"
              placeholder=""
              value={selectedMember?.url || ""}
              onChange={(value) => setMemberFields("url", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <UploadFile
              label={t('image')}
              onChange={(props) => setMemberFields("image", props.url)}
              defaultFileName={selectedMember?.image || ""}
            />

            <ButtonsBlock>
              {/* add FAQ */}
              <Button
                fullWidth={true}
                btnStyleType="primary"
                text={selectedMemberID ? `${t('update')}` : `${t('create')}`}
                onClick={StoreMember}
              />
              {/* add FAQ */}
              {/* <br /> */}
              {/* remove FAQ */}
              {/* {selectedMemberID && (
                <Button
                  fullWidth={true}
                  btnStyleType="transparent"
                  border={true}
                  text={'წაშლა'}
                  onClick={() => window.confirm('ნამდვილად გსურთ FAQ - ს წაშლა?') && removeFaq(selectedMemberID)}
                />
              )} */}
              {/* remove FAQ */}
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
    </Wrapper>
  );
};
//
// styles
const TextBlock = styled.div``;
const TextAreaTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
  border-left: 2px solid ${Colors.primaryRed};
  padding-left: 3px;
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid ${Colors.primaryRed};
  padding: 10px;
  margin-bottom: 20px;
`;
const EditAboutTextBox = styled.div`
  border: 1px solid ${Colors.primaryRed};
  padding: 15px;
  margin-block: 20px;
  border-radius: 4px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.grey};
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 20px;
`;
const TeamMember = styled.div`
  border: 1px solid ${Colors.primaryRed};
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: 200ms ease all;
  /*  */
  img {
    height: 100px;
  }
  /*  */
  div {
    margin-top: 10px;
  }

  /*  */
  &:hover {
    transform: scale(1.02);
    transition: 200ms ease all;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  text-align: center;
`;
const Wrapper = styled.div``;

//
export default Admin_about_us;
