import * as React from "react"

function Logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={164.242}
      height={70.867}
      viewBox="0 0 164.242 70.867"
      {...props}
    >
      <g data-name="Group 52577">
        <path
          data-name="Path 65333"
          d="M263.512 294.3l-26.293 26.289V294.3zm-13.812-5.8h-18.282v18.285l-26.289 26.285V262.2H276l-26.3 26.3"
          transform="translate(-201 -250.344) translate(-4.129 -11.859)"
          fill="#fe5f55"
          fillRule="evenodd"
        />
        <path
          data-name="Path 65334"
          d="M267.422 320.8h4.758v-11.179h8.976v-3.676h-8.976v-7.929h11.531v-3.754h-16.289zm0 0"
          transform="translate(-201 -250.344) translate(-4.129 -11.859)"
          fill="#373535"
        />
        <path
          data-name="Path 65335"
          d="M288.43 320.8h4.761v-10.714h4.063l5.918 10.719h5.183l-6.578-11.723v-.2c2.981-1.16 4.8-3.133 4.8-6.848 0-5.184-3.137-7.777-9.25-7.777h-8.9zm4.761-14.008v-8.859h3.6c3.4 0 4.875 1.238 4.875 4.257 0 3.094-1.394 4.6-4.836 4.6zm0 0"
          transform="translate(-201 -250.344) translate(-4.129 -11.859)"
          fill="#373535"
        />
        <path
          data-name="Path 65336"
          d="M309.75 320.8h4.41l2.4-6.735h9.867l2.4 6.735h4.914l-9.441-26.538h-4.875zm11.645-20.895h.117l3.715 10.6h-7.547zm0 0"
          transform="translate(-201 -250.344) translate(-4.129 -11.859)"
          fill="#373535"
        />
        <path
          data-name="Path 65337"
          d="M337.531 320.8h4.215v-20h.117l11.645 20h4.722v-26.538h-4.218v18.726h-.117l-11.028-18.726h-5.336zm0 0"
          transform="translate(-201 -250.344) translate(-4.129 -11.859)"
          fill="#373535"
        />
        <path
          data-name="Path 65338"
          d="M364.574 320.8h4.8v-26.538h-4.8zm0 0"
          transform="translate(-201 -250.344) translate(-4.129 -11.859)"
          fill="#373535"
        />
      </g>
    </svg>
  )
}

export default Logo
