import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'

const AdminDropdown: React.FC<{
  id: string
  label: string
  options: Array<{ label: string; value: any }>
  selectedValue?: any
  minWidth?: number
  onChange: (_) => void
}> = ({ id, label, options, selectedValue, onChange, minWidth = 100 }) => {
  // 
  const { t } = useTranslation()
  // 
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={selectedValue}
        label={'label'}
        onChange={onChange}
        autoWidth={true}
        style={{ minWidth: minWidth }}
      >
        <MenuItem value={''}>{t('choose')}</MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default AdminDropdown
