import * as React from "react"

function DoubleRightIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.557}
      height={15.557}
      viewBox="0 0 15.557 15.557"
      {...props}
    >
      <g data-name="arrow more">
        <g data-name="Group 52068">
          <path
            data-name="Subtraction 3"
            d="M0 11V0h11L7.688 3.312H3.311v4.377L0 11z"
            fill="CurrentColor"
            transform="rotate(135 651.512 155.073) translate(997.047 708.914)"
          />
        </g>
        <path
          data-name="Path 64577"
          d="M0 0h5L0 5z"
          transform="rotate(135 651.512 155.073) translate(1002.057 713.925)"
          fill="CurrentColor"
        />
      </g>
    </svg>
  )
}

export default DoubleRightIcon
