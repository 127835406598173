import * as React from "react"

function CheckmarkIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={10}
      viewBox="0 0 16 10"
      {...props}
    >
      <path
        data-name="Subtraction 16"
        d="M6.154 6L16-4h-2.347l-7.5 7.567L2.393-.25H0z"
        transform="translate(0 4)"
        fill="CurrentColor"
      />
    </svg>
  )
}

export default CheckmarkIcon