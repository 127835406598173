import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import api from "../../../../api";
import Colors from "../../../../assets/styles/Colors";
import Checkbox from "../../../../components/Checkbox";
import CustomDropdown from "../../../../components/CustomDropdown";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import { PetBreed } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState, setEditedlanguage } from "../../../../store/appSlice";
import Button from "../../../admin_components/AdminButton";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import { Tooltip } from "antd";
import { AiOutlineClear } from "react-icons/ai";
import { Button as AntdButton } from "antd";
//

type FilterFieldsType = {
  searchTerm: string;
  animalCategoryId: number;
  status: string;
};
//
//
const AdminPetBreed = () => {
  //
  const { t } = useTranslation();
  //
  const dispatch = useDispatch();
  //
  const [petBreeds, setPetBreeds] = useState<Partial<PetBreed>[]>([]);
  //
  const [updatedIds, setUpdatedIds] = useState<number[]>([]);
  //
  const [filterFields, setFilterFields] =
    useState<Partial<FilterFieldsType> | null>();
  //
  const _setFilterFields = (field: keyof FilterFieldsType, value: any) => {
    setFilterFields({
      ...filterFields,
      [field]: value,
    });
  };
  //
  const [storeBreeds, { isLoading: isStoreBreedsLoading }] =
    api.useUpdatePetBreedsMutation();
  //
  const setFields = (id: number, field: keyof PetBreed, value: any) => {
    //
    // set updated data
    const edited = petBreeds.map((item) =>
      item.id === id ? { ...item, [field]: value } : item
    );
    //
    setPetBreeds(edited);
    // set updated item ids in array
    if (updatedIds.includes(id)) {
    } else {
      setUpdatedIds([...updatedIds, id]);
    }
  };
  //
  //
  const addNewBreed = () => {
    setPetBreeds([...petBreeds, { id: -1 * petBreeds.length }]);
  };
  //
  // console.log("updatedIds", updatedIds);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const { data: petCategories, isLoading: isPetCategoriesLoading } =
    api.useGetPetCategoriesQuery(undefined);
  //
  const [debouncedSearchTerm] = useDebounce(filterFields?.searchTerm, 1000);
  //
  const query = `${
    debouncedSearchTerm !== undefined && debouncedSearchTerm !== ""
      ? `SearchTerm=${debouncedSearchTerm}&`
      : ``
  }${
    filterFields?.animalCategoryId !== undefined &&
    filterFields?.animalCategoryId !== null
      ? `AnimalCategoryId=${filterFields?.animalCategoryId}&`
      : ``
  }${
    filterFields?.status !== undefined && filterFields?.status !== ""
      ? `Status=${filterFields?.status}`
      : ``
  }`;
  //
  //
  const {
    data: petBreedsData,
    isLoading: isPetBreedsLoading,
    refetch: refetchPetBreeds,
  } = api.useGetPetBreedsQuery({
    query: query,
  });
  //
  //
  // set existing breeds
  useEffect(() => {
    if (petBreedsData) {
      setPetBreeds(petBreedsData);
    }
    //
    return () => {};
  }, [petBreedsData]);
  //
  //
  // console.log("petBreeds", petBreeds);
  //
  //
  const _StorePetBreeds = async () => {
    //
    const filteredObjects = petBreeds.filter((obj1) =>
      updatedIds.some((obj2) => obj2 === obj1.id)
    );
    //
    const formatedData =
      filteredObjects &&
      filteredObjects.map((item) => ({
        id: item.id && item.id > 0 ? item.id : null,
        categoryId: item.categoryId,
        translations: item.translations,
        status: item.status,
      }));
    //
    try {
      await storeBreeds(formatedData as never).unwrap();
      //
      // refetch
      refetchPetBreeds();
      //
      toast.success(`${t("updatedSuccessfully")}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  const statusesOptions = [
    {
      translations: {
        en: {
          title: "Draft",
        },
        ge: {
          title: "დრაფტი",
        },
      },
      slug: "draft",
    },
    {
      translations: {
        en: {
          title: "Published",
        },
        ge: {
          title: "დადასტურებული",
        },
      },
      slug: "published",
    },
  ];
  //
  const MainLoading =
    isPetCategoriesLoading || isPetBreedsLoading || isStoreBreedsLoading;
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <HeadStyle>
        <h6>{t("addAndUpdateBreeds")}</h6>
        <AdminLangSwicher
          onChange={(value) => dispatch(setEditedlanguage(value))}
        />
      </HeadStyle>

      <hr />

      <FilterBlock>
        <SeperateDiv>
          <Input
            id="pet-search-term"
            name="pet-search-term"
            label=""
            type="text"
            placeholder={`${t("searchByName")}`}
            value={filterFields?.searchTerm || ""}
            onChange={(value) => _setFilterFields("searchTerm", value)}
            width={"fullWidth"}
          />

          <CustomDropdown
            title=""
            placeholder={`${t("petCategory")}`}
            isMulti={false}
            value={filterFields?.animalCategoryId || null}
            isClearable={false}
            onChange={(obj) => _setFilterFields("animalCategoryId", obj.value)}
            options={
              petCategories
                ? [
                    ...petCategories.map((item) => ({
                      value: item.id,
                      label: item?.translations?.[field_Language]?.name,
                    })),
                  ]
                : []
            }
          />

          {statusesOptions ? (
            <div style={{ display: "flex", gap: "16px" }}>
              {statusesOptions?.map((status, statusIndex) => (
                <Checkbox
                  key={statusIndex}
                  type="radio"
                  id={`id-${status}`}
                  checked={status.slug === filterFields?.status}
                  label={status?.translations?.[field_Language]?.title || ""}
                  onChange={() => _setFilterFields("status", status.slug)}
                />
              ))}
            </div>
          ) : null}
        </SeperateDiv>

        <Tooltip placement="bottom" title={"clean up"}>
          <AntdButton
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "40px",
            }}
            type="dashed"
            danger
            size="large"
            icon={<AiOutlineClear />}
            onClick={() => [
              setFilterFields({})
            ]}
          />
        </Tooltip>
      </FilterBlock>

      <hr />

      {petBreeds?.map((item, index) => {
        return (
          <BreedBlock key={index}>
            <Input
              id="pet-name"
              name="addon-slug"
              label=""
              type="text"
              placeholder={t("breedName")}
              value={item?.translations?.[field_Language]?.name || ""}
              onChange={(value) => {
                const currentTranslationObj = petBreeds.find(
                  (n) => n.id === item.id
                );
                //
                if (currentTranslationObj) {
                  const updated = {
                    ...currentTranslationObj?.translations,
                    [field_Language]: {
                      ...currentTranslationObj?.translations?.[field_Language],
                      name: value,
                    },
                  };
                  //
                  setFields(item.id as number, "translations", updated);
                  //
                }
                //
              }}
              width={"fullWidth"}
            />

            {petCategories ? (
              <CategoryDiv>
                {petCategories?.map((category, categoryIndex) => (
                  <Checkbox
                    key={categoryIndex}
                    type="radio"
                    id={`id-${category}`}
                    checked={category.id === item?.categoryId}
                    label={category?.translations?.[field_Language]?.name || ""}
                    onChange={() =>
                      setFields(item.id as number, "categoryId", category.id)
                    }
                  />
                ))}
              </CategoryDiv>
            ) : null}

            <CustomDropdown
              title=""
              isMulti={false}
              value={item?.status || ""}
              isClearable={false}
              onChange={(obj) =>
                setFields(item.id as number, "status", obj.value)
              }
              options={[
                ...["draft", "published"].map((item) => ({
                  value: item,
                  label: item === "draft" ? t("draft") : t("published"),
                })),
              ]}
            />
          </BreedBlock>
        );
      })}

      <hr />

      <Buttons>
        <Button
          fullWidth={true}
          btnStyleType="transparent"
          border={true}
          text={t("add")}
          onClick={addNewBreed}
        />

        <Button
          fullWidth={true}
          btnStyleType="primary"
          border={false}
          text={t("save")}
          onClick={_StorePetBreeds}
        />
      </Buttons>
    </Wrapper>
  );
};
//
const SeperateDiv = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const FilterBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
const HeadStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CategoryDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
const BreedBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  border: 1px solid ${Colors.primaryGrey};
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  transition: all 200ms ease;

  &:hover {
    border-color: ${Colors.primaryBlack};
    transition: all 200ms ease;
  }
`;
const Wrapper = styled.div`
  /*  */
`;
//
export default AdminPetBreed;
