import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import NotFound404 from "../../../components/NotFound404";
import UploadFile from "../../../components/UploadFile";
import { AdminCompany, EnabledLanguage } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store/appSlice";
//
//
type CompanyTranslationFields = {
  title: string;
  excerpt: string;
  body: string;
  logo: string;
};
//
//
const Admin_company_detail = () => {
  //
  const { companyId: companyidStr = "0" } = useParams();
  const companyId = parseInt(companyidStr);
  //
  const { t } = useTranslation()
  //
  const navigate = useNavigate();
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );  
  //
  //
  // add travel addon state
  const [addCompanyState, setAddCompanyState] = useState<Partial<AdminCompany>>(
    {}
  );
  //
  //
  // existing company
  const { data: existingCompany, isLoading: isExistingCompanyLoading } =
    api.useGetCurrentCompanyByIdQuery(companyId);
  //
  //
  const [saveCompany, { isLoading: isSaveCompanyLoading }] =
    api.useSaveCompanyMutation();
  //
  //
  //
  // add company function
  const saveCompanyFunction = async () => {
    //
    try {
      await saveCompany(addCompanyState).unwrap();
      //
      //
      toast.success(
        companyId
          ? `${t('company')} ${t('updatedSuccessfully')}`
          : `${t('company')} ${t('addedSuccessfully')}`
      );
      //
      //
      navigate("/authorized-companies");
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  //
  //
  // add company fields
  const setCompany = (field: keyof AdminCompany, value: any) => {
    setAddCompanyState({
      ...addCompanyState,
      [field]: value,
    });
  };
  //
  //
  // add company translation fields
  const setCompanyTranslatedField = (
    field: keyof CompanyTranslationFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddCompanyState({
      ...addCompanyState,
      translations: {
        ...addCompanyState.translations,
        [language]: {
          ...addCompanyState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  // set company brand name translation field
  const setBrandNameTranslatedField = (
    field: keyof { title: string },
    language: EnabledLanguage,
    value: any
  ) => {
    setAddCompanyState({
      ...addCompanyState,
      brandName: {
        ...addCompanyState.brandName,
        [language]: {
          ...addCompanyState?.brandName?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  useEffect(() => {
    if (existingCompany) {
      setAddCompanyState(existingCompany);
    }
  }, [existingCompany]);
  //
  //
  //
  //
  // main loading
  const MainLoading = isSaveCompanyLoading || isExistingCompanyLoading;
  //
  //
  return MainLoading ? (
    <Loader />
  ) : companyId > 0 && !existingCompany ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <div>
        <ModalHead>
          <h4>{companyId ? `${t('update')}` : `${t('create')}`}</h4>
          <AdminLangSwicher />
        </ModalHead>

        <FieldsContent>
          <Row>
            <Col>
              <Input
                id="company-slug"
                label={t('companySlug')}
                type="text"
                placeholder=""
                value={addCompanyState.slug || ""}
                name="company-slug"
                onChange={(value) => setCompany("slug", value)}
                width={"fullWidth"}
                style={{ marginBottom: "24px" }}
              />
            </Col>

            <Col>
              <Input
                id="company-identification-number"
                name="company-identification-number"
                label={t('companyID')}
                type="text"
                placeholder=""
                value={addCompanyState.identificationNumber || ""}
                onChange={(value) => setCompany("identificationNumber", value)}
                width={"fullWidth"}
                style={{ marginBottom: "24px" }}
              />
            </Col>

            <Col>
              <Input
                id="brand-name"
                name="brand-name"
                label={t('brandTitle')}
                type="text"
                placeholder=""
                value={addCompanyState?.brandName?.[field_Language]?.title || ""}
                onChange={(value) =>
                  setBrandNameTranslatedField("title", field_Language, value)
                }
                width={"fullWidth"}
                style={{ marginBottom: "24px" }}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                id="company-title"
                label={t('nameOfCompany')}
                type="text"
                placeholder=""
                value={addCompanyState?.translations?.[field_Language]?.title || ""}
                name="company-title"
                onChange={(value) =>
                  setCompanyTranslatedField("title", field_Language, value)
                }
                width={"fullWidth"}
                style={{ marginBottom: "24px" }}
              />
            </Col>

            <Col>
              <Input
                id="company-email"
                name="company-email"
                label={t('companyEmail')}
                type="email"
                placeholder=""
                value={addCompanyState?.emailAddress || ""}
                onChange={(value) => setCompany("emailAddress", value)}
                width={"fullWidth"}
                style={{ marginBottom: "24px" }}
              />
            </Col>
          </Row>

          <TextBlock style={{ marginBottom: "24px" }}>
            <TextAreaTitle>{t('companyBriefDescription')}</TextAreaTitle>
            <TextAreaStyle
              id={`company-short-excerpt`}
              placeholder=""
              value={addCompanyState?.translations?.[field_Language]?.excerpt || ""}
              onChange={(event) =>
                setCompanyTranslatedField(
                  "excerpt",
                  field_Language,
                  event?.target?.value
                )
              }
            ></TextAreaStyle>
          </TextBlock>

          <TextBlock style={{ marginBottom: "24px" }}>
            <TextAreaTitle>{t('companyDescription')}</TextAreaTitle>
            <TextAreaStyle
              id={`company-body`}
              placeholder=""
              value={addCompanyState?.translations?.[field_Language]?.body || ""}
              onChange={(event) =>
                setCompanyTranslatedField(
                  "body",
                  field_Language,
                  event?.target?.value
                )
              }
            ></TextAreaStyle>
          </TextBlock>

          <Row>
            <Col>
              <UploadFile
                label={t('companyCover')}
                onChange={(props) => setCompany("cover", props.url)}
                defaultFileName={addCompanyState?.cover || ""}
              />
            </Col>
            <Col>
              <UploadFile
                label={`${t('companyLogo')} ${field_Language?.toUpperCase()}`}
                onChange={(props) =>
                  setCompanyTranslatedField("logo", field_Language, props.url)
                }
                defaultFileName={
                  addCompanyState?.translations?.[field_Language]?.logo || ""
                }
              />
            </Col>
          </Row>
          <ButtonsBlock>
            {/* add travel addon */}
            <Button
              fullWidth={true}
              btnStyleType="primary"
              text={companyId ? `${t('update')}` : `${t('create')}`}
              onClick={saveCompanyFunction}
            />
            {/* add travel addon */}
          </ButtonsBlock>
        </FieldsContent>
      </div>
    </Wrapper>
  );
};
//
// styles
const TextBlock = styled.div`
  width: 100%;
`;
const TextAreaTitle = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  color: ${Colors.primaryGrey};
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid ${Colors.secondaryGrey};
  padding: 10px;
  font-size: 14px;
  color: ${Colors.primaryBlack};
  &:focus {
    outline-color: ${Colors.rollingStone};
  }
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 20px;
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.grey};
`;
const Wrapper = styled.div``;

//
//
export default Admin_company_detail;
