import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../../api";
import { MediumScreenTitle } from "../../../../assets/styles/GlobalStyle";
import CustomLink from "../../../../components/CustomLink";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import { EnabledLanguage, TravelPagesWording } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import Button from "../../../admin_components/AdminButton";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import { AppState } from "../../../../store/appSlice";
//
//
type TravelPagesWordingTranslation = {
  title: string;
  body: string;
  excerpt: string;
  secondTitle: string;
  thirdTitle: string;
  secondExcerpt: string;
  fourthTitle: string;
  thirdExcerpt: string;
};
//
//
const Admin_manage_travel_pages_edit = () => {
  const { id: idStr = "0" } = useParams();
  const itemID = parseInt(idStr);
  //
  //
  const navigate = useNavigate();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );  
  //
  //
  // updated item
  const [updatedPageTextsState, setUpdatedPageTextsState] = useState<
    Partial<TravelPagesWording>
  >({});
  //
  //
  const {
    data: existingTravelPageItem,
    isLoading: isExistringTravelPageItemLoading,
    refetch: RefetchExistingItem,
  } = api.useGetTravelFlowPageTextItemByListItemIdQuery(itemID as number, {
    skip: !itemID && true,
  });
  //
  //
  // add travel addon fields
  const setTravelFlowTexts = (field: keyof TravelPagesWording, value: any) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      [field]: value,
    });
  };
  //
  //
  // add travel translation fields
  const setAddTravelAddonTranslatedField = (
    field: keyof TravelPagesWordingTranslation,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      translations: {
        ...updatedPageTextsState.translations,
        [language]: {
          ...updatedPageTextsState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  const [updatePageByListItemId, { isLoading: isUpdatePageLoading }] =
    api.useUpdateTravelFlowPageItemByListItemIdMutation();
  //
  //
  const UpdateTravelPageTexts = async () => {
    //
    try {
      await updatePageByListItemId(updatedPageTextsState).unwrap();
      //
      //
      toast.success(`${t('text')} ${t('updatedSuccessfully')}`);
      //
      //
      RefetchExistingItem();
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  useEffect(() => {
    if (existingTravelPageItem) {
      setUpdatedPageTextsState(existingTravelPageItem);
    }
  }, [existingTravelPageItem]);
  //
  //
  //
  const mainLoading = isExistringTravelPageItemLoading || isUpdatePageLoading;
  //
  //
  const { t } = useTranslation();
  //
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <br />

      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: "20px",
        }}
      >
        <CustomLink
          style={{ justifyContent: "flex-start" }}
          onClick={() => navigate(-1)}
          size={"small"}
          leftLineIcon={true}
          text={`${t("back")}`}
        />
        <MediumScreenTitle>{updatedPageTextsState?.translations?.[field_Language]?.title}</MediumScreenTitle>
        <AdminLangSwicher />
      </div>

      <div>
        {/* {updatedPageTextsState?.translations?.[language]?.title && ( */}
        <Input
          id="Title"
          name="title"
          label={t('title')}
          type="text"
          placeholder=""
          value={updatedPageTextsState?.translations?.[field_Language]?.title || ""}
          onChange={(value) =>
            setAddTravelAddonTranslatedField("title", field_Language, value)
          }
          width={"fullWidth"}
          style={{ marginBottom: "25px" }}
        />
        {/* )} */}

        {/* {updatedPageTextsState?.translations?.[field_Language]?.body && ( */}
        <Input
          id="body"
          name="body"
          label={t('body')}
          type="text"
          placeholder=""
          value={updatedPageTextsState?.translations?.[field_Language]?.body || ""}
          onChange={(value) =>
            setAddTravelAddonTranslatedField("body", field_Language, value)
          }
          width={"fullWidth"}
          style={{ marginBottom: "25px" }}
        />
        {/* )} */}

        {/* {updatedPageTextsState?.translations?.[field_Language]?.excerpt && ( */}
        <Input
          id="excerpt"
          name="excerpt"
          label={t('additionalInformation')}
          type="text"
          placeholder=""
          value={updatedPageTextsState?.translations?.[field_Language]?.excerpt || ""}
          onChange={(value) =>
            setAddTravelAddonTranslatedField("excerpt", field_Language, value)
          }
          width={"fullWidth"}
          style={{ marginBottom: "25px" }}
        />
        {/* )} */}

        {/* {updatedPageTextsState?.translations?.[field_Language]?.secondTitle && ( */}
        <Input
          id="secondTitle"
          name="secondTitle"
          label={t('secondTitle')}
          type="text"
          placeholder=""
          value={
            updatedPageTextsState?.translations?.[field_Language]?.secondTitle || ""
          }
          onChange={(value) =>
            setAddTravelAddonTranslatedField("secondTitle", field_Language, value)
          }
          width={"fullWidth"}
          style={{ marginBottom: "25px" }}
        />
        {/* )} */}

        {/* {updatedPageTextsState?.translations?.[field_Language]?.secondExcerpt && ( */}
        <Input
          id="secondExcerpt"
          name="secondExcerpt"
          label={t('secondAdditionalInformation')}
          type="text"
          placeholder=""
          value={
            updatedPageTextsState?.translations?.[field_Language]?.secondExcerpt || ""
          }
          onChange={(value) =>
            setAddTravelAddonTranslatedField("secondExcerpt", field_Language, value)
          }
          width={"fullWidth"}
          style={{ marginBottom: "25px" }}
        />
        {/* )} */}

        {/* {updatedPageTextsState?.translations?.[field_Language]?.thirdTitle && ( */}
        <Input
          id="thirdTitle"
          name="thirdTitle"
          label={t('thirdTitle')}
          type="text"
          placeholder=""
          value={
            updatedPageTextsState?.translations?.[field_Language]?.thirdTitle || ""
          }
          onChange={(value) =>
            setAddTravelAddonTranslatedField("thirdTitle", field_Language, value)
          }
          width={"fullWidth"}
          style={{ marginBottom: "25px" }}
        />
        {/* )} */}

        {/* {updatedPageTextsState?.translations?.[field_Language]?.thirdExcerpt && ( */}
        <Input
          id="thirdExcerpt"
          name="thirdExcerpt"
          label={t('thirdAdditionalInformation')}
          type="text"
          placeholder=""
          value={
            updatedPageTextsState?.translations?.[field_Language]?.thirdExcerpt || ""
          }
          onChange={(value) =>
            setAddTravelAddonTranslatedField("thirdExcerpt", field_Language, value)
          }
          width={"fullWidth"}
          style={{ marginBottom: "25px" }}
        />
        {/* )} */}

        <UploadFile
          label={t('uploadImage')}
          onChange={(props) => setTravelFlowTexts("image", props.url)}
          defaultFileName={updatedPageTextsState?.image || ""}
        />
      </div>
      <br />
      <br />
      <Button
        fullWidth={true}
        btnStyleType="primary"
        text={t('update')}
        onClick={UpdateTravelPageTexts}
      />
    </Wrapper>
  );
};
//
//  styles
const Wrapper = styled.div``;
//
export default Admin_manage_travel_pages_edit;
