import * as React from "react"

function CloseEye(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.2}
      height={15.875}
      viewBox="0 0 21.2 15.875"
      {...props}
    >
      <g data-name="Group 53088">
        <g data-name="Group 4378" fill="#fff">
          <g data-name="Group 4377">
            <path
              data-name="Path 12743"
              d="M11 14c5.523 0 10-7 10-7s-4.477-7-10-7S1 7 1 7s4.477 7 10 7z"
              stroke="#d3dade"
              strokeLinejoin="round"
              strokeWidth={1.2}
              transform="translate(-910.4 -630.364) translate(910 630.801) translate(0 .46)"
            />
            <g data-name="Path 12744">
              <path
                d="M6.102 8.385c-2.15 0-3.9-1.75-3.9-3.9s1.75-3.9 3.9-3.9 3.9 1.75 3.9 3.9-1.75 3.9-3.9 3.9z"
                transform="translate(-910.4 -630.364) translate(910 630.801) translate(0 .46) translate(4.898 2.515)"
              />
              <path
                d="M6.102 1.185c-1.82 0-3.3 1.48-3.3 3.3 0 1.82 1.48 3.3 3.3 3.3 1.82 0 3.3-1.48 3.3-3.3 0-1.82-1.48-3.3-3.3-3.3m0-1.2a4.5 4.5 0 110 9 4.5 4.5 0 010-9z"
                fill="#d3dade"
                transform="translate(-910.4 -630.364) translate(910 630.801) translate(0 .46) translate(4.898 2.515)"
              />
            </g>
          </g>
          <path
            data-name="Path 12745"
            d="M2.999 15l16-15"
            stroke="#d3dade"
            strokeWidth={1.2}
            transform="translate(-910.4 -630.364) translate(910 630.801)"
          />
        </g>
      </g>
    </svg>
  )
}

export default CloseEye
