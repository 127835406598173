import React from "react";
import { FullScreen } from "../../../assets/styles/GlobalStyle";

const Admin_notification_detail: React.FC = () => {
  return (
    <FullScreen className="container">Admin_notification_detail</FullScreen>
  );
};

export default Admin_notification_detail;
