import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import CloseIcon from "../../../assets/icons/JSX/CloseIcon";
import Colors from "../../../assets/styles/Colors";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import BaseModal from "../../../components/modals/BaseModal";
import Button from "../../admin_components/AdminButton";
import { useTranslation } from "react-i18next";
//
//
const AdminTraveFranchise = () => {
  //
  const { t } = useTranslation()
  // states
  const [openTravelAddModal, setOpenTravelAddModal] = useState<boolean>(false);
  //
  const [editedFranchise, setEditedFranchise] = useState<number | null>(null);
  //
  // franchaise array
  const {
    data: franchiseList,
    isLoading: isFranchiseLoading,
    refetch: refetchFranchiseList,
  } = api.useGetAdminTravelFranchiseListQuery("travel-abroad-franchise");
  //
  //
  const [saveTravelFranchise, { isLoading: isSaveFranchiseLoading }] =
    api.useAddAdminTravelFranchiseMutation();
  //
  //
  const [
    removeFranchise,
    { isLoading: isRemoveFranchiseLoading, isSuccess: removeFranchiseSucces },
  ] = api.useRemoveFranchiseMutation();
  //
  //
  //
  //close add trave franchise modal
  const _onAddTravelFranchiseCloseModal = () => {
    setOpenTravelAddModal(false);
    setEditedFranchise(null);
  };
  //
  //
  const saveFranchiseFunction = async () => {
    if (editedFranchise) {
      try {
        await saveTravelFranchise({ franchise: editedFranchise }).unwrap();
        //
        // refetch franchise list
        refetchFranchiseList();
        //
        // message for user
        toast.success(`${t('deductible')} ${t('addedSuccessfully')}`);
        //
        // close modal
        _onAddTravelFranchiseCloseModal();
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    } else {
      toast.error(`${t('enterDeductible')}`);
    }
  };
  //
  //
  useEffect(() => {
    if (removeFranchiseSucces) {
      refetchFranchiseList();
    }
  }, [removeFranchiseSucces, refetchFranchiseList]);
  //
  //
  const MainLoading =
    isFranchiseLoading || isSaveFranchiseLoading || isRemoveFranchiseLoading;
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        btnStyleType="primary"
        text={t('createDeductible')}
        onClick={() => setOpenTravelAddModal(!openTravelAddModal)}
      />
      <br />
      <br />
      <RisksContent>
        {franchiseList &&
          franchiseList.map((item, index) => (
            <RiskBox key={index}>
              <RemoveBtn
                onClick={() =>
                  window.confirm(`${t('certainToDeleteIt')}`) &&
                  removeFranchise(item.id)
                }
              >
                <CloseIcon />
              </RemoveBtn>
              <div>{item.value}</div>
            </RiskBox>
          ))}
      </RisksContent>

      {/* add new travel risk */}
      <BaseModal
        onClose={_onAddTravelFranchiseCloseModal}
        isOpen={openTravelAddModal}
      >
        <div>
          <ModalHead>
            <h4>{t('createDeductible')}</h4>
          </ModalHead>

          <FieldsContent>
            <Input
              id="franchise-name"
              label={t('deductible')}
              type="number"
              placeholder=""
              value={editedFranchise || ""}
              name="franchise-name"
              onChange={(value) => setEditedFranchise(value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <ButtonsBlock>
              {/* add travel risk */}
              <Button
                fullWidth={true}
                btnStyleType="primary"
                text={t('create')}
                onClick={saveFranchiseFunction}
              />
              {/* add travel risk */}
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
      {/* add new travel risk  */}
    </Wrapper>
  );
};
//
//styles
const RemoveBtn = styled.button`
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: transparent;
  border: 0px;
  outline: none;
  height: 20px;
  width: 20px;
  padding: 0px;
  display: grid;
  place-items: center;
  svg {
    height: 13px;
    color: ${Colors.primaryRed};
  }
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-top: 1px solid ${Colors.grey};
  padding-top: 40px;
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.grey};
`;
const RisksContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  /*  */
  img {
    width: 50px;
  }
`;
const RiskBox = styled.div`
  position: relative;
  height: 150px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
`;
const Wrapper = styled.div``;
//
export default AdminTraveFranchise;
