import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import { MediumScreenTitle } from "../../../assets/styles/GlobalStyle";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import BaseModal from "../../../components/modals/BaseModal";
import { EnabledLanguage, FaqTypes } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store/appSlice";
//
//
const Admin_manage_faq = () => {
  //
  const { t } = useTranslation()
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );  
  //
  const [selectedFaqId, setSelectedFaqId] = useState<number | null | undefined>(
    null
  );
  //
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  //
  const [selectedFaq, setSelectedFaq] = useState<Partial<FaqTypes>>({});
  //
  //
  const { data, isLoading, refetch } = api.useGetWebPageQuery("faq");
  //
  //
  const FaqArray: FaqTypes = data as FaqTypes;
  //
  //
  const [updatedFaqMainTextsState, setUpdatedFaqMainTextsState] = useState<
    Partial<FaqTypes>
  >({});
  //
  const {
    data: faqList,
    isLoading: isFaqListLoading,
    refetch: isFaqListRefetch,
  } = api.useGetFaqListQuery(undefined);
  //
  //
  const { data: faqById, isLoading: isFaqByIdLoading } = api.useGetFaqByIdQuery(
    selectedFaqId as number,
    {
      skip: !selectedFaqId && true,
    }
  );
  //
  //
  // remvoe travel addon
  const [
    removeFaq,
    { isLoading: isRemoveFaqLoading, isSuccess: removeTravelFaqSucces },
  ] = api.useRemoveFaqByIdMutation();
  //
  //
  const [storeFaq, { isLoading: isStoreFaqLoading }] =
    api.useStoreFAQMutation();
  //
  //  update faq main texts
  const [updateFaqMainText, { isLoading: isUpdateFaqMainTextLoading }] =
    api.useUpdateFaqMainTextMutation();
  //
  //
  // update faq main texts fun
  const updateFaqMainTextFun = async () => {
    //
    try {
      await updateFaqMainText(updatedFaqMainTextsState).unwrap();
      //
      refetch();
      //
      toast.success(`${t('updatedSuccessfully')}`);
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  // update Faq text
  const StoreFAQFnction = async () => {
    //
    try {
      await storeFaq(selectedFaq).unwrap();
      //
      // refetch
      isFaqListRefetch();
      // close modal
      _onCloseModal();
      //
      toast.success(`FAQ ${t('updatedSuccessfully')}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  // add FAQ translation fields
  const setUpdateFaqMainTextTranslatedField = (
    field: keyof FaqTypes,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedFaqMainTextsState({
      ...updatedFaqMainTextsState,
      translations: {
        ...updatedFaqMainTextsState.translations,
        [language]: {
          ...updatedFaqMainTextsState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  // add FAQ translation fields
  const setEditFaqTranslatedField = (
    field: keyof FaqTypes,
    language: EnabledLanguage,
    value: any
  ) => {
    setSelectedFaq({
      ...selectedFaq,
      translations: {
        ...selectedFaq.translations,
        [language]: {
          ...selectedFaq?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  //close base modal
  const _onCloseModal = () => {
    setOpenEditModal(false);
    setSelectedFaqId(null);
  };
  //
  //
  //
  // auto refetch when user remove FAQ
  useEffect(() => {
    if (removeTravelFaqSucces) {
      isFaqListRefetch();
      _onCloseModal();
    }
  }, [removeFaq, removeTravelFaqSucces, isFaqListRefetch]);
  //
  // set Faq info in fields when user click Faq card
  useEffect(() => {
    if (faqById) {
      setSelectedFaq(faqById);
    }
  }, [faqById]);
  //
  //
  // updatedFaqMainTextsState
  useEffect(() => {
    if (FaqArray) {
      setUpdatedFaqMainTextsState(FaqArray);
    }
  }, [FaqArray]);
  //
  // main loading
  const MainLoader =
    isFaqListLoading ||
    isStoreFaqLoading ||
    isFaqByIdLoading ||
    isRemoveFaqLoading ||
    isUpdateFaqMainTextLoading ||
    isLoading;
  //
  return MainLoader ? (
    <Loader />
  ) : (
    <Wrapper>
      <MediumScreenTitle>{t('manageFAQ')}</MediumScreenTitle>

      <Button
        btnStyleType="primary"
        text={t('addFAQ')}
        onClick={() => [setOpenEditModal(!openEditModal), setSelectedFaq({})]}
      />
      <br />
      <br />

      <AdminLangSwicher />
      <br />
      <br />
      <TextAreaBlock style={{ marginBottom: "24px" }}>
        <TextAreaTitle>{t('pageName')}</TextAreaTitle>
        <TextAreaStyle
          id={`faq-short-title`}
          placeholder=""
          value={
            updatedFaqMainTextsState?.translations?.[field_Language]?.title || ""
          }
          onChange={(event) =>
            setUpdateFaqMainTextTranslatedField(
              "title",
              field_Language,
              event?.target?.value
            )
          }
        ></TextAreaStyle>
      </TextAreaBlock>

      <TextAreaBlock style={{ marginBottom: "24px" }}>
        <TextAreaTitle>{t('pageDescription')}</TextAreaTitle>
        <TextAreaStyle
          id={`faq-short-body`}
          placeholder=""
          value={updatedFaqMainTextsState?.translations?.[field_Language]?.body || ""}
          onChange={(event) =>
            setUpdateFaqMainTextTranslatedField(
              "body",
              field_Language,
              event?.target?.value
            )
          }
        ></TextAreaStyle>
      </TextAreaBlock>
      <Button
        fullWidth={true}
        btnStyleType="primary"
        text={t('updateMainTexts')}
        onClick={updateFaqMainTextFun}
      />
      <br />
      <hr />
      <br />

      <Grid>
        {faqList &&
          faqList.map((item, index) => (
            <TextBlock
              key={index}
              onClick={() => [
                setOpenEditModal(!openEditModal),
                setSelectedFaqId(item?.id),
              ]}
            >
              <div>{item?.translations?.[field_Language]?.title}</div>
              <div>{item?.translations?.[field_Language]?.body}</div>
            </TextBlock>
          ))}
      </Grid>

      <br />
      <br />

      <BaseModal onClose={_onCloseModal} isOpen={openEditModal}>
        <div>
          <ModalHead>
            <h4>{selectedFaqId ? `${t('update')}` : `${t('create')}`}</h4>
            <AdminLangSwicher />
          </ModalHead>

          <FieldsContent>
            <Input
              id="title"
              label={t('title')}
              type="text"
              placeholder=""
              value={selectedFaq?.translations?.[field_Language]?.title || ""}
              name="title"
              onChange={(value) =>
                setEditFaqTranslatedField("title", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="body"
              label={t('description')}
              type="text"
              placeholder=""
              value={selectedFaq?.translations?.[field_Language]?.body || ""}
              name="body"
              onChange={(value) =>
                setEditFaqTranslatedField("body", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <ButtonsBlock>
              {/* add FAQ */}
              <Button
                fullWidth={true}
                btnStyleType="primary"
                text={selectedFaqId ? `${t('update')}` : `${t('create')}`}
                onClick={StoreFAQFnction}
              />
              {/* add FAQ */}
              <br />
              {/* remove FAQ */}
              {selectedFaqId && (
                <Button
                  fullWidth={true}
                  btnStyleType="transparent"
                  border={true}
                  text={t('delete')}
                  onClick={() =>
                    window.confirm(`${t('certainToDeleteIt')}`) &&
                    removeFaq(selectedFaqId)
                  }
                />
              )}
              {/* remove FAQ */}
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
    </Wrapper>
  );
};
//
const TextAreaBlock = styled.div`
  width: 100%;
`;
const TextAreaTitle = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  color: ${Colors.primaryGrey};
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid ${Colors.secondaryGrey};
  padding: 10px;
  font-size: 14px;
  color: ${Colors.primaryBlack};
  &:focus {
    outline-color: ${Colors.rollingStone};
  }
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 20px;
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.grey};
`;
const TextBlock = styled.div`
  border: 1px solid ${Colors.primaryRed};
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: 200ms ease all;
  /*  */
  div {
    border-bottom: 1px dashed ${Colors.primaryGrey};
    margin-bottom: 5px;
    &:last-child {
      border-bottom: 0px;
    }
  }
  /*  */
  &:hover {
    transform: scale(1.02);
    transition: 200ms ease all;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  text-align: center;
`;
const Wrapper = styled.div``;
//
export default Admin_manage_faq;
