import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../assets/styles/Colors";
import api from "../../../../api";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import Button from "../../../admin_components/AdminButton";
import CloseIcon from "../../../../assets/icons/JSX/CloseIcon";
import BaseModal from "../../../../components/modals/BaseModal";
import Input from "../../../../components/Input";
import { useTranslation } from "react-i18next";

const AdminWelcomerFranchise = () => {
  //
  const { t } = useTranslation()
  //
  const [openWelcomerAddModal, setOpenWelcomerAddModal] =
    useState<boolean>(false);
  //
  const [editedFranchise, setEditedFranchise] = useState<number | null>(null);
  //
  // franchise array
  const {
    data: franchiseList,
    isLoading: isFranchiseListLoading,
    refetch: refetchFranchiseList,
  } = api.useGetAdminWelcomerFranchiseListQuery("welcomer-franchise");
  //
  // update and add welcomer franchise
  const [saveWelcomerFranchise, { isLoading: isSaveWelcomerFranchise }] =
    api.useAddAdminWelcomerFranchiseMutation();
  //
  // remove welcomer franchise
  const [
    removeFranchise,
    { isLoading: isRemoveFranchiseLoading, isSuccess: removeFranchiseSuccess },
  ] = api.useRemoveWelcomerFranchiseMutation();
  //
  //
  // close welcomer add modal
  const onWelcomerAddModalClose = () => {
    setOpenWelcomerAddModal(false);
    //
    setEditedFranchise(null);
  };
  //
  //
  const saveFranchiseFunction = async () => {
    if (editedFranchise) {
      try {
        await saveWelcomerFranchise({ franchise: editedFranchise }).unwrap();
        //
        // refetch franchise list
        refetchFranchiseList();
        //
        //message for user
        toast.success(`${t('deductible')} ${t('addedSuccessfully')}`);
        //
        // close modal
        onWelcomerAddModalClose();
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    } else {
      toast.error(`${t('enterDeductible')}`);
    }
  };

  //
  //
  useEffect(() => {
    if (removeFranchiseSuccess) {
      refetchFranchiseList();
    }
  }, [removeFranchiseSuccess, refetchFranchiseList]);
  //
  //
  const MainLoading =
    isFranchiseListLoading ||
    isSaveWelcomerFranchise ||
    isRemoveFranchiseLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        btnStyleType="primary"
        text={t('createDeductible')}
        onClick={() => setOpenWelcomerAddModal(!openWelcomerAddModal)}
      />
      <br />
      <br />
      <RisksContent>
        {franchiseList &&
          franchiseList.map((item, index) => (
            <RiskBox key={index}>
              <RemoveBtn
                onClick={() =>
                  window.confirm(`${t('certainToDeleteIt')}`) &&
                  removeFranchise(item.id)
                }
              >
                <CloseIcon />
              </RemoveBtn>
              <div>{item.value}</div>
            </RiskBox>
          ))}
      </RisksContent>
      {/*  */}
      {/* add new welcomer franchise */}
      <BaseModal
        onClose={onWelcomerAddModalClose}
        isOpen={openWelcomerAddModal}
      >
        <div>
          <ModalHead>
            <h4>{t('createDeductible')}</h4>
          </ModalHead>

          <FieldsContent>
            <Input
              id="franchise-name"
              label={t('deductible')}
              type="number"
              placeholder=""
              value={editedFranchise || ""}
              name="franchise-name"
              onChange={(value) => setEditedFranchise(value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <ButtonsBlock>
              <Button
                fullWidth={true}
                btnStyleType="primary"
                text={t('create')}
                onClick={saveFranchiseFunction}
              />
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
      {/* add new welcomer franchise */}
    </Wrapper>
  );
};
//
//
//styles
const RemoveBtn = styled.button`
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: transparent;
  border: 0px;
  outline: none;
  height: 20px;
  width: 20px;
  padding: 0px;
  display: grid;
  place-items: center;
  svg {
    height: 13px;
    color: ${Colors.primaryRed};
  }
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-top: 1px solid ${Colors.grey};
  padding-top: 40px;
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.grey};
`;
const RisksContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  /*  */
  img {
    width: 50px;
  }
`;
const RiskBox = styled.div`
  position: relative;
  height: 150px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
`;
const Wrapper = styled.div``;

export default AdminWelcomerFranchise;
