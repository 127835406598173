import React from "react";
import { useNavigate } from "react-router-dom";
import Accordion from "../../../../components/Accordion";
import Button from "../../../admin_components/AdminButton";
//
const Admin_product_managment = () => {
  //
  const navigate = useNavigate();
  //
  return (
    <div>
      <Button
        btnStyleType="primary"
        icon={true}
        onClick={() => alert("შექმენი შენი პროდუქტი")}
        text={"შექმენი შენი პროდუქტი"}
      />
      <div className="mt-5">
        <Accordion title="Travel Abroad">
          {/* edit product */}
          <Button
            btnStyleType="primary"
            icon={true}
            onClick={() => navigate("/travel-aboard-edit")}
            text={"პროდუქტის დაედიტება"}
          />
          {/*  */}
          <div className="mt-5">travel abord content here</div>
        </Accordion>
        <Accordion title="Travel Georgia">
          travel georgia content here
        </Accordion>
      </div>
    </div>
  );
};
//
export default Admin_product_managment;
