import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import Loader from "../../../components/Loader";
import { RootState, useAppSelector } from "../../../store";
import Button from "../../admin_components/AdminButton";
//
import { useSelector } from "react-redux";
import { AuthState } from "../../../store/authSlice";
import { useTranslation } from "react-i18next";
//
//
const AdminUsers = () => {
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const {lang} = useAppSelector((root) => root.app)
  // 
  const { t } = useTranslation()
  //
  const navigate = useNavigate();
  //
  // authorized company users list
  const {
    data: authorizedCompanies,
    isLoading: isAuthorizedUsersLoading,
    refetch: refetchAuthorizedCompanies,
  } = api.useGetAdminAuthorizedCompaniesQuery(undefined);
  //
  //
  // remove company
  const [removeCompany, { isLoading: isRemoveCompanyLoading }] =
    api.useRemoveCompanyMutation();
  //
  // remove user in admin functon
  const removeCompanyFun = async (companyId: number) => {
    if (companyId) {
      try {
        await removeCompany(companyId).unwrap();
        // refetch authorized users
        refetchAuthorizedCompanies();
        //
        toast.success(`${t('company')} ${t('deletedSuccessfully')}`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    }
  };
  //
  // main loading
  const MainLoading = isAuthorizedUsersLoading || isRemoveCompanyLoading;
  //
  //
  const refetchCompanies = useCallback(() => {
    //
    refetchAuthorizedCompanies();
    //
  }, [refetchAuthorizedCompanies]);
  //
  // refetch companies
  useEffect(() => {
    refetchCompanies();

    return () => {};
  }, [refetchCompanies]);
  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      {(auth?.user?.companyUsers[0]?.role || auth?.user?.userType) !==
      "Frani-Admin" ? null : (
        <Button
          btnStyleType="primary"
          text={t('createCompany')}
          onClick={() => navigate(`/company-detail/0`)}
        />
      )}
      <br />
      <br />
      <CompanyBlock>
        {authorizedCompanies &&
          authorizedCompanies.map((item, index) => (
            <CompanyCard
              style={{
                cursor: "default",
                userSelect:
                  auth?.user?.userType === "Frani-Product-Owner"
                    ? "none"
                    : "unset",
                pointerEvents:
                  auth?.user?.userType === "Frani-Product-Owner"
                    ? "none"
                    : "unset",
              }}
              key={index}
            >
              <CompanyImages>
                <div>
                  <img src={item.cover} alt="company cover" />
                  <span>{t('cover')}</span>
                </div>
                <div>
                  <img src={item.logo} alt="company logo" />
                  <span>{t('logo')}</span>
                </div>
              </CompanyImages>
              <CompanyTitle>{item?.translations[lang]?.title}</CompanyTitle>
              <CompanyExcerpt>{item?.translations[lang]?.excerpt}</CompanyExcerpt>
              <CompanyButtonsBlock>
                {(auth?.user?.companyUsers[0]?.role || auth?.user?.userType) ===
                  "Insurance-Admin" ||
                "Insurance-Company-Editor" ||
                "Frani-Admin" ? (
                  <button
                    style={{
                      color: "#3D84C2",
                    }}
                    onClick={() => navigate(`/company-detail/${item.id}`)}
                  >
                    {t('details')}
                  </button>
                ) : null}

                {(auth?.user?.companyUsers[0]?.role || auth?.user?.userType) ===
                  "Frani-Admin" ||
                "Insurance-Admin" ||
                "Frani-Product-Owner" ? (
                  <button
                    style={{
                      color: "#52AB51",
                    }}
                    onClick={() =>
                      navigate(`/authorized-company-users/${item.id}`)
                    }
                  >
                    {t('users')}
                  </button>
                ) : null}

                {(auth?.user?.companyUsers[0]?.role || auth?.user?.userType) !==
                "Frani-Admin" ? null : (
                  <button
                    style={{
                      color: "#C63532",
                    }}
                    onClick={() =>
                      window.confirm(`${t('certainToDeleteIt')}`) &&
                      removeCompanyFun(item.id)
                    }
                  >
                    {t('delete')}
                  </button>
                )}
              </CompanyButtonsBlock>
            </CompanyCard>
          ))}
      </CompanyBlock>
    </Wrapper>
  );
};
//
//
//  styles
const CompanyButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*  */
  button {
    width: 30%;
    border: 0px;
    padding: 5px;
    margin: 0px;
    border-radius: 4px;
    font-size: 15px;
    /*  */
    &:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
  }
  /*  */
`;
const CompanyExcerpt = styled.p`
  font-size: 14px;
`;
const CompanyTitle = styled.h1`
  font-size: 15px;
  margin-bottom: 5px;
`;
const CompanyImages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /*  */
  div {
    position: relative;
    img {
      height: 35px;
    }
    span {
      font-size: 14px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: inherit;
      width: 100%;
      height: 100%;
      padding: 5px;
      display: none;
    }
    /*  */
    &:hover {
      span {
        display: grid;
        place-items: center;
      }
      img {
        opacity: 0;
      }
    }
  }
`;
const CompanyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: ${Colors.primaryGrey};
  transition: all 200ms ease;
  &:hover {
    transition: all 200ms ease;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transform: scale(1.05);
  }
`;
const CompanyBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
`;
const Wrapper = styled.div``;
//
//
export default AdminUsers;
