import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../assets/styles/Colors";
import { useAppSelector } from "../../../../store";
import { EnabledLanguage, WelcomerAddon } from "../../../../domain/types";
import api from "../../../../api";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import Button from "../../../admin_components/AdminButton";
import BaseModal from "../../../../components/modals/BaseModal";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import CustomSelect from "../../../../components/CustomSelect";
import Input from "../../../../components/Input";
import UploadFile from "../../../../components/UploadFile";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../../store/appSlice";

//
//
type AddWelcomerAddonTranslatedFields = {
  title: string;
  body: string;
};
//
//
const AdminWelcomerAddons = () => {
  //
  const { t } = useTranslation();
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  // selected addon id
  const [welcomerAddonId, setWelcomerAddonId] = useState<number | null>(null);
  //
  // welcomer addon modal
  const [openWelcomerAddonModal, setOpenWelcomerAddonModal] =
    useState<boolean>(false);
  //
  // welcomer addon state
  const [addWelcomerAddonState, setAddWelcomerAddonState] = useState<
    Partial<WelcomerAddon>
  >({});
  //
  //
  const [defaultSelectedCategory, setDefaultSelectedCategory] = useState<
    string | null
  >(null);
  //
  //
  //get welcomer addons list
  const {
    data: welcomerAddons,
    isLoading: isWelcomerAddonsLoading,
    refetch: refetchWelcomerAddons,
  } = api.useGetAdminWelcomerAddonListQuery(undefined);
  //
  // get admin product category list
  const { data: productCategoryList, isLoading: isProductCategoryListLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);
  //
  // add welcomer addon
  const [saveWelcomerAddon, { isLoading: isAddWelcomerAddonLoading }] =
    api.useSaveWelcomerAddonMutation();
  //
  // remove welcomer addon
  const [
    removeWelcomerAddon,
    {
      isLoading: isRemoveWelcomerAddonLoading,
      isSuccess: removeWelcomerAddonSuccess,
    },
  ] = api.useRemoveWelcomerAddonMutation();
  //
  //
  // close add welcomer addon modal
  const onWelcomerAddonModalClose = () => {
    setOpenWelcomerAddonModal(false);
    //
    setWelcomerAddonId(null);
    //
    setDefaultSelectedCategory(null);
  };
  //
  //
  // add welcomer addon fields
  const setAddWelcomerAddon = (field: keyof WelcomerAddon, value: any) => {
    setAddWelcomerAddonState({
      ...addWelcomerAddonState,
      [field]: value,
    });
  };
  //
  //
  // get welcomer addon by id
  const {
    data: existingWelcomerAddon,
    isLoading: isExistingWelcomerAddonLoading,
  } = api.useGetAdminWelcomerAddonByIdQuery(welcomerAddonId as number, {
    skip: true && !welcomerAddonId,
  });
  //
  //
  // add welcomer addons translation fields
  const setAddWelcomerAddonTranslatedField = (
    field: keyof AddWelcomerAddonTranslatedFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddWelcomerAddonState({
      ...addWelcomerAddonState,
      translations: {
        ...addWelcomerAddonState.translations,
        [language]: {
          ...addWelcomerAddonState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  // add welcomer addon function
  const AddWelcomerAddonFunction = async () => {
    try {
      await saveWelcomerAddon(addWelcomerAddonState).unwrap();
      //
      // refetch welcomer addons
      refetchWelcomerAddons();
      //
      onWelcomerAddonModalClose();
      //
      toast.success(`${t("additionalCoverages")} ${t("addedSuccessfully")}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  //
  // auto refetch welcomer addons when user remove addon
  useEffect(() => {
    if (removeWelcomerAddonSuccess) {
      refetchWelcomerAddons();
      onWelcomerAddonModalClose();
    }
  }, [removeWelcomerAddon, removeWelcomerAddonSuccess, refetchWelcomerAddons]);
  //
  //
  //
  useEffect(() => {
    if (existingWelcomerAddon) {
      setAddWelcomerAddonState(existingWelcomerAddon);
    }
  }, [existingWelcomerAddon]);
  //
  //
  // default category
  useEffect(() => {
    if (productCategoryList && addWelcomerAddonState) {
      const category = productCategoryList?.find(
        (x) => x.id === addWelcomerAddonState.productCategoryId
      )?.title;
      //
      if (category) {
        setDefaultSelectedCategory(category);
      }
    }
  }, [productCategoryList, addWelcomerAddonState]);
  //
  //
  //
  const MainLoading =
    isAddWelcomerAddonLoading ||
    isExistingWelcomerAddonLoading ||
    isProductCategoryListLoading ||
    isRemoveWelcomerAddonLoading ||
    isWelcomerAddonsLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        btnStyleType="primary"
        text={t("createAddons")}
        onClick={() => [
          setOpenWelcomerAddonModal(!openWelcomerAddonModal),
          setAddWelcomerAddonState({}),
        ]}
      />
      <br />
      <br />
      <AddonContent>
        {welcomerAddons &&
          welcomerAddons.map((item, index) => (
            <AddonBox
              key={index}
              onClick={() => [
                setWelcomerAddonId(item.id),
                setOpenWelcomerAddonModal(!openWelcomerAddonModal),
              ]}
            >
              <img src={item.image} alt={`addon icon`} />
              <div>{item?.translations?.[lang]?.title}</div>
              <p>{item?.translations?.[lang]?.body}</p>
            </AddonBox>
          ))}
      </AddonContent>
      {/*  */}
      {/* add new welcomer addon */}
      <BaseModal
        onClose={onWelcomerAddonModalClose}
        isOpen={openWelcomerAddonModal}
      >
        <div>
          <ModalHead>
            <h4>{welcomerAddonId ? t("update") : t("create")}</h4>
            <AdminLangSwicher />
          </ModalHead>
          {/* productCategoryList */}

          {((productCategoryList &&
            defaultSelectedCategory &&
            welcomerAddonId) ||
            (productCategoryList && !welcomerAddonId)) && (
            <CustomSelect
              placeholder=""
              defaultInputValue={defaultSelectedCategory || ""}
              headText={t("productCategory")}
              onChange={(event) =>
                setAddWelcomerAddon("productCategoryId", event.value)
              }
              data={[
                ...productCategoryList.map((item) => ({
                  value: item.id,
                  label: item.title,
                  isDisabled:
                    item.slug === "welcomer-to-georgia" ? false : true,
                })),
              ]}
            />
          )}

          <FieldsContent>
            <Input
              id="addon-slug"
              label={t("slug")}
              type="text"
              placeholder=""
              value={addWelcomerAddonState.slug || ""}
              name="addon-slug"
              onChange={(value) => setAddWelcomerAddon("slug", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="addon-title"
              label={t("title")}
              type="text"
              placeholder=""
              value={
                addWelcomerAddonState?.translations?.[field_Language]?.title ||
                ""
              }
              name="addon-title"
              onChange={(value) =>
                setAddWelcomerAddonTranslatedField(
                  "title",
                  field_Language,
                  value
                )
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="addon-body"
              label={t("description")}
              type="text"
              placeholder=""
              value={
                addWelcomerAddonState?.translations?.[field_Language]?.body ||
                ""
              }
              name="addon-body"
              onChange={(value) =>
                setAddWelcomerAddonTranslatedField(
                  "body",
                  field_Language,
                  value
                )
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <UploadFile
              label={t("icon")}
              onChange={(props) => setAddWelcomerAddon("image", props.url)}
              defaultFileName={addWelcomerAddonState?.image || ""}
            />

            <ButtonsBlock>
              {/* add travel addon */}
              <Button
                fullWidth={true}
                btnStyleType="primary"
                text={welcomerAddonId ? t("update") : t("create")}
                onClick={AddWelcomerAddonFunction}
              />
              {/* add travel addon */}
              <br />
              {/* remove travel addon */}
              {welcomerAddonId && (
                <Button
                  fullWidth={true}
                  btnStyleType="transparent"
                  border={true}
                  text={t("delete")}
                  onClick={() =>
                    window.confirm(`${t("certainToDeleteIt")}`) &&
                    removeWelcomerAddon(welcomerAddonId)
                  }
                />
              )}
              {/* remove travel addon */}
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
      {/* add new welcomer addon */}
    </Wrapper>
  );
};
//
// styles
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 20px;
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.grey};
`;
const AddonBox = styled.div`
  height: 200px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
  /*  */
  img {
    width: 60px;
  }
  /*  */
  div {
    font-size: 18px;
    margin-block: 10px;
    text-align: center;
  }
  /*  */
  p {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
  }
`;
const AddonContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  /*  */
`;
const Wrapper = styled.div``;
//
export default AdminWelcomerAddons;
