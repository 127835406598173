import React from 'react'
import styled from 'styled-components'
import Responsive from '../assets/config/Responsive'
import Fonts from '../assets/Fonts'
import { useAppSelector } from '../store'

const RichText: React.FC<{ html: string }> = ({ html: htmlValue }) => {
  //
  const { lang } = useAppSelector((root) => root.app)
  //
  //
  return <Description $lang={lang} dangerouslySetInnerHTML={{ __html: htmlValue }}></Description>
  //
}
//
//
const Description = styled.div<{ $lang: string }>`
  * {
    font-family: ${({ $lang }) =>
      $lang === 'en'
        ? `${Fonts.helvetica_Neue_regular} !important`
        : `${Fonts.Helvetica_Neue_LT_GEO_55_roman} !important`};
  }
  &,
  p {
    font-size: 16px !important;
    font-weight: 300;
    line-height: 1.4;
  }
  p {
    margin-bottom: 15px;
    strong {
      font-weight: bold;
    }
  }
  margin-bottom: 20px;
  text-align: left;
  & ul {
    padding-left: 20px;
    li {
      list-style-type: disc;
      &:marker {
        margin-left: 20px;
      }
    }
  }
  ${Responsive.mobile} {
    font-size: 16px !important;
  }
`

export default RichText
