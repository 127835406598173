import React from 'react'
import styled from 'styled-components'
import Responsive from '../../assets/config/Responsive'
import CloseIcon from '../../assets/icons/JSX/CloseIcon'
import Colors from '../../assets/styles/Colors'
import { Scrollbar } from '../../assets/styles/GlobalStyle'

const BaseModal: React.FC<{
  isOpen: boolean
  onClose: Function
  children: React.ReactNode
}> = ({ isOpen = false, onClose, children }) => {
  return isOpen === false ? null : (
    <Wrapper>
      <Scrollbar hideScroll={isOpen} />
      <ShadowBackground onClick={() => onClose()} />
      <Content>
        <CloseModalBtn onClick={() => onClose()}>
          <CloseIcon />
        </CloseModalBtn>
        <div>{children}</div>
      </Content>
    </Wrapper>
  )
}

//
const CloseModalBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border: 0px;
  background-color: transparent;
  outline: none;
  padding: 10px;
  margin-right: -10px;
  margin-top: -10px;
  svg {
    width: 14px;
    height: 14px;
    color: ${Colors.rollingStone};
  }
  /*  */
  &:hover {
    svg {
      color: ${Colors.primaryBlack};
    }
  }
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 101;
  ${Responsive.mobile} {
    z-index: 3;
  }
`
const ShadowBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: black;
  opacity: 0.3;
  width: 100%;
  height: 100vh;
`
const Content = styled.div`
  padding: 24px;
  background-color: white;
  z-index: 6;
  border-radius: 8px;
  position: relative;
  min-width: 600px;
  max-width: 600px;
  min-height: 20vh;
  max-height: 100vh;
  overflow-y: scroll;
  /*  */
  ${Responsive.mobile} {
    padding: 40px 25px;
    min-width: unset;
    width: 100%;
    height: 100vh;
    border-radius: 0px;
  }
`

export default BaseModal
