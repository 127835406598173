import React, { useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import Responsive from "../../assets/config/Responsive";
import CheckmarkIcon from "../../assets/icons/JSX/CheckmarkIcon";
import RightArrow from "../../assets/icons/JSX/RightArrow";
import Colors from "../../assets/styles/Colors";
import Checkbox from "../../components/Checkbox";
import CloseIcon from "../../assets/icons/JSX/CloseIcon";
import { georgianFormatter } from "../../domain/types";
//
//
const CustomSelecte: React.FC<{
  style?: React.CSSProperties;
  maxSelectText?: string;
  maxSelectLenght?: number;
  isDisabled?: boolean;
  menuIsOpen?: boolean | undefined;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  options?: Array<{ label: any; value: any; imgUrl?: any; price?: any }>;
  value?: any;
  className?: string;
  onChange: (_) => void;
  title?: string;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  showInnerPrice?: boolean;
  showOptionCheckmark?: boolean;
  showOptionCheckbox?: boolean;
  showLabelCheckmark?: boolean;
}> = ({
  style,
  maxSelectText,
  maxSelectLenght = 99999999,
  isDisabled = false,
  menuIsOpen = undefined,
  isLoading = false,
  isClearable = false,
  isSearchable = false,
  placeholder = "",
  options,
  value,
  className = "base-dropdown",
  onChange,
  title = "",
  isMulti = false,
  closeMenuOnSelect = true,
  showInnerPrice = false,
  showOptionCheckmark = false,
  showOptionCheckbox = false,
  showLabelCheckmark = false,
}) => {
  //
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  //
  //
  const singleValue =
    isMulti === false &&
    value &&
    options?.find(
      (x) =>
        x?.value?.toString()?.toLowerCase() === value?.toString()?.toLowerCase()
    );
  //
  //
  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };
  //
  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };
  //
  //
  //
  // add checkmark icon
  const getOptionLabel = (option: {
    value: any;
    label: any;
    imgUrl: string;
  }) => {
    // @ts-ignore
    if (option.value === singleValue?.value) {
      return (
        <GetOptionLabelSpanStyle>
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {option.imgUrl ? (
              <img src={option.imgUrl} alt="select-company-img" />
            ) : null}
            {option?.label}
          </span>
          <span className="icon-class">
            {showLabelCheckmark ? (
              <CheckmarkIconStyle
                style={{
                  marginLeft: "15px",
                }}
              />
            ) : null}
          </span>
        </GetOptionLabelSpanStyle>
      );
    } else {
      return option.label;
    }
  };
  //
  //
  const customDropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <span>
          <RightArrowStyle $isMenuOpen={isMenuOpen} />
        </span>
      </components.DropdownIndicator>
    );
  };
  //
  //
  const handleChange = (selectedOptions) => {
    if (onChange) {
      onChange(selectedOptions);
    }
  };
  //
  //
  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <span>
          <CloseIcon />
        </span>
      </components.MultiValueRemove>
    );
  };
  //
  //
  const customOption = ({ innerProps, label, data, isSelected }) => {
    return (
      <CustomOptionStyles
        {...innerProps}
        style={{
          userSelect:
            value?.length >= maxSelectLenght && !isSelected ? "none" : "unset",
          pointerEvents:
            value?.length >= maxSelectLenght && !isSelected ? "none" : "unset",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {showOptionCheckbox ? (
              <Checkbox
                id="custom-checkbox-id"
                label={""}
                checked={isSelected}
                onChange={() => {
                  const updatedOptions = isSelected
                    ? value.filter((option) => option.value !== data.value)
                    : [...value, data];
                  handleChange(updatedOptions);
                }}
              />
            ) : null}
            {isSelected && !isMulti ? null : (
              <>
                {data.imgUrl ? (
                  <img src={data.imgUrl} alt="company-select-img" />
                ) : null}
              </>
            )}

            <span
              style={{
                color: isSelected ? Colors.primaryBlack : Colors.nevadaGrey,
              }}
            >
              {label}
            </span>
          </div>

          <InnerPriceDiv
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {showInnerPrice ? (
              <InnerPriceSpan
                $selected={showOptionCheckmark && isSelected ? true : false}
              >
                {georgianFormatter.format(data.price)} ₾
              </InnerPriceSpan>
            ) : null}
            {showOptionCheckmark && isSelected ? (
              <SelectedCheckmarkIcon />
            ) : null}
          </InnerPriceDiv>
        </div>
      </CustomOptionStyles>
    );
  };
  //
  //
  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        <>
          {optionSelectedLength >= maxSelectLenght ? (
            <MaxCountTag>{maxSelectText}</MaxCountTag>
          ) : null}
          {props.children}
        </>
      </components.Menu>
    );
  };
  //
  //
  return (
    <Wrapper
      $isMenuOpen={isMenuOpen}
      style={style}
      className="company-select-wrapper"
    >
      <Title>{title}</Title>
      <Select
        menuPlacement="auto"
        placeholder={placeholder}
        className={`${className}`}
        classNamePrefix="v2-select" // don't change this className
        value={isMulti ? value : singleValue}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={false}
        menuIsOpen={menuIsOpen}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isSearchable={isSearchable}
        onChange={(data) => onChange(data)}
        options={options}
        getOptionLabel={getOptionLabel}
        components={{
          DropdownIndicator: customDropdownIndicator,
          Option: customOption,
          MultiValueRemove,
          Menu,
        }}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        hideSelectedOptions={false}
        noOptionsMessage={() => null}
      />
    </Wrapper>
  );
};
//
const InnerPriceSpan = styled.span<{ $selected: boolean }>`
  white-space: nowrap;
  margin-left: 10px;
  margin-right: ${({ $selected }) => ($selected ? `14px` : `50px`)};

  ${Responsive.mobile} {
    margin-right: ${({ $selected }) => ($selected ? `14px` : `30px`)};
  }
`;
const InnerPriceDiv = styled.div``;
const SelectedCheckmarkIcon = styled(CheckmarkIcon)`
  width: 16px;
  margin-left: 20px;

  ${Responsive.mobile} {
    margin-left: 0px;
  }
`;
const MaxCountTag = styled.div`
  font-size: 12px;
  color: ${Colors.primaryRed};
  padding: 5px 7px;
`;

const CustomOptionStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2px;
  padding: 8px;
  border-radius: 4px;
  width: 100%;

  img {
    // icon
    height: 24px;
    width: 24px;
    object-fit: contain;
    margin-right: 15px;
  }

  span {
    //label
    font-size: 14px;
    color: ${Colors.primaryBlack};
  }

  /* hover */
  &:hover {
    background-color: ${Colors.lightGrey};
    cursor: pointer;
  }
`;
const Title = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${Colors.nevadaGrey};
`;
const GetOptionLabelSpanStyle = styled.span`
  display: flex;
  justify-content: space-between;
  align-content: center;
  /*  */
  img {
    // icon
    height: 24px;
    width: 24px;
    object-fit: contain;
    margin-right: 15px;
  }
  /*  */
  &:not(:first-of-type) {
    font-weight: bold;
  }
`;
const CheckmarkIconStyle = styled(CheckmarkIcon)`
  width: 16px;
  height: 10px;
  margin-top: -5px;
`;
const RightArrowStyle = styled(RightArrow)<{ $isMenuOpen: boolean }>`
  transform: ${({ $isMenuOpen }) =>
    $isMenuOpen ? "rotate(-90deg)" : "rotate(90deg)"};
  width: 14px;
  height: 14px;
  margin-right: 16px;
  margin-top: ${({ $isMenuOpen }) => ($isMenuOpen ? "-7px" : "-5px")};
`;
const Wrapper = styled.div<{ $isMenuOpen: boolean }>`
  /*  */
  .v2-select__multi-value {
    background-color: ${Colors.grey};
    border-radius: 4px;
    position: relative;

    .v2-select__multi-value__label {
      font-size: 14px;
      color: ${Colors.primaryBlack};
      padding-right: 23px;
      display: inline-block;
      width: 90px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    .v2-select__multi-value__remove {
      position: absolute;
      top: 5px;
      right: 4px;
      margin: 0px;
      padding: 5px;
      height: 9px;

      span {
        height: fit-content;
        svg {
          height: 9px;
          width: 9px;
          color: ${Colors.nevadaGrey};
        }
      }

      &:hover {
        background-color: inherit;
      }
    }
  }
  /*  */
  .v2-select__placeholder {
    font-size: 14px;
  }
  /*  */
  .v2-select__dropdown-indicator {
    /*  */
    ${Responsive.mobile} {
      pointer-events: none;
    }
  }
  /*  */
  .v2-select__menu-list {
    /*  */
    ${Responsive.mobile} {
      /* padding-block: 20px;
      position: ${({ $isMenuOpen }) => ($isMenuOpen ? "fixed" : "unset")};
      top: 0;
      left: 0;
      bottom: 0;
      overflow: hidden;
      background-color: ${Colors.white};
      margin: 0;
      width: 100%;
      height: fit-content;
      max-height: 70vh;
      overflow-y: scroll;
      margin-top: auto;
      z-index: 99999999999999999999;
      border-radius: 0px !important;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border: unset; */
    }
  }
  /*  */
  .v2-select__option--is-selected {
    background-color: transparent;
    color: ${Colors.primaryBlack};
  }
  /*  */
  .v2-select__option--is-focused {
    border-radius: 4px;
    background-color: ${Colors.lightGrey};
    color: ${Colors.primaryBlack};
  }
  /*  */
  .v2-select__menu {
    margin-block: 12px;
    border: 1px solid ${Colors.nevadaGrey};
    border-radius: 4px !important;
    padding: 12px;
    /*  */
    ${Responsive.mobile} {
      /* position: ${({ $isMenuOpen }) => ($isMenuOpen ? "fixed" : "unset")};
      top: 0;
      left: 0;
      bottom: 0;
      background-color: ${({ $isMenuOpen }) =>
        $isMenuOpen ? Colors.grey : "transparent"};
      z-index: 99999999999999999999;
      width: 100%;
      height: 100%;
      margin: ${({ $isMenuOpen }) => ($isMenuOpen ? "0px" : "12px 0px")};
      border-radius: ${({ $isMenuOpen }) => ($isMenuOpen ? "0px" : "4px")}; */
    }
    /*  */
  }
  /*  */
  .v2-select__option {
    font-size: 14px;
    padding: 12px;
    /*  */
    &:active {
      background-color: ${Colors.lightGrey};
      color: ${Colors.primaryBlack};
    }
  }
  /*  */
  .v2-select__value-container {
    padding-inline: 24px;
  }
  /*  */
  .v2-select__control {
    /* height: 56px; */
    padding-block: 7px;
    outline: none;
    box-shadow: none;
    border-color: ${Colors.secondaryGrey};
    ${Responsive.mobile} {
      /* height: 48px; */
      padding-block: 3px;
    }
  }
  /*  */
  .v2-select__control--is-focused {
    /*  */
  }
  .v2-select__control--menu-is-open {
    border-color: ${Colors.nevadaGrey};
  }
  /*  */
  .v2-select__control:hover {
    border-color: ${Colors.nevadaGrey};
  }
  /* hide arrow indicator */
  .v2-select__indicator-separator {
    display: none;
  }
  /*  */
  /* hide custom icon in head field */
  .v2-select__single-value {
    font-size: 14px;
    .icon-class {
      display: none;
    }
  }
  /*  */
`;
//
export default CustomSelecte;
