import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import {
  EnabledLanguage,
  WelcomerListItems,
  WelcomerPagesWording,
} from "../../../../domain/types";
import api from "../../../../api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loader from "../../../../components/Loader";
import styled from "styled-components";
import CustomLink from "../../../../components/CustomLink";
import { MediumScreenTitle } from "../../../../assets/styles/GlobalStyle";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import Input from "../../../../components/Input";
import UploadFile from "../../../../components/UploadFile";
import Button from "../../../admin_components/AdminButton";
import { AppState } from "../../../../store/appSlice";

type WelcomerPagesWordingTranslation = {
  title: string;
  body: string;
  excerpt: string;
  secondTitle: string;
  thirdTitle: string;
  secondExcerpt: string;
  fourthTitle: string;
  thirdExcerpt: string;
};

type WelcomerPageTranslation = {
  stepName: string;
  title: string;
};

const AdminWelcomerManagePagesEdit = () => {
  //
  const { id: idStr = "0" } = useParams();
  const itemID = parseInt(idStr);
  //
  const { slug } = useParams();
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  // updated item
  const [updatedPageTextsState, setUpdatedPageTextsState] = useState<
    Partial<WelcomerListItems>
  >({});
  //
  // updated main info
  const [updatedPageTextArry, setUpdatedPageTextArry] = useState<
    Partial<WelcomerPagesWording>
  >({});
  //
  //
  const {
    data: existingWelcomerPageItem,
    isLoading: isExistingWelcomerPageItemLoading,
    refetch: RefetchExistingWelcomerPage,
  } = api.useGetWelcomerFlowPageItemTextsByListItemIdQuery(
    { itemID: itemID as number, slug: slug as string },
    {
      skip: !itemID && !slug && true,
    }
  );
  //
  // get welcomer flow
  const {
    data: pageTextArray,
    isLoading: isPageTextArrayLoading,
    refetch: RefetchPageTextArry,
  } = api.useGetWelcomerFlowPageTextsBySlugQuery(slug as string, {
    skip: !slug && true,
  });
  //
  //
  // add welcomer page texts
  const setWelcomerFlowTexts = (field: keyof WelcomerListItems, value: any) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      [field]: value,
    });
  };
  //
  // add welcomer translations field
  const setAddWelcomerPagesTranslatedField = (
    field: keyof WelcomerPagesWordingTranslation,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      translations: {
        ...updatedPageTextsState.translations,
        [language]: {
          ...updatedPageTextsState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  // add tpl main translation fields
  const setAddWelcomerMainAddonTranslatedField = (
    field: keyof WelcomerPageTranslation,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedPageTextArry({
      ...updatedPageTextArry,
      translations: {
        ...updatedPageTextArry.translations,
        [language]: {
          ...updatedPageTextArry?.translations?.[language],
          [field]: value || " ",
        },
      },
    });
  };
  //
  //
  const [updatePageTextsByListItemId, { isLoading: isUpdatePageLoading }] =
    api.useUpdateWelcomerFlowPageItemTextsByListItemIdMutation();
  //
  const [updateTplFlow, { isLoading: isUpdateTplFlowLoading }] =
    api.useUpdateTplFlowMutation();
  //
  //
  const UpdateWelcomerPageTexts = async () => {
    //
    try {
      await updatePageTextsByListItemId(updatedPageTextsState).unwrap();
      //
      //
      toast.success(`${t("text")} ${t("updatedSuccessfully")}`);
      //
      //
      RefetchExistingWelcomerPage();
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  //
  const UpdateMainTexts = async () => {
    //
    try {
      await updateTplFlow({
        item: updatedPageTextArry,
        slug: slug as string,
      }).unwrap();
      //
      toast.success(`${t("text")} ${t("updatedSuccessfully")}`);
      //
      //
      RefetchPageTextArry();
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  //
  //
  useEffect(() => {
    if (existingWelcomerPageItem) {
      setUpdatedPageTextsState(existingWelcomerPageItem);
    }
  }, [existingWelcomerPageItem]);
  //
  useEffect(() => {
    if (pageTextArray) {
      setUpdatedPageTextArry(pageTextArray);
    }
  }, [pageTextArray]);
  //
  //
  //
  const MainLoading =
    isExistingWelcomerPageItemLoading ||
    isUpdatePageLoading ||
    isPageTextArrayLoading ||
    isUpdateTplFlowLoading;

  //
  //
  console.log(updatedPageTextsState);
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <br />
      <br />
      <Block>
        <CustomLink
          style={{ justifyContent: "flex-start" }}
          onClick={() => navigate(-1)}
          size={"small"}
          leftLineIcon={true}
          text={`${t("back")}`}
        />
        <MediumScreenTitle>
          {updatedPageTextsState?.translations?.[field_Language]?.title}
        </MediumScreenTitle>
        <AdminLangSwicher />
      </Block>

      <div>
        {updatedPageTextArry?.translations?.[field_Language]?.title &&
          itemID === 0 && (
            <Input
              id="pageTitle"
              name="pageTitle"
              label={t("pageTitle")}
              type="text"
              placeholder=""
              value={
                updatedPageTextArry?.translations?.[field_Language]?.title || ""
              }
              onChange={(value) =>
                setAddWelcomerMainAddonTranslatedField(
                  "title",
                  field_Language,
                  value
                )
              }
              width={"fullWidth"}
              style={{ marginBottom: "25px" }}
            />
          )}

        {updatedPageTextArry?.translations?.[field_Language]?.stepName &&
          itemID === 0 && (
            <Input
              id="StepName"
              name="stepName"
              label={t("stepName")}
              type="text"
              placeholder=""
              value={
                updatedPageTextArry?.translations?.[field_Language]?.stepName ||
                ""
              }
              onChange={(value) =>
                setAddWelcomerMainAddonTranslatedField(
                  "stepName",
                  field_Language,
                  value
                )
              }
              width={"fullWidth"}
              style={{ marginBottom: "25px" }}
            />
          )}
        {/* updated page text state title */}
        {itemID !== 0 && (
          <Input
            id="Title"
            name="title"
            label={t("title")}
            type="text"
            placeholder=""
            value={
              updatedPageTextsState?.translations?.[field_Language]?.title || ""
            }
            onChange={(value) =>
              setAddWelcomerPagesTranslatedField("title", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}
        {/* updated page text state body */}
        {itemID !== 0 && (
          <Input
            id="body"
            name="body"
            label={t("body")}
            type="text"
            placeholder=""
            value={
              updatedPageTextsState?.translations?.[field_Language]?.body || ""
            }
            onChange={(value) =>
              setAddWelcomerPagesTranslatedField("body", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}

        {/* updated page text state excerpt */}
        {itemID !== 0 && (
          <Input
            id="bodyTitle"
            name="body title"
            label={t("bodyTitle")}
            type="text"
            placeholder=""
            value={
              updatedPageTextsState?.translations?.[field_Language]
                ?.bodyTitle || ""
            }
            onChange={(value) =>
              setAddWelcomerPagesTranslatedField(
                "bodyTitle",
                field_Language,
                value
              )
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}
        {/* updated page text state second title */}
        {itemID !== 0 && (
          <Input
            id="stepName"
            name="name of step"
            label={t("stepName")}
            type="text"
            placeholder=""
            value={
              updatedPageTextsState?.translations?.[field_Language]?.stepName ||
              ""
            }
            onChange={(value) =>
              setAddWelcomerPagesTranslatedField(
                "stepName",
                field_Language,
                value
              )
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}
        {/* update page text state second excerpt */}
        {itemID !== 0 && (
          <Input
            id="bodyDescription"
            name="body description"
            label={t("bodyDescription")}
            type="text"
            placeholder=""
            value={
              updatedPageTextsState?.translations?.[field_Language]
                ?.bodyDescription || ""
            }
            onChange={(value) =>
              setAddWelcomerPagesTranslatedField(
                "bodyDescription",
                field_Language,
                value
              )
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}
        {/* <Input
          id="thirdTitle"
          name="thirdTitle"
          label="Third Title"
          type="text"
          placeholder=""
          value={
            updatedPageTextsState?.translations?.[language]?.thirdTitle || ""
          }
          onChange={(value) =>
            setAddWelcomerPagesTranslatedField("thirdTitle", language, value)
          }
          width={"fullWidth"}
          style={{ marginBottom: "25px" }}
        />
        {/* update page text state third excerpt */}
        <Input
          id="thirdExcerpt"
          name="thirdExcerpt"
          label="Third Excerpt"
          type="text"
          placeholder=""
          value={
            updatedPageTextsState?.translations?.[field_Language]
              ?.thirdExcerpt || ""
          }
          onChange={(value) =>
            setAddWelcomerPagesTranslatedField(
              "thirdExcerpt",
              field_Language,
              value
            )
          }
          width={"fullWidth"}
          style={{ marginBottom: "25px" }}
        />
        {/*  */}

        {itemID !== 0 && (
          <UploadFile
            label={t("uploadImage")}
            onChange={(props) => setWelcomerFlowTexts("image", props.url)}
            defaultFileName={updatedPageTextsState?.image || ""}
          />
        )}
      </div>
      <br />
      <br />
      {itemID !== 0 ? (
        <Button
          fullWidth={true}
          btnStyleType="primary"
          text={t("update")}
          onClick={UpdateWelcomerPageTexts}
        />
      ) : (
        <Button
          fullWidth={true}
          btnStyleType="primary"
          text={t("update")}
          onClick={UpdateMainTexts}
        />
      )}
    </Wrapper>
  );
};
//
//
// styles
const Wrapper = styled.div``;
const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export default AdminWelcomerManagePagesEdit;
