import React, { useState } from "react";
// import { useNavigate } from 'react-router-dom'
import styled from "styled-components";
// import Accordion from '../../../../../components/Accordion'
// import Input from '../../../../admin_components/AdminInput'
import Button from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";
import NotFound404 from "../../../../../components/NotFound404";
import {
  AdminPaymentScheduleType,
  Country,
  Currency,
  // Currency,
  PackageService,
  TplCurrencies,
  TplLimitList,
  TplProduct,
} from "../../../../../domain/types";
// import { Checkbox, InputLabel } from '@mui/material'
import { toast } from "react-toastify";
import api from "../../../../../api";
import BaseModal from "../../../../../components/modals/BaseModal";
import TplPackageEditor from "./TplPackageEditor";
import { useTranslation } from "react-i18next";
//
//

//
//
const EditTplPackagesTab: React.FC<{
  tplProduct?: TplProduct;
  countries: Country[];
  isLoading: boolean;
  refetchExistingTplProduct: () => void;
  setTplProductField: (fieldName: keyof TplProduct, fieldValue: any) => void;
  setUsedCurrencies: (tplPackageId: number, _: Currency[]) => void;
  setUsedServices: (tplPackageId: number, values: number[]) => void;
  packageServices: PackageService[] | undefined;
  setUsedPaymentSchedules: (tplPackageId: number, values: number[]) => void;
  paymentSchedules: AdminPaymentScheduleType[] | undefined;
  tplLimits: TplLimitList[] | undefined;
  tplCurrencies: TplCurrencies[] | undefined;
}> = ({
  tplProduct,
  countries,
  isLoading,
  refetchExistingTplProduct,
  setTplProductField,
  setUsedCurrencies,
  setUsedServices,
  packageServices,
  setUsedPaymentSchedules,
  paymentSchedules,
  tplLimits,
  tplCurrencies,
}) => {
  //
  //
  const { t } = useTranslation()
  //
  //
  const copyProduct = async (packageId: number) => {
    try {
      await m_copyProduct(packageId).unwrap();

      toast.success(`${t('product')} ${t('copied')}`);
      refetchExistingTplProduct();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t('unknownError')}`);
      }
    }
  };
  //
  //
  //
  const [m_copyProduct] = api.useAdminCopyProductMutation();
  //
  const [
    createTplPackage, //{ isLoading: isCreateTplPackageLoading }
  ] = api.useCreateTplPackageMutation();
  //
  const [isAddPackageModalOpen, setIsAddPackageModalOpen] =
    useState<boolean>(false);
  const _onCloseAddModal = () => setIsAddPackageModalOpen(false);
  //
  //
  return isLoading ? (
    <Loader />
  ) : !tplProduct ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <Block>
        <Button
          btnStyleType="primary"
          icon={false}
          onClick={() => setIsAddPackageModalOpen(true)}
          text={t('addPackage')}
        />
      </Block>
      <br />
      {[...tplProduct.mainProduct.packages]
        .sort((pkg1, pkg2) => (pkg1.id > pkg2.id ? 1 : -1))
        .map((pkg) =>
          pkg.target ? ( //
            <TplPackageEditor
              key={pkg.id}
              tplPackageId={pkg.target.id}
              countries={countries}
              refetchTplProduct={refetchExistingTplProduct}
              tplProduct={tplProduct}
              setTplProductField={setTplProductField}
              copyProduct={() => copyProduct(pkg?.target?.id ?? 0)}
              setUsedCurrencies={setUsedCurrencies}
              setUsedServices={setUsedServices}
              packageServices={packageServices}
              setUsedPaymentSchedules={setUsedPaymentSchedules}
              paymentSchedules={paymentSchedules}
              tplLimits={tplLimits}
              tplCurrencies={tplCurrencies}
            />
          ) : null
        )}
      {/* add package modal */}
      <BaseModal onClose={_onCloseAddModal} isOpen={isAddPackageModalOpen}>
        <div>
          <Block>
            <Text>{t('addPackage')}</Text>
          </Block>
          {/* {selectedProductCategoryID === undefined ? null : ( */}
          <Button
            btnStyleType="primary"
            icon={false}
            fullWidth={true}
            disabled={/* 'validation' == */ false}
            onClick={async () => {
              try {
                const title = `${
                  tplProduct.mainProduct.company.title
                }, მანქანის დაზღვევა, პაკეტი ${
                  tplProduct.mainProduct.packages.length + 1
                }`;
                await createTplPackage({
                  productId: tplProduct.mainProduct.id,
                  translations: {
                    ge: {
                      title,
                    },
                    en: {
                      title,
                    },
                    // ru: {
                    //   title,
                    // },
                  },
                  limitId:
                    tplLimits
                      ?.filter((limit) => limit.isActive)
                      .map((limit) => limit.id)[0] || 0,
                  currencyId:
                    tplCurrencies
                      ?.filter((currency) => currency.isActive)
                      .map((currency) => currency.id)[0] || 0,
                  paymentScheduleTypeIds:
                    paymentSchedules
                      ?.filter((scheduleType) => scheduleType.isActive)
                      .map((scheduleType) => scheduleType.id) || [],
                }).unwrap();
                //
                // refetch product list by id
                refetchExistingTplProduct();
                _onCloseAddModal();
                toast.success(`${t('package')} ${t('addedSuccessfully')}`);
              } catch (error) {
                if (error?.data?.errors?.constructor === Array) {
                  const firstError = error.data.errors[0];
                  const errorMessage = firstError.message;
                  toast.error(errorMessage);
                } else {
                  toast.warning(`${t('fillAllFields')}`);
                }
              }
            }}
            text={t('create')}
          />
          {/* )} */}
        </div>
        {/*  */}
      </BaseModal>
    </Wrapper>
  );
};
//
//
// styles
// const Text = styled.div``
const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /*  */
  > div {
    flex-basis: 50%;
  }
`;
const Wrapper = styled.div`
  padding: 20px 15px;
`;

const Text = styled.div`
  margin-bottom: 10px;
`;

export default EditTplPackagesTab;
