import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../../admin_components/AdminButton";
import Loader from "../../../../../components/Loader";
import Input from "../../../../admin_components/AdminInput";
import Col from "react-bootstrap/esm/Col";
import Colors from "../../../../../assets/styles/Colors";
import api from "../../../../../api";
import { toast } from "react-toastify";
import Message from "../../../../../components/Message";
import Row from "react-bootstrap/esm/Row";
import {
  EnabledLanguage,
  WelcomerPackagePrices,
  WelcomerPackagePricesList,
} from "../../../../../domain/types";
import { useTranslation } from "react-i18next";

const EditWelcomerPricesTab: React.FC<{
  welcomerPackagePrices: WelcomerPackagePrices[] | undefined;
  refetchWelcomerPackagePrice: () => void;
  isWelcomerPackagePricesLoading: boolean;
  language: EnabledLanguage;
}> = ({
  welcomerPackagePrices,
  refetchWelcomerPackagePrice,
  isWelcomerPackagePricesLoading,
  language
}) => {
  const [prices, setPrices] = useState<WelcomerPackagePricesList[]>();
  //
  const { t } = useTranslation();
  //
  const updatePriceById = (id: number, newPrice: number) => {
    const updatedPrices = prices?.map((item) => {
      if (item.priceId === id) {
        return { ...item, premium: newPrice };
      }
      return item;
    });

    setPrices(updatedPrices);
  };

  //
  const [updatePackagePrice, { isLoading: isUpdatePackagePriceLoading }] =
    api.useUpdateWelcomerPackagePricesMutation();
  //
  //
  const UpdatePackagePrice = async () => {
    //
    if (prices) {
      try {
        await updatePackagePrice({
          list: prices,
        }).unwrap();
        //
        // refetch price
        refetchWelcomerPackagePrice();
        //
        toast.success(`${t('price')} ${t('updatedSuccessfully')}`);
        //
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    } else {
      toast.info(`${t('enterPrice')}`);
    }
    //
  };
  //
  //
  const setIncomePrices = useCallback(() => {
    //
    if (welcomerPackagePrices && welcomerPackagePrices.length >= 1) {
      setPrices(
        welcomerPackagePrices?.map((x) => ({
          premium: x.premium,
          priceId: x.id,
        }))
      );
    }
    //
  }, [welcomerPackagePrices]);
  //
  //
  useEffect(() => {
    setIncomePrices();
    //
    return () => {};
  }, [setIncomePrices]);
  //
  //
  const MainLoading =
    isUpdatePackagePriceLoading || isWelcomerPackagePricesLoading;
  //
  //

  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Message variant="light-red" text={t("welcomerPricesMandatory")} />

      <hr />

      {welcomerPackagePrices && welcomerPackagePrices.length < 1 && (
        <h4
          style={{
            textAlign: "center",
            color: Colors.primaryRed,
          }}
        >
          {t('generatedPricesNotFound')}
        </h4>
      )}

      {welcomerPackagePrices && welcomerPackagePrices.length >= 1 ? (
        <>
          <Row>
            <Col xs={{ span: 4 }}>
              <label htmlFor="package-name">{t('packageTitle')}</label>
            </Col>
            <Col xs={{ span: 2 }}>
              <label htmlFor="limit-name">{t('limit')}</label>
            </Col>
            <Col xs={{ span: 1 }}>
              <label htmlFor="currency-name">{t('currency')}</label>
            </Col>
            <Col xs={{ span: 1 }}>
              <label htmlFor="currency-name">{t('ageGroups')}</label>
            </Col>
            <Col xs={{ span: 1 }}>
              <label htmlFor="currency-name">{t('daysRange')}</label>
            </Col>
            <Col xs={{ span: 1 }}>
              <label htmlFor="price-to-currency">{t('price')}</label>
            </Col>
            <Col xs={{ span: 2 }}>
              <Button
                style={{
                  minWidth: "unset",
                }}
                text={t('save')}
                onClick={() => {
                  UpdatePackagePrice();
                }}
                btnStyleType={"primary"}
                border={false}
                fullWidth={true}
              />
            </Col>
          </Row>

          <hr />
          {welcomerPackagePrices?.map((item, index) => {
            if (item && item.limit !== null) {
              return (
                <Row
                  key={index}
                  style={{
                    marginBottom: "15px",
                    width: "100%",
                  }}
                >
                  <Col xs={{ span: 4 }}>
                    <Input
                      id={`package-name-${index}`}
                      name="package-name"
                      label={``}
                      type="text"
                      placeholder={``}
                      value={item?.welcomerPackage?.mainPackage?.translations[language]?.title}
                      onChange={(value) => {}}
                      width={"fullWidth"}
                      disabled={true}
                    />
                  </Col>

                  <Col xs={{ span: 2 }}>
                    <Input
                      id={`limit-name-${index}`}
                      name="limit-name"
                      label={``}
                      type="text"
                      placeholder=""
                      // @ts-ignore
                      value={item?.limit?.value}
                      onChange={(value) => {}}
                      width={"fullWidth"}
                      disabled={true}
                    />
                  </Col>

                  <Col xs={{ span: 1 }}>
                    <Input
                      id={`currency-name-${index}`}
                      name="currency-name"
                      label={``}
                      type="text"
                      placeholder=""
                      value={item?.currency?.currencyCode}
                      onChange={(value) => {}}
                      width={"fullWidth"}
                      disabled={true}
                    />
                  </Col>

                  <Col xs={{ span: 1 }}>
                    <Input
                      id={`agegroup-name-${index}`}
                      name="agegroup-name"
                      label={``}
                      type="text"
                      placeholder=""
                      value={`${item?.welcomerAgeGroup?.ageFrom} - ${item?.welcomerAgeGroup?.ageTo}`}
                      onChange={(value) => {}}
                      width={"fullWidth"}
                      disabled={true}
                    />
                  </Col>

                  <Col xs={{ span: 1 }}>
                    <Input
                      id={`daygroup-name-${index}`}
                      name="daygroup-name"
                      label={``}
                      type="text"
                      placeholder=""
                      value={`${item?.welcomerDayGroup?.minDay} - ${item?.welcomerDayGroup?.maxDay}`}
                      onChange={(value) => {}}
                      width={"fullWidth"}
                      disabled={true}
                    />
                  </Col>

                  <Col xs={{ span: 1 }}>
                    <Input
                      id={`price-name-${index}`}
                      name="price-name"
                      label={``}
                      type="number"
                      placeholder=""
                      value={
                        prices?.find((x) => x.priceId === item.id)?.premium ||
                        ""
                      }
                      onChange={(value) => updatePriceById(item.id, value)}
                      width={"fullWidth"}
                    />
                  </Col>
                </Row>
              );
            } else {
              return null;
            }
          })}
        </>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px 15px;
`;

export default EditWelcomerPricesTab;
