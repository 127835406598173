import React from 'react'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'
import CloseIcon from '../assets/icons/JSX/CloseIcon'
import Colors from '../assets/styles/Colors'

const CustomToast = () => {
  //
  // close button
  const CloseButton = ({ closeToast }) => (
    <i className="material-icons close-icon-custom-style" onClick={closeToast}>
      <CloseIcon />
    </i>
  )
  //
  // Toastify__toast--success
  // Toastify__toast--error
  // Toastify__toast--warning
  // Toastify__toast--info
  //
  return (
    <Wrapper>
      <ToastContainer
        className={'custom-react-toastify'}
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        draggable={true}
        closeButton={CloseButton}
      />
    </Wrapper>
  )
}
//
const Wrapper = styled.div`
  user-select: none;
  /*  */
  .custom-react-toastify {
    /*  */
    .Toastify__toast--success {
      /* background-color: green; */
    }
    /*  */
    .Toastify__toast--error {
      /* background-color: red; */
    }
    /*  */
    .Toastify__toast--warning {
      /* background-color: blue; */
    }
    /*  */
    .Toastify__toast--info {
      /* background-color: aqua; */
    }
  }
  /*  */
  .Toastify__toast {
    background-color: ${Colors.white};
    -webkit-box-shadow: 0px 0px 15px 3px #55555511;
    box-shadow: 0px 0px 15px 3px #55555511;
    border-radius: 4px;
    padding: 10px 14px;
    font-family: inherit;
  }
  /* close icons styles */
  .close-icon-custom-style {
    svg {
      width: 11px;
      height: 11px;
      color: ${Colors.rollingStone};
    }
  }
  /*  */
  .Toastify__toast-body {
    font-size: 14px;
    color: ${Colors.primaryBlack};
    line-height: 20px;
  }
`

export default CustomToast
