import React, { useEffect } from "react";
import styled from "styled-components";
import api from "../../../api";
import Loader from "../../../components/Loader";
import Colors from "../../../assets/styles/Colors";
import AdminButton from "../../admin_components/AdminButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//
//
const Admin_products_page = () => {
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation()
  // 
  const {
    data: productsList,
    isLoading: isProductsListLoading,
    refetch,
  } = api.useGetAdminProductsListQuery(undefined);
  //
  //
  const MainLoading = isProductsListLoading;
  //
  //
  useEffect(() => {
    refetch();

    return () => {};
  }, [refetch]);

  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <AdminButton
        btnStyleType="primary"
        text={t('addProduct')}
        onClick={() => navigate("/product-page-edit/store")}
      />
      <br />
      <br />
      <GridBlock>
        {productsList?.map((item, index) => {
          return (
            <ProductsDiv
              key={index}
              onClick={() => navigate(`/product-page-edit/${item.type}`)}
            >
              <span>{item.type}</span>
            </ProductsDiv>
          );
        })}
      </GridBlock>
    </Wrapper>
  );
};
//
//
const GridBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  /*  */
`;
const ProductsDiv = styled.div`
  height: 200px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  user-select: none;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
  /*  */
  img {
    width: 60px;
  }
  /*  */
  div {
    font-size: 18px;
    margin-block: 10px;
    text-align: center;
  }
  /*  */
  p {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
  }
`;
const Wrapper = styled.div`
  /*  */
`;
//
//
export default Admin_products_page;
