import React from "react";
import api from "../../../../api";
import Loader from "../../../../components/Loader";
import styled from "styled-components";
import Colors from "../../../../assets/styles/Colors";
import { useAppSelector } from "../../../../store";

const AdminPetCategories = () => {
  //
  const {lang} = useAppSelector((root) => root.app)
  //
  const { data: petCategories, isLoading: isPetCategoriesLoading } =
    api.useGetPetCategoriesQuery(undefined);
  //
  //
  const MainLoadig = isPetCategoriesLoading;
  //
  return MainLoadig ? (
    <Loader />
  ) : (
    <Wrapper>
      <Block>
        {petCategories?.map((item, index) => (
          <Box key={index}>
            <div>{item?.translations?.[lang]?.name}</div>
          </Box>
        ))}
      </Block>
    </Wrapper>
  );
};
//
const Block = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  /*  */
  img {
    width: 50px;
  }
`;
const Box = styled.div`
  height: 150px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
`;
const Wrapper = styled.div`
  /*  */
`;
//
export default AdminPetCategories;
