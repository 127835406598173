import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Accordion from "../../../../../components/Accordion";
import Button from "../../../../admin_components/AdminButton";
import Input from "../../../../admin_components/AdminInput";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Logo from "../../../../../assets/icons/JSX/Logo";
import {
  Currency,
  EnabledLanguage,
  InsuranceException,
  Package,
  Product,
  TplCurrencies,
  TplLimitList,
  WelcomerEnabledRisk,
  TravelFranchise,
  WelcomerAddon,
  WelcomerPackage,
  WelcomerProduct,
  WelcomerRisk,
  supportedLocales,
} from "../../../../../domain/types";
import dayjs from "dayjs";
import api, { uploadUrl } from "../../../../../api";
import Responsive from "../../../../../assets/config/Responsive";
import Colors from "../../../../../assets/styles/Colors";
import CustomDropdown from "../../../../../components/CustomDropdown";
import { useAppSelector } from "../../../../../store";
import DocumentFieldUploaderBlock from "../../../../admin_components/DocumentFieldUploaderBlock";
import { AppState } from "../../../../../store/appSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
dayjs.locale("ka");

type Props = {
  welcomerPackageId: WelcomerPackage["id"];
  welcomerFranchise: TravelFranchise[];
  welcomerRisks: WelcomerRisk[];
  welcomerAddon: WelcomerAddon[];
  refetchWelcomerProduct: () => void;
  welcomerProduct: WelcomerProduct;
  /** setTravelProductField is not used for saving packages, but only to receive price combination-related updates */
  setWelcomerProductField: (
    fieldName: keyof WelcomerProduct,
    fieldValue: any
  ) => void;
  setUsedCurrencies: (welcomerPackageId: number, _: Currency[]) => void;
  setUsedFranchises: (welcomerPackageId: number, _: number[]) => void;
  welcomerLimits: TplLimitList[] | undefined;
  welcomerCurrencies: TplCurrencies[] | undefined;
  refetchWelcomerPackagePrices: () => void;
};

const WelcomerPackageEditor = ({
  welcomerPackageId: pkgId,
  welcomerFranchise,
  welcomerRisks,
  welcomerAddon,
  refetchWelcomerProduct,
  welcomerProduct,
  setWelcomerProductField,
  setUsedCurrencies,
  setUsedFranchises,
  welcomerLimits,
  welcomerCurrencies,
  refetchWelcomerPackagePrices,
}: Props) => {
  //
  //
  const { t } = useTranslation();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  //
  const auth = useAppSelector((data) => data.auth);
  const isAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";
  /** small context:
   * when the package fields change here, we have to propagate them back to the parent.
   * this means that we shouldn't receive `travelPackage` from the parent, or it will
   * become a two-way binding and we'll receive a "Maximum update depth exceeded" error.
   */
  //
  const {
    //
    data: initalWelcomerPage,
    isLoading: isInitialWelcomerPageLoading,
    refetch: refetchWelcomerPackage,
  } = api.useGetWelcomerPackageByIdQuery(pkgId);

  const [
    m_updateWelcomerPackage,
    { isLoading: isUpdateWelcomerPackageLoading },
  ] = api.useUpdateWelcomerPackageMutation();
  //
  const [
    _deleteWelcomerPackage,
    { isLoading: isDeleteWelcomerPackageLoading },
  ] = api.useDeleteWelcomerPackageMutation();
  // [admin]: approve package changes
  const [m_activatePackage, { isLoading: isActivatePackageLoading }] =
    api.useAdminActivatePackageMutation();
  // [admin]: reject package changes
  const [m_deactivatePackage, { isLoading: isDeactivatePackageLoading }] =
    api.useAdminDeactivatePackageMutation();
  //

  //
  const [addonsType, setAddonsType] = useState<"group" | "default" | string>(
    "group"
  );
  //
  const [editedPackage, only_setEditedPackage] = useState<
    WelcomerPackage | undefined
  >(undefined);
  //
  //
  //
  const setEditedPackage = useCallback(
    (newState: WelcomerPackage) => {
      only_setEditedPackage(newState);
      if (newState) {
        const mainProductWithUpdatedPackage: Product = {
          ...welcomerProduct.mainProduct,
          packages: welcomerProduct.mainProduct.packages.map((mainPkg) => {
            if (mainPkg.id === newState.mainPackage.id) {
              return {
                ...newState.mainPackage,
                target: { ...newState },
              };
            } else {
              return mainPkg;
            }
          }),
        };
        /** this will cause price combinations to regenerate with updated data */
        setWelcomerProductField("mainProduct", mainProductWithUpdatedPackage);
      }
    },
    [only_setEditedPackage, setWelcomerProductField, welcomerProduct]
  );
  useEffect(() => {
    if (initalWelcomerPage) {
      setEditedPackage(initalWelcomerPage);
    }
  }, [initalWelcomerPage, setEditedPackage]);
  //
  const setWelcomerPackageField = (
    fieldName: keyof WelcomerPackage,
    fieldValue: any
  ) => {
    if (!editedPackage) {
      return;
    }
    setEditedPackage({
      ...editedPackage,
      [fieldName]: fieldValue,
    });
  };
  //
  const setMainPackageField = (
    fieldName: keyof WelcomerPackage["mainPackage"],
    fieldValue: any
  ) => {
    if (!editedPackage || !editedPackage.mainPackage) {
      return;
    }
    setEditedPackage({
      ...editedPackage,
      mainPackage: {
        ...editedPackage.mainPackage,
        [fieldName]: fieldValue,
      },
    });
  };
  //
  //
  const setTranslatedFieldOnMainPackage = (
    field: keyof Package["translations"]["en"],
    language: EnabledLanguage,
    value: any
  ) => {
    if (!editedPackage || !editedPackage.mainPackage) {
      return;
    }
    setMainPackageField("translations", {
      ...editedPackage.mainPackage.translations,
      [language]: {
        ...editedPackage.mainPackage.translations?.[language],
        [field]: value,
      },
    });
  };
  //
  const groupedAttachedAddonInfos =
    editedPackage?.groupedAttachedAddonInfos?.map((groupAddon) => ({
      groupedPackageCurrency: groupAddon?.packageCurrency,
      groupedPremiumCurrency: "GEL",
      groupedPaymentType: groupAddon?.paymentType,
      groupedPremium: groupAddon?.premium,
      groupedTranslations: groupAddon?.translations,
      addonsInformation: groupAddon?.addons?.map((groupAddonInfo) => ({
        addonId: groupAddonInfo?.addonId,
        translations: groupAddonInfo?.translations,
      })),
    }));
  //
  //
  const saveWelcomerPackage = async () => {
    if (editedPackage) {
      try {
        /** `franchise` and `description` should always be strings, but some old data (including from older seeders) is still numbers and fails backend validation. this can be removed after a while (e.g. 1 month) */
        let fixedUpEditedPackage: WelcomerPackage = { ...editedPackage };

        fixedUpEditedPackage.groupedAttachedAddonInfos =
          groupedAttachedAddonInfos as never;
        //
        fixedUpEditedPackage.productId = welcomerProduct.id;
        //
        // send currency ids
        fixedUpEditedPackage.currencyIds = welcomerCurrencies
          ?.filter((x) =>
            fixedUpEditedPackage.mainPackage.compensationCurrencies
              .map((y) => y.currency)
              .includes(x.currencyCode)
          )
          ?.map((x) => x.id);
        //
        //
        fixedUpEditedPackage.limitId = welcomerLimits
          ? welcomerLimits
              ?.filter(
                (x) =>
                  x.value === fixedUpEditedPackage.mainPackage.compensationLimit
              )
              .map((x) => x.id)[0]
          : 0;
        //
        const result = await m_updateWelcomerPackage(
          fixedUpEditedPackage
        ).unwrap();
        // COMMENTED to avoid resetting product fields
        setEditedPackage(result);
        refetchWelcomerPackagePrices();
        console.log("save welcomer package", result);
        toast.success(`${t('package')} ${t('savedSuccessfully')}`);
      } catch (error) {
        const message =
          error?.data?.errors?.[0]?.message ||
          error?.message ||
          `${t('failedToSave')}`;
        toast.error(message);
      }
    }
  };
  const deleteWelcomerPackage = async () => {
    if (editedPackage) {
      if (!window.confirm(`${t('certainToDeleteIt')}`)) {
        return;
      }
      try {
        const result = await _deleteWelcomerPackage(editedPackage.id).unwrap();
        refetchWelcomerProduct();
        refetchWelcomerPackagePrices();
        console.log("delete welcomer package", result);
        toast.success(`${t('package')} ${t('deletedSuccessfully')}`);
      } catch (error) {
        const message =
          error?.data?.errors?.[0]?.message ||
          error?.message ||
          `${t('failedToDelete')}`;
        toast.error(message);
      }
    }
  };
  // approve package changes [admin does this]
  const approvePackageChanges = async () => {
    try {
      if (!editedPackage) {
        throw new Error("Missing package data");
      }
      if (editedPackage.mainPackage.status === "published") {
        throw new Error("დაუდასტურებელი ცვლილებები არ მოიძებნა");
      }
      // first, save package fields
      await saveWelcomerPackage();
      // second, activate the package
      const result = await m_activatePackage(
        editedPackage.mainPackage.id
      ).unwrap();
      //
      toast.success(`${t('package')} ${t('publishedSuccessfully')}`);
      //
      // WARNING: this resets product fields.
      // if this is enabled, also save product fields above.
      setEditedPackage({
        ...editedPackage,
        mainPackage: result,
      });
      // third refetch package data
      await refetchWelcomerPackage();
    } catch (error) {
      console.log("approve package tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t('unknownError')}`);
      }
    }
  };
  // reject package changes [admin does this]
  const rejectPackageChanges = async () => {
    try {
      if (!editedPackage) {
        throw new Error("Missing package data");
      }
      if (editedPackage.mainPackage.status === "published") {
        // in this case, admin is converting a published package to draft (probably just auditing existing published packages)
      }
      //const result =
      await m_deactivatePackage(editedPackage.mainPackage.id).unwrap();
      toast.success(`${t('package')} ${t('deactivated')}`);
      //
      setMainPackageField("status", "draft");
    } catch (error) {
      console.log("save documents tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t('unknownError')}`);
      }
    }
  };
  //

  // Generate a random color in hexadecimal format
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Create a color mapping based on object titles
  const colorMapping = {};
  editedPackage?.groupedAttachedAddonInfos?.forEach((object) => {
    if (!colorMapping[object.title]) {
      colorMapping[object.title] = getRandomColor();
    }
  });

  //
  //
  const isLoading =
    isInitialWelcomerPageLoading ||
    isUpdateWelcomerPackageLoading ||
    isActivatePackageLoading ||
    isDeactivatePackageLoading ||
    isDeleteWelcomerPackageLoading;
  //
  if (!editedPackage) {
    return null;
  }
  //
  const FranoHorizontalLine: React.FC<{ title?: string }> = ({ title }) => {
    return (
      <CustomHorizontalLine>
        <div></div>
        {title ? <span>{title}</span> : <Logo />}
        <div></div>
      </CustomHorizontalLine>
    );
  };
  //
  //
  //
  //
  return (
    <Wrapper>
      <Accordion title={editedPackage.mainPackage.title} key={editedPackage.id}>
        <Row>
          <Column $widthPercent={70} style={{ paddingLeft: "0px !important" }}>
            {/* <Block>
            <h4>ჯერადობის მიხედვით მარკეტინგული ფასი</h4>
            <Text>პაკეტი &quot;{pkg.title}&quot;</Text>
          </Block> */}

            <FranoHorizontalLine title={t("packageTitle")} />
            <Block
              style={{
                width: "100%",
              }}
            >
              <Input
                id=""
                label=""
                type="text"
                placeholder=""
                value={
                  editedPackage.mainPackage.translations[field_Language]
                    ?.title || ""
                }
                // @ts-ignore Partial EnabledLanguage cannot reliably detect translated field because 'en' is also Partial
                onChange={(value) =>
                  // @ts-ignore
                  setTranslatedFieldOnMainPackage(
                    // @ts-ignore
                    "title",
                    field_Language,
                    value
                  )
                }
                width={"fullWidth"}
              />
            </Block>

            <FranoHorizontalLine title={t("compensationLimit")} />
            {welcomerLimits
              ?.filter((limit) => limit.isActive)
              ?.map((limit, index) => (
                <RadioGroup
                  key={index}
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={editedPackage.mainPackage.compensationLimit}
                  onChange={(event) =>
                    setEditedPackage({
                      ...editedPackage,
                      limitId: limit.id,
                      mainPackage: {
                        ...editedPackage.mainPackage,
                        compensationLimit: Number(event.target.value),
                      },
                    })
                  }
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "unset",
                  }}
                >
                  <FormControlLabel
                    value={limit.value}
                    control={<Radio />}
                    label={limit.value}
                  />
                </RadioGroup>
              ))}

            <FranoHorizontalLine title={t("currencyForLimit")} />
            <Block>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                {welcomerCurrencies
                  ?.filter((currencies) => currencies.isActive)
                  .map((currency) => (
                    <Block key={currency.currencyCode}>
                      <InputLabel
                        htmlFor={`package-${editedPackage.id}-limit-currency-ckb-${currency}`}
                      >
                        {currency.currencyCode}
                      </InputLabel>
                      <Checkbox
                        id={`package-${editedPackage.id}-limit-currency-ckb-${currency}`}
                        checked={editedPackage?.mainPackage?.compensationCurrencies?.some(
                          (a) => a.currency === currency.currencyCode
                        )}
                        onChange={(event) => {
                          const checked = event?.target.checked;
                          const newCurrencyList = [
                            ...(
                              editedPackage?.mainPackage
                                .compensationCurrencies || []
                            ).filter(
                              (a) => a.currency !== currency.currencyCode
                            ),
                            ...(checked
                              ? [{ currency: currency.currencyCode }]
                              : []),
                          ];
                          setMainPackageField(
                            "compensationCurrencies",
                            newCurrencyList
                          );
                          setUsedCurrencies(
                            editedPackage.id,
                            newCurrencyList.map((v) => v.currency as Currency)
                          );
                        }}
                      />
                    </Block>
                  ))}
              </div>
            </Block>
            <FranoHorizontalLine title={t("deductible")} />

            <Block>
              {/* <Text>ფრანშიზა</Text> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                {welcomerFranchise?.map((franchiseOption) => (
                  <Block key={franchiseOption.value}>
                    <InputLabel
                      htmlFor={`package-${editedPackage.id}-franchise-ckb-${franchiseOption}`}
                    >
                      {franchiseOption.value}
                    </InputLabel>
                    <Checkbox
                      id={`package-${editedPackage.id}-franchise-ckb-${franchiseOption}`}
                      checked={editedPackage?.mainPackage.franchises?.some(
                        (fValue) => fValue === franchiseOption.value
                      )}
                      onChange={(event) => {
                        const checked = event?.target.checked;
                        const newFranchiseList = [
                          ...(
                            editedPackage?.mainPackage.franchises ?? []
                          ).filter(
                            (fValue) => fValue !== franchiseOption.value
                          ),
                          ...(checked ? [franchiseOption.value] : []),
                        ];
                        setMainPackageField("franchises", newFranchiseList);
                        setUsedFranchises(editedPackage.id, newFranchiseList);
                      }}
                    />
                  </Block>
                ))}
              </div>
            </Block>

            <FranoHorizontalLine />
            <Block style={{ flexDirection: "column" }}>
              {/* <Title>დაფარვები</Title> */}
              <Accordion
                title={t("coverages")}
                className="admin-addons-accoridon"
              >
                <RiskContent>
                  {[...welcomerRisks].map((risk) => (
                    <RiskDiv key={risk.id}>
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedPackage.mainPackage.welcomerEnabledRisks?.some(
                                (enabledRisk: WelcomerEnabledRisk) =>
                                  enabledRisk.riskId === risk.id
                              )}
                              onChange={(event) => {
                                const listWithoutThisRisk: WelcomerEnabledRisk[] =
                                  (
                                    editedPackage.mainPackage
                                      .welcomerEnabledRisks ?? []
                                  ).filter(
                                    (enabledRisk) =>
                                      enabledRisk.riskId !== risk.id
                                  );
                                if (event.target.checked) {
                                  const thisRiskEnabled: WelcomerEnabledRisk = {
                                    id: risk.id,
                                    riskId: risk.id,
                                    packageId: editedPackage.mainPackage.id,
                                    risk,
                                    translations: {
                                      [field_Language]: {
                                        ...risk.translations?.[field_Language],
                                        riskDescription: "",
                                      },
                                    },
                                  };

                                  const listIncludingThisRisk: WelcomerEnabledRisk[] =
                                    [...listWithoutThisRisk, thisRiskEnabled];
                                  setMainPackageField(
                                    "welcomerEnabledRisks",
                                    listIncludingThisRisk
                                  );
                                } else {
                                  setMainPackageField(
                                    "welcomerEnabledRisks",
                                    listWithoutThisRisk
                                  );
                                }
                              }}
                            />
                          }
                          label={
                            <>
                              <img src={uploadUrl(risk.icon || "")} alt="" />
                              <span>
                                {risk.translations?.[field_Language]
                                  ?.riskName ?? risk.riskName}
                              </span>
                            </>
                          }
                        />

                        <TextBlock>
                          <TextAreaTitle>{t("comment")}</TextAreaTitle>
                          <TextAreaStyle
                            id={`welcomerRisks-${risk.id}-comment`}
                            placeholder=""
                            value={
                              (
                                editedPackage.mainPackage
                                  .welcomerEnabledRisks ?? []
                              ).find(
                                (enabledRisk: WelcomerEnabledRisk) =>
                                  enabledRisk.riskId === risk.id
                              )?.translations?.[field_Language]
                                ?.riskDescription || ""
                            }
                            onChange={(event) => {
                              const updatedRisks = (
                                editedPackage.mainPackage
                                  .welcomerEnabledRisks ?? []
                              )?.map((item) =>
                                item?.riskId === risk.id
                                  ? {
                                      ...item,
                                      translations: {
                                        ...item.translations,
                                        [field_Language]: {
                                          ...item.translations?.[
                                            field_Language
                                          ],
                                          riskDescription: event.target.value,
                                        },
                                      },
                                    }
                                  : item
                              );
                              //
                              setMainPackageField(
                                "welcomerEnabledRisks",
                                updatedRisks
                              );
                            }}
                          ></TextAreaStyle>
                        </TextBlock>
                      </>
                    </RiskDiv>
                  ))}
                </RiskContent>
              </Accordion>
            </Block>

            <FranoHorizontalLine title={t("additionalCoveragesActivate")} />
            <AddonsHeadBlock>
              <FormGroup
                style={{
                  flexDirection: "unset",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                {/* <Title>დამატებითი დაფარვების შეძენის გააქტიურება</Title> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editedPackage.enableAddonsOnSingle}
                      onChange={(event) =>
                        setWelcomerPackageField(
                          "enableAddonsOnSingle",
                          event.target.checked
                        )
                      }
                    />
                  }
                  label={t("onSinglePolicy")}
                />
                {/* {editedPackage.enableMulti ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editedPackage.enableAddonsOnMulti}
                        onChange={(event) =>
                          setWelcomerPackageField(
                            "enableAddonsOnMulti",
                            event.target.checked
                          )
                        }
                      />
                    }
                    label="მულტიპოლისზე"
                  />
                ) : null} */}
              </FormGroup>
            </AddonsHeadBlock>

            <FranoHorizontalLine />

            <Accordion
              openAccordion={true}
              title={t("newAditionalCoverages")}
              className="admin-addons-accoridon"
            >
              <div>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={addonsType || ""}
                  onChange={(event) => {
                    if (
                      event.target.value === "default" ||
                      event.target.value === "group"
                    ) {
                      return [setAddonsType(event.target.value)];
                    }
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "unset",
                  }}
                >
                  <FormControlLabel
                    value="group"
                    control={<Radio />}
                    label={t("grouped")}
                  />
                  <FormControlLabel
                    value="default"
                    control={<Radio />}
                    label={t("standard")}
                  />
                </RadioGroup>
                {/*  */}
                {/*  */}
                {/* BEGIN group */}
                {addonsType === "group" && (
                  <AddonBlock>
                    {editedPackage?.groupedAttachedAddonInfos?.map(
                      (groupAddonItem, groupAddonIndex) => {
                        return (
                          <div
                            key={groupAddonIndex}
                            className="new-addon-item"
                            style={{
                              boxShadow: "unset",
                              border: `2px dashed ${
                                colorMapping[groupAddonItem?.title]
                              }`,
                            }}
                          >
                            <h6
                              style={{
                                textAlign: "center",
                                color: Colors.primaryRed,
                                borderBottom: `1px solid ${Colors.primaryRed}`,
                                paddingBottom: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {/* <b>ვალუტა: {groupAddonItem?.packageCurrency}</b> */}
                              <b>
                                {groupAddonItem?.title ||
                                  t("groupedNameNotFound")}{" "}
                                {`(${groupAddonItem?.packageCurrency})`}
                              </b>

                              {/* editedPackage?.groupedAttachedAddonInfos */}
                            </h6>

                            <div className="new-addon-head"></div>
                            <div className="new-addon-body">
                              <div className="new-addon-fields">
                                <CustomDropdown
                                  title={t("valueType")}
                                  placeholder={t("valueType")}
                                  isMulti={false}
                                  isClearable={false}
                                  value={groupAddonItem.paymentType}
                                  onChange={(obj) => {
                                    if (
                                      editedPackage &&
                                      editedPackage.groupedAttachedAddonInfos
                                    ) {
                                      setWelcomerPackageField(
                                        "groupedAttachedAddonInfos",
                                        editedPackage?.groupedAttachedAddonInfos?.map(
                                          (grAd) =>
                                            grAd.id === groupAddonItem.id ||
                                            grAd.translations.ge?.title ===
                                              groupAddonItem.translations.ge
                                                ?.title
                                              ? {
                                                  ...grAd,
                                                  paymentType: obj.value,
                                                }
                                              : grAd
                                        )
                                      );
                                    }
                                  }}
                                  options={[
                                    ...[
                                      { label: t("single"), value: "ONCE" },
                                      { label: t("daily"), value: "DAILY" },
                                    ].map((item) => ({
                                      value: item.value,
                                      label: item.label,
                                    })),
                                  ]}
                                />

                                <Input
                                  id="gel-addon-premium"
                                  name="gel-addon-premium"
                                  label={"GEL"}
                                  type="text"
                                  placeholder=""
                                  value={groupAddonItem.premium || ""}
                                  onChange={(value) => {
                                    if (
                                      editedPackage &&
                                      editedPackage.groupedAttachedAddonInfos
                                    ) {
                                      setWelcomerPackageField(
                                        "groupedAttachedAddonInfos",
                                        editedPackage?.groupedAttachedAddonInfos?.map(
                                          (grAd) =>
                                            grAd.id === groupAddonItem.id ||
                                            grAd.translations.ge?.title ===
                                              groupAddonItem.translations.ge
                                                ?.title
                                              ? {
                                                  ...grAd,
                                                  premium: parseInt(value, 10),
                                                }
                                              : grAd
                                        )
                                      );
                                    }
                                  }}
                                  width={"fullWidth"}
                                />
                              </div>

                              <Input
                                id="grouped-translation-title"
                                name="grouped-translation-title"
                                label={t("groupTitle")}
                                type="text"
                                placeholder=""
                                value={
                                  groupAddonItem?.translations?.[field_Language]
                                    ?.title || ""
                                }
                                onChange={(value) => {
                                  if (
                                    editedPackage &&
                                    editedPackage.groupedAttachedAddonInfos
                                  ) {
                                    setWelcomerPackageField(
                                      "groupedAttachedAddonInfos",
                                      editedPackage?.groupedAttachedAddonInfos?.map(
                                        (grAd) =>
                                          grAd.id === groupAddonItem.id ||
                                          grAd.translations.ge?.title ===
                                            groupAddonItem.translations.ge
                                              ?.title
                                            ? {
                                                ...grAd,
                                                translations: {
                                                  ...grAd.translations,
                                                  [field_Language]: {
                                                    ...grAd.translations[
                                                      field_Language
                                                    ],
                                                    title: value,
                                                  },
                                                },
                                              }
                                            : grAd
                                      )
                                    );
                                  }
                                }}
                                width={"fullWidth"}
                                style={{
                                  marginBlock: "20px",
                                }}
                              />

                              {groupAddonItem?.translations?.[field_Language]
                                ?.title &&
                              groupAddonItem.premium &&
                              groupAddonItem.paymentType ? (
                                <div className="defult-group-addon-block">
                                  <h6>{t("selectAdditonalCoverages")}</h6>
                                  {welcomerAddon?.map(
                                    (defaultAddonItem, defaultAddonIndex) => {
                                      return (
                                        <div
                                          key={defaultAddonIndex}
                                          className="defult-group-addon-item"
                                        >
                                          <div className="defult-group-addon-inner-item">
                                            <img
                                              src={defaultAddonItem.image}
                                              alt={`default-addon-img-${defaultAddonItem.id}`}
                                            />

                                            {
                                              defaultAddonItem?.translations[
                                                field_Language
                                              ]?.title
                                            }

                                            <Checkbox
                                              checked={
                                                editedPackage?.groupedAttachedAddonInfos
                                                  ?.find(
                                                    (x) =>
                                                      x.id === groupAddonItem.id
                                                  )
                                                  ?.addons?.find(
                                                    (x) =>
                                                      x.addonId ===
                                                      defaultAddonItem.id
                                                  )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(event) => {
                                                if (
                                                  editedPackage &&
                                                  editedPackage.groupedAttachedAddonInfos &&
                                                  event.target.checked
                                                ) {
                                                  setWelcomerPackageField(
                                                    "groupedAttachedAddonInfos",
                                                    editedPackage?.groupedAttachedAddonInfos?.map(
                                                      (grAd) =>
                                                        grAd.title ===
                                                        groupAddonItem?.title
                                                          ? {
                                                              ...grAd,
                                                              addons:
                                                                grAd.addons
                                                                  ? [
                                                                      ...grAd.addons,

                                                                      {
                                                                        id: defaultAddonItem.id,
                                                                        addonId:
                                                                          defaultAddonItem.id,
                                                                        translations:
                                                                          {
                                                                            ge: {
                                                                              description:
                                                                                "",
                                                                              franchiseDescription:
                                                                                "",
                                                                            },
                                                                          },
                                                                      },
                                                                    ]
                                                                  : [
                                                                      {
                                                                        id: defaultAddonItem.id,
                                                                        addonId:
                                                                          defaultAddonItem.id,
                                                                        translations:
                                                                          {
                                                                            ge: {
                                                                              description:
                                                                                "",
                                                                              franchiseDescription:
                                                                                "",
                                                                            },
                                                                          },
                                                                      },
                                                                    ],
                                                            }
                                                          : grAd
                                                    )
                                                  );
                                                }
                                                //
                                                //
                                                if (
                                                  event.target.checked ===
                                                    false &&
                                                  editedPackage &&
                                                  editedPackage.groupedAttachedAddonInfos
                                                ) {
                                                  //
                                                  setWelcomerPackageField(
                                                    "groupedAttachedAddonInfos",
                                                    editedPackage.groupedAttachedAddonInfos?.map(
                                                      (inerITem) => {
                                                        const updatedItems =
                                                          inerITem.addons.filter(
                                                            (x) =>
                                                              x.addonId !==
                                                              defaultAddonItem.id
                                                          );
                                                        return {
                                                          ...inerITem,
                                                          addons: updatedItems,
                                                        };
                                                      }
                                                    )
                                                  );
                                                  //
                                                }
                                              }}
                                            />
                                          </div>

                                          {/* levani */}

                                          {editedPackage?.groupedAttachedAddonInfos
                                            ?.find(
                                              (x) => x.id === groupAddonItem.id
                                            )
                                            ?.addons?.find(
                                              (x) =>
                                                x.addonId ===
                                                defaultAddonItem.id
                                            ) ? (
                                            <div className="defult-group-addon-inner-item-description">
                                              {editedPackage?.mainPackage?.compensationCurrencies
                                                .filter(
                                                  (x) =>
                                                    x.currency ===
                                                    groupAddonItem.packageCurrency
                                                )
                                                ?.map(
                                                  (
                                                    currencyItem,
                                                    currencyindex
                                                  ) => {
                                                    //
                                                    //
                                                    return (
                                                      <div key={currencyindex}>
                                                        <Input
                                                          id={`1-addon-premium`}
                                                          name={`1-addon-premium`}
                                                          label={`${t(
                                                            "deductibleDescription"
                                                          )} ${
                                                            currencyItem.currency
                                                          }`}
                                                          type="text"
                                                          placeholder=""
                                                          value={
                                                            editedPackage?.groupedAttachedAddonInfos
                                                              ?.find(
                                                                (x) =>
                                                                  x.id ===
                                                                    groupAddonItem.id &&
                                                                  x.packageCurrency ===
                                                                    currencyItem.currency
                                                              )
                                                              ?.addons?.find(
                                                                (x) =>
                                                                  x.addonId ===
                                                                  defaultAddonItem.id
                                                              )?.translations?.[
                                                              field_Language
                                                            ]
                                                              ?.franchiseDescription ||
                                                            ""
                                                          }
                                                          onChange={(value) => {
                                                            //
                                                            const updateFranchiseDescription1 =
                                                              (
                                                                groupedPackageCurrency,
                                                                addonId,
                                                                newDescription
                                                              ) => {
                                                                const updatedTravelAttachedAddonInfos =
                                                                  editedPackage.groupedAttachedAddonInfos?.map(
                                                                    (info) => {
                                                                      if (
                                                                        info.packageCurrency ===
                                                                        groupedPackageCurrency
                                                                      ) {
                                                                        const updatedAddonsInformation =
                                                                          info.addons.map(
                                                                            (
                                                                              addonInfo
                                                                            ) => {
                                                                              if (
                                                                                addonInfo.id ===
                                                                                addonId
                                                                              ) {
                                                                                const updatedTranslations =
                                                                                  {
                                                                                    ...addonInfo.translations,
                                                                                    [field_Language]:
                                                                                      {
                                                                                        ...addonInfo
                                                                                          .translations?.[
                                                                                          field_Language
                                                                                        ],
                                                                                        franchiseDescription:
                                                                                          newDescription,
                                                                                      },
                                                                                  };

                                                                                return {
                                                                                  ...addonInfo,
                                                                                  translations:
                                                                                    updatedTranslations,
                                                                                };
                                                                              }
                                                                              return addonInfo;
                                                                            }
                                                                          );

                                                                        return {
                                                                          ...info,
                                                                          addons:
                                                                            updatedAddonsInformation,
                                                                        };
                                                                      }
                                                                      return info;
                                                                    }
                                                                  );

                                                                setWelcomerPackageField(
                                                                  "groupedAttachedAddonInfos",
                                                                  updatedTravelAttachedAddonInfos
                                                                );
                                                              };
                                                            //
                                                            updateFranchiseDescription1(
                                                              groupAddonItem?.packageCurrency,
                                                              defaultAddonItem?.id,
                                                              value
                                                            );
                                                            //
                                                          }}
                                                          width={"fullWidth"}
                                                          style={{
                                                            marginBottom:
                                                              "20px",
                                                          }}
                                                        />

                                                        <Input
                                                          id={`2-addon-description`}
                                                          name={`2-addon-description`}
                                                          label={`${t(
                                                            "comment"
                                                          )} ${
                                                            currencyItem.currency
                                                          }`}
                                                          type="text"
                                                          placeholder=""
                                                          value={
                                                            editedPackage?.groupedAttachedAddonInfos
                                                              ?.find(
                                                                (x) =>
                                                                  x.id ===
                                                                    groupAddonItem.id &&
                                                                  x.packageCurrency ===
                                                                    currencyItem.currency
                                                              )
                                                              ?.addons?.find(
                                                                (x) =>
                                                                  x.addonId ===
                                                                  defaultAddonItem.id
                                                              )?.translations?.[
                                                              field_Language
                                                            ]?.description || ""
                                                          }
                                                          onChange={(value) => {
                                                            //
                                                            const updateFranchiseDescription1 =
                                                              (
                                                                groupedPackageCurrency,
                                                                addonId,
                                                                newDescription2
                                                              ) => {
                                                                const updatedTravelAttachedAddonInfos =
                                                                  editedPackage.groupedAttachedAddonInfos?.map(
                                                                    (info) => {
                                                                      if (
                                                                        info.packageCurrency ===
                                                                        groupedPackageCurrency
                                                                      ) {
                                                                        const updatedAddonsInformation =
                                                                          info.addons.map(
                                                                            (
                                                                              addonInfo
                                                                            ) => {
                                                                              if (
                                                                                addonInfo.id ===
                                                                                addonId
                                                                              ) {
                                                                                const updatedTranslations =
                                                                                  {
                                                                                    ...addonInfo.translations,
                                                                                    [field_Language]:
                                                                                      {
                                                                                        ...addonInfo
                                                                                          .translations?.[
                                                                                          field_Language
                                                                                        ],
                                                                                        description:
                                                                                          newDescription2,
                                                                                      },
                                                                                  };

                                                                                return {
                                                                                  ...addonInfo,
                                                                                  translations:
                                                                                    updatedTranslations,
                                                                                };
                                                                              }
                                                                              return addonInfo;
                                                                            }
                                                                          );

                                                                        return {
                                                                          ...info,
                                                                          addons:
                                                                            updatedAddonsInformation,
                                                                        };
                                                                      }
                                                                      return info;
                                                                    }
                                                                  );

                                                                setWelcomerPackageField(
                                                                  "groupedAttachedAddonInfos",
                                                                  updatedTravelAttachedAddonInfos
                                                                );
                                                              };
                                                            //
                                                            updateFranchiseDescription1(
                                                              groupAddonItem?.packageCurrency,
                                                              defaultAddonItem?.id,
                                                              value
                                                            );
                                                            //
                                                          }}
                                                          width={"fullWidth"}
                                                          style={{
                                                            marginBottom:
                                                              "20px",
                                                          }}
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                <div>{t("additionalCoveragesMandatiory")}</div>
                              )}

                              <hr />

                              <Button
                                btnStyleType="primary"
                                border={false}
                                size="medium"
                                icon={false}
                                text={t("delete")}
                                fullWidth={true}
                                disabled={false}
                                onClick={() => {
                                  if (
                                    editedPackage?.groupedAttachedAddonInfos
                                  ) {
                                    const editedGrAddon =
                                      editedPackage?.groupedAttachedAddonInfos?.filter(
                                        (x) => x.title !== groupAddonItem.title
                                      );
                                    //
                                    setWelcomerPackageField(
                                      "groupedAttachedAddonInfos",
                                      editedGrAddon
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                    )}

                    <Button
                      btnStyleType="transparent"
                      border={true}
                      size="medium"
                      icon={false}
                      fullWidth={true}
                      disabled={false}
                      onClick={() => {
                        const initialGroupAddon =
                          editedPackage?.mainPackage?.compensationCurrencies.map(
                            (currencyItem) => ({
                              id:
                                1 *
                                  editedPackage.groupedAttachedAddonInfos
                                    ?.length +
                                  1 || 1,
                              packageCurrency: currencyItem.currency,
                              premiumCurrency: "GEL",
                              paymentType: "",
                              premium: 0,
                              translations: {
                                ge: {
                                  title: "",
                                },
                              },
                              addons: [],
                            })
                          );

                        const updatedGroupArray = [
                          ...(editedPackage?.groupedAttachedAddonInfos || []),

                          ...initialGroupAddon,
                        ];

                        //
                        return setWelcomerPackageField(
                          "groupedAttachedAddonInfos",
                          updatedGroupArray
                        );
                      }}
                      text={t("add")}
                    />
                  </AddonBlock>
                )}

                {/* END group */}
                {/*  */}
                {/* BEGIN default addon */}
                {addonsType === "default" && (
                  <div>
                    <AddonBlock>
                      {welcomerAddon?.map((addonItem, addonIndex) => {
                        //
                        //
                        return (
                          <div key={addonIndex} className="new-addon-item">
                            <div className="new-addon-head">
                              <img src={addonItem.image} alt="addon-img" />
                              <div>
                                {addonItem.translations[field_Language]?.title}
                              </div>
                              <Checkbox
                                id={`package-${addonIndex}-id`}
                                checked={
                                  editedPackage?.attachedAddons?.find(
                                    (x) => x.addonId === addonItem.id
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  //
                                  const newObj =
                                    editedPackage?.mainPackage?.compensationCurrencies
                                      ? editedPackage?.mainPackage?.compensationCurrencies?.map(
                                          (currency) => {
                                            //
                                            return {
                                              addonId: addonItem.id,
                                              paymentType: "",
                                              packageCurrency: currency?.currency,
                                              premiumCurrency: "GEL",
                                              premium: null,
                                              translations:
                                                supportedLocales.reduce(
                                                  (result, language) => {
                                                    result[language] = {
                                                      description: "",
                                                      franchiseDescription: "",
                                                    };
                                                    return result;
                                                  },
                                                  {}
                                                ),
                                            };
                                          }
                                        )
                                      : [];
                                  //
                                  const updatedArry = [
                                    ...(editedPackage?.attachedAddons || []),
                                    ...newObj,
                                  ];
                                  //
                                  if (event.target.checked) {
                                    setWelcomerPackageField(
                                      "attachedAddons",
                                      updatedArry
                                    );
                                  } else {
                                    const filteredArray =
                                      editedPackage?.attachedAddons?.filter(
                                        (item) => item.addonId !== addonItem.id
                                      );
                                    //
                                    setWelcomerPackageField(
                                      "attachedAddons",
                                      filteredArray
                                    );
                                  }
                                }}
                              />
                            </div>

                            <hr />

                            <div className="new-addon-body">
                              <div className="new-addon-fields">
                                <CustomDropdown
                                  title={t("valueType")}
                                  placeholder={t("valueType")}
                                  isMulti={false}
                                  value={
                                    editedPackage?.attachedAddons?.find(
                                      (x) => x.addonId === addonItem.id
                                    )?.paymentType || undefined
                                  }
                                  isClearable={false}
                                  onChange={(obj) => {
                                    setWelcomerPackageField(
                                      "attachedAddons",
                                      editedPackage.attachedAddons?.map(
                                        (atAd) =>
                                          atAd.addonId === addonItem.id
                                            ? {
                                                ...atAd,
                                                paymentType: obj.value,
                                              }
                                            : atAd
                                      )
                                    );
                                  }}
                                  options={[
                                    ...[
                                      { label: t("single"), value: "ONCE" },
                                      { label: t("daily"), value: "DAILY" },
                                    ].map((item) => ({
                                      value: item.value,
                                      label: item.label,
                                    })),
                                  ]}
                                />

                                <Input
                                  id="gel-addon-premium"
                                  name="gel-addon-premium"
                                  label={"GEL"}
                                  type="text"
                                  placeholder=""
                                  value={
                                    editedPackage?.attachedAddons?.find(
                                      (x) => x.addonId === addonItem.id
                                    )?.premium || ""
                                  }
                                  onChange={(value) => {
                                    setWelcomerPackageField(
                                      "attachedAddons",
                                      editedPackage.attachedAddons?.map(
                                        (atAd) =>
                                          atAd.addonId === addonItem.id
                                            ? {
                                                ...atAd,
                                                premium: parseInt(value, 10),
                                              }
                                            : atAd
                                      )
                                    );
                                  }}
                                  width={"fullWidth"}
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {editedPackage?.mainPackage?.compensationCurrencies?.map(
                                (welcomerCurrency, currencyIndex) => {
                                  //
                                  let currency = welcomerCurrency?.currency
                                  //
                                  return (
                                    <div key={currencyIndex}>
                                      <hr />
                                      <Input
                                        id={`${currency}-addon-premium`}
                                        name={`${currency}-addon-premium`}
                                        label={`${t(
                                          "deductibleDescription"
                                        )} (${currency})`}
                                        type="text"
                                        placeholder=""
                                        value={
                                          editedPackage?.attachedAddons?.find(
                                            (x) =>
                                              x.addonId === addonItem.id &&
                                              x.packageCurrency === currency
                                          )?.translations?.[field_Language]
                                            ?.franchiseDescription
                                        }
                                        //
                                        onChange={(value) => {
                                          setWelcomerPackageField(
                                            "attachedAddons",
                                            editedPackage.attachedAddons?.map(
                                              (atAd) =>
                                                atAd.addonId === addonItem.id &&
                                                atAd.packageCurrency ===
                                                  currency
                                                  ? {
                                                      ...atAd,
                                                      translations: {
                                                        ...atAd.translations,
                                                        [field_Language]: {
                                                          ...atAd.translations[
                                                            field_Language
                                                          ],
                                                          franchiseDescription:
                                                            value,
                                                        },
                                                      },
                                                    }
                                                  : atAd
                                            )
                                          );
                                        }}
                                        //
                                        //
                                        width={"fullWidth"}
                                        style={{
                                          marginBottom: "20px",
                                        }}
                                      />

                                      <Input
                                        id={`${currency}-addon-description`}
                                        name={`${currency}-addon-description`}
                                        label={`${t("comment")} (${currency})`}
                                        type="text"
                                        placeholder=""
                                        value={
                                          editedPackage?.attachedAddons?.find(
                                            (x) =>
                                              x.addonId === addonItem.id &&
                                              x.packageCurrency === currency
                                          )?.translations?.[field_Language]
                                            ?.description
                                        }
                                        onChange={(value) => {
                                          setWelcomerPackageField(
                                            "attachedAddons",
                                            editedPackage.attachedAddons?.map(
                                              (atAd) =>
                                                atAd.addonId === addonItem.id &&
                                                atAd.packageCurrency ===
                                                  currency
                                                  ? {
                                                      ...atAd,
                                                      translations: {
                                                        ...atAd.translations,
                                                        [field_Language]: {
                                                          ...atAd.translations[
                                                            field_Language
                                                          ],
                                                          description: value,
                                                        },
                                                      },
                                                    }
                                                  : atAd
                                            )
                                          );
                                        }}
                                        width={"fullWidth"}
                                        style={{
                                          marginBottom: "20px",
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </AddonBlock>
                  </div>
                )}
                {/* END default */}
              </div>
            </Accordion>

            <FranoHorizontalLine />
            <Accordion
              title={t("exceptionalCases")}
              className="admin-addons-accoridon"
            >
              <div style={{ marginTop: "20px" }}>
                {/* <Text>გამონაკლისი შემთხვევები</Text> */}
                <div>
                  {(editedPackage.mainPackage.insuranceExceptions ?? []).map(
                    (insuranceException, insuranceExceptionIndex) => (
                      <div
                        // key={`${ageGroup.minAge}-${ageGroup.maxAge}`}
                        key={insuranceExceptionIndex}
                        style={{
                          marginBottom: 10,
                        }}
                      >
                        <TextAreaTitle>{t("comment")}</TextAreaTitle>
                        <TextAreaStyle
                          id={`insurance-exception-${insuranceExceptionIndex}`}
                          value={
                            insuranceException?.translations?.[field_Language]
                              ?.title
                          }
                          style={{
                            display: "inline-flex",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                            // marginBottom: 8,
                            marginRight: 8,
                          }}
                          onChange={(event) => {
                            const listWithUpdatedTitle = (
                              editedPackage.mainPackage.insuranceExceptions ??
                              []
                            ).map((g, i) =>
                              i === insuranceExceptionIndex
                                ? {
                                    ...g,
                                    translations: {
                                      ...g.translations,
                                      [field_Language]: {
                                        ...g.translations?.[field_Language],
                                        title: event.target.value,
                                      },
                                    },
                                  }
                                : g
                            );
                            setMainPackageField(
                              "insuranceExceptions",
                              listWithUpdatedTitle
                            );
                          }}
                        ></TextAreaStyle>

                        <Button
                          style={{
                            marginLeft: "auto",
                          }}
                          btnStyleType="secondary"
                          size="medium"
                          icon={false}
                          fullWidth={false}
                          disabled={false}
                          onClick={() => {
                            setMainPackageField(
                              "insuranceExceptions",
                              editedPackage.mainPackage.insuranceExceptions!.filter(
                                (iEx, i) => i !== insuranceExceptionIndex
                              )
                            );
                          }}
                          text={t("delete")}
                        />
                      </div>
                    )
                  )}
                  <br />
                  <Button
                    btnStyleType="primary"
                    size="medium"
                    icon={false}
                    fullWidth={true}
                    disabled={false}
                    onClick={() => {
                      const listWithAddedItem: InsuranceException[] = [
                        ...(editedPackage.mainPackage.insuranceExceptions ||
                          []),
                        {
                          translations: {
                            ge: { title: "" },
                            en: { title: "" },
                          },
                        },
                      ];
                      setMainPackageField(
                        "insuranceExceptions",
                        listWithAddedItem
                      );
                    }}
                    text={t("addExceptionalCase")}
                  />
                </div>
              </div>
            </Accordion>
            {/* END insuranceExceptions */}

            <FranoHorizontalLine title={t("packageDocuments")} />
            <DocumentsWrapper
              style={{
                paddingTop: "0px",
              }}
            >
              {/* <MediumScreenTitle>დოკუმენტების მართვა</MediumScreenTitle> */}
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Column $widthPercent={50}>
                  <DocumentBlockTitle>{t('wordingFile')} (GE)</DocumentBlockTitle>
                  <DocumentFieldUploaderBlock
                    url={editedPackage.mainPackage.wordingFiles?.translations?.ge?.url || ""}
                    onChange={(newUrl) =>
                      setMainPackageField("wordingFiles", {
                        ...editedPackage.mainPackage.wordingFiles,
                        translations: {
                          ...editedPackage.mainPackage.wordingFiles?.translations,
                          ge: {
                            url: newUrl,
                          },
                        },
                      })
                    }
                    width={275}
                  />
                </Column>
                <Column $widthPercent={50}>
                  <DocumentBlockTitle>{t('wordingFile')} (EN)</DocumentBlockTitle>
                  <DocumentFieldUploaderBlock
                    url={editedPackage.mainPackage.wordingFiles?.translations?.en?.url || ""}
                    onChange={(newUrl) =>
                      setMainPackageField("wordingFiles", {
                        ...editedPackage.mainPackage.wordingFiles,
                        translations: {
                          ...editedPackage.mainPackage.wordingFiles?.translations,
                          en: {
                            url: newUrl,
                          },
                        },
                      })
                    }
                    width={275}
                  />
                </Column>
                <Column $widthPercent={50}>
                  <div>
                    <DocumentBlockTitle>
                      {t("policyFormTemplate")}
                    </DocumentBlockTitle>
                    <DocumentFieldUploaderBlock
                      url={editedPackage.mainPackage.policyFileUrl}
                      onChange={(newUrl) =>
                        setMainPackageField("policyFileUrl", newUrl)
                      }
                    />
                  </div>
                </Column>
              </Row>
            </DocumentsWrapper>
          </Column>
          <Column $widthPercent={30}>
            <Block>
              {isLoading ? (
                `${t('loading')}`
              ) : (
                <Button
                  btnStyleType="primary"
                  fullWidth={true}
                  icon={false}
                  onClick={() => saveWelcomerPackage()}
                  text={t("save")}
                />
              )}
            </Block>
            <br />
            <Block>
              {isLoading ? (
                `${t('loading')}`
              ) : (
                <Button
                  btnStyleType="secondary"
                  fullWidth={true}
                  icon={false}
                  onClick={() => deleteWelcomerPackage()}
                  text={t("deletePackage")}
                />
              )}
            </Block>
            {editedPackage.mainPackage.createdAt ? (
              <Block>
                <Text>{t("createdDate")}:</Text>
                <Text>
                  {dayjs(editedPackage.mainPackage.createdAt).format(
                    "DD MMMM YYYY HH:mm"
                  )}
                </Text>
              </Block>
            ) : null}
            {editedPackage.mainPackage.updatedAt ? (
              <Block>
                <Text>{t("lastUpdatedAt")}:</Text>
                <Text>
                  {dayjs(editedPackage.mainPackage.updatedAt).format(
                    "DD MMMM YYYY HH:mm"
                  )}
                </Text>
              </Block>
            ) : null}
            {/* if approved */}
            {/* if approved, activationDate MUST be present */}
            {editedPackage.mainPackage.approvedByAdmin ? (
              <Block>
                <Text>{t("lastPublishedAt")}:</Text>
                <Text>
                  {editedPackage.mainPackage.activatedDate
                    ? dayjs(editedPackage.mainPackage.activatedDate).format(
                        "DD MMMM YYYY HH:mm"
                      )
                    : `${t('notFound')}`}
                </Text>
              </Block>
            ) : null}
            {/* if NOT approved */}
            {/* if NOT approved, two cases: 1) never rejected before, we have no deactivation date */}{" "}
            {/* 2) rejected recently, and we have deactiation date */}
            {/* that's why we don't */}
            {!editedPackage.mainPackage.approvedByAdmin ? (
              editedPackage.mainPackage.deactivatedDate ? (
                <Block>
                  <Text>{t("lastRejectedAt")}:</Text>
                  <Text>
                    {dayjs(editedPackage.mainPackage.deactivatedDate).format(
                      "DD MMMM YYYY HH:mm"
                    )}
                  </Text>
                </Block>
              ) : (
                <Block>
                  <Text style={{ flexBasis: "100%" }}>
                    {t('waitingAdminReview')}
                  </Text>
                </Block>
              )
            ) : null}
            <Block>
              <Text>{t("status")}:</Text>
              <Text>{editedPackage.mainPackage.status}</Text>
            </Block>
            {isAdmin && editedPackage.mainPackage.status === "draft" ? (
              <Button
                btnStyleType="primary"
                icon={false}
                fullWidth={true}
                style={{ marginBottom: 16 }}
                disabled={false}
                onClick={approvePackageChanges}
                text={t("publish")}
              />
            ) : null}
            {isAdmin ? (
              <Button
                btnStyleType="primary"
                icon={false}
                fullWidth={true}
                style={{ marginBottom: 16 }}
                disabled={false}
                onClick={rejectPackageChanges}
                text={
                  editedPackage.mainPackage.status === "draft"
                    ? `${t("rejectChanges")}`
                    : `${t("moveToDraft")}`
                }
              />
            ) : null}
          </Column>
        </Row>
      </Accordion>
    </Wrapper>
  );
};
//
//
//
// styles
const AddonBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  margin-top: 20px;

  /*  */
  .defult-group-addon-block {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      height: 50px;
    }
  }
  /*  */
  .defult-group-addon-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    border: 1px dashed ${Colors.primaryRed};
    border-radius: 8px;
    padding: 5px;

    .defult-group-addon-inner-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .defult-group-addon-inner-item-description {
      width: 100%;
      border-top: 1px solid ${Colors.primaryRed};
      padding-top: 10px;
      margin-top: 10px;
    }
  }
  /*  */
  .new-addon-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  /*  */
  .new-addon-body {
  }
  /*  */
  .new-addon-item {
    box-shadow: ${Colors.primaryGrey} 0px 0px 0px 1px;
    border-radius: 8px;
    font-size: 14px;
    padding: 15px;
  }
  /*  */
  .new-addon-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    row-gap: 10px;

    img {
      height: 50px;
    }
  }
`;

const CustomHorizontalLine = styled.div`
  margin-block: 20px;
  padding-block: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: all 200ms ease;
    height: 25px;
    width: 55px;
    min-width: 55px;
  }
  span {
    white-space: nowrap;
    font-weight: bold;
    color: ${Colors.primaryBlack};
  }
  div {
    height: 2px;
    width: 100%;
    background-color: ${Colors.primaryRed};
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
  /* hover */
  &:hover {
    svg {
      transition: all 200ms ease;
      transform: scale(1.05);
    }
  }
`;

const AddonsHeadBlock = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const TextBlock = styled.div`
  width: 100%;
`;
const TextAreaTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid ${Colors.primaryRed};
  padding: 10px;
  &:focus {
    outline-color: ${Colors.primaryRed};
  }
`;
const RiskDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-block: 20px;
  border-radius: 4px;
  box-shadow: ${Colors.lightRed} 0px 2px 5px -1px,
    ${Colors.lightRed} 0px 1px 3px -1px;
  /*  */
  .MuiFormControlLabel-labelPlacementEnd {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0px 0px 10px 0px;
  }
  .MuiFormControlLabel-label {
    font-family: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }

  /*  */
  &:hover {
    box-shadow: ${Colors.lightRed} 0px 10px 20px, ${Colors.lightRed} 0px 6px 6px;
  }
`;
// const Title = styled.div`
//   margin-bottom: 10px;
//   color: ${Colors.primaryRed};
//   font-size: 16px;
//   font-weight: bold;
//   text-align: center;
// `
const RiskContent = styled.div`
  width: 100%;
`;
const Wrapper = styled.div`
  .MuiSvgIcon-fontSizeMedium {
    color: ${Colors.primaryRed};
  }
  .MuiFormControlLabel-label {
    font-family: inherit;
  }
  .MuiFormLabel-root {
    font-family: inherit;
    font-weight: 600;
  }
  .admin-addons-accoridon {
    .accordion-head-title {
      color: ${Colors.primaryRed};
      font-weight: bold;
    }
    .accordion-head-title ~ svg {
      color: ${Colors.primaryRed};
    }
  }
`;
const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid ${Colors.primaryGrey};
  padding-bottom: 10px;
  margin-bottom: 10px; */
  /*  */
  /* > div {
    flex-basis: 50%;
  } */
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  ${Responsive.tablet} {
    flex-direction: column;
  }
`;

const Column = styled.div<{ $widthPercent?: number }>`
  padding: 20px 15px;
  ${({ $widthPercent = 50 }) =>
    $widthPercent ? `width: calc(${$widthPercent}% - 15px);` : ""}
  ${Responsive.tablet} {
    width: 100%;
  }
`;
const Text = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
  color: ${Colors.primaryBlack};
`;
const DocumentsWrapper = styled.div`
  padding: 20px 15px;
  margin-bottom: 50px;
`;
const DocumentBlockTitle = styled.h5`
  /* min-height: 55px; */
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  font-size: 18px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
`;

const areEqual = (prevProps: Props, nextProps: Props) => {
  if (prevProps.welcomerPackageId === nextProps.welcomerPackageId) {
    return true; // do NOT re-render
  } else {
    return false; // will re-render
  }
};

export default React.memo(WelcomerPackageEditor, areEqual);
