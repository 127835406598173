import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../../api";
import Colors from "../../../../assets/styles/Colors";
import CustomDropdown from "../../../../components/CustomDropdown";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import BaseModal from "../../../../components/modals/BaseModal";
import { EnabledLanguage, PetService } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState, setEditedlanguage } from "../../../../store/appSlice";
import AdminButton from "../../../admin_components/AdminButton";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";

const AdminCriticalManageRisks = () => {
  //
  const { t } = useTranslation()
  // 
  const dispatch = useDispatch();
  //
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  //
  const [existingRisk, setExistingRisk] = useState<
    Partial<PetService> | undefined
  >(undefined);
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const {
    data: criticalRisks,
    isLoading: isCriticalRisksLoading,
    refetch: refetchRisks,
  } = api.useGetCriticalRisksQuery(undefined);
  //
  const [createRisk, { isLoading: isCreateRiskLoading }] =
    api.useCreateCriticalRiskMutation();
  //
  const [updateRisk, { isLoading: isUpdateRiskLoading }] =
    api.useUpdateCriticalRiskMutation();
  //
  const [
    deleteCriticalRisk,
    {
      isLoading: isDeleteCriticalRiskLoading,
      isSuccess: isDeleteCriticalRiskSuccess,
    },
  ] = api.useDeleteCrtiticalRiskMutation();
  //
  const _createAndUpdateRisk = async () => {
    //
    if (existingRisk) {
      try {
        if (existingRisk && existingRisk.id) {
          // update
          await updateRisk(existingRisk).unwrap();
        } else {
          // create
          await createRisk(existingRisk).unwrap();
        }
        //
        refetchRisks();
        //
        setModalIsOpen(false);
        //
        toast.success(
          existingRisk?.id
            ? `${t('service')} ${t('updatedSuccessfully')}`
            : `${t('service')} ${t('createdSuccessfully')}`
        );
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    }
    //
  };
  //
  //
  const _closeModal = () => {
    setModalIsOpen(false);
  };
  //
  const deleteRisk = (id: number) => {
    if (id) {
      return [
        window.confirm(`${t('certainToDeleteIt')}`) &&
          deleteCriticalRisk(id),
        refetchRisks(),
        _closeModal(),
      ];
    }
  };
  //
  const setRiskFields = (field: keyof PetService, value: any) => {
    setExistingRisk({
      ...existingRisk,
      [field]: value,
    });
  };
  //
  const setRisksTranslationFields = (
    field: keyof { name: string },
    language: EnabledLanguage,
    value: any
  ) => {
    setExistingRisk({
      ...existingRisk,
      translations: {
        ...existingRisk?.translations,
        [language]: {
          ...existingRisk?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  console.log("existingRisk", existingRisk);

  useEffect(() => {
    if (isDeleteCriticalRiskSuccess) {
      refetchRisks();
      _closeModal();
    }

    return () => {};
  }, [isDeleteCriticalRiskSuccess, refetchRisks]);

  //
  const MainLoading =
    isCriticalRisksLoading ||
    isCreateRiskLoading ||
    isDeleteCriticalRiskLoading ||
    isUpdateRiskLoading;
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <AdminButton
        btnStyleType="primary"
        text={t('createService')}
        onClick={() => [setExistingRisk(undefined), setModalIsOpen(true)]}
      />
      <br />
      <br />
      <Content>
        <Block>
          {criticalRisks?.map((item, index) => (
            <ServiceBox
              key={index}
              onClick={() => [setExistingRisk(item), setModalIsOpen(true)]}
            >
              <div>{item.translations?.[field_Language]?.name}</div>
              <div>{item.status === 'active' ? t('active') : t('inActive')}</div>
              <div>{item.priority}</div>
            </ServiceBox>
          ))}
        </Block>
      </Content>

      <BaseModal onClose={_closeModal} isOpen={modalIsOpen}>
        <div>
          <ModalHead>
            <h4>{existingRisk?.id ? t('update') : t('create')}</h4>
            <AdminLangSwicher
              onChange={(value) => dispatch(setEditedlanguage(value))}
            />
          </ModalHead>

          <Input
            id="service-name"
            name="service-name"
            label={t('title')}
            type="text"
            placeholder=""
            value={existingRisk?.translations?.[field_Language]?.name || ""}
            onChange={(value) =>
              setRisksTranslationFields("name", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "24px" }}
          />

          <CustomDropdown
            isMulti={false}
            placeholder=""
            isSearchable={false}
            value={existingRisk?.status || ""}
            title={t('status')}
            onChange={(event) => setRiskFields("status", event.value)}
            options={[
              ...["active", "inactive"].map((item) => ({
                value: item,
                label: item === 'active' ? t('active') : t('inActive'),
              })),
            ]}
          />

          <FieldsContent>
            <Input
              id="service-priority"
              name="service-priority"
              label={t('priority')}
              type="number"
              placeholder=""
              value={existingRisk?.priority || ""}
              onChange={(value) =>
                setRiskFields("priority", parseInt(value))
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <ButtonsBlock>
              <AdminButton
                fullWidth={true}
                btnStyleType="primary"
                disabled={
                  modalIsOpen &&
                  existingRisk?.priority &&
                  existingRisk.status
                    ? false
                    : true
                }
                text={existingRisk?.id ? t('update') : t('create')}
                onClick={_createAndUpdateRisk}
              />

              <br />
              {existingRisk && existingRisk.id && (
                <AdminButton
                  fullWidth={true}
                  btnStyleType="transparent"
                  border={true}
                  text={t('delete')}
                  onClick={() => {
                    if (existingRisk && existingRisk.id) {
                      deleteRisk(existingRisk.id);
                    }
                  }}
                />
              )}
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
    </Wrapper>
  );
};
//
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.grey};
`;
const Block = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
`;
const ServiceBox = styled.div`
  height: 200px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
`;
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
`;
const Wrapper = styled.div`
  /*  */
`;
//
export default AdminCriticalManageRisks;
