import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api";
import Loader from "../../../components/Loader";
import styled from "styled-components";
import { MediumScreenTitle } from "../../../assets/styles/GlobalStyle";
import { toast } from "react-toastify";
import { StoreFraniUser } from "../../../domain/types";
import Input from "../../../components/Input";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import CustomSelect from "../../../components/CustomSelect";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";
//
//
const Admin_frani_user_edit = () => {
  //
  //
  const { userId: userIdStr = "0" } = useParams();
  const userID = parseInt(userIdStr);
  //
  const { t } = useTranslation()
  //
  const [franiUserInfo, setFraniUserInfo] = useState<Partial<StoreFraniUser>>(
    {}
  );
  //
  const rolesArray = ["Frani-Product-Owner", "Frani-Admin"];
  //
  const navigate = useNavigate();
  //
  // frani user by id
  const {
    data: user,
    isLoading: isUserLoading,
    refetch: isUserRefetch,
  } = api.useGetFraniUserByIdQuery(userID, { skip: !userID && true });
  //
  //
  //
  //
  //  store frani user  & update frani user
  const [storeFraniUser, { isLoading: isStoreFraniUserLoading }] =
    api.useStoreFraniUserMutation();
  //
  //
  // stroe frani user function
  const StoreFraniUserunction = async () => {
    //
    // set user id for store & update
    setFraniUser("id", userID);
    //
    try {
      await storeFraniUser(franiUserInfo).unwrap();
      //
      // refetch
      isUserRefetch();
      //
      toast.success(
        userID === 0
          ? `${t('user')} ${t('createdSuccessfully')}`
          : `${t('user')} ${t('updatedSuccessfully')}`
      );
      //
      navigate("/frani-users");
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      }
    }
  };
  //
  //
  // add travel addon fields
  const setFraniUser = (field: keyof StoreFraniUser, value: any) => {
    setFraniUserInfo({
      ...franiUserInfo,
      [field]: value,
    });
  };
  //
  // set updated user in state
  useEffect(() => {
    if (user) {
      const customUserVariable = {
        adminRole: user?.roles?.[0].role,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        id: user?.id,
      };
      if (customUserVariable) {
        setFraniUserInfo(customUserVariable);
      }
    }
  }, [user]);
  //
  //
  //
  //
  const mainLoading = isUserLoading || isStoreFraniUserLoading;
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <MediumScreenTitle>
        {userID === 0 ? `${t('createUser')}` :`${t('updateUser')}`}
      </MediumScreenTitle>
      <div>
        <Row>
          <Col>
            <Input
              id="user-name"
              name="user-name"
              label={t('firstName')}
              type="text"
              placeholder=""
              value={franiUserInfo.firstName || ""}
              onChange={(value) => setFraniUser("firstName", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />
          </Col>
          <Col>
            <Input
              id="user-surname"
              name="user-surname"
              label={t('lastName')}
              type="text"
              placeholder=""
              value={franiUserInfo.lastName || ""}
              onChange={(value) => setFraniUser("lastName", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />
          </Col>
        </Row>
        {/*  */}
        <Row>
          <Col>
            {userID === 0 ? ( // create user
              <>
                <CustomSelect
                  defaultInputValue={franiUserInfo.adminRole || ""}
                  placeholder=""
                  headText={t('userRole')}
                  onChange={(event) => setFraniUser("adminRole", event.value)}
                  data={[
                    ...rolesArray.map((item) => ({
                      value: item,
                      label: item,
                    })),
                  ]}
                />
              </>
            ) : (
              // update user
              <>
                {rolesArray && franiUserInfo && franiUserInfo.adminRole && (
                  <CustomSelect
                    defaultInputValue={franiUserInfo.adminRole || ""}
                    placeholder=""
                    headText={t('userRole')}
                    onChange={(event) => setFraniUser("adminRole", event.value)}
                    data={[
                      ...rolesArray.map((item) => ({
                        value: item,
                        label: item,
                      })),
                    ]}
                  />
                )}
              </>
            )}
          </Col>
          <Col>
            <Input
              id="user-email"
              name="user-email"
              label={t('email')}
              type="text"
              placeholder=""
              value={franiUserInfo.email || ""}
              onChange={(value) => setFraniUser("email", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />
          </Col>
        </Row>
        {/*  */}
        <Row>
          <Col>
            <Input
              id="password"
              name="password"
              label={t('password')}
              type="password"
              placeholder=""
              value={franiUserInfo.password || ""}
              onChange={(value) => setFraniUser("password", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
              icon={true}
            />
          </Col>
          <Col>
            <Input
              id="password-confirm"
              name="password-confirm"
              label={t('repeatPassword')}
              type="password"
              placeholder=""
              value={franiUserInfo.passwordConfirm || ""}
              onChange={(value) => setFraniUser("passwordConfirm", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
              icon={true}
            />
          </Col>
        </Row>
      </div>
      <br />
      <Button
        fullWidth={true}
        btnStyleType="primary"
        text={userID === 0 ? `${t('create')}` : `${t('update')}`}
        onClick={StoreFraniUserunction}
      />
    </Wrapper>
  );
};
//
const Wrapper = styled.div``;
//
export default Admin_frani_user_edit;
