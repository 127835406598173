import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../../../api";
import Colors from "../../../../assets/styles/Colors";
import CustomSelect from "../../../../components/CustomSelect";
import Loader from "../../../../components/Loader";
import { TranslationsField } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../../store/appSlice";
//
//
const AdminPetManagePages = () => {
  //
  const { t } = useTranslation();
  // 
  const navigate = useNavigate();
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  // page slugs
  const pageSlugs: {
    id: number;
    slug: string;
    text: string;
    translations: TranslationsField<{ text: string }>;
  }[] = [
    {
      id: 1,
      slug: "pet-dog-or-cat",
      text: "ძაღლი თუ კატა?",
      translations: {
        en: {
          text: "Who do you buy insurance for",
        },
        ge: {
          text: "ძაღლი თუ კატა?",
        },
      },
    },
    {
      id: 2,
      slug: "pet-info",
      text: "შინაური ცხოველის ინფორმაცია",
      translations: {
        en: {
          text: "Pet information",
        },
        ge: {
          text: "შინაური ცხოველის ინფორმაცია",
        },
      },
    },
    {
      id: 3,
      slug: "pet-insurance-start-date",
      text: "სადაზღვევო პერიოდი",
      translations: {
        en: {
          text: "Insurance period",
        },
        ge: {
          text: "სადაზღვევო პერიოდი",
        },
      },
    },
    {
      id: 4,
      slug: "pet-trust-us-or-yourself",
      text: "შეთავაზების ტიპი",
      translations: {
        en: {
          text: "Offer type",
        },
        ge: {
          text: "შეთავაზების ტიპი",
        },
      },
    },
    {
      id: 5,
      slug: "pet-which-offer",
      text: "შეთავაზებები",
      translations: {
        en: {
          text: "Offers",
        },
        ge: {
          text: "შეთავაზებები",
        },
      },
    },
    {
      id: 6,
      slug: "pet-best-offer",
      text: "საუკეთესო შეთავაზება",
      translations: {
        en: {
          text: "Best offer",
        },
        ge: {
          text: "საუკეთესო შეთავაზება",
        },
      },
    },
    {
      id: 7,
      slug: "pet-your-personal-info",
      text: "დამზღვევის ინფორმაცია",
      translations: {
        en: {
          text: "Insured's Information",
        },
        ge: {
          text: "დამზღვევის ინფორმაცია",
        },
      },
    },
    {
      id: 8,
      slug: "pet-check-info",
      text: "ინფორმაციის გადამოწმება",
      translations: {
        en: {
          text: "Check information",
        },
        ge: {
          text: "ინფორმაციის გადამოწმება",
        },
      },
    },
    {
      id: 9,
      slug: "pet-your-contact-info",
      text: "საკონტაქტო ინფორმაცია",
      translations: {
        en: {
          text: "Contact info",
        },
        ge: {
          text: "საკონტაქტო ინფორმაცია",
        },
      },
    },
  ];
  //
  // selected slug
  const [selectedSlug, setSelectedSlug] = useState<string | null>(null);

  // get pet flow
  const { data: pageTextArry, isLoading: isPageTextArrayLoading } =
    api.useGetTplFlowPageTextsBySlugQuery(selectedSlug as string, {
      skip: !selectedSlug && true,
    });
  //
  //
  //
  const mainLoading = isPageTextArrayLoading;
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <CustomSelect
        defaultInputValue={
          pageSlugs.find((x) => x.slug === selectedSlug)?.text || ""
        }
        placeholder=""
        headText={t('choosePage')}
        onChange={(event) => setSelectedSlug(event.value)}
        data={[
          ...pageSlugs.map((item) => ({
            value: item.slug,
            label: item?.translations?.[lang]?.text,
          })),
        ]}
      />
      {pageTextArry && (
        <InfoBlock>
          <div>
            <AdminLangSwicher />
          </div>
          {/*  */}
          <Content>
            {pageTextArry?.translations && (
              <InnerBox
                onClick={() =>
                  navigate(`/manage-pet-pages-edit/${selectedSlug}`)
                }
                style={{ cursor: "pointer" }}
              >
                {pageTextArry?.translations?.[field_Language]?.title && (
                  <>
                    <div style={{ color: Colors.primaryRed }}>{t('title')}</div>
                    <TextBlock>
                      {pageTextArry?.translations?.[field_Language]?.title}
                    </TextBlock>
                  </>
                )}

                {pageTextArry?.translations?.[field_Language]?.stepName && (
                  <>
                    <div style={{ color: Colors.primaryRed }}>{t('stepName')}</div>
                    <TextBlock>
                      {pageTextArry?.translations?.[field_Language]?.stepName}
                    </TextBlock>
                  </>
                )}
              </InnerBox>
            )}
            {pageTextArry?.listItems?.map((item, index) => (
              <InnerBox
                key={index}
                onClick={() =>
                  navigate(`/manage-pet-pages-edit/${selectedSlug}/${item?.id}`)
                }
                style={{ cursor: "pointer" }}
              >
                {item?.translations?.[field_Language]?.title && (
                  <>
                    <div style={{ color: Colors.primaryRed }}>{t('title')}</div>
                    <TextBlock>{item?.translations?.[field_Language]?.title}</TextBlock>
                  </>
                )}
                {item?.translations?.[field_Language]?.body && (
                  <>
                    <div style={{ color: Colors.primaryRed }}>{t('body')}</div>
                    <TextBlock>{item?.translations?.[field_Language]?.body}</TextBlock>
                  </>
                )}
                {item?.translations?.[field_Language]?.excerpt && (
                  <>
                    <div style={{ color: Colors.primaryRed }}>{t('additionalInformation')}</div>
                    <TextBlock>
                      {item?.translations?.[field_Language]?.excerpt}
                    </TextBlock>
                  </>
                )}

                {item?.translations?.[field_Language]?.secondTitle && (
                  <>
                    <div style={{ color: Colors.primaryRed }}>{t('secondTitle')}</div>
                    <TextBlock>
                      {item?.translations?.[field_Language]?.secondTitle}
                    </TextBlock>
                  </>
                )}

                {item?.translations?.[field_Language]?.secondExcerpt && (
                  <>
                    <div style={{ color: Colors.primaryRed }}>
                      {t('secondAdditionalInformation')}
                    </div>
                    <TextBlock>
                      {item?.translations?.[field_Language]?.secondExcerpt}
                    </TextBlock>
                  </>
                )}

                {item?.translations?.[field_Language]?.thirdTitle && (
                  <>
                    <div style={{ color: Colors.primaryRed }}>{t('thirdTitle')}</div>
                    <TextBlock>
                      {item?.translations?.[field_Language]?.thirdTitle}
                    </TextBlock>
                  </>
                )}

                {item?.translations?.[field_Language]?.thirdExcerpt && (
                  <>
                    <div style={{ color: Colors.primaryRed }}>
                      {t('thirdAdditionalInformation')}
                    </div>
                    <TextBlock>
                      {item?.translations?.[field_Language]?.thirdExcerpt}
                    </TextBlock>
                  </>
                )}
                <ImageStyle src={item?.image} alt="" />
              </InnerBox>
            ))}
          </Content>
          {/*  */}
        </InfoBlock>
      )}
    </Wrapper>
  );
};
//
// styles
const ImageStyle = styled.img`
  height: 100px;
  width: fit-content;
  margin-inline: auto;
`;
const TextBlock = styled.div`
  border-bottom: 1px dashed ${Colors.primaryRed};
  margin-bottom: 10px;
  padding-block: 5px;
`;
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;
const InnerBox = styled.div`
  border: 1px solid ${Colors.primaryRed};
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const InfoBlock = styled.div`
  margin-top: 30px;
  box-shadow: 0px 1px 12px 1px rgba(118, 131, 139, 0.23);
  -webkit-box-shadow: 0px 1px 12px 1px rgba(118, 131, 139, 0.23);
  -moz-box-shadow: 0px 1px 12px 1px rgba(118, 131, 139, 0.23);
  border-radius: 4px;
  background-color: ${Colors.grey};
  padding: 20px;
  color: ${Colors.primaryGrey};
  /*  */
  a {
    &:last-child {
      color: ${Colors.secondaryRed};
      text-decoration-color: ${Colors.primaryRed};
    }
  }
`;
const Wrapper = styled.div``;
//
//
export default AdminPetManagePages;
