import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import DoubleRightIcon from "../../../assets/icons/JSX/DoubleRightIcon";
import Colors from "../../../assets/styles/Colors";
import Accordion from "../../../components/Accordion";
import Button from "../../../components/Button";
import CustomDropdown from "../../../components/CustomDropdown";
import CustomLink from "../../../components/CustomLink";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import { RootState, useAppSelector } from "../../../store";
import { AuthState } from "../../../store/authSlice";
import UploadFile from "../../../components/UploadFile";
import { toast } from "react-toastify";
import {
  DeactivatedPolicy,
  EnabledLanguage,
  currencySymbol,
  georgianFormatter,
} from "../../../domain/types";

const AdminCompanySoldPoliciesDetail = () => {
  //
  const { policyId: policyIdStr = "0" } = useParams();
  const policyId = parseInt(policyIdStr);
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const [language] = useState<EnabledLanguage>(lang);
  //
  const navigate = useNavigate();
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const { t } = useTranslation();
  //
  const [showPolicySetting, setShowPolicySetting] = useState<boolean>(false);
  //
  const [activePolicyStatus, setActivePolicyStatus] = useState("");
  //
  const [showActivePolicySetting, setShowActivePolicySetting] =
    useState<boolean>(false);
  //
  // active policy mutation
  const [activePolicy, { isLoading: isActivePolicyLoading }] =
    api.useActivePolicyMutation();
  //
  // deactive policy mutation
  const [deActivePolicy, { isLoading: isDeActivePolicyLoading }] =
    api.useDeactivePolicyMutation();
  //
  const {
    data: adminPolicyDetail,
    isLoading: isAdminPolicyDetailLoading,
    refetch: refetchAdminPolicy,
  } = api.useGetAdminPolicyDetailQuery(policyId, {
    skip:
      auth.user?.userType === "Frani-Admin" ||
      auth.user?.userType === "Frani-Product-Owner"
        ? false
        : true,
  });
  //
  // get travel policy deactive reason
  const { data: deactiveReasonArr, isLoading: isDeaactiveReasonArrLoading } =
    api.useGetAdminPolicyDeactiveReasonQuery(
      {
        categoryId: (adminPolicyDetail?.productCategoryId as number) || 0,
        isActive: true,
      },
      {
        skip: adminPolicyDetail?.productCategoryId ? false : true,
      }
    );
  //
  const { data: companyPolicyDetail, isLoading: isCompanyPolicyDetailLoading } =
    api.useGetAdminPolicyByIdQuery(policyId);
  //
  const [deactiveInfo, setDeactiveInfo] = useState<Partial<DeactivatedPolicy>>({
    policyId: policyId,
  });
  //
  const setDeactiveFields = (field: keyof DeactivatedPolicy, value: any) => {
    setDeactiveInfo({
      ...deactiveInfo,
      [field]: value,
    });
  };
  //
  const setDeactiveTranslatedField = (
    field: keyof DeactivatedPolicy,
    language: EnabledLanguage,
    value: any
  ) => {
    setDeactiveInfo({
      ...deactiveInfo,
      translations: {
        ...deactiveInfo.translations,
        [language]: {
          ...deactiveInfo?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  const _activePolicyFuntion = async (
    policyId: number,
    policyDeactivationId: number,
    policyStatus: string
  ) => {
    //
    if (policyId && policyDeactivationId && policyStatus) {
      try {
        await activePolicy({
          policyId,
          policyDeactivationId,
          policyStatus,
        }).unwrap();
        //
        setShowActivePolicySetting(false);
        //
        toast.success(`${t("policy")} ${t("activatedSuccessfully")}`);
        //
        refetchAdminPolicy();
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
    //
  };
  //
  //
  const _deActivePolicyFuntion = async () => {
    //
    if (deactiveInfo) {
      try {
        await deActivePolicy({
          cancellationPenalty: Number(deactiveInfo.cancellationPenalty),
          comment: deactiveInfo.comment,
          deactivatedDate: deactiveInfo.deactivatedDate,
          docUrl: deactiveInfo.docUrl,
          earnedPremium: Number(deactiveInfo.earnedPremium),
          policyId: policyId,
          policyDeactivationReasonId: deactiveInfo.policyDeactivationReasonId,
          refundAmount: deactiveInfo.refundAmount,
          translations: deactiveInfo.translations,
        }).unwrap();
        //
        setShowPolicySetting(false);
        //
        toast.success(`${t("policy")} ${t("deactivated")} !`);
        //
        refetchAdminPolicy();
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
    //
  };
  //
  const policy = adminPolicyDetail || companyPolicyDetail;
  //
  const discount = policy?.mainPolicy?.orderItems?.[0]?.order?.discount;
  //
  // const refundAmount = policy?.refundAmount
  //   ? parseFloat(policy?.refundAmount) -
  //     (deactiveInfo?.earnedPremium || 0) -
  //     (deactiveInfo?.cancellationPenalty || 0)
  //   : null;
  //
  //
  const parsedWordingFileUrl = policy?.mainPolicy?.productWordingUrl?.includes(
    "translations"
  )
    ? JSON.parse(policy?.mainPolicy?.productWordingUrl)
    : "";
  //
  //
  // main loading
  const MainLoading =
    isAdminPolicyDetailLoading ||
    isCompanyPolicyDetailLoading ||
    isActivePolicyLoading ||
    isDeActivePolicyLoading ||
    isDeaactiveReasonArrLoading;
  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBlock: "20px",
        }}
      >
        <CustomLink
          text={t("prevPage")}
          leftLineIcon={true}
          onClick={() => navigate(-1)}
        />
        {(auth.user?.userType === "Frani-Admin" ||
          auth.user?.userType === "Frani-Product-Owner") && (
          <Button
            text={
              policy?.mainPolicy?.deactivatedPolicy
                ? `${t("policyActivation")}`
                : `${t("policyDeactivation")}`
            }
            btnStyleType={
              policy?.mainPolicy?.deactivatedPolicy ? "primary" : "transparent"
            }
            border={policy?.mainPolicy?.deactivatedPolicy ? false : true}
            onClick={() => {
              if (policy?.mainPolicy?.deactivatedPolicy) {
                setShowActivePolicySetting(!showActivePolicySetting);
              }
              //
              if (!policy?.mainPolicy?.deactivatedPolicy) {
                // show deactive form
                setShowPolicySetting(!showPolicySetting);
                //
                setDeactiveFields(
                  "refundAmount",
                  Number(policy?.refundAmount || 0)
                );
              }
            }}
          />
        )}
      </div>
      <hr />

      {/* active policy */}
      {showActivePolicySetting && (
        <Accordion title={t("changePolicyStatus")} openAccordion={true}>
          <GridBlock>
            {/* setShowActivePolicySetting */}
            <CustomDropdown
              title={t("choosePolicyStatus")}
              isMulti={false}
              value={activePolicyStatus}
              isClearable={false}
              onChange={(obj) => setActivePolicyStatus(obj.value)}
              options={[
                ...["ACTIVE", "CLOSED", "EXPIRED", "PENDING"].map((item) => ({
                  value: item,
                  label: item,
                })),
              ]}
            />
          </GridBlock>

          <br />

          <Button
            border={true}
            btnStyleType="transparent"
            fullWidth={true}
            text="გაგზავნა"
            disabled={activePolicyStatus ? false : true}
            onClick={() => {
              if (
                activePolicyStatus &&
                policyId &&
                policy?.mainPolicy?.deactivatedPolicy?.id &&
                window.confirm(`${t("certainToChangeStatus")}`)
              )
                _activePolicyFuntion(
                  policyId,
                  policy?.mainPolicy?.deactivatedPolicy?.id,
                  activePolicyStatus
                );
            }}
          />
        </Accordion>
      )}

      {/* active policy */}

      {/* deactive policy */}

      {showPolicySetting && (
        <Accordion title={t("policyDeactivation")} openAccordion={true}>
          <GridBlock>
            <Input
              id="deactive-date"
              name="deactive-date"
              label={t("selectDeactivationDate")}
              type="date"
              placeholder=""
              value={deactiveInfo.deactivatedDate || ""}
              onChange={(value) =>
                setDeactiveFields(
                  "deactivatedDate",
                  dayjs(value).format("YYYY-MM-DD")
                )
              }
              width={"fullWidth"}
            />

            {deactiveReasonArr && (
              <CustomDropdown
                title={t("deactivationReason")}
                isMulti={false}
                value={deactiveInfo.policyDeactivationReasonId}
                isClearable={false}
                onChange={(obj) =>
                  setDeactiveFields("policyDeactivationReasonId", obj.value)
                }
                options={[
                  ...deactiveReasonArr.map((item) => ({
                    value: item.id,
                    label: item?.translations[lang]?.title,
                  })),
                ]}
              />
            )}

            <Input
              id="earned-premium"
              name="earned-premium"
              label={t("earnedPremium")}
              type="number"
              placeholder=""
              value={deactiveInfo.earnedPremium || ""}
              onChange={(value) => setDeactiveFields("earnedPremium", value)}
              width={"fullWidth"}
            />

            <Input
              id="cancellation-penalty"
              name="cancellation-penalty"
              label={t("deactivationPenalty")}
              type="number"
              placeholder=""
              value={deactiveInfo.cancellationPenalty || ""}
              onChange={(value) =>
                setDeactiveFields("cancellationPenalty", value)
              }
              width={"fullWidth"}
            />

            <Input
              id="refunded-amount"
              name="refunded-amount"
              label={t("refundableAmount")}
              type="number"
              placeholder=""
              value={deactiveInfo.refundAmount || ""}
              onChange={(value) => setDeactiveFields("refundAmount", value)}
              width={"fullWidth"}
            />

            <UploadFile
              label={t("attachDocument")}
              onChange={(props) => setDeactiveFields("docUrl", props.url)}
              defaultFileName={deactiveInfo?.docUrl || ""}
            />
          </GridBlock>

          <br />

          <div>
            <TextAreaTitle>{t("comment")}</TextAreaTitle>
            <TextAreaStyle
              value={deactiveInfo.translations?.[language]?.title}
              onChange={(event) =>
                setDeactiveTranslatedField(
                  "comment",
                  language,
                  event.target.value
                )
              }
            ></TextAreaStyle>
          </div>

          <br />

          <Button
            border={true}
            btnStyleType="transparent"
            fullWidth={true}
            text={t("policyDeactivate")}
            disabled={
              deactiveInfo.policyDeactivationReasonId &&
              deactiveInfo.deactivatedDate &&
              deactiveInfo.cancellationPenalty &&
              deactiveInfo.policyId &&
              deactiveInfo.earnedPremium
                ? false
                : true
            }
            onClick={() => {
              window.confirm(`${t("certainToDeactivate")}`) &&
                _deActivePolicyFuntion();
            }} // deactive policy
          />
        </Accordion>
      )}

      {/* deactive policy modal */}

      <InsurerAccordionBlock>
        <Accordion title={t("insurerInformation")} openAccordion={false}>
          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("firstName")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.user?.firstNameGe ??
                policy?.mainPolicy?.user?.firstName}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("lastName")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.user?.lastNameGe ??
                policy?.mainPolicy?.user?.lastName}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("personalNumber")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.user?.personalNumber}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("passportNumber")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.user?.passportNumber}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("dateOfBirth")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {dayjs(policy?.mainPolicy?.user?.birthday).format(
                "DD / MMM / YYYY"
              )}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("phone")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.user?.phone}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("email")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.user?.email}
            </OffetTextRightSide>
          </CheckInfoTextBox>
        </Accordion>
      </InsurerAccordionBlock>

      <AccordionWrapper>
        <Accordion title={t("policyInfo")} openAccordion={true}>
          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("insuranceCompany")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {
                policy?.mainPolicy?.package?.product?.company?.translations[
                  lang
                ]?.title
              }
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("policyType")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {(policy?.travelType?.toLowerCase() === "single" &&
                lang === "ge" &&
                "ერთჯერადი") ||
                (policy?.travelType?.toLowerCase() === "single" &&
                  lang === "en" &&
                  "single") ||
                (policy?.travelType?.toLowerCase() === "multi" &&
                  lang === "ge" &&
                  "მრავალჯერადი") ||
                (policy?.travelType?.toLowerCase() === "multi" &&
                  lang === "en" &&
                  "multi")}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("category")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {String(
                policy?.mainPolicy?.targetType?.match(/[A-Z][a-z]+/g)
              )?.replace(",", " ")}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("policyNumber")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.policyNumber}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("startDate")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {dayjs(policy?.mainPolicy?.startDate).format("DD MMMM YYYY")}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("endDate")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {dayjs(policy?.mainPolicy?.endDate).format("DD MMMM YYYY")}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("numberOfDays")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.insuranceDays} {t("day")}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("travelArea")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.area?.toLowerCase()}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("limit")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.compensationLimit}{" "}
              {currencySymbol(policy?.mainPolicy?.compensationCurrency)}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("franchise")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.franchise}{" "}
              {currencySymbol(policy?.mainPolicy?.compensationCurrency)}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("policePrice")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {discount === null
                ? `${georgianFormatter.format(policy?.eachPrice)} ₾`
                : `${georgianFormatter.format(
                    discount?.promoCode?.applyingDiscount?.actualAmount || 0
                  )} ₾`}{" "}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          {discount !== null && (
            <>
              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("promoCode")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {discount?.promoCode?.code}
                </OffetTextRightSide>
              </CheckInfoTextBox>
              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("promoCodeName")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {discount?.promoCode?.name}
                </OffetTextRightSide>
              </CheckInfoTextBox>
              {discount?.promoCode?.applyingDiscount?.days && (
                <CheckInfoTextBox>
                  <OffetTextLeftSide>{t("discountByDays")}</OffetTextLeftSide>
                  <OffetTextRightSide>
                    {`${discount?.promoCode?.applyingDiscount?.days} ${t(
                      "day"
                    )}`}{" "}
                  </OffetTextRightSide>
                </CheckInfoTextBox>
              )}
              {discount?.promoCode?.applyingDiscount?.amount && (
                <CheckInfoTextBox>
                  <OffetTextLeftSide>{t("discountByAmount")}</OffetTextLeftSide>
                  <OffetTextRightSide>
                    {discount?.promoCode?.applyingDiscount?.amount}{" "}
                  </OffetTextRightSide>
                </CheckInfoTextBox>
              )}
              {discount?.promoCode?.applyingDiscount?.percentageDiscount && (
                <>
                  <CheckInfoTextBox>
                    <OffetTextLeftSide>
                      {t("discountByPercent")}
                    </OffetTextLeftSide>
                    <OffetTextRightSide>
                      {`${discount?.promoCode?.applyingDiscount?.percentageDiscount?.percentage} %`}{" "}
                    </OffetTextRightSide>
                  </CheckInfoTextBox>
                  <CheckInfoTextBox>
                    <OffetTextLeftSide>{t("maximumAmount")}</OffetTextLeftSide>
                    <OffetTextRightSide>
                      {`${discount?.promoCode?.applyingDiscount?.percentageDiscount?.maximumAmount} `}{" "}
                    </OffetTextRightSide>
                  </CheckInfoTextBox>
                </>
              )}
            </>
          )}

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("orderId")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.orderItems[0]?.orderId}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("pan")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.orderItems[0]?.order.payments.map((p) => {
                return p.status === "paid" ? p.detailJson?.pan : "";
              })}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("accountId")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.orderItems[0]?.order.payments.map((p) => {
                return p.status === "paid" ? p.detailJson?.accountId : "";
              })}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("releaseDate")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {dayjs(policy?.mainPolicy?.createdAt).format(
                "DD MMMM YYYY | HH:mm"
              )}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("status")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.status?.toUpperCase()}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          {policy?.mainPolicy?.deactivatedPolicy && (
            <div>
              <hr
                style={{
                  color: Colors.primaryBlack,
                }}
              />

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("dateOfDeactivation")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {dayjs(
                    policy?.mainPolicy?.deactivatedPolicy?.deactivatedDate
                  ).format("DD / MMMM / YYYY")}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>
                  {t("deactivationRequestDate")}
                </OffetTextLeftSide>
                <OffetTextRightSide>
                  {dayjs(
                    policy?.mainPolicy?.deactivatedPolicy?.createdAt
                  ).format("DD / MMMM / YYYY | HH:mm")}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("refundableAmount")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {policy?.mainPolicy?.deactivatedPolicy?.refundAmount}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("earnedPremium")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {policy?.mainPolicy?.deactivatedPolicy?.earnedPremium}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>
                  {t("deactivationPenalty")}
                </OffetTextLeftSide>
                <OffetTextRightSide>
                  {policy?.mainPolicy?.deactivatedPolicy?.cancellationPenalty}
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("document")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  <CustomATag
                    href={policy?.mainPolicy?.deactivatedPolicy?.docUrl}
                    target={"_blank"}
                  >
                    {policy?.mainPolicy?.deactivatedPolicy?.docUrl}
                  </CustomATag>
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("decativationReason")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {
                    deactiveReasonArr?.find(
                      (x) =>
                        x?.id ===
                        policy?.mainPolicy?.deactivatedPolicy
                          ?.policyDeactivationReasonId
                    )?.translations?.[language]?.title
                  }
                </OffetTextRightSide>
              </CheckInfoTextBox>

              <CheckInfoTextBox>
                <OffetTextLeftSide>{t("comment")}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {policy?.mainPolicy?.deactivatedPolicy?.comment}
                </OffetTextRightSide>
              </CheckInfoTextBox>
            </div>
          )}
        </Accordion>
        {/*  */}
        {/*  */}
        {/*  */}
        <Accordion title={t("travelerInformation")} openAccordion={false}>
          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("firstName")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.firstName ||
                policy?.mainPolicy?.translations?.en?.firstName}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("lastName")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.mainPolicy?.lastName ||
                policy?.mainPolicy?.translations?.en?.lastName}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("birthDay")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {dayjs(policy?.mainPolicy?.birthday).format("DD MMMM YYYY")}
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("passportNumber")}</OffetTextLeftSide>
            <OffetTextRightSide>{policy?.passportNumber}</OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("personalNumber")}</OffetTextLeftSide>
            <OffetTextRightSide>{policy?.personalNumber}</OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("gender")}</OffetTextLeftSide>
            <OffetTextRightSide>
              {policy?.gender === "female" && lang === "ge"
                ? "ქალი"
                : policy?.gender === "male" && lang === "ge"
                ? "კაცი"
                : policy?.gender}
            </OffetTextRightSide>
          </CheckInfoTextBox>
        </Accordion>
        {/*  */}
        {/*  */}
        {/*  */}
        {(policy?.attachedAddons && policy?.attachedAddons.length >= 1) ||
        (policy?.grouppedAddons && policy?.grouppedAddons.length >= 1) ? (
          <Accordion title={t("additionalCoverages")} openAccordion={false}>
            {policy?.attachedAddons?.map((item, index) => (
              <CheckInfoTextBox key={index}>
                <OffetTextLeftSide>{item?.title}</OffetTextLeftSide>
                <OffetTextRightSide>{item?.price} ₾</OffetTextRightSide>
              </CheckInfoTextBox>
            ))}
            {/*  */}
            {policy?.grouppedAddons?.map((item, index) => (
              <CheckInfoTextBox key={index}>
                <OffetTextLeftSide>{item?.title}</OffetTextLeftSide>
                <OffetTextRightSide>{item?.price} ₾</OffetTextRightSide>
              </CheckInfoTextBox>
            ))}
          </Accordion>
        ) : null}
        {/*  */}
        {/*  */}
        {/*  */}
        <Accordion title={t("whatIsCovered")} openAccordion={false}>
          {policy?.mainPolicy?.package?.travelAttachedRisks?.map(
            (item, index) => (
              <CheckInfoTextBox key={index}>
                <OffetTextLeftSide>
                  {item?.risk?.translations[lang]?.riskName}
                </OffetTextLeftSide>
                <OffetTextRightSide>
                  {item?.translations[lang]?.riskDescription}
                </OffetTextRightSide>
              </CheckInfoTextBox>
            )
          )}
        </Accordion>
        {/*  */}
        {/*  */}
        {/*  */}
        <Accordion title={t("importantInformation")} openAccordion={false}>
          <InsuranceExceptionsBlock>
            {policy?.mainPolicy?.package?.insuranceExceptions?.map(
              (item, index) => (
                <MessageText key={index}>
                  <DoubleRightIcon />{" "}
                  <span>{item?.translations?.[lang]?.title}</span>
                </MessageText>
              )
            )}
          </InsuranceExceptionsBlock>
        </Accordion>
        {/*  */}
        {/*  */}
        {/*  */}
        <Accordion title={t("documents")} openAccordion={false}>
          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("insuranceContract")}</OffetTextLeftSide>
            <OffetTextRightSide>
              <CustomATag
                href={
                  policy?.mainPolicy?.productWordingUrl?.includes(
                    "translations"
                  )
                    ? parsedWordingFileUrl?.translations?.[lang]?.url
                    : policy?.mainPolicy?.productWordingUrl
                }
                target={"_blank"}
              >
                {policy?.mainPolicy?.productWordingUrl?.includes("translations")
                  ? parsedWordingFileUrl?.translations?.[lang]?.url
                  : policy?.mainPolicy?.productWordingUrl}
              </CustomATag>
            </OffetTextRightSide>
          </CheckInfoTextBox>

          <CheckInfoTextBox>
            <OffetTextLeftSide>{t("policyAgreement")}</OffetTextLeftSide>
            <OffetTextRightSide>
              <CustomATag
                href={policy?.mainPolicy?.policyPdfUrl}
                target={"_blank"}
              >
                {policy?.mainPolicy?.policyPdfUrl}
              </CustomATag>
            </OffetTextRightSide>
          </CheckInfoTextBox>
        </Accordion>
      </AccordionWrapper>

      {/* <BoxSection>
          <div>Policy type</div>
          <div>{String(policyDetail?.targetType?.match(/[A-Z][a-z]+/g))?.replace(',', ' ')}</div>
        </BoxSection> */}
    </Wrapper>
  );
};
//
// styles
const TextAreaTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid ${Colors.primaryRed};
  padding: 10px;
  &:focus {
    outline-color: ${Colors.primaryRed};
  }
`;
const GridBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px 20px;
`;
const InsurerAccordionBlock = styled.div`
  .accordion-head-title {
    color: ${Colors.primaryRed};
  }
  svg {
    color: ${Colors.primaryRed};
  }
`;
const CustomATag = styled.a`
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration-color: ${Colors.primaryRed};
  color: ${Colors.primaryBlack};
  /*  */
  &:hover {
    color: ${Colors.primaryBlack};
    opacity: 0.9;
  }
`;
const MessageText = styled.div`
  font-size: 14px;
  color: ${Colors.primaryBlack};
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  /*  */
  &:last-child {
    margin-bottom: 0px;
  }

  /* message text span */
  span {
    margin-left: 15px;
    color: ${Colors.primaryGrey};
  }

  /* message checkmark icon & svg */
  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-left: -3px;
    color: ${Colors.primaryGrey};
  }

  /*  */
  ${Responsive.mobile} {
    /* message checkmark icon & svg */
    svg {
      margin-left: 0px;
      min-width: 15px;
      width: 15px;
    }

    /* message text span */
    span {
      margin-left: 20px;
      color: ${Colors.primaryGrey};
      line-height: 20px;
    }
  }
`;
const InsuranceExceptionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const AccordionWrapper = styled.div``;
const CheckInfoTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  /*  */
  &:last-child {
    margin-bottom: 0px;
  }
  /*  */
  ${Responsive.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const OffetTextLeftSide = styled.span`
  color: ${Colors.primaryGrey};
  font-size: 14px;
  /* font-weight: 600; */
`;
const OffetTextRightSide = styled.span`
  color: ${Colors.primaryBlack};
  font-size: 14px;
  /* font-weight: 600; */
`;
const Wrapper = styled.div``;
//
export default AdminCompanySoldPoliciesDetail;
