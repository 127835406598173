import { createGlobalStyle } from 'styled-components'

// helvetica_neue_regular
import helvetica_Neue_regular_woff from './helveticaneue-01-webfont.woff'
import helvetica_Neue_regular_woff2 from './helveticaneue-01-webfont.woff2'

// Helvetica Neue LT GEO 55 Roman
import Helvetica_Neue_LT_GEO_55_Roman_woff from './HelveticaNeueLTGEO-55Roman.woff'
import Helvetica_Neue_LT_GEO_55_Roman_woff2 from './HelveticaNeueLTGEO-55Roman.woff2'

import Fonts from '../Fonts'

export default createGlobalStyle`
    /* helvetica_neueregular */
    @font-face {
        font-family: ${Fonts.helvetica_Neue_regular};
        src: url(${helvetica_Neue_regular_woff2}) format('woff2'),
            url(${helvetica_Neue_regular_woff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

     /* Helvetica Neue LT GEO 55 Roman */
     @font-face {
        font-family: ${Fonts.Helvetica_Neue_LT_GEO_55_roman};
        src: url(${Helvetica_Neue_LT_GEO_55_Roman_woff2}) format('woff2'),
            url(${Helvetica_Neue_LT_GEO_55_Roman_woff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

`
