import React, { useState } from 'react'
import styled from 'styled-components'
import Responsive from '../assets/config/Responsive'
import Arrow from '../assets/icons/JSX/RightArrow'
import Colors from '../assets/styles/Colors'
//
const Accordion: React.FC<{
  children?: React.ReactNode
  title?: string
  openAccordion?: boolean
  className?: string
}> = ({ children, title, openAccordion = false, className }) => {
  //
  const [open, setOpen] = useState<boolean>(openAccordion)
  //
  return (
    <AccordionWrapper className={className}>
      <Header $open={open} onClick={() => setOpen(!open)}>
        <span className="accordion-head-title">{title}</span>
        <ArrowStyle $open={open} />
      </Header>
      <Content style={open === true ? {} : { display: 'none' }}>{children}</Content>
    </AccordionWrapper>
  )
}
//
//
// styles
const ArrowStyle = styled(Arrow)<{ $open: boolean }>`
  transform: ${({ $open }) => ($open === true ? 'rotate(-90deg)' : 'rotate(90deg)')};
  color: ${({ $open }) => ($open === true ? `${Colors.primaryBlack}` : `${Colors.rollingStone}`)};
  width: 16px;
  height: 16px;
  /*  */
  ${Responsive.tablet} {
    width: 13px;
    height: 13px;
  }
  ${Responsive.mobile} {
    width: 13px;
    height: 13px;
  }
`
const Content = styled.div`
  background-color: ${Colors.white};
  padding-inline: 64px;
  padding-bottom: 38px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /*  */
  ${Responsive.tablet} {
    padding-inline: 24px;
    padding-bottom: 35px;
  }
  ${Responsive.mobile} {
    padding-inline: 24px;
    padding-bottom: 35px;
  }
`
const Header = styled.div<{ $open: boolean }>`
  height: 96px;
  background-color: ${Colors.white};
  padding: ${({ $open }) => ($open === true ? '38px 64px 24px 64px' : '38px 64px')};
  border-radius: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;

  /* innter titile span */
  span {
    font-size: 18px;
    color: ${Colors.primaryBlack};
    user-select: none;
  }
  /*  */
  ${Responsive.tablet} {
    height: 64px;
    padding: ${({ $open }) => ($open === true ? '18.5px 24px 24px 24px' : '18.5px 24px')};

    /* innter titile span */
    span {
      font-size: 14px;
      color: ${Colors.primaryBlack};
    }
  }
  ${Responsive.mobile} {
    height: 64px;
    padding: ${({ $open }) => ($open === true ? '18.5px 24px 24px 24px' : '18.5px 24px')};

    /* innter titile span */
    span {
      font-size: 14px;
      color: ${Colors.primaryBlack};
    }
  }
`
const AccordionWrapper = styled.div`
  -webkit-box-shadow: 0px 0px 15px 3px #88888811;
  box-shadow: 0px 0px 15px 3px #88888811;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 32px;
  /* user-select: none; */

  ${Responsive.tablet} {
    margin-bottom: 20px;
  }
  ${Responsive.mobile} {
    margin-bottom: 20px;
  }
`
export default Accordion
