import React from 'react'
import styled from 'styled-components'
import Colors from '../assets/styles/Colors'
import InfoIcon from '../assets/icons/JSX/InfoIcon'
import RichText from './RichText'
//
const Message: React.FC<{
  variant?: 'gray' | 'light-red'
  text?: string
}> = ({ variant = 'gray', text }) => {
  return (
    <Wrapper $variant={variant}>
      <IconStyle />
      <span>
        <RichText html={text || ''} />
      </span>
    </Wrapper>
  )
}
//
//
// styles
const IconStyle = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  margin-right: 10px;
  margin-top: 3px;
`
const Wrapper = styled.div<{ $variant: string }>`
  background-color: ${({ $variant }) => ($variant === 'light-red' ? Colors.lightRed : Colors.grey)};
  padding: 23px 13px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  width: 100%;

  /*  */
  span {
    div {
      font-size: 14px !important;
    }
  }

  /* inner text span */
  span {
    font-size: 14px;
    line-height: 22px;
    div {
      margin-bottom: 0px;
    }
  }
`
export default Message
