import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../../api";
import { MediumScreenTitle } from "../../../../assets/styles/GlobalStyle";
import CustomLink from "../../../../components/CustomLink";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import {
  EnabledLanguage,
  TplListItems,
  TplPagesWording,
} from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import Button from "../../../admin_components/AdminButton";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import { AppState } from "../../../../store/appSlice";
//
//
type CriticalPagesWordingTranslation = {
  title: string;
  body: string;
  excerpt: string;
  secondTitle: string;
  thirdTitle: string;
  secondExcerpt: string;
  fourthTitle: string;
  thirdExcerpt: string;
};
//
//
type CriticalPageTranslation = {
  title: string;
  stepName: string;
};
//
const AdminCriticalManagePagesEdit = () => {
  const { id: idStr = "0" } = useParams();
  const itemID = parseInt(idStr);

  const { slug } = useParams();
  //
  //
  const navigate = useNavigate();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  // updated item
  const [updatedPageTextsState, setUpdatedPageTextsState] = useState<
    Partial<TplListItems>
  >({});

  // updated main info
  const [updatedPageTextArry, setUpdatedPageTextArry] = useState<
    Partial<TplPagesWording>
  >({});
  //
  //
  const {
    data: existingCriticalPageItem,
    isLoading: isExistringCriticalPageItemLoading,
    refetch: RefetchExistingItem,
  } = api.useGetTplFlowPageTextItemByListItemIdQuery(
    { itemID: itemID as number, slug: slug as string },
    {
      skip: !itemID && !slug && true,
    }
  );
  //
  // get critical flow for page title and stepName
  const {
    data: pageTextArry,
    isLoading: isPageTextArrayLoading,
    refetch: RefetchPageTextArry,
  } = api.useGetTplFlowPageTextsBySlugQuery(slug as string, {
    skip: !slug && true,
  });
  //
  //
  // add critical fields
  const setCriticalFlowTexts = (field: keyof TplListItems, value: any) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      [field]: value,
    });
  };
  //
  //
  // add critical translation fields
  const setAddCriticalAddonTranslatedField = (
    field: keyof CriticalPagesWordingTranslation,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      translations: {
        ...updatedPageTextsState.translations,
        [language]: {
          ...updatedPageTextsState?.translations?.[language],
          [field]: value || " ",
        },
      },
    });
  };
  //
  // add critical main translation fields
  const setAddCriticalMainAddonTranslatedField = (
    field: keyof CriticalPageTranslation,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedPageTextArry({
      ...updatedPageTextArry,
      translations: {
        ...updatedPageTextArry.translations,
        [language]: {
          ...updatedPageTextArry?.translations?.[language],
          [field]: value || " ",
        },
      },
    });
  };
  //
  const [updatePageByListItemId, { isLoading: isUpdatePageLoading }] =
    api.useUpdateTplFlowPageItemByListItemIdMutation();
  //
  const [updateTplFlow, { isLoading: isUpdateCriticalFlowLoading }] =
    api.useUpdateTplFlowMutation();
  //
  //
  const UpdateCriticalPageTexts = async () => {
    //
    try {
      await updatePageByListItemId({
        item: updatedPageTextsState,
        slug: slug as string,
      }).unwrap();
      //
      //
      toast.success(`${t('text')} ${t('updatedSuccessfully')}`);
      //
      //
      RefetchExistingItem();
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  const UpdateMainTexts = async () => {
    //
    try {
      await updateTplFlow({
        item: updatedPageTextArry,
        slug: slug as string,
      }).unwrap();
      //
      toast.success(`${t('text')} ${t('updatedSuccessfully')}`);
      //
      //
      RefetchPageTextArry();
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  useEffect(() => {
    if (existingCriticalPageItem) {
      setUpdatedPageTextsState(existingCriticalPageItem);
    }
  }, [existingCriticalPageItem]);
  //
  useEffect(() => {
    if (pageTextArry) {
      setUpdatedPageTextArry(pageTextArry);
    }
  }, [pageTextArry]);
  //
  //
  //
  const mainLoading =
    isExistringCriticalPageItemLoading ||
    isUpdatePageLoading ||
    isPageTextArrayLoading ||
    isUpdateCriticalFlowLoading;
  //
  //
  const { t } = useTranslation();
  //
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <br />

      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: "20px",
        }}
      >
        <CustomLink
          style={{ justifyContent: "flex-start" }}
          onClick={() => navigate(-1)}
          size={"small"}
          leftLineIcon={true}
          text={`${t("back")}`}
        />
        <MediumScreenTitle>{updatedPageTextsState?.translations?.[field_Language]?.title}</MediumScreenTitle>
        <AdminLangSwicher />
      </div>
      <div>
        {updatedPageTextArry?.translations?.[field_Language]?.title &&
          itemID === 0 && (
            <Input
              id="pageTitle"
              name="pageTitle"
              label={t('pageTitle')}
              type="text"
              placeholder=""
              value={updatedPageTextArry?.translations?.[field_Language]?.title || ""}
              onChange={(value) =>
                setAddCriticalMainAddonTranslatedField("title", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "25px" }}
            />
          )}

        {updatedPageTextArry?.translations?.[field_Language]?.stepName &&
          itemID === 0 && (
            <Input
              id="StepName"
              name="stepName"
              label={t('stepName')}
              type="text"
              placeholder=""
              value={
                updatedPageTextArry?.translations?.[field_Language]?.stepName || ""
              }
              onChange={(value) =>
                setAddCriticalMainAddonTranslatedField("stepName", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "25px" }}
            />
          )}

        {updatedPageTextsState?.translations?.[field_Language]?.title && (
          <Input
            id="Title"
            name="title"
            label={t('title')}
            type="text"
            placeholder=""
            value={updatedPageTextsState?.translations?.[field_Language]?.title || ""}
            onChange={(value) =>
              setAddCriticalAddonTranslatedField("title", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}

        {updatedPageTextsState?.translations?.[field_Language]?.body && (
          <Input
            id="body"
            name="body"
            label={t('body')}
            type="text"
            placeholder=""
            value={updatedPageTextsState?.translations?.[field_Language]?.body || ""}
            onChange={(value) =>
              setAddCriticalAddonTranslatedField("body", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}

        {updatedPageTextsState?.translations?.[field_Language]?.excerpt && (
          <Input
            id="excerpt"
            name="excerpt"
            label={t('additionalInformation')}
            type="text"
            placeholder=""
            value={
              updatedPageTextsState?.translations?.[field_Language]?.excerpt || ""
            }
            onChange={(value) =>
              setAddCriticalAddonTranslatedField("excerpt", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}

        {updatedPageTextsState?.translations?.[field_Language]?.secondTitle && (
          <Input
            id="secondTitle"
            name="secondTitle"
            label={t('secondTitle')}
            type="text"
            placeholder=""
            value={
              updatedPageTextsState?.translations?.[field_Language]?.secondTitle || ""
            }
            onChange={(value) =>
              setAddCriticalAddonTranslatedField("secondTitle", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}

        {updatedPageTextsState?.translations?.[field_Language]?.secondExcerpt && (
          <Input
            id="secondExcerpt"
            name="secondExcerpt"
            label={t('secondAdditionalInformation')}
            type="text"
            placeholder=""
            value={
              updatedPageTextsState?.translations?.[field_Language]?.secondExcerpt ||
              ""
            }
            onChange={(value) =>
              setAddCriticalAddonTranslatedField("secondExcerpt", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}

        {updatedPageTextsState?.translations?.[field_Language]?.thirdTitle && (
          <Input
            id="thirdTitle"
            name="thirdTitle"
            label={t('thirdTitle')}
            type="text"
            placeholder=""
            value={
              updatedPageTextsState?.translations?.[field_Language]?.thirdTitle || ""
            }
            onChange={(value) =>
              setAddCriticalAddonTranslatedField("thirdTitle", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}

        {updatedPageTextsState?.translations?.[field_Language]?.thirdExcerpt && (
          <Input
            id="thirdExcerpt"
            name="thirdExcerpt"
            label={t('thirdAdditionalInformation')}
            type="text"
            placeholder=""
            value={
              updatedPageTextsState?.translations?.[field_Language]?.thirdExcerpt ||
              ""
            }
            onChange={(value) =>
              setAddCriticalAddonTranslatedField("thirdExcerpt", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "25px" }}
          />
        )}

        {updatedPageTextsState?.image && (
          <UploadFile
            label={t('uploadImage')}
            onChange={(props) => setCriticalFlowTexts("image", props.url)}
            defaultFileName={updatedPageTextsState?.image || ""}
          />
        )}
      </div>
      <br />
      <br />
      {itemID !== 0 ? (
        <Button
          fullWidth={true}
          btnStyleType="primary"
          text={t('update')}
          onClick={UpdateCriticalPageTexts}
        />
      ) : (
        <Button
          fullWidth={true}
          btnStyleType="primary"
          text={t('update')}
          onClick={UpdateMainTexts}
        />
      )}
    </Wrapper>
  );
};
//
//  styles
const Wrapper = styled.div``;
//
export default AdminCriticalManagePagesEdit;
