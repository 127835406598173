import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../../api";
import Colors from "../../../../assets/styles/Colors";
import Accordion from "../../../../components/Accordion";
import CustomSelect from "../../../../components/CustomSelect";
import Loader from "../../../../components/Loader";
import BaseModal from "../../../../components/modals/BaseModal";
import NotFound404 from "../../../../components/NotFound404";
import { numberWithSpaces } from "../../../../domain/types";
import { RootState, useAppSelector } from "../../../../store";
import { AuthState } from "../../../../store/authSlice";
import Button from "../../../admin_components/AdminButton";
import { useTranslation } from "react-i18next";
//
//
const Admin_travel_product_list = () => {
  //
  const { t } = useTranslation()
  //
  const {lang} = useAppSelector((root) => root.app)
  // 
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const isFraniAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";
  //
  // new product modal state
  const [openNewProductModal, setopenNewProductModal] =
    useState<boolean>(false);
  //
  //
  // product categories list
  const {
    data: AdminProductCategoriesList,
    isLoading: isAdminProductCategoriesListLoading,
  } = api.useGetAdminProductCategoriesListQuery(undefined);
  //
  //
  // user current company
  const {
    data: AdminCurrentUserCompany,
    isLoading: isAdminCurrentUserCompanyLoading,
  } = api.useGetAdminCurrentUserCompanyQuery(undefined);
  //
  //
  const [selectedCompanyID, setSelectedCompanyID] = useState<
    number | undefined
  >();
  //
  const [selectedCategoryID, setSelectedCategoryID] = useState<
    number | undefined
  >();
  //
  //
  //
  //
  //
  //
  // get product list by id
  const {
    data: ProductListById,
    isLoading: isProductListByIdLoading,
    refetch: isProductListRefetchByid,
  } = api.useGetProductListByCategoryIdQuery(
    // @ts-ignore
    selectedCompanyID ? selectedCompanyID : AdminCurrentUserCompany?.id,
    {
      skip: !selectedCompanyID && !AdminCurrentUserCompany?.id && true,
    }
  );
  //
  //
  //
  // company list
  const { data: companyList, isLoading: isCompanyListLoading } =
    api.useCompanyListQuery(undefined, {
      skip:
        (auth.user?.companyUsers[0]?.role || auth.user?.userType) !==
          "Frani-Admin" && true,
    });
  //
  //
  //
  // create travel product
  const [createTravelProduct, { isLoading: isCreateTravelProductLoading }] =
    api.useCreateTravelProductMutation();
  //
  // create tpl product
  const [createTplProduct, { isLoading: isCreateTplProductLoading }] =
    api.useCreateTplProductMutation();
  //
  // create pet product
  const [createPetProduct, { isLoading: isCreatePetProductLoading }] =
    api.useCreatePetProductMutation();
  //
  // create welcomer product
  const [createWelcomerProduct, { isLoading: isCreateWelcomerProductLoading }] =
    api.useCreateWelcomerProductMutation();
  //
  // navigate
  const navigate = useNavigate();
  //
  //
  //close new product modal
  const _onCloseModal = () => {
    setopenNewProductModal(false);
  };
  //
  //
  // create travel product
  const CreateTravelProductFun = async () => {
    try {
      if (selectedCategoryID && AdminCurrentUserCompany) {
        await createTravelProduct({
          // TODO next time make normal request
          companyId: AdminCurrentUserCompany.id || selectedCompanyID || 0,
          productCategoryId: selectedCategoryID,
        }).unwrap();
        //
        // refetch product list by id
        isProductListRefetchByid();
        _onCloseModal();
      }
      toast.success(`Travel ${t('product')} ${t('createdSuccessfully')}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('fillRequiredFields')}`);
      }
    }
  };
  //
  //
  // create tpl product
  const CreateTplProductFun = async () => {
    try {
      if (selectedCategoryID && AdminCurrentUserCompany) {
        await createTplProduct({
          // TODO next time make normal request
          companyId: AdminCurrentUserCompany.id || selectedCompanyID || 0,
          productCategoryId: selectedCategoryID,
        }).unwrap();
        //
        // refetch product list by id
        isProductListRefetchByid();
        _onCloseModal();
      }
      toast.success(`TPL ${t('product')} ${t('createdSuccessfully')}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('fillRequiredFields')}`);
      }
    }
  };

  // create pet product
  const CreatePetProductFun = async () => {
    try {
      if (selectedCategoryID && AdminCurrentUserCompany) {
        await createPetProduct({
          // TODO next time make normal request
          companyId: AdminCurrentUserCompany.id || selectedCompanyID || 0,
          productCategoryId: selectedCategoryID,
        }).unwrap();
        //
        // refetch product list by id
        isProductListRefetchByid();
        _onCloseModal();
      }
      toast.success(`PET ${t('product')} ${t('createdSuccessfully')}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('fillRequiredFields')}`);
      }
    }
  };
  //
  // create travel product
  const CreateWelcomerProductFun = async () => {
    try {
      if (selectedCategoryID && AdminCurrentUserCompany) {
        await createWelcomerProduct({
          // TODO next time make normal request
          companyId: AdminCurrentUserCompany.id || selectedCompanyID || 0,
          productCategoryId: selectedCategoryID,
        }).unwrap();
        //
        // refetch product list by id
        isProductListRefetchByid();
        _onCloseModal();
      }
      toast.success(`Local Tour ${t('product')} ${t('createdSuccessfully')}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('fillRequiredFields')}`);
      }
    }
  };
  // main loader
  const MainLoading =
    isAdminProductCategoriesListLoading ||
    isCreateTravelProductLoading ||
    isAdminCurrentUserCompanyLoading ||
    isProductListByIdLoading ||
    isCompanyListLoading ||
    isCreateTplProductLoading ||
    isCreateWelcomerProductLoading ||
    isCreatePetProductLoading;
  //
  //
  //
  //
  return MainLoading ? (
    <Loader />
  ) : !AdminProductCategoriesList ? (
    <NotFound404 />
  ) : (
    <div>
      <Button
        btnStyleType="primary"
        icon={true}
        onClick={() => [
          setopenNewProductModal(!openNewProductModal),
          setSelectedCompanyID(undefined),
        ]}
        text={t('createYourProduct')}
      />

      {companyList && (
        <>
          <br />
          <CompanyBlock>
            {companyList &&
              companyList.map((item, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedCompanyID(item.id)}
                  style={{
                    backgroundColor:
                      item.id === selectedCompanyID
                        ? Colors.grey
                        : "transparent",
                    color: item.id === selectedCompanyID ? Colors.white : "red",
                    outline:
                      item.id === selectedCompanyID
                        ? `2px solid ${Colors.primaryRed}`
                        : "none",
                  }}
                >
                  <CompanyLogoImg src={item.logo} alt="company logo" />
                </div>
              ))}
          </CompanyBlock>
        </>
      )}

      <CurrentProductWrapper>
        {ProductListById &&
          ProductListById.map((item, index) => (
            <Accordion
              title={`${item.productCategory.title}: ${item.slug} |  ${
                item.integrated === true ? `${t('integrated')}` : `${t('nonIntegrated')}`
              }`}
              key={index}
            >
              <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">{t('package')}</TableCell>
                      {item.productCategoryId === 1 && (
                        <TableCell align="right">{t('multiPack')}</TableCell>
                      )}
                      {item.productCategoryId === 1 && (
                        <TableCell align="right">{t('areaa')}</TableCell>
                      )}
                      <TableCell align="right">{t('limit')}</TableCell>
                      <TableCell align="right">{t('currency')}</TableCell>
                      <TableCell align="right">{t('activationDate')}</TableCell>
                      <TableCell align="right">{t('status')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.packages.map((pkg) => (
                      <TableRow
                        key={pkg.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {pkg.title}
                        </TableCell>
                        {item.productCategoryId === 1 && (
                          <TableCell align="right">
                            {pkg.target?.enableMulti ? `${t('yes')}` : `${t('no')}`}
                          </TableCell>
                        )}
                        {item.productCategoryId === 1 && (
                          <TableCell align="right">
                            {pkg.target?.areas?.map((a) => a.area).join(", ")}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {/* {pkg.compensationLimit} */}
                          {numberWithSpaces(pkg.compensationLimit)}{" "}
                        </TableCell>
                        <TableCell align="right">
                          {pkg.compensationCurrencies
                            .map((c) => c.currency)
                            .join(", ")}
                        </TableCell>
                        <TableCell align="right">
                          {dayjs(pkg.activatedDate).format("DD.MM.YYYY HH:mm")}
                        </TableCell>
                        <TableCell align="right">{pkg.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                btnStyleType="primary"
                icon={false}
                onClick={() =>
                  navigate(
                    item.targetType === "TravelProduct"
                      ? `/travel-product-edit/${item.targetId}`
                      : item.targetType === "TplProduct"
                      ? `/tpl-product-edit/${item.targetId}`
                      : item.targetType === "WelcomerProduct"
                      ? `/welcomer-product-edit/${item.targetId}`
                      : "/"
                      ? `/pet-product-edit/${item.targetId}`
                      : "/"
                  )
                }
                text={t('editProduct')}
              />
            </Accordion>
          ))}
      </CurrentProductWrapper>

      <BaseModal onClose={_onCloseModal} isOpen={openNewProductModal}>
        <DropdownWrapper>
          <div>
            {AdminProductCategoriesList && (
              <CustomSelect
                // defaultInputValue={
                //   countries.find((x) => x.isoCode === insurerInfoState.cityzenship)?.translations?.[lang].name
                // }
                placeholder={t('chooseProductCategory')}
                headText={t('chooseProductCategory')}
                onChange={(event) => setSelectedCategoryID(event.value)}
                data={[
                  ...AdminProductCategoriesList.map((item) => ({
                    value: item.id,
                    label: item.title,
                  })),
                ]}
              />
            )}

            {companyList && (
              <CustomSelect
                // defaultInputValue={
                //   countries.find((x) => x.isoCode === insurerInfoState.cityzenship)?.translations?.[lang].name
                // }
                placeholder={t('chooseCompany')}
                headText={t('chooseCompany')}
                onChange={(event) => setSelectedCompanyID(event.value)}
                data={[
                  ...companyList.map((item) => ({
                    value: item.id,
                    label: item.translations[lang]?.title,
                  })),
                ]}
              />
            )}
          </div>

          {isFraniAdmin ? (
            <>
              {selectedCategoryID && selectedCompanyID && (
                <>
                  {(auth?.user?.companyUsers[0]?.role ||
                    auth?.user?.userType) === "Insurance-Admin" ||
                  "Insurance-Product-Editor" ||
                  "Insurance-Admin" ? (
                    <Button
                      btnStyleType="primary"
                      icon={false}
                      fullWidth={true}
                      disabled={selectedCategoryID === undefined ? true : false}
                      onClick={() => {
                        const existingCategory =
                          AdminProductCategoriesList.find(
                            (x) => x.id === selectedCategoryID
                          );
                        //
                        if (
                          existingCategory &&
                          existingCategory.slug === "travel-outside-georgia"
                        ) {
                          CreateTravelProductFun();
                        } else if (
                          existingCategory &&
                          existingCategory.slug === "tpl"
                        ) {
                          CreateTplProductFun();
                        } else if (
                          existingCategory &&
                          existingCategory.slug === "welcomer-to-georgia"
                        ) {
                          CreateWelcomerProductFun();
                        } else if (
                          existingCategory &&
                          existingCategory.slug === "pet"
                        ) {
                          CreatePetProductFun();
                        } else {
                          toast.info(
                            `${t('failtedToCrateProduct')}`
                          );
                        }
                      }}
                      text={t('create')}
                    />
                  ) : null}
                </>
              )}
            </>
          ) : (
            <>
              {selectedCategoryID && (
                <>
                  {(auth?.user?.companyUsers[0]?.role ||
                    auth?.user?.userType) === "Insurance-Admin" ||
                  "Insurance-Product-Editor" ||
                  "Insurance-Admin" ? (
                    <Button
                      btnStyleType="primary"
                      icon={false}
                      fullWidth={true}
                      disabled={selectedCategoryID === undefined ? true : false}
                      onClick={() => {
                        const existingCategory =
                          AdminProductCategoriesList.find(
                            (x) => x.id === selectedCategoryID
                          );
                        //
                        if (
                          existingCategory &&
                          existingCategory.slug === "travel-outside-georgia"
                        ) {
                          CreateTravelProductFun();
                        } else if (
                          existingCategory &&
                          existingCategory.slug === "tpl"
                        ) {
                          CreateTplProductFun();
                        } else if (
                          existingCategory &&
                          existingCategory.slug === "welcomer-to-georgia"
                        ) {
                          CreateWelcomerProductFun();
                        }
                      }}
                      text={t('create')}
                    />
                  ) : null}
                </>
              )}
            </>
          )}
        </DropdownWrapper>
        {/*  */}
      </BaseModal>
    </div>
  );
};
//
//
// styles
const CompanyLogoImg = styled.img`
  width: 100%;
  height: 50px;
  object-fit: contain;
`;
const CompanyBlock = styled.div`
  -webkit-box-shadow: 0px 0px 15px 3px #64646411;
  box-shadow: 0px 0px 15px 3px #64646411;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  padding: 20px;
  div {
    padding: 10px;
    margin: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    user-select: none;
    border-radius: 4px;
  }
`;
const DropdownWrapper = styled.div`
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const CurrentProductWrapper = styled.div`
  margin-top: 50px;
  .MuiTableCell-root {
    font-family: inherit !important;
  }
`;

export default Admin_travel_product_list;
