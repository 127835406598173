import React, { useState } from "react";

import styled from "styled-components";

import Responsive from "../../../../../assets/config/Responsive";
import Colors from "../../../../../assets/styles/Colors";

import DocumentFieldUploaderBlock from "../../../../admin_components/DocumentFieldUploaderBlock";
import Button from "../../../../../components/Button";
import {
  PolicyFileType,
  TplProduct,
  WordingFileType,
} from "../../../../../domain/types";
import Loader from "../../../../../components/Loader";
import NotFound404 from "../../../../../components/NotFound404";
import { useTranslation } from "react-i18next";

const AdminTplDocumentsTabThree: React.FC<{
  tplProduct?: TplProduct;
  isLoading: boolean;
  saveTab: any;
}> = ({ tplProduct, isLoading, saveTab }) => {
  //
  const { t } = useTranslation();
  //
  const [wordingFileTypes, setWordingFileTypes] = useState<any[]>([
    {
      language: "GE",
      url: tplProduct?.mainProduct?.wordingFileUrl
        ? (JSON.parse(tplProduct.mainProduct.wordingFileUrl) as WordingFileType)
            ?.translations?.ge?.url
        : "",
    },
    {
      language: "EN",
      url: tplProduct?.mainProduct?.wordingFileUrl
        ? (JSON.parse(tplProduct.mainProduct.wordingFileUrl) as WordingFileType)
            ?.translations?.en?.url
        : "",
    },
  ]);

  const [policyFileTypes, setPolicyFileTypes] = useState<any[]>([
    {
      name: "ყოველთვიური",
      type: "MONTHLY",
      url: tplProduct?.mainProduct?.policyFileUrl
        ? (
            JSON.parse(tplProduct.mainProduct.policyFileUrl) as PolicyFileType[]
          ).find((p) => p.type === "MONTHLY")?.url
        : "",
    },
    {
      name: "პირველ თვეში ორი",
      type: "MONTHLY-FIRST-TWO",
      url: tplProduct?.mainProduct?.policyFileUrl
        ? (
            JSON.parse(tplProduct.mainProduct.policyFileUrl) as PolicyFileType[]
          ).find((p) => p.type === "MONTHLY-FIRST-TWO")?.url
        : "",
    },
    {
      name: "ერთჯერადი",
      type: "ONE-TIME",
      url: tplProduct?.mainProduct?.policyFileUrl
        ? (
            JSON.parse(tplProduct.mainProduct.policyFileUrl) as PolicyFileType[]
          ).find((p) => p.type === "ONE-TIME")?.url
        : "",
    },
  ]);

  const setWordingFileTypesHandler = (newUrl, language) => {
    const newTypes = wordingFileTypes.map((f) => {
      return {
        ...f,
        url: language === f.language ? newUrl : f.url,
      } as any;
    });

    setWordingFileTypes(newTypes);
  };

  const setPolicyFileTypesHandler = (newUrl, type) => {
    const newTypes = policyFileTypes.map((f) => {
      return {
        ...f,
        url: type === f.type ? newUrl : f.url,
      } as any;
    });

    setPolicyFileTypes(newTypes);
  };

  const saveHandler = () => {
    const policyFiles = policyFileTypes.map((p) => {
      return {
        type: p.type,
        url: p.url,
      } as PolicyFileType;
    });

    const wordingFiles: WordingFileType = {
      translations: {
        ge: {
          url: wordingFileTypes.find((w) => w.language === "GE")?.url,
        },
        en: {
          url: wordingFileTypes.find((w) => w.language === "EN")?.url,
        },
      },
    };

    saveTab(policyFiles, wordingFiles);
  };

  return isLoading ? (
    <Loader />
  ) : !tplProduct ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <DocumentBlockTitle>{t("policyWording")}</DocumentBlockTitle>
      <Row>
        {wordingFileTypes.map((wordingFile) => (
          <Column $widthPercent={50} key={wordingFile.language}>
            <Block>
              <DocumentBlockTitle>{wordingFile.language}</DocumentBlockTitle>
              <DocumentFieldUploaderBlock
                url={wordingFile.url}
                onChange={(newUrl) =>
                  setWordingFileTypesHandler(newUrl, wordingFile.language)
                }
                width={300}
              />
            </Block>
          </Column>
        ))}
      </Row>
      <br />
      <DocumentBlockTitle>{t("policyFormTemplate")}</DocumentBlockTitle>
      <Row>
        {policyFileTypes.map((policyFile) => (
          <Column $widthPercent={33} key={policyFile.type}>
            <Block>
              <DocumentBlockTitle>
                {policyFile.name}
                {/* {policyFile.name === "ყოველთვიური" ? t("monthly") : t("single")} */}
              </DocumentBlockTitle>
              <DocumentFieldUploaderBlock
                url={policyFile.url}
                onChange={(newUrl) =>
                  setPolicyFileTypesHandler(newUrl, policyFile.type)
                }
                width={300}
              />
            </Block>
          </Column>
        ))}
      </Row>
      <Button
        btnStyleType="primary"
        icon={false}
        fullWidth={true}
        disabled={false}
        onClick={saveHandler}
        text={t("save")}
      />
    </Wrapper>
  );
};

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.primaryGrey};
  margin-bottom: 20px;
  /*  */
  > div {
    flex-basis: 50%;
  }
`;

const Wrapper = styled.div`
  padding: 20px 15px;
  margin-bottom: 50px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${Responsive.tablet} {
    flex-direction: column;
  }
`;

const Column = styled.div<{ $widthPercent?: number }>`
  padding: 0 15px 20px 15px;
  ${({ $widthPercent = 50 }) =>
    $widthPercent ? `width: calc(${$widthPercent}% - 15px);` : ""}
  ${Responsive.tablet} {
    width: 100%;
  }
`;

const DocumentBlockTitle = styled.h5`
  /* min-height: 55px; */
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  font-size: 18px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
`;
//
export default AdminTplDocumentsTabThree;
