import * as React from "react"

function InfoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <g data-name="Group 51600">
        <g data-name="Group 10074">
          <g data-name="Group 9726">
            <g data-name="Group 9366">
              <g
                data-name="Ellipse 841"
                fill="none"
                stroke="CurrentColor"
                strokeWidth={1.8}
                transform="translate(0 -.214) translate(0 .214)"
              >
                <circle cx={10} cy={10} r={10} stroke="none" />
                <circle cx={10} cy={10} r={9.1} />
              </g>
            </g>
          </g>
        </g>
        <g
          data-name="Group 51568"
          fill="none"
          stroke="CurrentColor"
          strokeLinejoin="round"
          strokeWidth={1.8}
        >
          <path
            data-name="Path 17832"
            d="M.003 3v6"
            transform="translate(0 -.214) translate(10 6.081)"
          />
          <path
            data-name="Path 17833"
            d="M.003 0v1.6"
            transform="translate(0 -.214) translate(10 6.081)"
          />
        </g>
      </g>
    </svg>
  )
}

export default InfoIcon
