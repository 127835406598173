import React, { useCallback, useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom'
import styled from "styled-components";
import Accordion from "../../../../../components/Accordion";
import Button from "../../../../admin_components/AdminButton";
import Input from "../../../../admin_components/AdminInput";
// import Loader from '../../../../../components/Loader'
// import NotFound404 from '../../../../../components/NotFound404'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import Logo from "../../../../../assets/icons/JSX/Logo";
import {
  AdminPaymentScheduleType,
  Country,
  Currency,
  EnabledLanguage,
  Package,
  PackageService,
  PetPackage,
  Product,
  TplCoverageTranslations,
  TplCurrencies,
  TplImportantTermTranslations,
  TplLimitList,
  TplProduct,
} from "../../../../../domain/types";
// import BaseModal from '../../../../../components/modals/BaseModal'
import dayjs from "dayjs";
import api from "../../../../../api";
import Responsive from "../../../../../assets/config/Responsive";
import Colors from "../../../../../assets/styles/Colors";
import { useAppSelector } from "../../../../../store";
import { AppState } from "../../../../../store/appSlice";
import CustomSelecte from "../../../../admin_components/CustomSelecte";
import DocumentFieldUploaderBlock from "../../../../admin_components/DocumentFieldUploaderBlock";
import { useTranslation } from "react-i18next";
dayjs.locale("ka");

type Props = {
  tplPackageId: PetPackage["id"];
  countries: Country[];
  refetchExistingPetProduct: () => void;
  tplProduct: TplProduct;
  /** setTplProductField is not used for saving packages, but only to receive price combination-related updates */
  setTplProductField: (fieldName: keyof TplProduct, fieldValue: any) => void;
  copyProduct: any;
  setUsedCurrencies: (tplPackageId: number, _: Currency[]) => void;
  setUsedServices: (tplPackageId: number, values: number[]) => void;
  packageServices: PackageService[] | undefined;
  setUsedPaymentSchedules: (tplPackageId: number, values: number[]) => void;
  paymentSchedules: AdminPaymentScheduleType[] | undefined;
  tplLimits: TplLimitList[] | undefined;
  tplCurrencies: TplCurrencies[] | undefined;
};

const PetPackageEditor = ({
  tplPackageId: pkgId,
  countries,
  refetchExistingPetProduct,
  tplProduct,
  setTplProductField,
  copyProduct,
  setUsedCurrencies,
  setUsedServices,
  packageServices,
  setUsedPaymentSchedules,
  paymentSchedules,
  tplLimits,
  tplCurrencies,
}: Props) => {
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const { t } = useTranslation();
  //
  const auth = useAppSelector((data) => data.auth);
  const isAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";
  /** small context:
   * when the package fields change here, we have to propagate them back to the parent.
   * this means that we shouldn't receive `tplPackage` from the parent, or it will
   * become a two-way binding and we'll receive a "Maximum update depth exceeded" error.
   */
  const {
    //
    data: initialPetPackage,
    isLoading: isInitialPetPackageLoading,
    // refetch: refetchInitialTplPackage,
  } = api.useGetPetPackageByIdQuery(pkgId);
  //
  const { data: petCategories, isLoading: isPetCategoriesLoading } =
    api.useGetPetCategoriesQuery(undefined);
  //
  const { data: petBreeds, isLoading: isPetBreedsLoading } =
    api.useGetPetBreedsQuery(undefined);
  //
  const { data: petServices, isLoading: isPetServicesLoading } =
    api.useGetPetServicesQuery(undefined);
  //
  const { data: petMedicalServices, isLoading: isPetMedicalServicesLoading } =
    api.useGetPetServicesSecondQuery(undefined);
  //
  const [m_updatePetPackage, { isLoading: isUpdatePetPackageLoading }] =
    api.useUpdatePetPackageMutation();
  //
  const [_deletePetPackage, { isLoading: isDeletePetPackageLoading }] =
    api.useDeletePetPackageMutation();
  // [admin]: approve package changes
  const [m_activatePackage, { isLoading: isActivatePackageLoading }] =
    api.useAdminActivatePackageMutation();
  // [admin]: reject package changes
  const [m_deactivatePackage, { isLoading: isDeactivatePackageLoading }] =
    api.useAdminDeactivatePackageMutation();
  //
  //
  const [editedPackage, only_setEditedPackage] = useState<
    PetPackage | undefined
  >(undefined);
  //
  //
  //
  /** also update parent TplProduct state so that price combinations are updated */
  const setEditedPackage = useCallback(
    (newState: PetPackage) => {
      only_setEditedPackage(newState);
      if (newState) {
        const mainProductWithUpdatedPackage: Product = {
          ...tplProduct.mainProduct,
          packages: tplProduct.mainProduct.packages.map((mainPkg) => {
            if (mainPkg.id === newState.mainPackage.id) {
              return {
                ...newState.mainPackage,
                target: { ...newState },
              };
            } else {
              return mainPkg;
            }
          }),
        };
        /** this will cause price combinations to regenerate with updated data */
        setTplProductField("mainProduct", mainProductWithUpdatedPackage);
      }
    },
    [only_setEditedPackage, setTplProductField, tplProduct]
  );
  //
  const [editedCoverageTranslations, setEditedCoverageTranslations] =
    useState<TplCoverageTranslations[]>();
  //
  //
  const [
    editedImportantTermsTranslations,
    setEditedImportantTermsTranslations,
  ] = useState<TplImportantTermTranslations[]>();
  //
  const [pkgServiceIds, setPkgServiceIds] = useState<number[]>();
  //
  //
  useEffect(() => {
    if (initialPetPackage) {
      setEditedPackage(initialPetPackage);
      setEditedCoverageTranslations(
        initialPetPackage?.petCoverages?.map(
          (coverage) => coverage?.translations
        )
      );

      setEditedImportantTermsTranslations(
        initialPetPackage?.petImportantTerms?.map((term) => term?.translations)
      );

      setPkgServiceIds(
        initialPetPackage?.packageServiceRelations?.map(
          (service) => service?.packageService.id
        )
      );
    }
  }, [initialPetPackage, setEditedPackage]);
  //
  //
  const setTplPackageField = (fieldName: keyof PetPackage, fieldValue: any) => {
    if (!editedPackage) {
      return;
    }
    setEditedPackage({
      ...editedPackage,
      [fieldName]: fieldValue,
    });
  };
  //
  const setMainPackageField = (
    fieldName: keyof PetPackage["mainPackage"],
    fieldValue: any
  ) => {
    if (!editedPackage || !editedPackage.mainPackage) {
      return;
    }
    setEditedPackage({
      ...editedPackage,
      mainPackage: {
        ...editedPackage.mainPackage,
        [fieldName]: fieldValue,
      },
    });
  };
  //
  //
  const setTranslatedFieldOnMainPackage = (
    field: keyof Package["translations"]["en"],
    language: EnabledLanguage,
    value: any
  ) => {
    if (!editedPackage || !editedPackage.mainPackage) {
      return;
    }
    setMainPackageField("translations", {
      ...editedPackage.mainPackage.translations,
      [language]: {
        ...editedPackage.mainPackage.translations?.[language],
        [field]: value,
      },
    });
  };
  //
  //
  const saveTplPackage = async () => {
    if (editedPackage) {
      try {
        let fixedUpEditedPackage: PetPackage = { ...editedPackage };

        const parsedScheduleArray =
          typeof editedPackage?.mainPackage.paymentScheduleTypeIds === "string"
            ? JSON.parse(editedPackage?.mainPackage.paymentScheduleTypeIds)
            : editedPackage?.mainPackage.paymentScheduleTypeIds;

        const parsedBreedsArray =
          typeof editedPackage?.exceptionBreedIds === "string"
            ? JSON.parse(editedPackage?.exceptionBreedIds)
            : editedPackage?.exceptionBreedIds;

        const parsedAnimalIdsArray =
          typeof editedPackage?.animalCategoryIds === "string"
            ? JSON.parse(editedPackage?.animalCategoryIds)
            : editedPackage?.animalCategoryIds;

        const parsedPackageServicesArray =
          typeof editedPackage?.packageServices === "string"
            ? JSON.parse(editedPackage?.packageServices)
            : editedPackage?.packageServices;

        const parsedServicesArray =
          typeof editedPackage?.services === "string"
            ? JSON.parse(editedPackage?.services)
            : editedPackage?.services;

        fixedUpEditedPackage = {
          ...fixedUpEditedPackage,
          exceptionBreedIds: parsedBreedsArray,
          animalCategoryIds: parsedAnimalIdsArray,
          packageServices: parsedPackageServicesArray,
          services: parsedServicesArray,
          minAge: Number(editedPackage?.minAge),
          maxAge: Number(editedPackage?.maxAge),
          mainPackage: {
            ...fixedUpEditedPackage.mainPackage,
            paymentScheduleTypeIds: parsedScheduleArray,
            wordingFiles: editedPackage?.mainPackage?.wordingFiles,
            policyFiles: editedPackage?.mainPackage?.policyFiles,
          },
        };

        //in case the checkboxes are unchanged and editedPackage?.packageServiceIds is an empty array
        fixedUpEditedPackage = {
          ...fixedUpEditedPackage,
          packageServiceIds: pkgServiceIds,
        };

        // @ts-ignore
        const result = await m_updatePetPackage(fixedUpEditedPackage).unwrap();
        // refetchExistingPetProduct()
        setEditedPackage(result);
        toast.success(`${t("package")} ${t("savedSuccessfully")}`);
      } catch (error) {
        const message =
          error?.data?.errors?.[0]?.message ||
          error?.message ||
          `${t("failedToSave")}`;
        toast.error(message);
      }
    }
  };
  const deleteTplPackage = async () => {
    if (editedPackage) {
      if (!window.confirm(`${t("certainToDeleteIt")}`)) {
        return;
      }
      try {
        const result = await _deletePetPackage(editedPackage.id).unwrap();
        refetchExistingPetProduct();
        console.log("delete tpl package", result);
        toast.success(`${t("package")} ${t("deletedSuccessfully")}`);
      } catch (error) {
        const message =
          error?.data?.errors?.[0]?.message ||
          error?.message ||
          `${t("failedToDelete")}`;
        toast.error(message);
      }
    }
  };
  // approve package changes [admin does this]
  const approvePackageChanges = async () => {
    try {
      if (!editedPackage) {
        throw new Error("Missing package data");
      }
      if (editedPackage.mainPackage.status === "published") {
        throw new Error("დაუდასტურებელი ცვლილებები არ მოიძებნა");
      }
      // first, save package fields
      await saveTplPackage();
      // second, activate the package
      const result = await m_activatePackage(
        editedPackage.mainPackage.id
      ).unwrap();
      toast.success(`${t("package")} ${t("publishedSuccessfully")}`);
      //
      // refetch tpl products
      // WARNING: this resets product fields.
      // if this is enabled, also save product fields above.
      // refetchExistingTplProduct()
      setEditedPackage({
        ...editedPackage,
        mainPackage: result,
      });
    } catch (error) {
      // console.log("approve package tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  // reject package changes [admin does this]
  const rejectPackageChanges = async () => {
    try {
      if (!editedPackage) {
        throw new Error("Missing package data");
      }
      if (editedPackage.mainPackage.status === "published") {
        // in this case, admin is converting a published package to draft (probably just auditing existing published packages)
      }
      //const result =
      await m_deactivatePackage(editedPackage.mainPackage.id).unwrap();
      toast.success(`${t("package")} ${t("deactivated")}`);
      //
      // refetch tpl products
      // WARNING: this resets edited package inputs. if you enable this, also save product fields tab here.
      // 09.02.2022: deactivation without saving modified fields. hoping for no problems in UX.
      //  refetchExistingTplPackage()
      // WARNING: this also resets edited package inputs, so only update the status field
      // setEditedPackage({
      //   ...editedPackage,
      //   mainPackage: result,
      // })
      setMainPackageField("status", "draft");
    } catch (error) {
      // console.log("save documents tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //
  //
  const isLoading =
    isInitialPetPackageLoading ||
    isUpdatePetPackageLoading ||
    isActivatePackageLoading ||
    isDeactivatePackageLoading ||
    isPetCategoriesLoading ||
    isPetBreedsLoading ||
    isPetMedicalServicesLoading ||
    isPetServicesLoading ||
    isDeletePetPackageLoading;
  //
  if (!editedPackage) {
    return null;
  }
  //
  //
  const FranoHorizontalLine: React.FC<{ title?: string }> = ({ title }) => {
    return (
      <CustomHorizontalLine>
        <div></div>
        {title ? <span>{title}</span> : <Logo />}
        <div></div>
      </CustomHorizontalLine>
    );
  };
  //
  //
  const dogCategoryId =
    petCategories?.find((x) => x.alias === "dog")?.id || null;
  //
  const catCategoryId =
    petCategories?.find((x) => x.alias === "cat")?.id || null;
  //
  //
  const parsedScheduleArray =
    typeof editedPackage?.mainPackage.paymentScheduleTypeIds === "string"
      ? JSON.parse(editedPackage?.mainPackage.paymentScheduleTypeIds)
      : editedPackage?.mainPackage.paymentScheduleTypeIds;
  //
  //
  const parsedExceptionBreedIds =
    typeof editedPackage?.exceptionBreedIds === "string"
      ? JSON.parse(editedPackage?.exceptionBreedIds)
      : editedPackage?.exceptionBreedIds;
  //
  const selectedBreeds = petBreeds?.filter((num) =>
    parsedExceptionBreedIds?.includes(num.id)
  );
  //
  //
  const parsedPackageServices =
    typeof editedPackage?.packageServices === "string"
      ? JSON.parse(editedPackage?.packageServices)
      : editedPackage?.packageServices;
  //
  //
  const parsedServices =
    typeof editedPackage?.services === "string"
      ? JSON.parse(editedPackage?.services)
      : editedPackage?.services;
  //
  //
  const selectedPackageServices = petMedicalServices?.filter((item1) =>
    parsedPackageServices?.some((item2) => item1.id === item2.id)
  );
  //
  //
  const parsedWordingFiles =
    typeof editedPackage?.mainPackage?.wordingFiles === "string"
      ? JSON.parse(editedPackage?.mainPackage?.wordingFiles)
      : editedPackage?.mainPackage?.wordingFiles;
  //
  //
  const parsedPolicyFiles = editedPackage?.mainPackage?.policyFiles || [];

  // console.log("selectedPackageServices", selectedPackageServices);
  // console.log("editedPackage?.packageServices", editedPackage?.packageServices);
  // console.log("editedPackage?.services", editedPackage?.services);
  // console.log("parsedWordingFiles", parsedWordingFiles);
  // console.log("parsedPolicyFiles", parsedPolicyFiles);
  // console.log(
  //   "editedPackage?.mainPackage?.policyFiles",
  //   editedPackage?.mainPackage?.policyFiles
  // );

  //
  return (
    <Wrapper>
      <Accordion title={editedPackage.mainPackage.title} key={editedPackage.id}>
        <Row>
          <Column $widthPercent={70} style={{ paddingLeft: "0px !important" }}>
            <FranoHorizontalLine title={t("packageTitle")} />
            <CustomBlock>
              <Input
                id=""
                label={t("packageTitle")}
                type="text"
                placeholder=""
                value={
                  editedPackage.mainPackage.translations[field_Language]
                    ?.title || ""
                }
                // @ts-ignore Partial EnabledLanguage cannot reliably detect translated field because 'en' is also Partial
                onChange={(value) =>
                  setTranslatedFieldOnMainPackage(
                    // @ts-ignore
                    "title",
                    field_Language,
                    value
                  )
                }
              />
            </CustomBlock>

            {/* setTplPackageField("minAgeOfCarRelease", value) */}
            <FranoHorizontalLine title={t("choosePet")} />
            <Block>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                {petCategories?.map((pet) => (
                  <Block key={pet.id}>
                    <InputLabel
                      htmlFor={`package-${editedPackage.id}-pet-${pet.id}`}
                    >
                      {pet.translations?.[field_Language]?.name}
                    </InputLabel>
                    <Checkbox
                      id={`package-${editedPackage.id}-pet-${pet.id}`}
                      checked={
                        typeof editedPackage?.animalCategoryIds === "string"
                          ? JSON.parse(editedPackage?.animalCategoryIds)
                          : editedPackage?.animalCategoryIds?.find(
                              (x) => x === pet.id
                            ) !== undefined
                      }
                      onChange={(event) => {
                        if (
                          editedPackage?.animalCategoryIds?.find(
                            (x) => x === pet.id
                          )
                        ) {
                          setTplPackageField("animalCategoryIds", [
                            ...editedPackage?.animalCategoryIds?.filter(
                              (x: number) => x !== pet.id
                            ),
                          ]);
                        } else {
                          setTplPackageField("animalCategoryIds", [
                            ...(editedPackage?.animalCategoryIds || []),
                            pet.id,
                          ]);
                        }
                      }}
                    />
                  </Block>
                ))}
              </div>
            </Block>

            <FranoHorizontalLine title={t("chooseExceptionalBreeds")} />
            <Block
              style={{
                width: "100%",
                gap: "20px",
              }}
            >
              <CustomSelecte
                key={1}
                title={t("dog&Cat")}
                style={{
                  width: "100%",
                }}
                closeMenuOnSelect={false}
                onChange={(data) =>
                  setTplPackageField(
                    "exceptionBreedIds",
                    data?.map((item) => item.value)
                  )
                }
                isMulti={true}
                placeholder={t("dog&Cat")}
                showOptionCheckbox={true}
                value={selectedBreeds?.map((item) => ({
                  label: `${item?.translations?.[field_Language]?.name}`,
                  value: item.id,
                }))}
                isSearchable={true}
                options={
                  petBreeds?.map((item) => ({
                    value: item.id,
                    label:
                      item.translations?.[field_Language]?.name +
                      `${
                        item.categoryId === dogCategoryId
                          ? ` (${t("dog")})`
                          : item.categoryId === catCategoryId
                          ? ` (${t("cat")})`
                          : "NFC"
                      }`,
                  })) || []
                }
              />
            </Block>

            <FranoHorizontalLine title={t("specifyAgeGroup")} />
            <Block
              style={{
                gap: "20px",
              }}
            >
              <Input
                width={"fullWidth"}
                id={"package-min-age"}
                label={t("fromAge")}
                type="text"
                placeholder=""
                value={editedPackage?.minAge?.toString() || ""}
                onChange={(value) => setTplPackageField("minAge", value)}
              />

              <Input
                width={"fullWidth"}
                id={"package-max-age"}
                label={t("upToAge")}
                type="text"
                placeholder=""
                value={editedPackage?.maxAge?.toString() || ""}
                onChange={(value) => setTplPackageField("maxAge", value)}
              />
            </Block>

            <FranoHorizontalLine title={t("medicalServicesInfo")} />
            <Block
              style={{
                flexDirection: "column",
              }}
            >
              <CustomSelecte
                title={t("medicalServices")}
                style={{
                  width: "100%",
                }}
                closeMenuOnSelect={false}
                onChange={(data) => {
                  //parsedPackageServices
                  const compareServicec = data?.map(
                    (item) =>
                      parsedPackageServices?.find(
                        (x) => x.id === item.value
                      ) ?? { id: item.value }
                  );
                  //
                  setTplPackageField(
                    "packageServices",
                    // data?.map((item) => ({ id: item.value }))
                    compareServicec
                  );
                }}
                isMulti={true}
                placeholder={t("medicalServices")}
                showOptionCheckbox={true}
                value={selectedPackageServices?.map((item) => ({
                  label: `${item?.translations?.[field_Language]?.name}`,
                  value: item.id,
                }))}
                isSearchable={true}
                options={
                  petMedicalServices?.map((item) => ({
                    value: item.id,
                    label: item.translations?.[field_Language]?.name,
                  })) || []
                }
              />
              {/*  */}
              <div
                style={{
                  width: "100%",
                  marginBlock: "30px",
                }}
              >
                {parsedPackageServices && parsedPackageServices.length >= 1 ? (
                  <ServiceDiv>
                    <div>{t("terms")}</div>
                    <div>{t("coverage")}</div>
                    <div>{t("limit")}</div>
                    <div>{t("waitingPeriod")}</div>
                  </ServiceDiv>
                ) : null}

                <div>
                  {(parsedPackageServices || []).map(
                    (serviceItem, serviceIndex) => (
                      <ServiceDiv key={serviceIndex}>
                        <div>
                          {
                            petMedicalServices?.find(
                              (x) => x.id === serviceItem.id
                            )?.translations?.[field_Language]?.name
                          }
                        </div>
                        <div>
                          <Input
                            id={`package-service-cover-${serviceIndex}`}
                            name={`package-service-cover-${serviceIndex}`}
                            width={"fullWidth"}
                            placeholder="80%"
                            type="text"
                            label=""
                            value={serviceItem?.cover || ""}
                            onChange={(value) => {
                              //
                              const editedSevice = parsedPackageServices?.map(
                                (e) =>
                                  e.id === serviceItem.id
                                    ? { ...e, cover: value }
                                    : e
                              );
                              //
                              setTplPackageField(
                                "packageServices",
                                editedSevice
                              );
                            }}
                          />
                        </div>
                        <div>
                          <Input
                            id={`package-service-limit-${serviceIndex}`}
                            name={`package-service-limit-${serviceIndex}`}
                            width={"fullWidth"}
                            placeholder="2000"
                            type="text"
                            label=""
                            value={
                              serviceItem?.translations?.[field_Language]
                                ?.limit || ""
                            }
                            onChange={(value) => {
                              //
                              const editedSevice = parsedPackageServices?.map(
                                (e) =>
                                  e.id === serviceItem.id
                                    ? {
                                        ...e,
                                        translations: {
                                          ...e.translations,
                                          [field_Language]: {
                                            ...e.translations?.[field_Language],
                                            limit: value,
                                          },
                                        },
                                      }
                                    : e
                              );
                              //
                              setTplPackageField(
                                "packageServices",
                                editedSevice
                              );
                            }}
                          />
                        </div>
                        <div>
                          <Input
                            id={`package-service-wait-period-${serviceIndex}`}
                            name={`package-service-wait-period-${serviceIndex}`}
                            width={"fullWidth"}
                            placeholder="12 თვე"
                            type="text"
                            label=""
                            value={
                              serviceItem?.translations?.[field_Language]
                                ?.waitPeriod || ""
                            }
                            onChange={(value) => {
                              //
                              const editedSevice = parsedPackageServices?.map(
                                (e) =>
                                  e.id === serviceItem.id
                                    ? {
                                        ...e,
                                        translations: {
                                          ...e.translations,
                                          [field_Language]: {
                                            ...e.translations?.[field_Language],
                                            waitPeriod: value,
                                          },
                                        },
                                      }
                                    : e
                              );
                              //
                              setTplPackageField(
                                "packageServices",
                                editedSevice
                              );
                            }}
                          />
                        </div>
                      </ServiceDiv>
                    )
                  )}
                </div>
              </div>
              {/*  */}
            </Block>

            <FranoHorizontalLine title={t("medicalServicesInfo")} />
            <Block>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "45%",
                    }}
                  >
                    {t("servicesTitle")}
                  </div>
                  <div
                    style={{
                      marginLeft: "-25px",
                      width: "10%",
                    }}
                  >
                    {`${t("yes")}/${t("no")}`}
                  </div>
                  <div
                    style={{
                      width: "40%",
                    }}
                  >
                    {t("comment")}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {petServices?.map((s, s_index) => (
                    <div
                      key={s_index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "45%",
                        }}
                      >
                        {s.translations?.[field_Language]?.name}
                      </div>
                      <div
                        style={{
                          width: "10%",
                        }}
                      >
                        <Checkbox
                          id={`package-${editedPackage.id}-service-${s_index}`}
                          checked={
                            parsedServices?.find((x) => x.id === s.id)
                              ? true
                              : false
                          }
                          onChange={(event) => {
                            if (parsedServices?.find((x) => x.id === s.id)) {
                              // remove
                              setTplPackageField("services", [
                                ...parsedServices?.filter((x) => x.id !== s.id),
                              ]);
                            } else {
                              // add
                              setTplPackageField("services", [
                                ...(parsedServices || []),
                                { id: s.id, translations: {} },
                              ]);
                            }
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "40%",
                        }}
                      >
                        <Input
                          id={`package-service-comment-${s_index}`}
                          name={`package-service-comment-${s_index}`}
                          width={"fullWidth"}
                          label=""
                          type="text"
                          placeholder=""
                          disabled={
                            parsedServices?.find((x) => x.id === s.id)
                              ? false
                              : true
                          }
                          value={
                            parsedServices?.find((x) => x.id === s.id)
                              ?.translations?.[field_Language]?.comment || ""
                          }
                          onChange={(value) => {
                            //
                            const editedSevice = parsedServices?.map((e) =>
                              e.id === s.id
                                ? {
                                    ...e,
                                    translations: {
                                      ...e.translations,
                                      [field_Language]: {
                                        ...e.translations?.[field_Language],
                                        comment: value,
                                      },
                                    },
                                  }
                                : e
                            );
                            //
                            setTplPackageField("services", editedSevice);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Block>

            {/*  */}
            <FranoHorizontalLine title={t("theMedicalServices")} />
            <h5>{t("coverages")}</h5>
            <CoverageBlock>
              {(editedCoverageTranslations &&
                editedCoverageTranslations?.length < 1) ||
              editedCoverageTranslations === undefined ? (
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    paddingBlock: "30px",
                  }}
                >
                  {t("added")} 0 {t("coverage")}
                </div>
              ) : (
                <>
                  {editedCoverageTranslations?.map((item, index) => (
                    <CoverageItem key={index}>
                      <Input
                        style={{
                          marginTop: "20px",
                          marginRight: "10px",
                        }}
                        id={`coverage-title-${index}`}
                        name="coverage-title"
                        label={`#${index + 1} ${t("coverageName")}`}
                        type="text"
                        placeholder=""
                        value={item?.[field_Language]?.title}
                        onChange={(value) => {
                          const oldBody = item?.[field_Language]?.body;
                          const listWithUpdatedTitle = (
                            editedCoverageTranslations ?? []
                          ).map((g, i) =>
                            i === index
                              ? {
                                  ...g,
                                  ...g.translations,
                                  [field_Language]: {
                                    ...g.translations?.[field_Language],
                                    title: value,
                                    body: oldBody,
                                  },
                                }
                              : g
                          );
                          setEditedCoverageTranslations(listWithUpdatedTitle);
                          setTplPackageField(
                            "coveragesTranslationsArray",
                            listWithUpdatedTitle
                          );
                        }}
                        width={"fullWidth"}
                      />
                      <Input
                        style={{
                          marginTop: "20px",
                        }}
                        id={`coverage-body-${index}`}
                        name="coverage-name"
                        label={`${t("description")}`}
                        type="text"
                        placeholder=""
                        value={item?.[field_Language]?.body}
                        onChange={(value) => {
                          const oldTitle = item?.[field_Language]?.title;
                          const listWithUpdatedBody = (
                            editedCoverageTranslations ?? []
                          ).map((g, i) =>
                            i === index
                              ? {
                                  ...g,
                                  ...g.translations,
                                  [field_Language]: {
                                    ...g.translations?.[field_Language],
                                    body: value,
                                    title: oldTitle,
                                  },
                                }
                              : g
                          );
                          // console.log(listWithUpdatedBody);

                          setEditedCoverageTranslations(listWithUpdatedBody);
                          setTplPackageField(
                            "coveragesTranslationsArray",
                            listWithUpdatedBody
                          );
                        }}
                        width={"fullWidth"}
                      />

                      <Button
                        style={{
                          marginLeft: "auto",
                        }}
                        btnStyleType="secondary"
                        size="medium"
                        icon={false}
                        fullWidth={false}
                        disabled={false}
                        onClick={() => {
                          setEditedCoverageTranslations(
                            editedCoverageTranslations!.filter(
                              (i, coverageIndex) => coverageIndex !== index
                            )
                          );
                          setTplPackageField(
                            "coveragesTranslationsArray",
                            editedCoverageTranslations!.filter(
                              (i, coverageIndex) => coverageIndex !== index
                            )
                          );
                        }}
                        text={t("delete")}
                      />
                    </CoverageItem>
                  ))}
                </>
              )}
            </CoverageBlock>
            <Button
              btnStyleType="primary"
              icon={false}
              disabled={false}
              style={{
                marginTop: "20px",
              }}
              onClick={() => {
                const newItem = {
                  translations: {
                    ge: {
                      title: " ",
                      body: " ",
                    },
                    en: {
                      title: " ",
                      body: " ",
                    },
                    ru: {
                      title: " ",
                      body: " ",
                    },
                  },
                } as TplCoverageTranslations;
                const listWithAddedItem: TplCoverageTranslations[] = [
                  ...(editedCoverageTranslations || []),
                  newItem.translations as TplCoverageTranslations,
                ];
                setEditedCoverageTranslations(listWithAddedItem);
                setTplPackageField(
                  "coveragesTranslationsArray",
                  listWithAddedItem
                );
              }}
              text={t("add")}
            />
            <br />
            <br />
            <h5>{t("importantTerms")}</h5>
            <CoverageBlock>
              {(editedImportantTermsTranslations &&
                editedImportantTermsTranslations.length < 1) ||
              editedImportantTermsTranslations === undefined ? (
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    paddingBlock: "30px",
                  }}
                >
                  {t("added")} 0 {t("term")}
                </div>
              ) : (
                <>
                  {editedImportantTermsTranslations?.map((item, index) => (
                    <CoverageItem key={index}>
                      <Input
                        style={{
                          marginTop: "20px",
                          marginRight: "10px",
                        }}
                        id={`term-title-${index}`}
                        name="term-title"
                        label={`#${index + 1} ${t("conditionTitle")}`}
                        type="text"
                        placeholder=""
                        value={item?.[field_Language]?.title}
                        onChange={(value) => {
                          const oldBody = item?.[field_Language]?.body;
                          const listWithUpdatedTitle = (
                            editedImportantTermsTranslations ?? []
                          ).map((g, i) =>
                            i === index
                              ? {
                                  ...g,
                                  ...g.translations,
                                  [field_Language]: {
                                    ...g.translations?.[field_Language],
                                    title: value,
                                    body: oldBody,
                                  },
                                }
                              : g
                          );
                          setEditedImportantTermsTranslations(
                            listWithUpdatedTitle
                          );
                          setTplPackageField(
                            "importantTermsTranslationsArray",
                            listWithUpdatedTitle
                          );
                        }}
                        width={"fullWidth"}
                      />
                      <Input
                        style={{
                          marginTop: "20px",
                        }}
                        id={`term-body-${index}`}
                        name="term-name"
                        label={t("description")}
                        type="text"
                        placeholder=""
                        value={item?.[field_Language]?.body}
                        onChange={(value) => {
                          const oldTitle = item?.[field_Language]?.title;
                          const listWithUpdatedBody = (
                            editedImportantTermsTranslations ?? []
                          ).map((g, i) =>
                            i === index
                              ? {
                                  ...g,
                                  ...g.translations,
                                  [field_Language]: {
                                    ...g.translations?.[field_Language],
                                    body: value,
                                    title: oldTitle,
                                  },
                                }
                              : g
                          );

                          setEditedImportantTermsTranslations(
                            listWithUpdatedBody
                          );
                          setTplPackageField(
                            "importantTermsTranslationsArray",
                            listWithUpdatedBody
                          );
                        }}
                        width={"fullWidth"}
                      />

                      <Button
                        style={{
                          marginLeft: "auto",
                        }}
                        btnStyleType="secondary"
                        size="medium"
                        icon={false}
                        fullWidth={false}
                        disabled={false}
                        onClick={() => {
                          setEditedImportantTermsTranslations(
                            editedImportantTermsTranslations!.filter(
                              (i, termIndex) => termIndex !== index
                            )
                          );
                          setTplPackageField(
                            "importantTermsTranslationsArray",
                            editedImportantTermsTranslations!.filter(
                              (i, termIndex) => termIndex !== index
                            )
                          );
                        }}
                        text={t("delete")}
                      />
                    </CoverageItem>
                  ))}
                </>
              )}
            </CoverageBlock>
            <Button
              btnStyleType="primary"
              icon={false}
              disabled={false}
              style={{
                marginTop: "20px",
              }}
              onClick={() => {
                const newItem = {
                  translations: {
                    ge: {
                      title: " ",
                      body: " ",
                    },
                    en: {
                      title: " ",
                      body: " ",
                    },
                    ru: {
                      title: " ",
                      body: " ",
                    },
                  },
                } as TplImportantTermTranslations;
                const listWithAddedItem: TplImportantTermTranslations[] = [
                  ...(editedImportantTermsTranslations || []),
                  newItem.translations as TplImportantTermTranslations,
                ];
                setEditedImportantTermsTranslations(listWithAddedItem);
                setTplPackageField(
                  "importantTermsTranslationsArray",
                  listWithAddedItem
                );
              }}
              text={t("add")}
            />
            {/*  */}
            <FranoHorizontalLine title={t("paymentTerms")} />
            <FormGroup>
              <span style={{ fontWeight: "700" }}>{`${t(
                "paymentType"
              )}:`}</span>
              {paymentSchedules
                ?.filter((paymentSchedule) => paymentSchedule.isActive)
                .map((paymentSchedule) => (
                  <FormControlLabel
                    key={paymentSchedule.id}
                    control={
                      <Checkbox
                        id={`package-${editedPackage.id}-currency-${paymentSchedule}`}
                        checked={
                          parsedScheduleArray?.includes(paymentSchedule.id) ??
                          false
                        }
                        onChange={(event) => {
                          const checked = event?.target.checked;
                          const newPaymentScheduleList = [
                            ...(parsedScheduleArray || []).filter(
                              (a) => a !== paymentSchedule.id
                            ),
                            ...(checked ? [paymentSchedule.id] : []),
                          ];

                          setMainPackageField(
                            "paymentScheduleTypeIds",
                            newPaymentScheduleList as number[]
                          );
                          setUsedPaymentSchedules(
                            editedPackage.id,
                            newPaymentScheduleList
                          );
                        }}
                      />
                    }
                    label={
                      paymentSchedule?.translations?.[field_Language]?.title ||
                      ""
                    }
                  />
                ))}
            </FormGroup>
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            <FranoHorizontalLine title={t("policyWording")} />
            <DocumentsWrapper
              style={{
                paddingTop: "0px",
              }}
            >
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                {/* <Column $widthPercent={50}>
                  <div>
                    <DocumentBlockTitle>
                      წესების და პირობების ფაილი
                    </DocumentBlockTitle>
                    <DocumentFieldUploaderBlock
                      url={editedPackage.mainPackage.conditionFileUrl}
                      onChange={(newUrl) =>
                        setMainPackageField("conditionFileUrl", newUrl)
                      }
                      width={275}
                    />
                  </div>
                </Column> */}

                <Column $widthPercent={50}>
                  <DocumentBlockTitle>
                    {t("wordingFile")} (GE)
                  </DocumentBlockTitle>
                  <DocumentFieldUploaderBlock
                    url={parsedWordingFiles?.translations?.ge?.url || ""}
                    onChange={(newUrl) =>
                      setMainPackageField("wordingFiles", {
                        ...parsedWordingFiles,
                        translations: {
                          ...parsedWordingFiles?.translations,
                          ge: {
                            url: newUrl,
                          },
                        },
                      })
                    }
                    width={275}
                  />
                </Column>
                <Column $widthPercent={50}>
                  <DocumentBlockTitle>
                    {t("wordingFile")} (EN)
                  </DocumentBlockTitle>
                  <DocumentFieldUploaderBlock
                    url={parsedWordingFiles?.translations?.en?.url || ""}
                    onChange={(newUrl) =>
                      setMainPackageField("wordingFiles", {
                        ...parsedWordingFiles,
                        translations: {
                          ...parsedWordingFiles?.translations,
                          en: {
                            url: newUrl,
                          },
                        },
                      })
                    }
                    width={275}
                  />
                </Column>
              </Row>
              {/* <Button
              btnStyleType="primary"
              icon={false}
              fullWidth={true}
              disabled={false}
              onClick={saveTab}
              text={'შენახვა'}
            /> */}
            </DocumentsWrapper>

            <FranoHorizontalLine title={t("policyFormTemplate")} />
            <DocumentsWrapper
              style={{
                paddingTop: "0px",
              }}
            >
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Column>
                  <DocumentBlockTitle>{t("monthly")}</DocumentBlockTitle>
                  <DocumentFieldUploaderBlock
                    url={
                      parsedPolicyFiles?.find((x) => x.type === "MONTHLY")
                        ?.url || ""
                    }
                    onChange={(newUrl) => {
                      const initFiles = parsedPolicyFiles || [];
                      //
                      const edited = () => {
                        const newArray = [...initFiles];
                        //
                        const oneIndex = newArray.findIndex(
                          (item) => item.type === "MONTHLY"
                        );
                        if (oneIndex !== -1) {
                          newArray[oneIndex] = {
                            ...newArray[oneIndex],
                            url: newUrl,
                          };
                        } else {
                          newArray.push({ type: "MONTHLY", url: newUrl });
                        }
                        //
                        return newArray;
                      };
                      console.log("newArray", edited());
                      //
                      setMainPackageField("policyFiles", edited());
                    }}
                    width={275}
                  />
                </Column>

                <Column>
                  <DocumentBlockTitle>პირველ თვეში ორი</DocumentBlockTitle>
                  <DocumentFieldUploaderBlock
                    url={
                      parsedPolicyFiles?.find(
                        (x) => x.type === "MONTHLY-FIRST-TWO"
                      )?.url || ""
                    }
                    onChange={(newUrl) => {
                      const initFiles = parsedPolicyFiles || [];
                      //
                      const edited = () => {
                        const newArray = [...initFiles];
                        //
                        const oneIndex = newArray.findIndex(
                          (item) => item.type === "MONTHLY-FIRST-TWO"
                        );
                        if (oneIndex !== -1) {
                          newArray[oneIndex] = {
                            ...newArray[oneIndex],
                            url: newUrl,
                          };
                        } else {
                          newArray.push({
                            type: "MONTHLY-FIRST-TWO",
                            url: newUrl,
                          });
                        }
                        //
                        return newArray;
                      };
                      console.log("newArray", edited());
                      //
                      setMainPackageField("policyFiles", edited());
                    }}
                    width={275}
                  />
                </Column>

                <Column>
                  <DocumentBlockTitle>{t("single")}</DocumentBlockTitle>
                  <DocumentFieldUploaderBlock
                    url={
                      parsedPolicyFiles?.find((x) => x.type === "ONE-TIME")
                        ?.url || ""
                    }
                    onChange={(newUrl) => {
                      const initFiles = parsedPolicyFiles || [];
                      //
                      const edited = () => {
                        const newArray = [...initFiles];
                        //
                        const oneIndex = newArray.findIndex(
                          (item) => item.type === "ONE-TIME"
                        );
                        if (oneIndex !== -1) {
                          newArray[oneIndex] = {
                            ...newArray[oneIndex],
                            url: newUrl,
                          };
                        } else {
                          newArray.push({ type: "ONE-TIME", url: newUrl });
                        }
                        //
                        return newArray;
                      };
                      //
                      setMainPackageField("policyFiles", edited());
                    }}
                    width={275}
                  />
                </Column>
                {/*  */}
              </Row>
            </DocumentsWrapper>
          </Column>

          <Column $widthPercent={30}>
            <Block>
              {isLoading ? (
                `${t("loading")}`
              ) : (
                <Button
                  btnStyleType="primary"
                  fullWidth={true}
                  icon={false}
                  onClick={() => saveTplPackage()}
                  text={t("save")}
                />
              )}
            </Block>
            <br />
            <Block>
              {isLoading ? (
                `${t("loading")}`
              ) : (
                <Button
                  btnStyleType="secondary"
                  fullWidth={true}
                  icon={false}
                  onClick={() => deleteTplPackage()}
                  text={t("deletePackage")}
                />
              )}
            </Block>
            <br />
            {editedPackage.mainPackage.createdAt ? (
              <Block>
                <Text>{t("createdDate")}:</Text>
                <Text>
                  {dayjs(editedPackage.mainPackage.createdAt).format(
                    "DD MMMM YYYY HH:mm"
                  )}
                </Text>
              </Block>
            ) : null}
            {editedPackage.mainPackage.updatedAt ? (
              <Block>
                <Text>{t("lastUpdatedAt")}:</Text>
                <Text>
                  {dayjs(editedPackage.mainPackage.updatedAt).format(
                    "DD MMMM YYYY HH:mm"
                  )}
                </Text>
              </Block>
            ) : null}
            {/* if approved */}
            {/* if approved, activationDate MUST be present */}
            {editedPackage.mainPackage.approvedByAdmin ? (
              <Block>
                <Text>{t("lastPublishedAt")}:</Text>
                <Text>
                  {editedPackage.mainPackage.activatedDate
                    ? dayjs(editedPackage.mainPackage.activatedDate).format(
                        "DD MMMM YYYY HH:mm"
                      )
                    : `${t("notFound")}`}
                </Text>
              </Block>
            ) : null}
            {/* if NOT approved */}
            {/* if NOT approved, two cases: 1) never rejected before, we have no deactivation date */}{" "}
            {/* 2) rejected recently, and we have deactiation date */}
            {/* that's why we don't */}
            {!editedPackage.mainPackage.approvedByAdmin ? (
              editedPackage.mainPackage.deactivatedDate ? (
                <Block>
                  <Text>{t("lastRejectedAt")}:</Text>
                  <Text>
                    {dayjs(editedPackage.mainPackage.deactivatedDate).format(
                      "DD MMMM YYYY HH:mm"
                    )}
                  </Text>
                </Block>
              ) : (
                <Block>
                  <Text style={{ flexBasis: "100%" }}>
                    {t("waitingAdminReview")}
                  </Text>
                </Block>
              )
            ) : null}
            <br />
            <Block>
              <Text>{t("status")}:</Text>
              <Text>{editedPackage.mainPackage.status}</Text>
            </Block>
            {isAdmin && editedPackage.mainPackage.status === "draft" ? (
              <Button
                btnStyleType="primary"
                icon={false}
                fullWidth={true}
                style={{ marginBottom: 16 }}
                disabled={false}
                onClick={approvePackageChanges}
                text={t("publish")}
              />
            ) : null}
            {isAdmin ? (
              <Button
                btnStyleType="primary"
                icon={false}
                fullWidth={true}
                style={{ marginBottom: 16 }}
                disabled={false}
                onClick={rejectPackageChanges}
                text={
                  editedPackage.mainPackage.status === "draft"
                    ? `${t("rejectChanges")}`
                    : `${t("moveToDraft")}`
                }
              />
            ) : null}
            {isAdmin ? (
              <Button
                btnStyleType="primary"
                icon={false}
                fullWidth={true}
                style={{ marginBottom: 16 }}
                disabled={false}
                onClick={copyProduct}
                text={t("copy")}
              />
            ) : null}
            {/* {editedPackage.mainPackage.activatedDate ? (
            <Block>
              <Text>გააქტიურდა:</Text>
              <Text>{dayjs(editedPackage.mainPackage.activatedDate).format('DD MMMM YYYY HH:mm')}</Text>
            </Block>
          ) : null}
          {editedPackage.mainPackage.deactivatedDate ? (
            <Block>
              <Text>დასადასტურებლად გაიგზავნა:</Text>
              <Text>{dayjs(editedPackage.mainPackage.deactivatedDate).format('DD MMMM YYYY HH:mm')}</Text>
            </Block>
          ) : null} */}
          </Column>
        </Row>
      </Accordion>
    </Wrapper>
  );
};
//
//
//
// styles
const ServiceDiv = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  gap: 10px;
  align-items: center;

  div {
    margin-bottom: 10px;
  }
`;
const CustomHorizontalLine = styled.div`
  margin-block: 20px;
  padding-block: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: all 200ms ease;
    height: 25px;
    width: 55px;
    min-width: 55px;
  }
  span {
    white-space: nowrap;
    font-weight: bold;
    color: ${Colors.primaryBlack};
  }
  div {
    height: 2px;
    width: 100%;
    background-color: ${Colors.primaryRed};
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
  /* hover */
  &:hover {
    svg {
      transition: all 200ms ease;
      transform: scale(1.05);
    }
  }
`;
const Wrapper = styled.div`
  .MuiSvgIcon-fontSizeMedium {
    color: ${Colors.primaryRed};
  }
  .MuiFormControlLabel-label {
    font-family: inherit;
  }
  .MuiFormLabel-root {
    font-family: inherit;
    font-weight: 600;
  }
  .admin-addons-accoridon {
    .accordion-head-title {
      color: ${Colors.primaryRed};
      font-weight: bold;
    }
    .accordion-head-title ~ svg {
      color: ${Colors.primaryRed};
    }
  }
`;
const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid ${Colors.primaryGrey};
  padding-bottom: 10px;
  margin-bottom: 10px; */
  /*  */
  /* > div {
    flex-basis: 50%;
  } */
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  ${Responsive.tablet} {
    flex-direction: column;
  }
`;

const Column = styled.div<{ $widthPercent?: number }>`
  padding: 20px 15px;
  ${({ $widthPercent = 50 }) =>
    $widthPercent ? `width: calc(${$widthPercent}% - 15px);` : ""}
  ${Responsive.tablet} {
    width: 100%;
  }
`;
const Text = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
  color: ${Colors.primaryBlack};
`;
const DocumentsWrapper = styled.div`
  padding: 20px 15px;
  margin-bottom: 50px;
`;
const DocumentBlockTitle = styled.h5`
  /* min-height: 55px; */
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  font-size: 18px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
`;
const CoverageItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const CoverageBlock = styled.div`
  max-height: 450px;
  overflow-y: scroll;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 2px;
  margin-top: 20px;
`;
const CustomBlock = styled(Block)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  div {
    width: 100%;
    /* width: 475px; */
  }

  /* ${Responsive.laptop} {
    div {
      width: 330px;
    }
  }
  ${Responsive.tablet} {
    flex-direction: row;
    div {
      width: 300px;
    }
  } */
`;
const areEqual = (prevProps: Props, nextProps: Props) => {
  if (prevProps.tplPackageId === nextProps.tplPackageId) {
    return true; // do NOT re-render
  } else {
    return false; // will re-render
  }
};

export default React.memo(PetPackageEditor, areEqual);
