import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import CustomSelect from "../../../components/CustomSelect";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import UploadFile from "../../../components/UploadFile";
import BaseModal from "../../../components/modals/BaseModal";
import { EnabledLanguage, TravelAddon } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import Button from "../../admin_components/AdminButton";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store/appSlice";
//
//
type AddTravelAddonTranslatedFields = {
  title: string;
  body: string;
};
//
//
const AdminAddons = () => {
  //
  const { t } = useTranslation();
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  ); 
  //
  // selected addon id
  const [travelAddonId, setTravelAddonId] = useState<number | null>(null);
  //
  // states
  const [openTravelAddModal, setOpenTravelAddModal] = useState<boolean>(false);
  //
  // add travel addon state
  const [addTravelAddonState, setAddTravelAddonState] = useState<
    Partial<TravelAddon>
  >({});
  //
  //
  const [defaultSelectedCategory, setDefaultSelectedCategory] = useState<
    string | null
  >(null);
  //
  // get travel addons
  const {
    data: travelAddons,
    isLoading: isTravelAddonsLoading,
    refetch: refetchTravelAddons,
  } = api.useGetAdminTravelAbroadAddonListQuery(undefined);
  //
  //
  // get admin product category list
  const { data: productCategoryList, isLoading: isProductCategoryListLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);
  //
  // add travel addon
  const [saveTravelAddon, { isLoading: isAddTravelAddonLoading }] =
    api.useSaveTravelAddonMutation();
  //
  // remvoe travel addon
  const [
    removeTravelAddon,
    {
      isLoading: isRemoveTravelAddonLoading,
      isSuccess: removeTravelAddonSucces,
    },
  ] = api.useRemoveTravelAddonMutation();
  //
  //
  //close add trave addon modal
  const _onAddTravelAddonCloseModal = () => {
    setOpenTravelAddModal(false);
    setTravelAddonId(null);
    setDefaultSelectedCategory(null);
  };
  //
  //
  // add travel addon fields
  const setAddTravelAddon = (field: keyof TravelAddon, value: any) => {
    setAddTravelAddonState({
      ...addTravelAddonState,
      [field]: value,
    });
  };
  //
  //
  const { data: existingTravelAddon, isLoading: isExistingTravelAddonLoading } =
    api.useGetAdminTravelAddonByIdQuery(travelAddonId as number, {
      skip: true && !travelAddonId,
    });
  //
  //
  //
  // add travel translation fields
  const setAddTravelAddonTranslatedField = (
    field: keyof AddTravelAddonTranslatedFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddTravelAddonState({
      ...addTravelAddonState,
      translations: {
        ...addTravelAddonState.translations,
        [language]: {
          ...addTravelAddonState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  // add travel Addon function
  const AddTravelAddonFunction = async () => {
    //
    try {
      await saveTravelAddon(addTravelAddonState).unwrap();
      //
      // refetch travel Addons
      refetchTravelAddons();
      // close modal
      _onAddTravelAddonCloseModal();
      //
      toast.success(`${t("additionalCoverages")} ${t("addedSuccessfully")}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  //
  // auto refetch travel addons when user remove addon
  useEffect(() => {
    if (removeTravelAddonSucces) {
      refetchTravelAddons();
      _onAddTravelAddonCloseModal();
    }
  }, [removeTravelAddon, removeTravelAddonSucces, refetchTravelAddons]);
  //
  //
  //
  useEffect(() => {
    if (existingTravelAddon) {
      setAddTravelAddonState(existingTravelAddon);
    }
  }, [existingTravelAddon]);
  //
  //
  //
  // default category
  useEffect(() => {
    if (productCategoryList && addTravelAddonState) {
      const category = productCategoryList?.find(
        (x) => x.id === addTravelAddonState.productCategoryId
      )?.title;
      //
      if (category) {
        setDefaultSelectedCategory(category);
      }
    }
  }, [productCategoryList, addTravelAddonState]);

  //
  //
  const MainLoading =
    isTravelAddonsLoading ||
    isAddTravelAddonLoading ||
    isRemoveTravelAddonLoading ||
    isExistingTravelAddonLoading ||
    isProductCategoryListLoading;
  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        btnStyleType="primary"
        text={t("createAddons")}
        onClick={() => [
          setOpenTravelAddModal(!openTravelAddModal),
          setAddTravelAddonState({}),
        ]}
      />
      <br />
      <br />
      <AddonContent>
        {travelAddons &&
          travelAddons.map((item, index) => (
            <AddonBox
              key={index}
              onClick={() => [
                setTravelAddonId(item.id),
                setOpenTravelAddModal(!openTravelAddModal),
              ]}
            >
              <img src={item.image} alt={`addon icon`} />
              <div>{item.translations[lang]?.title}</div>
              <p>{item.translations[lang]?.body}</p>
            </AddonBox>
          ))}
      </AddonContent>
      {/*  */}
      {/* add new travel addon */}
      <BaseModal
        onClose={_onAddTravelAddonCloseModal}
        isOpen={openTravelAddModal}
      >
        <div>
          <ModalHead>
            <h4>{travelAddonId ? t("update") : t("create")}</h4>
            <AdminLangSwicher />
          </ModalHead>
          {/* productCategoryList */}

          {((productCategoryList && defaultSelectedCategory && travelAddonId) ||
            (productCategoryList && !travelAddonId)) && (
            <CustomSelect
              placeholder=""
              defaultInputValue={defaultSelectedCategory || ""}
              headText={t("productCategory")}
              onChange={(event) =>
                setAddTravelAddon("productCategoryId", event.value)
              }
              data={[
                ...productCategoryList.map((item) => ({
                  value: item.id,
                  label: item.title,
                  isDisabled: item.id === 1 ? false : true,
                })),
              ]}
            />
          )}

          <FieldsContent>
            <Input
              id="addon-slug"
              label={t("slug")}
              type="text"
              placeholder=""
              value={addTravelAddonState.slug || ""}
              name="addon-slug"
              onChange={(value) => setAddTravelAddon("slug", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="addon-title"
              label={t("title")}
              type="text"
              placeholder=""
              value={addTravelAddonState?.translations?.[field_Language]?.title || ""}
              name="addon-title"
              onChange={(value) =>
                setAddTravelAddonTranslatedField("title", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="addon-body"
              label={t("description")}
              type="text"
              placeholder=""
              value={addTravelAddonState?.translations?.[field_Language]?.body || ""}
              name="addon-body"
              onChange={(value) =>
                setAddTravelAddonTranslatedField("body", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <UploadFile
              label={t("icon")}
              onChange={(props) => setAddTravelAddon("image", props.url)}
              defaultFileName={addTravelAddonState?.image || ""}
            />

            <ButtonsBlock>
              {/* add travel addon */}
              <Button
                fullWidth={true}
                btnStyleType="primary"
                text={travelAddonId ? t("update") : t("create")}
                onClick={AddTravelAddonFunction}
              />
              {/* add travel addon */}
              <br />
              {/* remove travel addon */}
              {travelAddonId && (
                <Button
                  fullWidth={true}
                  btnStyleType="transparent"
                  border={true}
                  text={t("delete")}
                  onClick={() =>
                    window.confirm(`${t("certainToDeleteIt")}`) &&
                    removeTravelAddon(travelAddonId)
                  }
                />
              )}
              {/* remove travel addon */}
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
      {/* add new travel addon */}
    </Wrapper>
  );
};
//
// styles
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 20px;
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.grey};
`;
const AddonBox = styled.div`
  height: 200px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
  /*  */
  img {
    width: 60px;
  }
  /*  */
  div {
    font-size: 18px;
    margin-block: 10px;
    text-align: center;
  }
  /*  */
  p {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
  }
`;
const AddonContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  /*  */
`;
const Wrapper = styled.div``;
//
export default AdminAddons;
