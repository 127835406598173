import React, { useState } from "react";
import styled from "styled-components";
import { FullScreen } from "../../../assets/styles/GlobalStyle";

// MUI
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { MediumScreenTitle } from "./../../../assets/styles/GlobalStyle";

// stepper steps
const steps = ["step 1", "step 2", "step 3"];

const Admin_tpl_insurance = () => {
  const [activeStep, setActiveStep] = useState<number>(0);


  return (
    <FullScreen className="container">
      <MediumScreenTitle>TPL Insurance</MediumScreenTitle>
      <Wrapper>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {/* step 1 content */}
        <StepOneWrapper
          style={{ display: activeStep === 0 ? "block" : "none" }}
        >
          step 1 content
        </StepOneWrapper>
        {/* step 1 content */}

        {/* step 2 content */}
        <StepTwoWrapper
          style={{ display: activeStep === 1 ? "block" : "none" }}
        >
          step 2 content
        </StepTwoWrapper>
        {/* step 2 content */}

        {/* step 3 content */}
        <StepThreeWrapper
          style={{ display: activeStep === 2 ? "block" : "none" }}
        >
          step 3 content
        </StepThreeWrapper>
        {/* step 3 content */}

        <ButtonsWrapper>
          {activeStep === 0 ? null : (
            <button
              disabled={activeStep === 0 ? true : false}
              onClick={() => setActiveStep(activeStep - 1)}
            >
              prev step
            </button>
          )}

          {activeStep === 2 ? null : (
            <button
              disabled={activeStep === 4 ? true : false}
              onClick={() => setActiveStep(activeStep + 1)}
            >
              next step
            </button>
          )}

          {activeStep === 2 ? (
            <button
              disabled={activeStep !== 2 ? true : false}
              onClick={() => alert("form has been sent")}
            >
              submit
            </button>
          ) : null}
        </ButtonsWrapper>
      </Wrapper>
    </FullScreen>
  );
};

const Wrapper = styled.div``;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
`;
const StepContentWrapper = styled.div`
  border: 2px dashed black;
  border-radius: 10px;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 20px;
  min-height: 50vh;
`;

const StepOneWrapper = styled(StepContentWrapper)``;
const StepTwoWrapper = styled(StepContentWrapper)``;
const StepThreeWrapper = styled(StepContentWrapper)``;

export default Admin_tpl_insurance;
