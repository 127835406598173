import React, { useEffect, useState } from "react";
import {
  EnabledLanguage,
  Product,
  WelcomerProduct,
} from "../../../../../domain/types";
import Loader from "../../../../../components/Loader";
import styled from "styled-components";
import Responsive from "../../../../../assets/config/Responsive";
import Colors from "../../../../../assets/styles/Colors";
import Input from "../../../../admin_components/AdminInput";
import {
  default as AdminButton,
  default as Button,
} from "../../../../admin_components/AdminButton";
import { toast } from "react-toastify";
import CloseIcon from "../../../../../assets/icons/JSX/CloseIcon";
import Checkbox from "../../../../../components/Checkbox";
import Col from "react-bootstrap/esm/Col";
import BaseModal from "../../../../../components/modals/BaseModal";
import { useAppSelector } from "../../../../../store";
import dayjs from "dayjs";
import NotFound404 from "../../../../../components/NotFound404";
import { useTranslation } from "react-i18next";

const EditWelcomerProductFieldsTab: React.FC<{
  welcomerProduct?: WelcomerProduct;
  language: EnabledLanguage;
  isLoading: boolean;
  setTranslatedFieldOnProduct: any;
  setWelcomerProductField: (
    fieldName: keyof WelcomerProduct,
    fieldValue: any
  ) => void;
  setMainProductField: (fieldName: keyof Product, fieldValue: any) => void;
  saveTab: any;
  approveProductChanges: any;
  rejectProductChanges: any;
  saveWelcomerAgeGroupsFun: any;
  deleteWelcomerAgeGroups: any;
  saveWelcomerDayGroupsFun: any;
  deleteWelcomerDayGroups: any;
}> = ({
  welcomerProduct,
  language,
  setTranslatedFieldOnProduct,
  setWelcomerProductField,
  setMainProductField,
  isLoading,
  saveTab,
  approveProductChanges,
  rejectProductChanges,
  saveWelcomerAgeGroupsFun,
  deleteWelcomerAgeGroups,
  saveWelcomerDayGroupsFun,
  deleteWelcomerDayGroups,
}) => {
  //
  const { t } = useTranslation();
  //
  const auth = useAppSelector((data) => data.auth);
  const isAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";
  //
  const [warningAlertRange, setWarningAlertRange] = useState<string | number>(
    ""
  );
  //
  const [emailsOfCompanyUsers, setEmailsOfCompanyUsers] = useState<
    string[] | []
  >(welcomerProduct?.mainProduct?.emails || []);
  //
  const [enteredEmail, setEnteredEmail] = useState<string | undefined>("");
  //
  //
  // set exist company user

  const removeLeadingZeros = (input: string) => {
    const trimmedString = input.replace(/^0+/, "");
    const numberValue = parseInt(trimmedString, 10);

    return numberValue;
  };

  useEffect(() => {
    if (welcomerProduct?.mainProduct?.emails) {
      setEmailsOfCompanyUsers(welcomerProduct?.mainProduct?.emails);
    }
    //
    return () => {};
  }, [welcomerProduct?.mainProduct?.emails]);
  //
  //
  useEffect(() => {
    if (emailsOfCompanyUsers) {
      setMainProductField("emails", emailsOfCompanyUsers);
    }
    //
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsOfCompanyUsers]);
  //
  //
  const [openWarningRangeModal, setOpenWarningRangeModal] = useState<{
    isOpen: boolean;
    index: number;
  }>({ isOpen: false, index: 0 });
  //
  //
  const _CloseWarningRangeModal = (isOpen: boolean, index: number) => {
    setOpenWarningRangeModal({ isOpen, index });
  };
  //
  //

  return isLoading ? (
    <Loader />
  ) : !welcomerProduct ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <ContentBox>
        <Column style={{ padding: "20px 10px 20px 20px" }} $widthPercent={70}>
          <div>
            <h6>{t("companyUserEmails")}</h6>
            <div
              style={{
                position: "relative",
              }}
            >
              <Input
                id="email"
                label={""}
                type="email"
                placeholder="example@gmail.com"
                value={enteredEmail}
                name="email"
                onChange={(value) => setEnteredEmail(value)}
                width={"fullWidth"}
                style={{ marginBottom: "24px" }}
              />

              <AdminButton
                style={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
                size="medium"
                text={t("add")}
                fullWidth={false}
                btnStyleType="primary"
                onClick={() => {
                  if (
                    enteredEmail &&
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      enteredEmail
                    )
                  ) {
                    setEmailsOfCompanyUsers((prevNames) => [
                      ...prevNames,
                      enteredEmail,
                    ]);
                    //
                    setEnteredEmail("");
                    //
                  } else {
                    toast.error(`${t('validEmail')}`);
                  }
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {emailsOfCompanyUsers?.map(
                (email_item: string, email_index: number) => {
                  return (
                    <div
                      style={{
                        padding: "12px",
                        margin: "10px",
                        backgroundColor: Colors.Sazerac,
                        borderRadius: "6px",
                        position: "relative",
                      }}
                      key={email_index}
                    >
                      <span>{email_item}</span>
                      <CloseIcon
                        onClick={() =>
                          emailsOfCompanyUsers && [
                            setEmailsOfCompanyUsers(
                              emailsOfCompanyUsers.filter(
                                (x) => x !== email_item
                              )
                            ),
                          ]
                        }
                        style={{
                          color: Colors.primaryRed,
                          height: "17px",
                          width: "17px",
                          position: "absolute",
                          top: "-6px",
                          right: "-6px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  );
                }
              )}
            </div>
            <br />
          </div>

          <br />

          <Checkbox
            id="integrate-product"
            label={t("integrated")}
            defaultChecked={welcomerProduct?.mainProduct?.integrated}
            onChange={(checked) => {
              if (checked === true) {
                setMainProductField("integrated", true);
              } else {
                setMainProductField("integrated", false);
              }
            }}
          />

          <br />
          <Row>
            <Column $widthPercent={50} style={{ padding: "0px" }}>
              <Block>
                {/* <Text>დასახელება</Text> */}
                <Input
                  id=""
                  label={t("title")}
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  value={
                    welcomerProduct?.mainProduct.translations?.[language]
                      ?.title || ""
                  }
                  required={false}
                  error={false}
                  onChange={(value) =>
                    setTranslatedFieldOnProduct("title", language, value)
                  }
                />
              </Block>
            </Column>
            <Column $widthPercent={50} style={{ padding: "0px" }}>
              <Block>
                {/* <Text>პროდუქტის ტიპი</Text> */}
                <Input
                  id=""
                  label={t("productType")}
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  value={t("welcomerInsurance")}
                  required={false}
                  error={false}
                  onChange={(value) => {}}
                  disabled={true}
                />
              </Block>
            </Column>
          </Row>
          <Block>
            <Title>{t("ageGroups")}</Title>
            <AgeGroupDiv>
              <Row>
                <Col>
                  <Text>{t("fromAge")}</Text>
                </Col>
                <Col>
                  <Text>{t("upToAge")}</Text>
                </Col>
                <Col>
                  <Text>{t("minPremiumInsured")}</Text>
                </Col>
              </Row>
              <AgeGroupScrollView>
                {welcomerProduct?.welcomerAgeGroup?.map((info, infoIndex) => (
                  <AgeGroupBlock key={infoIndex}>
                    <Row>
                      <Col>
                        <Input
                          id={`welcomerAgeGrpup-${infoIndex}-ageFrom`}
                          width="fullWidth"
                          length={3}
                          // label="წლის ასაკიდან (ჩათვლით)"
                          label=""
                          value={info.ageFrom}
                          // style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                          onChange={(value) => {
                            const listWithUpdatedMinAge =
                              welcomerProduct?.welcomerAgeGroup?.map((g, i) =>
                                i === infoIndex ? { ...g, ageFrom: value } : g
                              );
                            setWelcomerProductField(
                              "welcomerAgeGroup",
                              listWithUpdatedMinAge
                            );
                          }}
                        />
                      </Col>
                      <Col>
                        <Input
                          id={`welcomerAgeGroup-${infoIndex}-ageTo`}
                          width="fullWidth"
                          length={3}
                          // label="წლამდე (ჩათვლით)"
                          label=""
                          value={info.ageTo}
                          // style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                          onChange={(value) => {
                            const listWithUpdatedMaxAge =
                              welcomerProduct?.welcomerAgeGroup?.map((g, i) =>
                                i === infoIndex ? { ...g, ageTo: value } : g
                              );
                            setWelcomerProductField(
                              "welcomerAgeGroup",
                              listWithUpdatedMaxAge
                            );
                          }}
                        />
                      </Col>
                      <Col>
                        <div style={{ display: "flex", paddingRight: "5px" }}>
                          <Input
                            id={`welcomerAgeGroup-${infoIndex}-minTotalPremium`}
                            width="fullWidth"
                            length={3}
                            // label="GEL: მინიმალური პრემია 1 დაზღვეულზე"
                            label=""
                            value={info.minPremium}
                            // style={{ display: 'inline-flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                            onChange={(value) => {
                              const listWithUpdatedMinTotalPremium =
                                welcomerProduct?.welcomerAgeGroup?.map((g, i) =>
                                  i === infoIndex
                                    ? { ...g, minPremium: value }
                                    : g
                                );
                              setWelcomerProductField(
                                "welcomerAgeGroup",
                                listWithUpdatedMinTotalPremium
                              );
                            }}
                          />

                          <Button
                            btnStyleType="secondary"
                            icon={false}
                            fullWidth={false}
                            disabled={false}
                            // style={{ display: 'inline-block' }}
                            onClick={() => {
                              if (info.id === undefined) {
                                setWelcomerProductField(
                                  "welcomerAgeGroup",
                                  welcomerProduct?.welcomerAgeGroup?.filter(
                                    (g, i) => i !== infoIndex
                                  )
                                );
                              } else {
                                deleteWelcomerAgeGroups(info.id);
                              }
                            }}
                            text={t("delete")}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <AgeInputBlock></AgeInputBlock> */}
                  </AgeGroupBlock>
                ))}
              </AgeGroupScrollView>
              <br />
              <ButtonDiv>
                <Button
                  btnStyleType="secondary"
                  size="medium"
                  icon={false}
                  fullWidth={true}
                  disabled={false}
                  onClick={() => {
                    const maxUsedAge =
                      welcomerProduct?.welcomerAgeGroup?.reduce(
                        (c, n) => Math.max(c, n.ageFrom),
                        0
                      );
                    const newMinAge = maxUsedAge ? maxUsedAge + 1 : 1;
                    const listWithAddedItem = [
                      //@ts-ignore
                      ...welcomerProduct.welcomerAgeGroup,
                      {
                        ageFrom: newMinAge,
                        ageTo: newMinAge + 1,
                        minPremium: 0,
                      },
                    ];
                    setWelcomerProductField(
                      "welcomerAgeGroup",
                      listWithAddedItem
                    );
                  }}
                  text={t("add")}
                />
                <Button
                  btnStyleType="secondary"
                  size="medium"
                  icon={false}
                  fullWidth={true}
                  disabled={false}
                  onClick={saveWelcomerAgeGroupsFun}
                  text={t("save")}
                />
              </ButtonDiv>
            </AgeGroupDiv>
          </Block>
          <Block>
            <Title>{t("policyNumber")}</Title>
            <PolicyNumberGroupDiv>
              <Row>
                <Col>
                  <Text>{`(${t("prefix")})`}</Text>
                </Col>
                <Col>
                  <Text>{t("from")}</Text>
                </Col>
                <Col>
                  <Text>{t("to")}</Text>
                </Col>
                <Col>
                  <Text>{`(${t("suffix")})`}</Text>
                </Col>
                <Col>
                  <Text>{t("utilized")}</Text>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <PolicyNumberGroupScrollView>
                {welcomerProduct?.mainProduct.policyNumberRanges.map(
                  (range, rangeIndex) => (
                    <PolicyNumberBlock key={range.id}>
                      {/*  alert range modal */}
                      <BaseModal
                        isOpen={
                          openWarningRangeModal.isOpen === true &&
                          openWarningRangeModal.index === rangeIndex
                            ? true
                            : false
                        }
                        onClose={() =>
                          _CloseWarningRangeModal(false, rangeIndex)
                        }
                      >
                        {/*  */}
                        <WarningAlertRanges>
                          {range?.warningAlertRanges?.map(
                            (warningRange, warningRangeIndex) => (
                              <WarningRangeDiv key={warningRangeIndex}>
                                <span>{warningRange}</span>
                                <button
                                  onClick={() => {
                                    const listWithUpdatedPolicyWarningAlertRange =
                                      welcomerProduct?.mainProduct.policyNumberRanges.map(
                                        (g, i) =>
                                          i === rangeIndex
                                            ? {
                                                ...g,
                                                warningAlertRanges:
                                                  g.warningAlertRanges.filter(
                                                    (x) => x !== warningRange
                                                  ),
                                              }
                                            : g
                                      );
                                    setMainProductField(
                                      "policyNumberRanges",
                                      listWithUpdatedPolicyWarningAlertRange
                                    );
                                  }}
                                >
                                  X
                                </button>
                              </WarningRangeDiv>
                            )
                          )}
                        </WarningAlertRanges>
                        <hr />

                        <Input
                          width="fullWidth"
                          id="email-warning-range-input"
                          label={t("numbersOfEmails")}
                          value={warningAlertRange || ""}
                          onChange={(value) =>
                            setWarningAlertRange(parseInt(value))
                          }
                        />

                        <br />

                        <AdminButton
                          fullWidth={true}
                          text={t("add")}
                          onClick={() => {
                            const listWithUpdatedPolicyWarningAlertRange =
                              welcomerProduct.mainProduct.policyNumberRanges.map(
                                (g, i) => {
                                  //
                                  if (i === rangeIndex) {
                                    //
                                    const currentRanges =
                                      g.warningAlertRanges === null
                                        ? []
                                        : [...g.warningAlertRanges];

                                    const editedRange = [
                                      ...currentRanges,
                                      warningAlertRange,
                                    ];
                                    //
                                    return {
                                      ...g,
                                      warningAlertRanges:
                                        editedRange || g.warningAlertRanges,
                                    };
                                    //
                                  } else {
                                    //
                                    return g;
                                    //
                                  }
                                  //
                                }
                              );
                            //
                            setWarningAlertRange("");
                            //
                            setMainProductField(
                              "policyNumberRanges",
                              listWithUpdatedPolicyWarningAlertRange
                            );
                          }}
                        />
                        {/*  */}
                      </BaseModal>
                      {/*  alert range modal */}

                      <Row>
                        <Col>
                          <Input
                            id={`policy-numbers-prefix`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.policyPrefix}
                            onChange={(value) => {
                              const listWithUpdatedPolicyPrefix =
                                welcomerProduct?.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, policyPrefix: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedPolicyPrefix
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            id={`policy-numbers-minPolicyNumber-${rangeIndex}`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.minPolicyNumber}
                            onChange={(value) => {
                              const listWithUpdatedMinPolicyNumber =
                                welcomerProduct.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, minPolicyNumber: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedMinPolicyNumber
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            id={`policy-numbers-maxPolicyNumber-${rangeIndex}`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.maxPolicyNumber}
                            onChange={(value) => {
                              const listWithUpdatedMaxPolicyNumber =
                                welcomerProduct?.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, maxPolicyNumber: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedMaxPolicyNumber
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            id={`policy-numbers-suffix`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.policySuffix}
                            onChange={(value) => {
                              const listWithUpdatedPolicySuffix =
                                welcomerProduct.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, policySuffix: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedPolicySuffix
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {range.usedPolicyNumber
                              ? `${range.policyPrefix ?? ""}${
                                  range.usedPolicyNumber
                                }${range.policySuffix ?? ""}`
                              : `${t("none")}`}
                          </div>
                        </Col>

                        <Col>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            <Button
                              btnStyleType="transparent"
                              size="medium"
                              border={true}
                              icon={false}
                              fullWidth={false}
                              disabled={false}
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                              }}
                              text={t("ranges")}
                              onClick={() =>
                                setOpenWarningRangeModal({
                                  isOpen: true,
                                  index: rangeIndex,
                                })
                              }
                            />

                            <Button
                              btnStyleType="secondary"
                              size="medium"
                              icon={false}
                              fullWidth={false}
                              disabled={false}
                              style={{ display: "inline-block" }}
                              onClick={() => {
                                setMainProductField(
                                  "policyNumberRanges",
                                  welcomerProduct.mainProduct.policyNumberRanges.filter(
                                    (g, i) => i !== rangeIndex
                                  )
                                );
                              }}
                              text={t("delete")}
                            />
                          </div>
                        </Col>
                      </Row>
                    </PolicyNumberBlock>
                  )
                )}
              </PolicyNumberGroupScrollView>
              <br />
              <Button
                btnStyleType="secondary"
                size="medium"
                icon={false}
                fullWidth={true}
                disabled={false}
                onClick={() => {
                  const maxUsedPolicyNumber =
                    welcomerProduct.mainProduct.policyNumberRanges.reduce(
                      (c, n) =>
                        Math.max(c, removeLeadingZeros(n.maxPolicyNumber)),
                      0
                    );
                  const lastUsedPolicyPrefix = [
                    ...welcomerProduct.mainProduct.policyNumberRanges,
                  ].reverse()[0]?.policyPrefix;

                  const lastUsedPolicySuffix = [
                    ...welcomerProduct.mainProduct.policyNumberRanges,
                  ].reverse()[0]?.policySuffix;

                  const newMinPolicyNumber = maxUsedPolicyNumber + 1;

                  setMainProductField("policyNumberRanges", [
                    ...welcomerProduct?.mainProduct.policyNumberRanges,
                    {
                      id:
                        -1 *
                        (welcomerProduct?.mainProduct.policyNumberRanges
                          .length +
                          1),
                      minPolicyNumber: newMinPolicyNumber.toLocaleString(),
                      maxPolicyNumber: (
                        newMinPolicyNumber + 1
                      ).toLocaleString(),
                      policyPrefix: lastUsedPolicyPrefix,
                      policySuffix: lastUsedPolicySuffix,
                    },
                  ]);
                }}
                text={t("addPolicyNumber")}
              />
            </PolicyNumberGroupDiv>
          </Block>
          <Block>
            <Title>{t("daysRange")}</Title>
            <PolicyNumberGroupDiv>
              <Row>
                <Col>
                  <Text>{t("fromDay")}</Text>
                </Col>
                <Col>
                  <Text>{t("uptToDay")}</Text>
                </Col>
                <Col></Col>
              </Row>
              <PolicyNumberGroupScrollView>
                {welcomerProduct?.welcomerDayGroup?.map(
                  (dayGroup, groupIndex) => (
                    <DayRangeBlock
                      // key={`${dayGroup.minDay}-${dayGroup.maxDay}`}
                      key={groupIndex}
                    >
                      <Row>
                        <Col>
                          <Input
                            id={`welcomerDayGroups-${groupIndex}-minDay`}
                            width="auto"
                            length={3}
                            // label="დღიდან (ჩათვლით)"
                            label=""
                            value={dayGroup.minDay}
                            style={{
                              display: "inline-flex",
                              flexDirection: "row-reverse",
                              alignItems: "center",
                            }}
                            onChange={(value) => {
                              const listWithUpdatedMinDay =
                                welcomerProduct?.welcomerDayGroup?.map((g, i) =>
                                  i === groupIndex ? { ...g, minDay: value } : g
                                );
                              setWelcomerProductField(
                                "welcomerDayGroup",
                                listWithUpdatedMinDay
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            id={`dayGroups-${groupIndex}-minDay`}
                            width="auto"
                            length={3}
                            // label="დღემდე (ჩათვლით)"
                            label=""
                            value={dayGroup.maxDay}
                            style={{
                              display: "inline-flex",
                              flexDirection: "row-reverse",
                              alignItems: "center",
                            }}
                            onChange={(value) => {
                              const listWithUpdatedMaxDay =
                                welcomerProduct?.welcomerDayGroup?.map((g, i) =>
                                  i === groupIndex ? { ...g, maxDay: value } : g
                                );
                              setWelcomerProductField(
                                "welcomerDayGroup",
                                listWithUpdatedMaxDay
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            <Button
                              btnStyleType="secondary"
                              size="medium"
                              icon={false}
                              fullWidth={false}
                              disabled={false}
                              style={{ display: "inline-block" }}
                              onClick={() => {
                                if (dayGroup.id === undefined) {
                                  setWelcomerProductField(
                                    "welcomerDayGroup",
                                    welcomerProduct?.welcomerDayGroup?.filter(
                                      (g, i) => i !== groupIndex
                                    )
                                  );
                                } else {
                                  deleteWelcomerDayGroups(dayGroup.id);
                                }
                              }}
                              text={t("delete")}
                            />
                          </div>
                        </Col>
                      </Row>
                    </DayRangeBlock>
                  )
                )}
              </PolicyNumberGroupScrollView>
              <br />
              <ButtonDiv>
                <Button
                  btnStyleType="secondary"
                  size="medium"
                  icon={false}
                  fullWidth={true}
                  disabled={false}
                  onClick={() => {
                    const maxUsedDay =
                      welcomerProduct?.welcomerDayGroup?.reduce(
                        (c, n) => Math.max(c, n.maxDay),
                        0
                      );
                    const newMinDay = maxUsedDay + 1;
                    const listWithAddedItem = [
                      ...welcomerProduct.welcomerDayGroup,
                      {
                        minDay: newMinDay,
                        maxDay: newMinDay + 1,
                        coefficient: 0,
                      },
                    ];
                    setWelcomerProductField(
                      "welcomerDayGroup",
                      listWithAddedItem
                    );
                  }}
                  text={t("add")}
                />
                <Button
                  btnStyleType="secondary"
                  size="medium"
                  icon={false}
                  fullWidth={true}
                  disabled={false}
                  onClick={saveWelcomerDayGroupsFun}
                  text={t("save")}
                />
              </ButtonDiv>
            </PolicyNumberGroupDiv>
          </Block>
        </Column>
        <Column $widthPercent={30} style={{ padding: "20px 20px 20px 10px" }}>
          <Block>
            <Text>{t("productMarketingPrice")}</Text>
            <Input
              id=""
              label=""
              placeholder=""
              type="text"
              width="fullWidth"
              value={welcomerProduct?.productMinPremium || ""}
              required={false}
              error={false}
              onChange={(value) =>
                setWelcomerProductField("productMinPremium", value)
              }
            />
          </Block>
          <h6
            style={{
              color: Colors.primaryBlack,
              borderLeft: `1px solid ${Colors.primaryGrey}`,
              paddingLeft: "5px",
            }}
          >
            {t("multipleMarketingPrice")}
          </h6>
          <br />
          <Block>
            <Text>{t("singleMarketingPrice")}</Text>
            <Input
              id=""
              label=""
              type="text"
              placeholder=""
              value={welcomerProduct?.singleMinPremium}
              onChange={(value) =>
                setWelcomerProductField("singleMinPremium", value)
              }
              width={"fullWidth"}
            />
          </Block>
          <Block>
            <Text>{t("productRealizationDate")}</Text>
            <Input
              id=""
              label=""
              type="text"
              placeholder=""
              value={welcomerProduct?.limitBeforeRealization}
              onChange={(value) =>
                setWelcomerProductField("limitBeforeRealization", value)
              }
              width={"fullWidth"}
            />
          </Block>

          <Block>
            <Text>{t("minimumInsuranceDays")}</Text>
            <Input
              id=""
              label=""
              type="text"
              placeholder=""
              value={welcomerProduct?.minimumInsuranceDays}
              onChange={(value) =>
                setWelcomerProductField("minimumInsuranceDays", value)
              }
              width={"fullWidth"}
            />
          </Block>

          <Block>
            <Text>{t("maximumInsuranceDays")}</Text>
            <Input
              id=""
              label=""
              type="text"
              placeholder=""
              value={welcomerProduct?.maximumInsuranceDays}
              onChange={(value) =>
                setWelcomerProductField("maximumInsuranceDays", value)
              }
              width={"fullWidth"}
            />
          </Block>

          <Block>
            <Text>{t("companyFee")}</Text>
            <Input
              id=""
              label=""
              type="number"
              placeholder=""
              value={welcomerProduct?.mainProduct?.commission}
              onChange={(value) => setMainProductField("commission", value)}
              width={"fullWidth"}
            />
          </Block>

          <Block>
            <Text>{t("createdDate")}</Text>
            <div>
              {dayjs(welcomerProduct?.createdAt).format(
                "DD MMMM YYYY HH:mm:ss"
              )}
            </div>
          </Block>
          <Block>
            <Text>{t("lastUpdatedAt")}</Text>
            <div>
              {dayjs(welcomerProduct?.updatedAt).format(
                "DD MMMM YYYY HH:mm:ss"
              )}
            </div>
          </Block>
          <Block>
            <Text>{t("status")}</Text>
            <div>{welcomerProduct?.mainProduct.status}</div>
          </Block>
          {welcomerProduct?.mainProduct.activationDate && (
            <Block>
              <Text>{t("lastActivationDate")}:</Text>
              <div>
                {dayjs(welcomerProduct.mainProduct.activationDate).format(
                  "DD MMMM YYYY HH:mm:ss"
                )}
              </div>
            </Block>
          )}
          {welcomerProduct?.mainProduct.deactivationDate && (
            <Block>
              <Text>{t("deactivationDate")}</Text>
              <div>
                {dayjs(welcomerProduct.mainProduct.deactivationDate).format(
                  "DD MMMM YYYY HH:mm:ss"
                )}
              </div>
            </Block>
          )}
          <Button
            btnStyleType="primary"
            icon={false}
            fullWidth={true}
            style={{ marginBottom: 16 }}
            disabled={false}
            onClick={saveTab}
            text={t("save")}
          />
          {isAdmin && welcomerProduct?.mainProduct.status === "draft" ? (
            <Button
              btnStyleType="primary"
              icon={false}
              fullWidth={true}
              style={{ marginBottom: 16 }}
              disabled={false}
              onClick={approveProductChanges}
              text={t("publish")}
            />
          ) : null}
          {isAdmin ? (
            <Button
              btnStyleType="primary"
              icon={false}
              fullWidth={true}
              style={{ marginBottom: 16 }}
              disabled={false}
              onClick={rejectProductChanges}
              text={
                welcomerProduct?.mainProduct.status === "draft"
                  ? `${t("rejectChanges")}`
                  : `${t("moveToDraft")}`
              }
            />
          ) : null}
        </Column>
      </ContentBox>
    </Wrapper>
  );
};
//
//
// styles
const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;
const WarningAlertRanges = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const WarningRangeDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  width: fit-content;
  background-color: ${Colors.Sazerac};
  margin-right: 10px;
  margin-bottom: 10px;

  button {
    color: ${Colors.primaryRed};
    background-color: unset;
    margin-left: 10px;
    outline: none;
    line-height: none;
    border: none;
    padding: 2px 5px;

    :hover {
      color: ${Colors.Black};
    }
  }
`;
const PolicyNumberGroupScrollView = styled.div`
  min-height: 150px;
  max-height: 290px;
  overflow-y: scroll;
  /*  */

  .policy-number-class {
    input {
      min-width: 53px !important;
      width: 70% !important;
    }
  }
`;
const AgeGroupScrollView = styled.div`
  min-height: 150px;
  max-height: 290px;
  overflow-y: scroll;
`;
const AgeGroupDiv = styled.div`
  input {
    text-align: center;
  }
  /* border: 1px solid red; */
  box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  -webkit-box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  -moz-box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  padding: 10px;
`;
const PolicyNumberGroupDiv = styled.div`
  input {
    text-align: center;
  }
  /* border: 1px solid red; */
  box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  -webkit-box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  -moz-box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  padding: 10px;
`;
const DayRangeBlock = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 5px;
  padding-block: 5px;
`;
const PolicyNumberBlock = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 5px;
  padding-block: 5px;
`;
const AgeGroupBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-block: 5px;
  padding-block: 5px;
`;
const Text = styled.div`
  margin-bottom: 10px;
  color: ${Colors.primaryGrey};
  font-size: 14px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 10px;
  /*  */
  > div {
    flex-basis: 50%;
  }
`;
const Title = styled.div`
  margin-bottom: 10px;
  color: ${Colors.primaryRed};
  font-size: 16px;
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  ${Responsive.tablet} {
    flex-direction: column;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const Column = styled.div<{ $widthPercent?: number }>`
  padding: 20px 15px;
  ${({ $widthPercent = 50 }) =>
    $widthPercent ? `width: calc(${$widthPercent}% - 15px);` : ""}

  ${Responsive.tablet} {
    width: 100%;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export default EditWelcomerProductFieldsTab;
