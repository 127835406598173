import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Responsive from "../../../../../assets/config/Responsive";
import Colors from "../../../../../assets/styles/Colors";
import { Container } from "../../../../../assets/styles/GlobalStyle";
import ScreenHeader from "../../../../../components/ScreenHeader";
import {
  Currency,
  EnabledLanguage,
  PolicyFileType,
  Product,
  TplPackage,
  TplProduct,
  WordingFileType,
} from "../../../../../domain/types";
// import { useAppSelector } from '../../../../../store'
import { Box } from "@mui/material";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../../../api";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { AppState, setEditedlanguage } from "../../../../../store/appSlice";
import AdminLangSwicher from "../../../../admin_components/AdminLangSwicher";
import AdminPetDocumentsTabThree from "./AdminPetDocumentsTabThree";
import AdminPetPriceTabFour from "./AdminPetPriceTabFour";
import EditPetPackagesTab from "./EditPetPackagesTab";
import EditPetProductFieldsTab from "./EditPetProductFieldsTab";

//
//
type ProductTabData = {
  tplProduct: TplProduct;
};

const AdminPetProductEdit = () => {
  const { petProductId: str = "0" } = useParams();
  const petProductId = parseInt(str);
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  // translation set
  const { t } = useTranslation();
  //
  const dispatch = useAppDispatch();
  //
  //
  const [activeTab, setActiveTab] = useState<string>("1");
  //
  //
  //
  // product categories list
  // აქ გავჩერდი
  const {
    data: existingPetProduct,
    isLoading: isExistingPetProductLoading,
    refetch: refetchExistingPetProduct,
  } = api.useGetPetProductByIdQuery(petProductId);
  //
  const {
    data: tplCurrencies,
    isLoading: isTplCurrenciesLoading,
    // refetch: refetchTplCurrencies,
  } = api.useGetTplCurrenciesQuery(undefined);

  const allCurrenciesOfTplProduct = (tplProduct: TplProduct): Currency[] => {
    return lodash.uniq(
      tplProduct.mainProduct.packages
        .map((pkg) =>
          pkg?.compensationCurrencies.map(
            (currencyHolder) => currencyHolder.currency
          )
        )
        .reduce((carry, next) => (carry ?? []).concat(next ?? []), [])
    ) as Currency[];
  };

  const allServicesOfTplProduct = (tplProduct: TplProduct): number[] => {
    // @ts-ignore
    return lodash.uniq(
      tplProduct.mainProduct.packages
        .map((pkg) => pkg.target as TplPackage)
        .map((pkg) => pkg?.packageServiceRelations)
        .reduce((carry, next) => (carry ?? []).concat(next ?? []), [])
    );
  };

  const allPaymentSchedulesOfTravelProduct = (
    tplproduct: TplProduct
  ): number[] => {
    return lodash.uniq(
      tplproduct.mainProduct.packages
        .map((pkg) => pkg?.paymentScheduleTypeIds)
        .reduce((carry, next) => (carry ?? []).concat(next ?? []), [])
    );
  };
  //
  //
  const [perTplPackage_usedCurrencies, only_setUsedCurrencies] = useState<
    Array<{ tplPackageId: number; values: Currency[] }>
  >([]);

  const setUsedCurrencies = (tplPackageId: number, values: Currency[]) => {
    const listWithout = perTplPackage_usedCurrencies.filter(
      (x) => x.tplPackageId !== tplPackageId
    );
    const listWith = [...listWithout, { tplPackageId, values: values }];
    only_setUsedCurrencies(listWith);
  };
  //
  //
  const {
    data: packageServices,
    isLoading: isGetPackageServicesLoading,
    // refetch: refetchPackageServices,
  } = api.useGetPackageServicesQuery({ category: "tpl" });

  const [perTplPackage_usedServices, only_setUsedServices] = useState<
    Array<{ tplPackageId: number; values: number[] }>
  >([]);
  const setUsedServices = (tplPackageId: number, values: number[]) => {
    const listWithout = perTplPackage_usedServices.filter(
      (x) => x.tplPackageId !== tplPackageId
    );
    const listWith = [...listWithout, { tplPackageId, values: values }];
    only_setUsedServices(listWith);
  };
  // const usedServices = lodash.uniq(
  //   perTplPackage_usedServices.reduce(
  //     (carry, next) => carry.concat(next.values),
  //     [] as number[]
  //   )
  // )

  //
  //
  const {
    data: paymentScheduleTypeArr,
    isLoading: isPaymentScheduleTypeArrLoading,
    // refetch: refetchPaymentSchedule,
  } = api.useGetPaymentScheduleTypesQuery(2);
  const [perTplPackage_usedPaymentSchedules, only_setUsedPaymentSchedules] =
    useState<Array<{ tplPackageId: number; values: number[] }>>([]);

  const setUsedPaymentSchedules = (tplPackageId: number, values: number[]) => {
    const listWithout = perTplPackage_usedPaymentSchedules.filter(
      (x) => x.tplPackageId !== tplPackageId
    );
    const listWith = [...listWithout, { tplPackageId, values: values }];
    only_setUsedPaymentSchedules(listWith);
  };
  //
  //
  const [productFieldsState, setProductFieldsState] = useState<
    ProductTabData | undefined
  >();

  const setMainProductField = (
    fieldName: keyof ProductTabData["tplProduct"]["mainProduct"],
    fieldValue: any
  ) => {
    if (!productFieldsState) {
      return;
    }
    setProductFieldsState({
      ...productFieldsState,
      tplProduct: {
        ...productFieldsState.tplProduct,
        mainProduct: {
          ...productFieldsState.tplProduct.mainProduct,
          [fieldName]: fieldValue,
        },
      },
    });
  };

  const setTplProductField = (
    fieldName: keyof ProductTabData["tplProduct"],
    fieldValue: any
  ) => {
    if (!productFieldsState) {
      return;
    }
    const updatedProductFields: TplProduct = {
      ...productFieldsState.tplProduct,
      [fieldName]: fieldValue,
    };
    // TODO if this becomes slow, check if these coefficient fields have changed:
    // minTotalPremiumInfos, dayGroupInfos, priceCombinations, priceCoefficients
    let productWithRegeneratedPriceCombinations: TplProduct;
    console.log(updatedProductFields);

    //change
    try {
      productWithRegeneratedPriceCombinations =
        // regeneratePriceCombinations(updatedProductFields)
        updatedProductFields;
    } catch (e) {
      // console.log(e);
      // revert to product without regenerated price combinations
      productWithRegeneratedPriceCombinations = updatedProductFields;
    }
    setProductFieldsState({
      ...productFieldsState,
      tplProduct: productWithRegeneratedPriceCombinations,
      // tplProduct: updatedProductFields,
    });
  };

  const setTranslatedFieldOnProduct = (
    field: keyof Product["translations"]["en"],
    language: EnabledLanguage,
    value: any
  ) => {
    if (!productFieldsState) {
      return;
    }
    setTplProductField("mainProduct", {
      ...productFieldsState.tplProduct.mainProduct,
      translations: {
        ...productFieldsState.tplProduct.mainProduct.translations,
        [language]: {
          ...productFieldsState.tplProduct.mainProduct?.translations?.[
            language
          ],
          [field]: value,
        },
      },
    });
  };

  // tpl product tab one fields store | save
  const [
    m_petProductTabOneProductFields,
    { isLoading: isSavePetProductFieldsLoading },
  ] = api.useStorePetProductTabOneProductFieldsMutation();

  const [
    m_saveProductDocumentFields,
    { isLoading: isSaveProductDocumentFieldsLoading },
  ] = api.useUpdateProductDocumentsMutation();
  // // save prices tab
  // const [
  //   m_updateTplProductPriceCombinations,
  //   { isLoading: isSavePricesTabLoading },
  // ] = api.useUpdateTplProductPriceCombinationsMutation()
  // [admin]: approve product changes
  const [m_activateProduct, { isLoading: isActivateProductLoading }] =
    api.useAdminActivateProductMutation();
  // [admin]: reject product changes
  const [m_deactivateProduct, { isLoading: isDeactivateProductLoading }] =
    api.useAdminDeactivateProductMutation();

  const storeTplProductGeneralFields = async () => {
    try {
      if (!productFieldsState?.tplProduct?.id) {
        throw new Error("Missing product data");
      }

      let policyRanges =
        productFieldsState?.tplProduct.mainProduct.policyNumberRanges || [];

      if (
        policyRanges.some(
          (p) =>
            hasNonNumericCharacters(p.minPolicyNumber) ||
            hasNonNumericCharacters(p.maxPolicyNumber)
        )
      ) {
        toast.error(
          `${t('incorrectPolicyFormat')}`
        );
        return;
      }

      let tplProductToSave = {
        ...productFieldsState.tplProduct,
      };

      tplProductToSave = {
        ...tplProductToSave,
        // @ts-ignore
        limitationOfStartDate: Number(tplProductToSave?.limitationOfStartDate),
      };

      await m_petProductTabOneProductFields(tplProductToSave).unwrap();
      toast.success(`${t('product')} ${t('savedSuccessfully')}`);

      refetchExistingPetProduct();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t('unknownError')}`);
      }
    }
  };

  const hasNonNumericCharacters = (input: string) => /[^0-9]/.test(input);

  // save uploaded documents

  // const updateTplProductPricesTab = async () => {
  //   try {
  //     if (!productFieldsState?.tplProduct?.id) {
  //       throw new Error('Missing product data')
  //     }
  //     await m_updateTplProductPriceCombinations({
  //       id: productFieldsState.tplProduct.id,
  //       productMinPremium: productFieldsState.tplProduct.productMinPremium,
  //     }).unwrap()
  //     toast.success('ფასები წარმატებით დამახსოვრდა')
  //     //
  //     // refetch tpl products
  //     // DISABLED, because it resets product fields. if you enable this, also save product fields tab here.
  //     // refetchExistingPetProduct()
  //   } catch (error) {
  //     // console.log("save prices tab error:", error);
  //     if (error?.data?.errors?.constructor === Array) {
  //       const firstError = error.data.errors[0]
  //       const errorMessage = firstError.message
  //       toast.error(errorMessage)
  //     } else {
  //       toast.warning(error?.message || 'მოხდა უცნობი შეცდომა')
  //     }
  //   }
  // }

  const storeProductDocumentFields = async (
    policyFiles: PolicyFileType[],
    wordingFiles: WordingFileType
  ) => {
    try {
      if (!productFieldsState?.tplProduct?.id) {
        throw new Error("Missing product data");
      }
      await m_saveProductDocumentFields({
        ...productFieldsState.tplProduct.mainProduct,
        policyFiles: policyFiles,
        wordingFiles: wordingFiles,
      }).unwrap();

      toast.success(`${t('uploadedSuccessfully')}`);

      refetchExistingPetProduct();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t('unknownError')}`);
      }
    }
  };

  // approve product changes [admin does this]
  const approveProductChanges = async () => {
    try {
      if (!productFieldsState?.tplProduct?.id) {
        throw new Error("Missing product data");
      }
      if (productFieldsState.tplProduct.mainProduct.status === "published") {
        throw new Error("დაუდასტურებელი ცვლილებები არ მოიძებნა");
      }
      // first, save main fields
      await storeTplProductGeneralFields();
      // second, save prices
      // await updateTplProductPricesTab()
      // third, activate the product
      await m_activateProduct(
        productFieldsState.tplProduct.mainProduct.id
      ).unwrap();
      toast.success(`${t('product')} ${t('publishedSuccessfully')}`);
      //
      // refetch tpl products
      // WARNING: this resets product fields.
      // if this is enabled, also save product fields above.
      refetchExistingPetProduct();
    } catch (error) {
      // console.log("approve product error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t('unknownError')}`);
      }
    }
  };
  // reject product changes [admin does this]
  const rejectProductChanges = async () => {
    try {
      if (!productFieldsState?.tplProduct?.id) {
        throw new Error("Missing product data");
      }
      if (productFieldsState.tplProduct.mainProduct.status === "published") {
        // in this case, admin is converting a published product to draft (probably just auditing existing published products)
      }
      await m_deactivateProduct(
        productFieldsState.tplProduct.mainProduct.id
      ).unwrap();
      toast.success(`${t('product')} ${t('deactivated')}`);
      //
      // refetch tpl products
      // WARNING: this resets product fields. if you enable this, also save product fields tab here.
      // 09.02.2022: deactivation without saving modified fields. hoping for no problems in UX.
      refetchExistingPetProduct();
    } catch (error) {
      // console.log("reject product changes error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t('unknownError')}`);
      }
    }
  };

  useEffect(() => {
    if (existingPetProduct) {
      // fill missing package coefficients, set default 1 (missing package coefficient was a frequent occurence)
      // const tplProductWithDefaultPackageCoefficients =
      //   decorateTplProductWithDefaultPackageCoefficients(
      //     existingPetProduct
      //   )
      setProductFieldsState({
        tplProduct: existingPetProduct,
      });

      for (let tplPackageId of existingPetProduct.mainProduct.packages.map(
        (pkg) => pkg.targetId
      )) {
        setUsedCurrencies(
          tplPackageId,
          allCurrenciesOfTplProduct(existingPetProduct)
        );
        setUsedServices(
          tplPackageId,
          allServicesOfTplProduct(existingPetProduct)
        );
        setUsedPaymentSchedules(
          tplPackageId,
          allPaymentSchedulesOfTravelProduct(existingPetProduct)
        );
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingPetProduct]);

  // countries list
  const { data: countries = [], isLoading: isCountriesLoading } =
    api.useGetCountriesQuery(undefined);

  const {
    data: tplLimits,
    isLoading: isTplLimitsLoading,
    // refetch: refetchTplLimits,
  } = api.useGetTplLimitsQuery({ isActive: "true" });

  const isFullPageLoading = isExistingPetProductLoading || !productFieldsState;

  //first tab loading
  const isProductFieldsTabLoading =
    isFullPageLoading ||
    isActivateProductLoading ||
    isDeactivateProductLoading ||
    isSavePetProductFieldsLoading;

  //second tab loading
  const isPackagesTabLoading =
    isFullPageLoading ||
    isCountriesLoading ||
    isGetPackageServicesLoading ||
    isTplLimitsLoading ||
    isPaymentScheduleTypeArrLoading ||
    isTplCurrenciesLoading;

  //third tab loading
  const isPDFTabLoading =
    isFullPageLoading || isSaveProductDocumentFieldsLoading;
  //
  // console.log("existingPetProduct", existingPetProduct);
  // console.log("id", existingPetProduct?.mainProduct.id);
  // console.log("petProductId", petProductId);
  //
  return (
    <div style={{ paddingBottom: "50px" }}>
      <ScreenHeader
        text={t("products")}
        url="/product-list"
        // TEMPORARY fix going back and forward resulting in package accordion having stale cached data from RTK Query.
        // correct fix is using RTK Invalidation Tags: https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
        onClick={() => {
          document.location.href = "/product-list";
        }}
        toolbar={
          <AdminLangSwicher
            onChange={(value) => dispatch(setEditedlanguage(value))}
          />
        }
      />
      <Container>
        <MuiBox>
          {/* <TabContext value={activeTab}> */}
          <Box>
            {/* onChange={handleChange} aria-label="lab API tabs example" */}
            <MuiTabList>
              {/* <MuiTab label={t('productFeatures')} value="1" />
                <MuiTab label={t('packages')} value="2" />
                <MuiTab label={t('documents')} value="3" />
                <MuiTab label={t('prices')} value="4" /> */}
              {/* <MuiTab label="ბენეფიტები" value="5" /> */}
              <MuiTab
                onClick={() => setActiveTab("1")}
                $active={activeTab === "1"}
              >
                {t("productFeatures")}
              </MuiTab>
              <MuiTab
                onClick={() => setActiveTab("2")}
                $active={activeTab === "2"}
              >
                {t("packages")}
              </MuiTab>
              <MuiTab
                onClick={() => setActiveTab("3")}
                $active={activeTab === "3"}
              >
                {t("documents")}
              </MuiTab>
              {/* {true === true ? null : ( */}
              <MuiTab
                onClick={() => setActiveTab("4")}
                $active={activeTab === "4"}
              >
                {t("prices")}
              </MuiTab>
              {/* )} */}
            </MuiTabList>
          </Box>

          {/* value="1" */}
          <MuiTabPanel style={activeTab === "1" ? {} : { display: "none" }}>
            {/*პროდუქტის მახასიათებლები */}
            <EditPetProductFieldsTab
              tplProduct={productFieldsState?.tplProduct}
              language={field_Language}
              isLoading={isProductFieldsTabLoading}
              setTplProductField={setTplProductField}
              setTranslatedFieldOnProduct={setTranslatedFieldOnProduct}
              setMainProductField={setMainProductField}
              saveTab={storeTplProductGeneralFields}
              approveProductChanges={approveProductChanges}
              rejectProductChanges={rejectProductChanges}
            />
          </MuiTabPanel>

          {/* value="2" */}
          <MuiTabPanel style={activeTab === "2" ? {} : { display: "none" }}>
            {/* პაკეტები */}
            <EditPetPackagesTab
              tplProduct={productFieldsState?.tplProduct}
              countries={countries}
              isLoading={isPackagesTabLoading}
              refetchExistingPetProduct={refetchExistingPetProduct}
              setTplProductField={setTplProductField}
              setUsedCurrencies={setUsedCurrencies}
              setUsedServices={setUsedServices}
              packageServices={packageServices}
              setUsedPaymentSchedules={setUsedPaymentSchedules}
              paymentSchedules={paymentScheduleTypeArr}
              tplLimits={tplLimits}
              tplCurrencies={tplCurrencies}
            />
          </MuiTabPanel>

          {/* value="3" */}
          <MuiTabPanel style={activeTab === "3" ? {} : { display: "none" }}>
            {/* დოკუმენტები */}
            {productFieldsState?.tplProduct ? (
              <AdminPetDocumentsTabThree
                isLoading={isPDFTabLoading}
                tplProduct={productFieldsState?.tplProduct}
                saveTab={storeProductDocumentFields}
              />
            ) : (
              ""
            )}
          </MuiTabPanel>

          <MuiTabPanel style={activeTab === "4" ? {} : { display: "none" }}>
            {/* ფასები */}
            <AdminPetPriceTabFour
              productId={productFieldsState?.tplProduct.mainProduct.id}
            />
          </MuiTabPanel>

          {/* <MuiTabPanel value="5">
              {/* ბენეფიტები * /}
              {/* <Tab /> * /}
            </MuiTabPanel> */}
          {/* </TabContext> */}
        </MuiBox>
      </Container>
    </div>
  );
};
//
const MuiTabList = styled.div`
  /* MUI tab bottom line */
  .MuiTabs-indicator {
    background-color: ${Colors.primaryRed} !important;
  }
  ${Responsive.mobile} {
    /* mui button */
    button {
      margin-right: auto;
      font-size: 17px;
    }
  }
`;
// const MuiTab = styled(Tab)`
const MuiTab = styled.button<{ $active?: boolean }>`
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px none;
  margin: 0px;
  margin-right: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.6);
  color: ${Colors.primaryBlack} !important;
  padding: 0px;
  margin-right: 30px;
  font-size: 18px;
  text-transform: none;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  ${({ $active = false }) =>
    $active ? `border-bottom-color: rgb(254, 95, 85);` : ""}

  &:last-child {
    margin-right: 0px;
  }
`;
// const MuiTabPanel = styled(TabPanel)`
const MuiTabPanel = styled.div`
  background-color: ${Colors.white};
  padding: 0px;
  padding-block: 20px;
  border-radius: 8px;
  box-shadow: ${Colors.white} 0px 30px 60px -12px inset,
    ${Colors.white} 0px 18px 36px -18px inset;
  ${Responsive.mobile} {
    margin-top: 20px;
  }
`;
// const MuiBox = styled(Box)`
const MuiBox = styled.div`
  margin-top: 30px;
  button {
    font-family: inherit;
  }
`;
//
export default AdminPetProductEdit;
