import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../../../api";
import Colors from "../../../../assets/styles/Colors";
import CustomSelect from "../../../../components/CustomSelect";
import Loader from "../../../../components/Loader";
import { TranslationsField } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../../store/appSlice";
//
//

//
//
const Admin_manage_travel_pages = () => {
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation();
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );  
  //
  // page slugs
  const pageSlugs: {
    id: number;
    slug: string;
    text: string;
    translations: TranslationsField<{ text: string }>;
  }[] = [
    {
      id: 1,
      slug: "homepage-product-category",
      text: "პროდუქტების კატეგორიები",
      translations: {
        en: {
          text: "Product categories",
        },
        ge: {
          text: "პროდუქტების კატეგორიები",
        },
      },
    },
    {
      id: 2,
      slug: "incoming-or-outgoing",
      text: "შემომავალი თუ გამავალი",
      translations: {
        en: {
          text: "To Georgia / Abroad",
        },
        ge: {
          text: "შემომავალი თუ გამავალი",
        },
      },
    },
    {
      id: 3,
      slug: "company-choose",
      text: "ფრანის & მომხმარებლის არჩევანი",
      translations: {
        en: {
          text: "Frani choice & user choice",
        },
        ge: {
          text: "ფრანის & მომხმარებლის არჩევანი",
        },
      },
    },
    {
      id: 4,
      slug: "insurance-kind",
      text: "ერთჯერადი & მრავალჯერადი მოგზაურობა",
      translations: {
        en: {
          text: "Single & multi travel",
        },
        ge: {
          text: "ერთჯერადი & მრავალჯერადი მოგზაურობა",
        },
      },
    },
    {
      id: 5,
      slug: "travel-abroad-single-and-multi-date-text",
      text: "ერთჯერადი & მრავალჯერადი თარიღები",
      translations: {
        en: {
          text: "Single & multi dates",
        },
        ge: {
          text: "ერთჯერადი & მრავალჯერადი თარიღები",
        },
      },
    },
    {
      id: 6,
      slug: "area",
      text: "არეალი",
      translations: {
        en: {
          text: "Area",
        },
        ge: {
          text: "არეალი",
        },
      },
    },
    {
      id: 7,
      slug: "currency",
      text: "ვალუტა & ლიმიტი & ფრანშიზა",
      translations: {
        en: {
          text: "Currency / Limit / Deductible",
        },
        ge: {
          text: "ვალუტა & ლიმიტი & ფრანშიზა",
        },
      },
    },
    {
      id: 8,
      slug: "travel-policy-registration-first-step",
      text: "ჩემთვის & სხვისთვის & ჩემთვის და სხვისთვის & ქარდები",
      translations: {
        en: {
          text: "For me /  For other /  For me & other",
        },
        ge: {
          text: "ჩემთვის & სხვისთვის & ჩემთვის და სხვისთვის & ქარდები",
        },
      },
    },
  ];
  //
  // selected slug
  const [selectedSlug, setSelectedSlug] = useState<string | null>(null);

  // get travel flow
  const { data: pageTextArry, isLoading: isPageTextArrayLoading } =
    api.useGetTravelFlowPageTextsBySlugQuery(selectedSlug as string, {
      skip: !selectedSlug && true,
    });
  //
  //
  //
  const mainLoading = isPageTextArrayLoading;
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <CustomSelect
        defaultInputValue={
          pageSlugs.find((x) => x.slug === selectedSlug)?.text || ""
        }
        placeholder=""
        headText={t("choosePage")}
        onChange={(event) => setSelectedSlug(event.value)}
        data={[
          ...pageSlugs.map((item) => ({
            value: item.slug,
            label: item.translations?.[lang].text,
          })),
        ]}
      />
      {pageTextArry && (
        <InfoBlock>
          <div>
            <AdminLangSwicher />
          </div>
          {/*  */}
          <Content>
            {pageTextArry &&
              pageTextArry.map((item, index) => (
                <InnerBox
                  key={index}
                  onClick={() =>
                    navigate(`/manage-travel-pages-edit/${item?.id}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {item?.translations?.[field_Language]?.title && (
                    <>
                      <div style={{ color: Colors.primaryRed }}>
                        {t("title")}
                      </div>
                      <TextBlock>
                        {item?.translations?.[field_Language]?.title}
                      </TextBlock>
                    </>
                  )}
                  {item?.translations?.[field_Language]?.body && (
                    <>
                      <div style={{ color: Colors.primaryRed }}>
                        {t("body")}
                      </div>
                      <TextBlock>
                        {item?.translations?.[field_Language]?.body}
                      </TextBlock>
                    </>
                  )}
                  {item?.translations?.[field_Language]?.excerpt && (
                    <>
                      <div style={{ color: Colors.primaryRed }}>
                        {t("additionalInformation")}
                      </div>
                      <TextBlock>
                        {item?.translations?.[field_Language]?.excerpt}
                      </TextBlock>
                    </>
                  )}

                  {item?.translations?.[field_Language]?.secondTitle && (
                    <>
                      <div style={{ color: Colors.primaryRed }}>
                        {t("secondTitle")}
                      </div>
                      <TextBlock>
                        {item?.translations?.[field_Language]?.secondTitle}
                      </TextBlock>
                    </>
                  )}

                  {item?.translations?.[field_Language]?.secondExcerpt && (
                    <>
                      <div style={{ color: Colors.primaryRed }}>
                        {t("secondAdditionalInformation")}
                      </div>
                      <TextBlock>
                        {item?.translations?.[field_Language]?.secondExcerpt}
                      </TextBlock>
                    </>
                  )}

                  {item?.translations?.[field_Language]?.thirdTitle && (
                    <>
                      <div style={{ color: Colors.primaryRed }}>
                        {t("thirdTitle")}
                      </div>
                      <TextBlock>
                        {item?.translations?.[field_Language]?.thirdTitle}
                      </TextBlock>
                    </>
                  )}

                  {item?.translations?.[field_Language]?.thirdExcerpt && (
                    <>
                      <div style={{ color: Colors.primaryRed }}>
                        {t("thirdAdditionalInformation")}
                      </div>
                      <TextBlock>
                        {item?.translations?.[field_Language]?.thirdExcerpt}
                      </TextBlock>
                    </>
                  )}

                  <ImageStyle src={item?.image} alt="" />
                </InnerBox>
              ))}
          </Content>
          {/*  */}
        </InfoBlock>
      )}
    </Wrapper>
  );
};
//
// styles
const ImageStyle = styled.img`
  height: 100px;
  width: fit-content;
  margin-inline: auto;
`;
const TextBlock = styled.div`
  border-bottom: 1px dashed ${Colors.primaryRed};
  margin-bottom: 10px;
  padding-block: 5px;
`;
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;
const InnerBox = styled.div`
  border: 1px solid ${Colors.primaryRed};
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const InfoBlock = styled.div`
  margin-top: 30px;
  box-shadow: 0px 1px 12px 1px rgba(118, 131, 139, 0.23);
  -webkit-box-shadow: 0px 1px 12px 1px rgba(118, 131, 139, 0.23);
  -moz-box-shadow: 0px 1px 12px 1px rgba(118, 131, 139, 0.23);
  border-radius: 4px;
  background-color: ${Colors.grey};
  padding: 20px;
  color: ${Colors.primaryGrey};
  /*  */
  a {
    &:last-child {
      color: ${Colors.secondaryRed};
      text-decoration-color: ${Colors.primaryRed};
    }
  }
`;
const Wrapper = styled.div``;
//
//
export default Admin_manage_travel_pages;
