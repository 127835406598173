import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../api";
import CloseIcon from "../../assets/icons/JSX/CloseIcon";
import Colors from "../../assets/styles/Colors";
import CustomSelect from "../../components/CustomSelect";
import Input from "../../components/Input";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import AdminButton from "../admin_components/AdminButton";
import { useTranslation } from "react-i18next";
//
//
const Admin_manage_emails = () => {
  //
  // selected product id
  const [selectedProductId, setSelectedProductId] = useState<
    number | undefined
  >(undefined);
  //
  // existing product emails
  const [existingEmailsArray, setExistingEmailsArray] = useState<string[] | []>(
    []
  );
  //
  const { t } = useTranslation()
  // 
  // entered email
  const [enteredEmail, setEnteredEmail] = useState<string | undefined>("");
  //
  const [isDelete, setIsDelete] = useState<boolean>(false);
  //
  // product categories list
  const {
    data: AdminProductCategoriesList,
    isLoading: isAdminProductCategoriesListLoading,
    refetch: isAdminProductCategoriesListRefetch,
  } = api.useGetAdminProductCategoriesListQuery(undefined);
  //
  //
  //  get frani product current emails
  const {
    data: getFraniProductCurrentEmail,
    isLoading: isGetFraniProductCurrentEmailLoading,
    refetch: isGetFraniProductCurrentEmailRefetch,
    isError: isGetFraniProductCurrentEmailError,
  } = api.useGetFraniProductCurrentEmailQuery(selectedProductId as number, {
    skip: !selectedProductId && true,
  });
  //
  //
  // remvoe frani product current emails
  const [
    removeFraniProductCurrentEmail,
    {
      isLoading: isRemoveFraniProductCurrentEmailsLoading,
      isSuccess: removeRemoveFraniProductCurrentEmailsSucces,
    },
  ] = api.useRemoveFraniProductMailsMutation();
  //
  //
  // store frani product emails mutation
  const [
    storeFraniProductEmails,
    { isLoading: isStoreFraniProductEmailsLoading },
  ] = api.useStoreFraniProductEmailsMutation();
  //
  //
  // store frani product emails mutation
  const [
    updateFraniProductEmails,
    { isLoading: isUpdateFraniProductEmailsLoading },
  ] = api.useUpdateFraniProductEmailsMutation();
  //
  //
  // store frani product emails function
  const StoreFraniProductEmailsFun = async () => {
    //
    if (AdminProductCategoriesList) {
      try {
        await storeFraniProductEmails({
          productCategoryId: selectedProductId,
          emails: existingEmailsArray,
        }).unwrap();
        //
        // refetch product emails
        isAdminProductCategoriesListRefetch();
        //
        isGetFraniProductCurrentEmailRefetch();
        //
        toast.success(`${t('createdSuccessfully')}`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    }
  };
  //
  //
  // store frani product emails function
  const updateFraniProductEmailsFun = async () => {
    //
    if (AdminProductCategoriesList) {
      try {
        await updateFraniProductEmails({
          productCategoryId: selectedProductId,
          emails: existingEmailsArray,
        }).unwrap();
        //
        // refetch product emails
        isAdminProductCategoriesListRefetch();
        //
        isGetFraniProductCurrentEmailRefetch();
        //
        setIsDelete(false);
        //
        toast.success(`${t('updatedSuccessfully')}`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    }
  };
  //
  //
  // remove frani product emails function
  const removeFraniProductEmailsFun = async () => {
    //
    if (window.confirm(`${t('certainToDeleteIt')}`) && selectedProductId) {
      try {
        await removeFraniProductCurrentEmail(selectedProductId).unwrap();
        //
        // refetch product emails
        isAdminProductCategoriesListRefetch();
        //
        isGetFraniProductCurrentEmailRefetch();
        //
        setExistingEmailsArray([]);
        //
        toast.success(`${t('deletedSuccessfully')}`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    }
  };
  //
  // set existing emails
  useEffect(() => {
    //
    if (AdminProductCategoriesList && getFraniProductCurrentEmail) {
      setExistingEmailsArray(getFraniProductCurrentEmail.emails);
    }
    //
    if (!getFraniProductCurrentEmail || isGetFraniProductCurrentEmailError) {
      setExistingEmailsArray([]);
    }
    //
    return () => {};
  }, [
    getFraniProductCurrentEmail,
    AdminProductCategoriesList,
    isGetFraniProductCurrentEmailError,
  ]);
  //
  //
  useEffect(() => {
    if (removeRemoveFraniProductCurrentEmailsSucces) {
      // refetch product emails
      isAdminProductCategoriesListRefetch();
      //
      isGetFraniProductCurrentEmailRefetch();
      //
      setExistingEmailsArray([]);
    }
  }, [
    removeRemoveFraniProductCurrentEmailsSucces,
    isAdminProductCategoriesListRefetch,
    isGetFraniProductCurrentEmailRefetch,
  ]);
  //
  //
  console.log(getFraniProductCurrentEmail);
  // main loader
  const MainLoader =
    isAdminProductCategoriesListLoading ||
    isGetFraniProductCurrentEmailLoading ||
    isStoreFraniProductEmailsLoading ||
    isRemoveFraniProductCurrentEmailsLoading ||
    isUpdateFraniProductEmailsLoading;

  //
  return MainLoader ? (
    <Loader />
  ) : (
    <Wrapper>
      <Row>
        <Col>
          {AdminProductCategoriesList && (
            <CustomSelect
              placeholder=""
              defaultInputValue={
                AdminProductCategoriesList?.find(
                  (x) => x?.id === selectedProductId
                )?.title || ""
              }
              headText={t('selectProduct')}
              onChange={(event) => setSelectedProductId(event.value)}
              data={[
                ...AdminProductCategoriesList?.map((item) => ({
                  value: item.id,
                  label: item.title,
                })),
              ]}
            />
          )}
        </Col>

        <Col>
          {existingEmailsArray && selectedProductId && (
            <div>
              <div
                style={{
                  position: "relative",
                }}
              >
                <Input
                  id="email"
                  label={t('addNewEmail')}
                  type="email"
                  placeholder=""
                  value={enteredEmail}
                  name="email"
                  onChange={(value) => setEnteredEmail(value)}
                  width={"fullWidth"}
                  style={{ marginBottom: "24px" }}
                />

                <AdminButton
                  style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                  }}
                  size="medium"
                  text={t('add')}
                  fullWidth={false}
                  btnStyleType="primary"
                  onClick={() => {
                    if (
                      enteredEmail &&
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                        enteredEmail
                      )
                    ) {
                      setExistingEmailsArray((prevNames) => [
                        ...prevNames,
                        enteredEmail,
                      ]);
                      //
                      setEnteredEmail("");
                      //
                    } else {
                      toast.error(`${t('validEmail')}`);
                    }
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {(existingEmailsArray &&
                  existingEmailsArray.length === 0 &&
                  isGetFraniProductCurrentEmailError) ||
                (getFraniProductCurrentEmail?.emails.length === 0 &&
                  existingEmailsArray.length === 0) ? (
                  <Message
                    variant="light-red"
                    text={t('emailNotFound')}
                  />
                ) : (
                  existingEmailsArray?.map(
                    (email_item: string, email_index: number) => {
                      return (
                        <div
                          style={{
                            padding: "12px",
                            margin: "10px",
                            backgroundColor: Colors.Sazerac,
                            borderRadius: "6px",
                            position: "relative",
                          }}
                          key={email_index}
                        >
                          <span>{email_item}</span>
                          <CloseIcon
                            onClick={() =>
                              existingEmailsArray && [
                                setExistingEmailsArray(
                                  existingEmailsArray.filter(
                                    (x) => x !== email_item
                                  )
                                ),
                              ]
                            }
                            style={{
                              color: Colors.primaryRed,
                              height: "17px",
                              width: "17px",
                              position: "absolute",
                              top: "-6px",
                              right: "-6px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      );
                    }
                  )
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>

      <br />
      <br />

      <Row>
        <Col>
          {AdminProductCategoriesList && selectedProductId && (
            <AdminButton
              size="medium"
              text={t('deleteProductEmails')}
              fullWidth={true}
              btnStyleType="secondary"
              onClick={removeFraniProductEmailsFun}
            />
          )}
        </Col>
        <Col>
          {AdminProductCategoriesList && selectedProductId && (
            <AdminButton
              size="medium"
              text={
                (!getFraniProductCurrentEmail &&
                  isGetFraniProductCurrentEmailError) ||
                isGetFraniProductCurrentEmailError ||
                isDelete === true
                  ? `${t('create')}`
                  : `${t('update')}`
              }
              fullWidth={true}
              btnStyleType="primary"
              onClick={
                (!getFraniProductCurrentEmail &&
                  isGetFraniProductCurrentEmailError) ||
                isGetFraniProductCurrentEmailError ||
                isDelete === true
                  ? StoreFraniProductEmailsFun
                  : updateFraniProductEmailsFun
              }
            />
          )}
        </Col>
      </Row>
    </Wrapper>
  );
};
//
// styles
const Wrapper = styled.div``;
//
//
export default Admin_manage_emails;
