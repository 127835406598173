import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import CloseIcon from "../../../assets/icons/JSX/CloseIcon";
import Colors from "../../../assets/styles/Colors";
import Accordion from "../../../components/Accordion";
import Checkbox from "../../../components/Checkbox";
import CustomDropdown from "../../../components/CustomDropdown";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import UploadFile from "../../../components/UploadFile";
import {
  AdminProductDetail,
  EnabledLanguage,
  Risks,
} from "../../../domain/types";
import { useAppSelector } from "../../../store";
import AdminButton from "../../admin_components/AdminButton";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import Editor from "../../admin_components/admin_editor/Editor";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store/appSlice";
//
//
type ProductTranslationFIelds = {
  title: string;
  description: string;
  importantConditions: string;
};
//
//
const Admin_products_edit = () => {
  //
  const { productType: productTypeStr = "" } = useParams();
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation()
  // 
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );  
  //
  //
  const [productState, setProductState] = useState<Partial<AdminProductDetail>>(
    {}
  );
  //
  //
  // console.log("productState", productState);
  //
  // existing product
  const {
    data: existingProduct,
    isLoading: isExistingProductLoading,
    refetch: isExistingProductRefetch,
  } = api.useGetAdminProductDetailByTypeQuery(productTypeStr);
  //
  //
  // get all travel risks
  const { data: travelRisks, isLoading: isTravelRisksLoading } =
    api.useGetAdminTravelRiskListQuery(undefined);
  //
  // get all welcomer risks
  const { data: welcomerRisks, isLoading: isWelcomerRisksLoading } =
    api.useGetAdminWelcomerRiskListQuery(undefined);
  //
  // create
  const [storePageInfo, { isLoading: isStoreProductPageInfoLoadign }] =
    api.useStoreAdminProductDetailMutation();
  //
  //
  // update
  const [updatePageInfo, { isLoading: isUpdateProductPageInfoLoadign }] =
    api.useUpdateAdminProductDetailMutation();
  //
  //
  // remove
  const [removePageInfo, { isLoading: isRemoveProductPageInfoLoadign }] =
    api.useRemoveAdminProductDetailMutation();
  //
  //
  useEffect(() => {
    if (existingProduct) {
      setProductState(existingProduct);
    }
  }, [existingProduct]);
  //
  // add product fields
  const setProductFields = (
    field: keyof Partial<AdminProductDetail>,
    value: any
  ) => {
    setProductState({
      ...productState,
      [field]: value,
    });
  };
  //
  // add risk fields
  const setRisksFields = (field: keyof Partial<Risks>, value: any) => {
    setProductState({
      ...productState,
      risks: {
        ...productState.risks,
        [field]: value,
      },
    });
  };
  //
  //
  // add product translation fields
  const setProductTranslationFIelds = (
    field: keyof ProductTranslationFIelds,
    language: EnabledLanguage,
    value: any
  ) => {
    setProductState({
      ...productState,
      translations: {
        ...productState?.translations,
        [language]: {
          ...productState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  // add comapny translation fields
  const setCompanyTranslationFIelds = (
    field: keyof Partial<ProductTranslationFIelds>,
    language: EnabledLanguage,
    value: any
  ) => {
    setProductState({
      ...productState,
      companyProfile: {
        ...productState?.companyProfile,
        [language]: {
          ...productState?.companyProfile?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  // add covereds translation fields
  const setCoveredsTranslationFIelds = (
    field: keyof { coveredTitle: string; notCoveredTitle: string },
    language: EnabledLanguage,
    value: any
  ) => {
    setProductState({
      ...productState,
      risks: {
        ...productState?.risks,
        translations: {
          ...productState?.risks?.translations,
          [language]: {
            ...productState?.risks?.translations?.[language],
            [field]: value,
          },
        },
      },
    });
  };
  //
  // store product page info
  const _storeProductPageInfo = async () => {
    //
    try {
      await storePageInfo(productState).unwrap();
      //
      // refetch
      isExistingProductRefetch();
      //
      toast.success(`${t('createdSuccessfully')}`);
      //
      // navigate("/edit-product-detail");
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  // store product page info
  const _updateProductPageInfo = async () => {
    //
    try {
      await updatePageInfo(productState).unwrap();
      //
      // refetch
      isExistingProductRefetch();
      //
      toast.success(`${t('updatedSuccessfully')}`);
      //
      // navigate("/edit-product-detail");
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  // store product page info
  const _removeProductPageInfo = async () => {
    //
    if (productState && productState.id) {
      try {
        await removePageInfo(productState.id).unwrap();
        //
        // refetch
        isExistingProductRefetch();
        //
        toast.success(`${t('deletedSuccessfully')}`);
        //
        navigate("/edit-product-detail");
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    }
    //
  };
  //
  const risks =
    productState?.type === "travel"
      ? travelRisks
      : productState?.type === "welcomer"
      ? welcomerRisks
      : undefined;
  //
  const MainLoading =
    isExistingProductLoading ||
    isTravelRisksLoading ||
    isStoreProductPageInfoLoadign ||
    isUpdateProductPageInfoLoadign ||
    isRemoveProductPageInfoLoadign ||
    isWelcomerRisksLoading;
  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <ScreenHead>
        <AdminButton
          btnStyleType="secondary"
          text={t('prevPage')}
          onClick={() => navigate("/edit-product-detail")}
        />
        <AdminLangSwicher />
      </ScreenHead>
      <hr />
      {/* inputs */}
      <h5>
        {t('fillPageFields')} *
        {productTypeStr === "store" ? ` (${t('create')})` : ` (${t('update')})`}
      </h5>

      <Inputs>
        <Input
          id="title"
          name="title"
          label={`${t('title')} / ${field_Language?.toUpperCase()}`}
          type="text"
          placeholder=""
          value={productState?.translations?.[field_Language]?.title || ""}
          onChange={(value) =>
            setProductTranslationFIelds("title", field_Language, value)
          }
          width={"fullWidth"}
        />

        <CustomDropdown
          title={t('productType')}
          isMulti={false}
          isDisabled={
            productTypeStr !== "store" && productState?.type ? true : false
          }
          value={productState?.type}
          isClearable={false}
          onChange={(obj) => setProductFields("type", obj.value)}
          options={[
            ...["travel", "tpl", "welcomer", "pet"].map((item) => ({
              value: item,
              label: item?.toUpperCase(),
            })),
          ]}
        />

        <Input
          id="company-title"
          name="company-title"
          label={`${t('companySectionTitle')} / ${field_Language?.toUpperCase()}`}
          type="text"
          placeholder=""
          value={productState?.companyProfile?.[field_Language]?.title || ""}
          onChange={(value) =>
            setCompanyTranslationFIelds("title", field_Language, value)
          }
          width={"fullWidth"}
        />

        <Input
          id="company-description"
          name="company-description"
          label={`${t('companySectionDesription')} / ${field_Language?.toUpperCase()}`}
          type="text"
          placeholder=""
          value={productState?.companyProfile?.[field_Language]?.description || ""}
          onChange={(value) =>
            setCompanyTranslationFIelds("description", field_Language, value)
          }
          width={"fullWidth"}
        />

        <UploadFile
          label={t('pageCoverImage')}
          btnText={t('choosePhoto')}
          onChange={(props) => {
            //
            console.log("aaaaaaaa", props);
            //
            if (props && props.url && props.key) {
              setProductState({
                ...productState,
                photoUrl: props.url,
                fileKey: props.key,
              });
            }
            //
          }}
          defaultFileName={productState?.photoUrl || ""}
        />
      </Inputs>

      <br />
      <hr />
      <br />

      <Flex>
        <EditorBox>
          <label>{t('description')} / {field_Language?.toUpperCase()}</label>
          <Editor
            value={productState?.translations?.[field_Language]?.description}
            onChange={(value) =>
              setProductTranslationFIelds("description", field_Language, value)
            }
          />
        </EditorBox>

        <EditorBox>
          <label>{t('importantTerms')} / {field_Language?.toUpperCase()}</label>
          <Editor
            value={productState?.translations?.[field_Language]?.importantConditions}
            onChange={(value) =>
              setProductTranslationFIelds(
                "importantConditions",
                field_Language,
                value
              )
            }
          />
        </EditorBox>
      </Flex>

      {/* inputs */}
      <br />
      <hr />
      <br />
      {(productState.type === "travel" || productState.type === "welcomer") && (
        <>
          <Accordion openAccordion={false} title={t('chooseWhatIsCovered')}>
            <Input
              id="covered-title"
              name="covered-title"
              label={`${t('title')} / ${field_Language?.toUpperCase()}`}
              type="text"
              placeholder=""
              value={
                productState.risks?.translations?.[field_Language]?.coveredTitle || ""
              }
              onChange={(value) =>
                setCoveredsTranslationFIelds("coveredTitle", field_Language, value)
              }
              width={"fullWidth"}
            />

            <hr />

            <CoveredBlock $rowNumber={risks ? risks.length : 1}>
              {risks &&
                risks.map((item, index) => {
                  //
                  const existingRisk = productState.risks?.covered?.find(
                    (x) => x.travelRiskId === item.id
                  );
                  //
                  return (
                    <RiskBox
                      key={index}
                      style={{
                        borderColor: existingRisk
                          ? Colors.primaryRed
                          : Colors.primaryBlack,
                      }}
                    >
                      <Checkbox
                        style={{
                          marginLeft: "auto",
                        }}
                        id={item.id}
                        label=""
                        checked={existingRisk ? true : false}
                        onChange={(value) => {
                          //
                          if (
                            productState?.risks?.covered?.find(
                              (x) => x.travelRiskId === item.id
                            )
                          ) {
                            // remove risk from state
                            setProductState({
                              ...productState,
                              risks: {
                                ...productState.risks,
                                covered: [
                                  ...productState.risks.covered.filter(
                                    (x) => x.travelRiskId !== item.id
                                  ),
                                ],
                              },
                            });
                          } else {
                            //
                            setProductState({
                              ...productState,
                              risks: {
                                ...productState.risks,
                                covered: [
                                  ...(productState?.risks?.covered || []),
                                  {
                                    travelRiskId: item.id,
                                    icon: item.icon,
                                    translations: {},
                                  },
                                ],
                              },
                            });
                          }
                          //
                        }}
                      />
                      <img src={item.icon} alt={`risk-icon-${index}`} />

                      <hr style={{ width: "100%" }} />

                      <Input
                        id="risk-title"
                        name="risk-title"
                        label={`${t('title')} / ${field_Language?.toUpperCase()}`}
                        type="text"
                        placeholder=""
                        disabled={
                          productState.risks?.covered?.find(
                            (x) => x.travelRiskId === item.id
                          )
                            ? false
                            : true
                        }
                        value={
                          productState.risks?.covered?.find(
                            (x) => x.travelRiskId === item.id
                          )?.translations?.[field_Language]?.title || ""
                        }
                        //
                        onChange={(value) => {
                          //
                          const editedCovered =
                            productState.risks?.covered?.map((c) =>
                              c.travelRiskId === item.id
                                ? {
                                    ...c,
                                    translations: {
                                      ...c.translations,
                                      [field_Language]: {
                                        ...c.translations?.[field_Language],
                                        title: value,
                                      },
                                    },
                                  }
                                : c
                            );
                          //
                          setRisksFields("covered", editedCovered);
                          //
                        }}
                        width={"fullWidth"}
                      />

                      <br />

                      <TextAreaTitle>
                        {t('description')} / {field_Language?.toUpperCase()}
                      </TextAreaTitle>
                      <TextAreaStyle
                        disabled={
                          productState.risks?.covered?.find(
                            (x) => x.travelRiskId === item.id
                          )
                            ? false
                            : true
                        }
                        id={`company-short-excerpt`}
                        placeholder=""
                        value={
                          productState.risks?.covered?.find(
                            (x) => x.travelRiskId === item.id
                          )?.translations?.[field_Language]?.description || ""
                        }
                        onChange={(event) => {
                          //
                          const editedCovered =
                            productState.risks?.covered?.map((c) =>
                              c.travelRiskId === item.id
                                ? {
                                    ...c,
                                    translations: {
                                      ...c.translations,
                                      [field_Language]: {
                                        ...c.translations?.[field_Language],
                                        description: event?.target?.value,
                                      },
                                    },
                                  }
                                : c
                            );
                          //
                          setRisksFields("covered", editedCovered);
                          //
                        }}
                      ></TextAreaStyle>
                    </RiskBox>
                  );
                })}
            </CoveredBlock>
          </Accordion>
          <hr />
        </>
      )}
      {/*  */}
      {/*  */}
      {(productState.type === "tpl" ||
        productState.type === "pet") && (
        <>
          <Accordion openAccordion={false} title={t('addWhatIsCovered')}>
            <Flex
              style={{
                alignItems: "flex-end",
              }}
            >
              <AdminButton
                btnStyleType="primary"
                text={t('addWhatIsCovered')}
                onClick={() => {
                  setProductState({
                    ...productState,
                    risks: {
                      ...productState.risks,
                      covered: [
                        ...(productState.risks?.covered || []),
                        {
                          travelRiskId:
                            1 *
                            ((productState.risks?.covered?.length || 0) + 1),
                          translations: {
                            [field_Language]: {
                              description: "",
                            },
                          },
                        },
                      ],
                    },
                  });
                }}
              />

              <Input
                id="covered-title"
                name="covered-title"
                label={`${t('title')} / ${field_Language?.toUpperCase()}`}
                type="text"
                placeholder=""
                value={
                  productState.risks?.translations?.[field_Language]?.coveredTitle ||
                  ""
                }
                onChange={(value) =>
                  setCoveredsTranslationFIelds("coveredTitle", field_Language, value)
                }
                width={"fullWidth"}
              />
            </Flex>

            <NotCoveredBlock>
              {productState.risks?.covered?.map((coveredItem, coveredIndex) => {
                return (
                  <>
                    <hr />
                    <NotCOveredDiv key={coveredIndex}>
                      <RemoveNotCoveredBtn
                        onClick={() => {
                          setProductState({
                            ...productState,
                            risks: {
                              ...productState?.risks,
                              covered: [
                                ...(productState.risks?.covered || [])?.filter(
                                  (x) =>
                                    x.travelRiskId !== coveredItem.travelRiskId
                                ),
                              ],
                            },
                          });
                        }}
                      >
                        <CloseIcon />
                      </RemoveNotCoveredBtn>
                      <TextAreaTitle>
                        {t('description')} / {field_Language?.toUpperCase()}{" "}
                        <b>#{coveredIndex + 1}</b>
                      </TextAreaTitle>
                      <TextAreaStyle
                        id={`covered-description`}
                        placeholder=""
                        value={
                          coveredItem.translations?.[field_Language]?.description ||
                          ""
                        }
                        onChange={(event) => {
                          //
                          const editedNotCovered =
                            productState.risks?.covered?.map((c) =>
                              c.travelRiskId === coveredItem.travelRiskId
                                ? {
                                    ...c,
                                    translations: {
                                      ...c.translations,
                                      [field_Language]: {
                                        ...c.translations?.[field_Language],
                                        description: event.target.value,
                                      },
                                    },
                                  }
                                : c
                            );
                          //
                          setRisksFields("covered", editedNotCovered);
                          //
                        }}
                      ></TextAreaStyle>
                    </NotCOveredDiv>
                  </>
                );
              })}
            </NotCoveredBlock>
          </Accordion>
          <hr />
        </>
      )}

      <Accordion openAccordion={false} title={t('whatIsNotCovered')}>
        <Flex
          style={{
            alignItems: "flex-end",
          }}
        >
          <AdminButton
            btnStyleType="primary"
            text={t('addWhatIsNotCovered')}
            onClick={() => {
              setProductState({
                ...productState,
                risks: {
                  ...productState.risks,
                  notCovered: [
                    ...(productState.risks?.notCovered || []),
                    {
                      id:
                        1 * ((productState.risks?.notCovered?.length || 0) + 1),
                      translations: {
                        [field_Language]: {
                          description: "",
                        },
                      },
                    },
                  ],
                },
              });
            }}
          />

          <Input
            id="not-covered-title"
            name="not-covered-title"
            label={`${t('title')} / ${field_Language?.toUpperCase()}`}
            type="text"
            placeholder=""
            value={
              productState.risks?.translations?.[field_Language]?.notCoveredTitle ||
              ""
            }
            onChange={(value) =>
              setCoveredsTranslationFIelds("notCoveredTitle", field_Language, value)
            }
            width={"fullWidth"}
          />
        </Flex>

        <NotCoveredBlock>
          {productState.risks?.notCovered?.map((nt, ntIndex) => {
            return (
              <>
                <hr />
                <NotCOveredDiv key={ntIndex}>
                  <RemoveNotCoveredBtn
                    onClick={() => {
                      setProductState({
                        ...productState,
                        risks: {
                          ...productState?.risks,
                          notCovered: [
                            ...(productState.risks?.notCovered || [])?.filter(
                              (x) => x.id !== nt.id
                            ),
                          ],
                        },
                      });
                    }}
                  >
                    <CloseIcon />
                  </RemoveNotCoveredBtn>
                  <TextAreaTitle>
                    {t('description')} / {field_Language?.toUpperCase()} <b>#{ntIndex + 1}</b>
                  </TextAreaTitle>
                  <TextAreaStyle
                    id={`not-covered-description`}
                    placeholder=""
                    value={nt.translations?.[field_Language]?.description || ""}
                    onChange={(event) => {
                      //
                      const editedNotCovered =
                        productState.risks?.notCovered?.map((c) =>
                          c.id === nt.id
                            ? {
                                ...c,
                                translations: {
                                  ...c.translations,
                                  [field_Language]: {
                                    ...c.translations?.[field_Language],
                                    description: event.target.value,
                                  },
                                },
                              }
                            : c
                        );
                      //
                      setRisksFields("notCovered", editedNotCovered);
                      //
                    }}
                  ></TextAreaStyle>
                </NotCOveredDiv>
              </>
            );
          })}
        </NotCoveredBlock>
      </Accordion>
      <hr />
      <br />
      <AdminButton
        fullWidth={true}
        disabled={
          productState.type &&
          productState.translations &&
          productState.translations.ge?.title &&
          productState.translations.en?.title
            ? false
            : true
        }
        btnStyleType="primary"
        text={productTypeStr === "store" ? `${t('create')}` : `${t('update')}`}
        onClick={
          productTypeStr === "store"
            ? _storeProductPageInfo
            : _updateProductPageInfo
        }
      />

      {productTypeStr === "store" ? null : (
        <>
          <br />
          <AdminButton
            fullWidth={true}
            btnStyleType="secondary"
            text={t('delete')}
            onClick={() =>
              window.confirm(`${t('certainToDeleteIt')}`) &&
              _removeProductPageInfo()
            }
          />
        </>
      )}

      <br />
    </Wrapper>
  );
};
//
//
const RemoveNotCoveredBtn = styled.button`
  all: unset;
  position: absolute;
  top: 0;
  right: 0;
  color: ${Colors.primaryRed};
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  transition: all 100ms ease;

  &:hover {
    transform: scale(1.2);
    transition: all 100ms ease;
  }
`;
const NotCOveredDiv = styled.div`
  margin-bottom: 20px;
  border: 1px solid ${Colors.primaryBlack};
  border-radius: 8px;
  padding: 20px;
  position: relative;
`;
const NotCoveredBlock = styled.div`
  max-height: 580px;
  overflow: auto;
  padding: 5px;
`;
const TextAreaTitle = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  color: ${Colors.primaryGrey};
  margin-right: auto;
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid ${Colors.secondaryGrey};
  padding: 10px;
  font-size: 14px;
  color: ${Colors.primaryBlack};
  &:focus {
    outline-color: ${Colors.rollingStone};
  }
`;
const ScreenHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Flex = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  :first-child {
    margin-right: 20px;
  }
`;
const EditorBox = styled.div`
  label {
    width: inherit;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
    text-align: left;
    padding-right: 25px;
    color: ${Colors.primaryGrey};
  }
`;
const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
`;
const CoveredBlock = styled.div<{ $rowNumber: number }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  grid-template-rows: repeat(( $rowNumber / 2), 1fr);
  gap: 20px;
  /*  */
  img {
    width: 50px;
  }
`;
const RiskBox = styled.div`
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  padding: 20px;
  /*  */
  &:hover {
    transform: scale(1.02);
    transition: all 200ms ease-in-out;
  }
`;
const Wrapper = styled.div`
  /*  */
`;

//
export default Admin_products_edit;
