import {
  Button,
  Checkbox,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { FaPlus } from "react-icons/fa6";
import styled from "styled-components";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import Loader from "../../../components/Loader";
import AdminCreateEditPromoCode from "./AdminCreateEditPromoCode";
import NotFound404 from "../../../components/NotFound404";
import { useAppSelector } from "../../../store";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import AdminExpiredPromoCodesDetails from "./AdminExpiredPromoCodesDetails";
import dayjs from "dayjs";
import { RiDeleteBinLine } from "react-icons/ri";
import Colors from "../../../assets/styles/Colors";
import { toast } from "react-toastify";

type FilterFieldsType = {
  name: string;
  statuses: string[];
  productCategorySlug: string | undefined;
};

const AdminPromoCodes = () => {
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const { t } = useTranslation();
  //
  const [editedPromoCodeId, setEditedPromoCodeId] = useState<number | null>(
    null
  );
  //
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  //
  const [openedPromoCodeDrawer, setOpenedPromoCodeDrawer] = useState(false);
  //
  const [openedDetailedPromoCodeDrawer, setOpenedDetailedPromoCodeDrawer] =
    useState(false);
  //
  const [promoCodeId, setPromoCodeId] = useState<number | null>(null);
  //
  const [filterFields, setFilterFields] =
    useState<Partial<FilterFieldsType> | null>();
  //
  const _setFilterFields = (field: keyof FilterFieldsType, value: any) => {
    setFilterFields({
      ...filterFields,
      [field]: value,
    });
  };
  //
  // get existring promo code for editing
  const { data: existingPromoCode, isLoading: isExistingPromoCodeLoading } =
    api.useGetPromoCodeByIdQuery(editedPromoCodeId as number, {
      skip: editedPromoCodeId ? false : true,
      refetchOnMountOrArgChange: true,
    });
  //
  const [debouncedPromoCodeName] = useDebounce(filterFields?.name, 1000);
  //
  const query = `${
    debouncedPromoCodeName !== undefined && debouncedPromoCodeName !== ""
      ? `name=${debouncedPromoCodeName}&`
      : ``
  }${
    filterFields?.productCategorySlug !== undefined
      ? `productCategorySlug=${filterFields?.productCategorySlug}&`
      : ``
  }${
    filterFields?.statuses?.length !== undefined &&
    filterFields?.statuses?.length >= 1
      ? `statuses=${filterFields?.statuses}`
      : ``
  }`;
  //
  //
  const {
    data: PromoCodeList,
    isLoading: isPromoCodeListLoading,
    refetch: promoCodeRefetch,
  } = api.useGetPromoCodesListQuery({
    query: query,
  });
  //
  const [deletePromoCode, { isLoading: isDeletePromoCodeLoading }] =
    api.useDeletePromoCodeMutation();
  //
  const {
    data: AdminProductCategoriesList,
    isLoading: isAdminProductCategoriesListLoading,
  } = api.useGetAdminProductCategoriesListQuery(undefined);
  //
  //
  const columns = [
    {
      translations: {
        en: {
          title: "Promo code name",
        },
        ge: {
          title: "პრომო კოდის სახელი",
        },
      },
      dataIndex: "name",
    },
    {
      translations: {
        en: {
          title: "Promo code number",
        },
        ge: {
          title: "პრომო კოდის ნომერი",
        },
      },
      dataIndex: "promoCode",
    },
    {
      translations: {
        en: {
          title: "Product",
        },
        ge: {
          title: "პროდუქტი",
        },
      },
      dataIndex: "product",
    },
    {
      translations: {
        en: {
          title: "Start Date",
        },
        ge: {
          title: "დაწყების თარიღი",
        },
      },
      dataIndex: "startDate",
    },
    {
      translations: {
        en: {
          title: "Expire date",
        },
        ge: {
          title: "დასრულების თარიღი",
        },
      },
      dataIndex: "expireDate",
    },
    {
      translations: {
        en: {
          title: "Days",
        },
        ge: {
          title: "დღეების რაოდენობა",
        },
      },
      dataIndex: "days",
    },
    {
      translations: {
        en: {
          title: "Activation date",
        },
        ge: {
          title: "აქტივაციის თარიღი",
        },
      },
      dataIndex: "activationDate",
    },
    {
      translations: {
        en: {
          title: "coupon used",
        },
        ge: {
          title: "გამოყენებული პრომო კოდები",
        },
      },
      dataIndex: "couponLeft",
    },
    {
      translations: {
        en: {
          title: "Status",
        },
        ge: {
          title: "სტატუსი",
        },
      },
      dataIndex: "status",
    },
  ];
  //
  const statusesOptions = [
    {
      translations: {
        en: {
          title: "Active",
        },
        ge: {
          title: "აქტიური",
        },
      },
      slug: "active",
    },
    {
      translations: {
        en: {
          title: "Deactivated",
        },
        ge: {
          title: "გაუქმებული",
        },
      },
      slug: "deactivated",
    },
    {
      translations: {
        en: {
          title: "Expired",
        },
        ge: {
          title: "ვადაგასული",
        },
      },
      slug: "expired",
    },
    {
      translations: {
        en: {
          title: "Draft",
        },
        ge: {
          title: "დრაფტი",
        },
      },
      slug: "draft",
    },
    {
      translations: {
        en: {
          title: "Prepared",
        },
        ge: {
          title: "გამზადებული",
        },
      },
      slug: "prepared",
    },
  ];
  //
  const _deletePromoCodeById = async (id: number) => {
    if (
      selectedRowKeys &&
      selectedRowKeys.length === 1 &&
      id &&
      PromoCodeList &&
      PromoCodeList.find(
        (x) => x.id === selectedRowKeys[0]
      )?.status?.toLowerCase() === "draft"
    ) {
      try {
        await deletePromoCode(id).unwrap();
        //
        promoCodeRefetch();
        //
        toast.success(`promo code removed`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };
  //
  const _closeCreatePromoCodeDrawer = () => {
    setOpenedPromoCodeDrawer(false);
    setEditedPromoCodeId(null);
  };
  //
  const getDayDifference = (startDate, endDate) => {
    const start = dayjs(startDate, "YYYY-MM-DD"); // Replace 'YYYY-MM-DD' with your date format
    const end = dayjs(endDate, "YYYY-MM-DD"); // Replace 'YYYY-MM-DD' with your date format
    const diffInDays = end.diff(start, "day");
    return diffInDays + 1;
  };
  //
  //
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };
  //
  //
  const MainLoading =
    isPromoCodeListLoading ||
    isAdminProductCategoriesListLoading ||
    isDeletePromoCodeLoading ||
    isExistingPromoCodeLoading;
  //
  return MainLoading ? (
    <Loader />
  ) : !AdminProductCategoriesList ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <Flex justify={"space-between"} align={"center"}>
        <Title
          style={{
            margin: 0,
          }}
          level={4}
        >
          {`${t("promoCodes")}`}
        </Title>

        <Flex>
          {selectedRowKeys &&
            selectedRowKeys.length === 1 &&
            PromoCodeList &&
            PromoCodeList.find(
              (x) => x.id === selectedRowKeys[0]
            )?.status?.toLowerCase() === "draft" && (
              <Popconfirm
                title="Are you sure to delete the promo code?"
                description="If you delete the promo code, you cannot restore it !"
                onConfirm={() =>
                  _deletePromoCodeById(selectedRowKeys[0] as number)
                }
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: Colors.primaryRed,
                    borderColor: Colors.primaryRed,
                  }}
                  size="large"
                  icon={<RiDeleteBinLine />}
                />
              </Popconfirm>
            )}

          <Button
            style={{
              marginLeft: "20px",
              display: "flex",
              alignItems: "center",
            }}
            size="large"
            icon={<FaPlus />}
            onClick={() => setOpenedPromoCodeDrawer(true)}
          >
            {`${t("newPromoCode")}`}
          </Button>
        </Flex>
      </Flex>

      <br />

      <InputBlock>
        <SeperateDiv>
          <CustomInputs>
            <Input
              size="large"
              placeholder={`${t("searchByName")}`}
              style={{
                width: "100%",
                minWidth: "200px",
              }}
              value={filterFields?.name || ""}
              onChange={(e) => _setFilterFields("name", e.target.value)}
            />

            <Select
              // mode="tags"
              size="large"
              style={{ width: "100%", minWidth: "200px" }}
              placeholder={`${t("allProducts")}`}
              onChange={(value: string) =>
                _setFilterFields("productCategorySlug", value)
              }
              value={filterFields?.productCategorySlug}
              options={[
                ...AdminProductCategoriesList.map((item) => ({
                  value: item.slug,
                  label: item.title,
                })),
              ]}
            />
          </CustomInputs>

          <Flex justify={"center"} align={"center"}>
            <Checkbox.Group
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
              }}
              options={[
                ...statusesOptions.map((item) => ({
                  label: item.translations[lang].title,
                  value: item.slug,
                })),
              ]}
              value={filterFields?.statuses}
              onChange={(checkedValues) =>
                _setFilterFields("statuses", checkedValues)
              }
            />
          </Flex>
        </SeperateDiv>

        <Tooltip placement="bottom" title={"clean up"}>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "40px",
            }}
            type="dashed"
            danger
            size="large"
            icon={<AiOutlineClear />}
            onClick={() => [setFilterFields({})]}
          />
        </Tooltip>
      </InputBlock>

      <PromoCodeListDiv>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                //
                const _promoCodeId = record.key;
                const _promoCodeStatus = record.status.toLowerCase();
                // opend expired drawer menu
                if (_promoCodeStatus === "expired" && _promoCodeId) {
                  setOpenedDetailedPromoCodeDrawer(true);
                  //
                  setPromoCodeId(_promoCodeId);
                }
                // opend edited drawer menu
                if (_promoCodeId && _promoCodeStatus !== "expired") {
                  return [
                    setEditedPromoCodeId(_promoCodeId),
                    setOpenedPromoCodeDrawer(true),
                  ];
                }
              },
            };
          }}
          rowSelection={rowSelection}
          pagination={false}
          dataSource={
            PromoCodeList && PromoCodeList?.length >= 1
              ? [
                  ...PromoCodeList?.map((item) => ({
                    key: item.id,
                    name: item.name,
                    promoCode: item.code,
                    product: AdminProductCategoriesList?.filter((x) =>
                      item.productCategorySlugs?.map((x) => x)?.includes(x.slug)
                    )?.map((x) => x.slug + " "),
                    startDate: dayjs(item.startDate).format("D MMM YYYY"),
                    expireDate: dayjs(item.endDate).format("D MMM YYYY"),
                    days: getDayDifference(item.startDate, item.endDate),
                    activationDate: dayjs(item.activationDate).format(
                      "DD / MM / YYYY"
                    ),
                    couponLeft: `${item.remainedCapacity} / ${
                      item.capacity ? item.capacity : t("unlimited")
                    }`,
                    status: item.status,
                  })),
                ]
              : []
          }
          columns={[
            ...columns.map((item) => ({
              title: item.translations[lang].title,
              dataIndex: item.dataIndex,
            })),
          ]}
        />
      </PromoCodeListDiv>

      {/* create & edit promo code draver */}
      <Drawer
        width={500}
        title={
          existingPromoCode ? `${t("update")}` : `${t("generatePromoCodes")}`
        }
        onClose={_closeCreatePromoCodeDrawer}
        open={openedPromoCodeDrawer}
      >
        <AdminCreateEditPromoCode
          promoCodeId={editedPromoCodeId ? editedPromoCodeId : undefined}
          promoCodeRefetch={promoCodeRefetch}
          closeDrawer={_closeCreatePromoCodeDrawer}
          editedPromoCodeData={
            editedPromoCodeId ? existingPromoCode : undefined
          }
        />
      </Drawer>
      {/*! create & edit promo code draver */}

      {/*! get expired promo codes details */}
      <Drawer
        width={500}
        title={`${t("promoCodeDetails")}`}
        onClose={() => setOpenedDetailedPromoCodeDrawer(false)}
        open={openedDetailedPromoCodeDrawer}
      >
        <AdminExpiredPromoCodesDetails promoCodeId={promoCodeId} />
      </Drawer>
      {/*! get expired promo codes details */}
    </Wrapper>
  );
};
//
//
const PromoCodeListDiv = styled.div`
  margin-top: 40px;
  overflow-x: scroll;
  white-space: nowrap;

  .ant-table-row {
    cursor: pointer;
  }
`;
const Wrapper = styled.div`
  /*  */
`;
const SeperateDiv = styled.div`
  display: flex;
  gap: 10px;

  ${Responsive.laptop} {
    flex-direction: column;
    width: 100%;
    margin-right: 20px;
  }
  ${Responsive.tablet} {
    flex-direction: column;
    width: 100%;
    margin-right: 20px;
  }
  ${Responsive.mobile} {
    flex-direction: column;
    width: 100%;
    margin-right: 20px;
  }
`;
const InputBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CustomInputs = styled.div`
  display: flex;
  gap: 10px;

  ${Responsive.mobile} {
    flex-direction: column;
  }
`;

export default AdminPromoCodes;
