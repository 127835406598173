import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AdminInformationSheet, EnabledLanguage } from "../../../domain/types";
import api from "../../../api";
import Loader from "../../../components/Loader";
import DocumentFieldUploaderBlock from "../../admin_components/DocumentFieldUploaderBlock";
import Button from "../../../components/Button";
import Responsive from "../../../assets/config/Responsive";

const AdminInformationSheets = () => {
  //
  const { slug } = useParams();
  //
  const { t } = useTranslation();
  //
  const [existingAttachment, setExistingAttachment] = useState<
    Partial<AdminInformationSheet>
  >({});
  //
  const { data: productCategories, isLoading: isProductCategoryLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);
  //
  const categoryId = productCategories?.find((x) => x.slug === slug)?.id || 0;
  //
  const {
    data: informationsheetlist,
    isLoading: isInformationSheetListLoading,
  } = api.useGetAdminInformationSheetQuery({
    categoryId: categoryId,
    attachmentSlug: "information-sheet",
  });
  //
  console.log(informationsheetlist);
  //
  const itemID =
    informationsheetlist?.find((x) => x.productCategoryId === categoryId)?.id ||
    0;
  //
  const {
    data: currentInformationSheet,
    isLoading: isCurrentInformationSheetLoading,
    refetch: refetchCurrentSheet,
  } = api.useGetAdminInformationSheetDetailQuery(itemID, {
    skip: !itemID && true,
  });
  //
  const [storeAttachment, { isLoading: isStoreAttachmentLoading }] =
    api.useStoreInformationSheetMutation();
  //
  useEffect(() => {
    if (
      informationsheetlist &&
      informationsheetlist?.length >= 1 &&
      currentInformationSheet
    ) {
      setExistingAttachment(currentInformationSheet);
    }
    //
    if(informationsheetlist && informationsheetlist?.length < 1) {
      setExistingAttachment({})
    }
  }, [currentInformationSheet, informationsheetlist]);
  //
  useEffect(() => {
    if (itemID !== 0) {
      refetchCurrentSheet();
    }
    //
    return () => {};
  }, [refetchCurrentSheet, itemID]);
  //
  const setEditAttachmentTranslationField = (
    field: string,
    language: EnabledLanguage,
    value: any
  ) => {
    setExistingAttachment({
      ...existingAttachment,
      attachment: {
        ...existingAttachment.attachment,
        [language]: {
          ...existingAttachment?.attachment?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  // store sheet
  const storeSheetFun = async () => {
    //
    try {
      if (itemID === 0) {
        await storeAttachment({
          productCategoryId: categoryId,
          attachmentSlug: "information-sheet",
          attachment: existingAttachment.attachment,
        }).unwrap();
      } else {
        await storeAttachment({
          ...existingAttachment,
        }).unwrap();
      }
      //
      toast.success(
        itemID === 0
          ? `${t("createdSuccessfully")}`
          : `${t("updatedSuccessfully")}`
      );
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  const mainLoading =
    isProductCategoryLoading ||
    isInformationSheetListLoading ||
    isCurrentInformationSheetLoading ||
    isStoreAttachmentLoading;
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <DocumentBlockTitle>{t("informationSheet")}</DocumentBlockTitle>

      <Row>
        <Column $widthPercent={50}>
          <Block>
            <DocumentBlockTitle>EN</DocumentBlockTitle>
            <DocumentFieldUploaderBlock
              url={existingAttachment?.attachment?.en?.url || ""}
              onChange={(newUrl) =>
                setEditAttachmentTranslationField("url", "en", newUrl)
              }
              width={300}
            />
          </Block>
        </Column>

        <Column $widthPercent={50}>
          <Block>
            <DocumentBlockTitle>GE</DocumentBlockTitle>
            <DocumentFieldUploaderBlock
              url={existingAttachment?.attachment?.ge?.url || ""}
              onChange={(newUrl) =>
                setEditAttachmentTranslationField("url", "ge", newUrl)
              }
              width={300}
            />
          </Block>
        </Column>
      </Row>

      <Button
        btnStyleType="primary"
        icon={false}
        fullWidth={true}
        disabled={false}
        onClick={() => storeSheetFun()}
        text={itemID ? t("update") : t("create")}
      />
    </Wrapper>
  );
};
//
//
const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-bottom: 20px;
  /*  */
  > div {
    flex-basis: 50%;
  }
`;

const Column = styled.div<{ $widthPercent?: number }>`
  padding: 0 15px 20px 15px;
  ${({ $widthPercent = 50 }) =>
    $widthPercent ? `width: calc(${$widthPercent}% - 15px);` : ""}
  ${Responsive.tablet} {
    width: 100%;
  }
  ${Responsive.mobile} {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${Responsive.tablet} {
    flex-direction: column;
  }
`;

const DocumentBlockTitle = styled.h5`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  font-size: 18px;
  /* padding-left: 5px; */
`;

const Wrapper = styled.div`
  padding: 20px 30px;
`;
export default AdminInformationSheets;
