import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import { MediumScreenTitle } from "../../../assets/styles/GlobalStyle";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import BaseModal from "../../../components/modals/BaseModal";
import { EnabledLanguage, MainPageText } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store/appSlice";
//
//
const Admin_landing_manage_texts = () => {
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );  
  //
  const [selectedPageId, setSelectedPageId] = useState<number | null>(null);
  //
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  //
  const [selectedPage, setSelectedPage] = useState<Partial<MainPageText>>({});
  //
  const { t } = useTranslation()
  //
  // main page text list query
  const {
    data: mainpageTextList,
    isLoading: isMainPageTextLoading,
    refetch: refetchMainPageText,
  } = api.useGetMainPageTextListQuery(undefined);
  //
  //
  const { data: pageById, isLoading: isPageIdLoading } =
    api.useGetMainPageTextByIdQuery(selectedPageId as number, {
      skip: !selectedPageId && true,
    });
  //
  //
  const [updatePage, { isLoading: isUpdatePageLoading }] =
    api.useUpdateMainPageTextMutation();
  //
  //
  // update page text
  const updatePageText = async () => {
    //
    try {
      await updatePage(selectedPage).unwrap();
      //
      // refetch
      refetchMainPageText();
      // close modal
      _onCloseModal();
      //
      toast.success(`${t('text')} ${t('updatedSuccessfully')}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  // add page translation fields
  const setEditPageTranslatedField = (
    field: keyof MainPageText,
    language: EnabledLanguage,
    value: any
  ) => {
    setSelectedPage({
      ...selectedPage,
      translations: {
        ...selectedPage.translations,
        [language]: {
          ...selectedPage?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  //close base modal
  const _onCloseModal = () => {
    setOpenEditModal(false);
    setSelectedPageId(null);
  };
  //
  //
  // set page info in fields when user click page card
  useEffect(() => {
    if (pageById) {
      setSelectedPage(pageById);
    }
  }, [pageById]);
  //
  // main loading
  const MainLoading =
    isMainPageTextLoading || isPageIdLoading || isUpdatePageLoading;
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <MediumScreenTitle>{t('managePages')}</MediumScreenTitle>

      <AdminLangSwicher />
      <br />
      <br />
      <Grid>
        {mainpageTextList &&
          mainpageTextList.map((item, index) => (
            <TextBlock
              key={index}
              onClick={() => [
                setOpenEditModal(!openEditModal),
                setSelectedPageId(item.id),
              ]}
            >
              <div>{item.translations?.[field_Language]?.title}</div>
              <div>{item.translations?.[field_Language]?.body}</div>
              <div>{item.translations?.[field_Language]?.excerpt}</div>
            </TextBlock>
          ))}
      </Grid>
      <br />
      <br />
      <BaseModal onClose={_onCloseModal} isOpen={openEditModal}>
        <div>
          <ModalHead>
            <h4>{t('update')}</h4>
            <AdminLangSwicher />
          </ModalHead>

          <FieldsContent>
            <Input
              id="title"
              label={t('title')}
              type="text"
              placeholder=""
              value={selectedPage?.translations?.[field_Language]?.title || ""}
              name="title"
              onChange={(value) =>
                setEditPageTranslatedField("title", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="body"
              label={t('description')}
              type="text"
              placeholder=""
              value={selectedPage?.translations?.[field_Language]?.body || ""}
              name="body"
              onChange={(value) =>
                setEditPageTranslatedField("body", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="body-two"
              label={`${t('description')} 2`}
              type="text"
              placeholder=""
              value={selectedPage?.translations?.[field_Language]?.excerpt || ""}
              name="body-two"
              onChange={(value) =>
                setEditPageTranslatedField("excerpt", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <br />

            <Button
              fullWidth={true}
              btnStyleType="transparent"
              border={true}
              text={t('save')}
              onClick={updatePageText}
            />
          </FieldsContent>
        </div>
      </BaseModal>
    </Wrapper>
  );
};
//
//
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.grey};
`;
const TextBlock = styled.div`
  border: 1px solid ${Colors.primaryRed};
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: 200ms ease all;
  /*  */
  div {
    border-bottom: 1px dashed ${Colors.primaryGrey};
    margin-bottom: 5px;
    &:last-child {
      border-bottom: 0px;
    }
  }
  /*  */
  &:hover {
    transform: scale(1.02);
    transition: 200ms ease all;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  text-align: center;
`;
const Wrapper = styled.div``;
//
export default Admin_landing_manage_texts;
