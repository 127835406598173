import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../../api";
import Colors from "../../../../assets/styles/Colors";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import BaseModal from "../../../../components/modals/BaseModal";
import { EnabledLanguage, PackageService } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import Button from "../../../admin_components/AdminButton";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../../store/appSlice";
//
//
type AddServiceTranslatedFields = {
  name: string;
  comment: string;
};
//
//
const AdminTplServices = () => {
  //
  const { t } = useTranslation();
  // 
  const { lang } = useAppSelector((root) => root.app);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  // selected service id
  const [packageServiceId, setPackageServiceId] = useState<number | null>(null);
  //
  // states
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  //
  // add service state
  const [addPackageServiceState, setAddPackageServiceState] = useState<
    Partial<PackageService>
  >({});
  //
  //
  // get package services
  const {
    data: packageServices,
    isLoading: isGetPackageServicesLoading,
    refetch: refetchPackageServices,
  } = api.useGetPackageServicesQuery({ category: "tpl" });
  //
  //
  // get package service by id
  const {
    data: existingPackageService,
    isLoading: isExistingPackageServiceLoading,
  } = api.useGetPackageServiceByIdQuery(
    { category: "tpl", id: packageServiceId as number },
    {
      skip: true && !packageServiceId,
    }
  );
  //
  //
  // add travel service
  const [addPackageService, { isLoading: isAddPackageServiceLoading }] =
    api.useAddPackageServiceMutation();
  //
  //
  // update travel service
  const [updatePackageService, { isLoading: isUpdatePackageServiceLoading }] =
    api.useUpdatePackageServiceMutation();
  //
  //
  const updatePackageServiceFun = async () => {
    //
    try {
      await updatePackageService({
        category: "tpl",
        service: addPackageServiceState,
      }).unwrap();
      //
      // refetch travel service
      refetchPackageServices();
      // close modal
      _onAddServiceCloseModal();
      //
      toast.success(`${t('service')} ${t('updatedSuccessfully')}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
    //
  };
  //
  //
  // remvoe travel service
  const [
    removePackageService,
    { isLoading: isRemoveServiceLoading, isSuccess: removeServiceSucces },
  ] = api.useRemovePackageServiceMutation();
  //
  //
  //close add service modal
  const _onAddServiceCloseModal = () => {
    setOpenCreateModal(false);
    setPackageServiceId(null);
  };
  //
  //
  // add service fields
  const setAddPackageService = (field: keyof PackageService, value: any) => {
    setAddPackageServiceState({
      ...addPackageServiceState,
      [field]: value,
    });
  };
  //
  //
  //
  // add service translation fields
  const setAddServiceTranslatedField = (
    field: keyof AddServiceTranslatedFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddPackageServiceState({
      ...addPackageServiceState,
      translations: {
        ...addPackageServiceState.translations,
        [language]: {
          ...addPackageServiceState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  // add service function
  const AddServiceFunction = async () => {
    //
    try {
      await addPackageService({
        category: "tpl",
        service: addPackageServiceState,
      }).unwrap();
      //
      // refetch travel services
      refetchPackageServices();
      // close modal
      _onAddServiceCloseModal();
      //
      toast.success(`${t('service')} ${t('addedSuccessfully')}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  // auto refetch services when user remove service
  useEffect(() => {
    if (removeServiceSucces) {
      refetchPackageServices();
      _onAddServiceCloseModal();
    }
  }, [removePackageService, removeServiceSucces, refetchPackageServices]);
  //
  //
  //
  useEffect(() => {
    if (existingPackageService) {
      setAddPackageServiceState(existingPackageService);
    }
  }, [existingPackageService]);
  //
  //
  //
  //
  const MainLoading =
    isGetPackageServicesLoading ||
    isAddPackageServiceLoading ||
    isUpdatePackageServiceLoading ||
    isRemoveServiceLoading ||
    isExistingPackageServiceLoading;
  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        btnStyleType="primary"
        text={t('createService')}
        onClick={() => [
          setOpenCreateModal(!openCreateModal),
          setAddPackageServiceState({}),
        ]}
      />
      <br />
      <br />
      <ServiceContent>
        {packageServices && packageServices.length ? (
          <>
            {packageServices.map((item, index) => (
              <ServiceBox
                key={index}
                onClick={() => [
                  setPackageServiceId(item.id),
                  setOpenCreateModal(!openCreateModal),
                ]}
              >
                <div>{t('position')}: {item.sort}</div>
                <p>{t('service')}: {item.translations?.[lang]?.name}</p>
                <p>{t('comment')}: {item.comment}</p>
              </ServiceBox>
            ))}
          </>
        ) : (
          <h5>{t('noServicesFound')}</h5>
        )}
      </ServiceContent>
      {/*  */}
      {/* add new service */}
      <BaseModal onClose={_onAddServiceCloseModal} isOpen={openCreateModal}>
        <div>
          <ModalHead>
            <h4>
              {packageServiceId ? t('updateService') : t('createService')}
            </h4>
            <AdminLangSwicher />
          </ModalHead>

          <FieldsContent>
            <Input
              id="service-sort-id"
              name="service-sort-id"
              label={t('positionExample')}
              type="number"
              placeholder=""
              value={addPackageServiceState.sort || ""}
              onChange={(value) => setAddPackageService("sort", value)}
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <Input
              id="service-name-id"
              name="service-name-id"
              label={t('serviceTitle')}
              type="text"
              placeholder=""
              value={
                addPackageServiceState?.translations?.[field_Language]?.name || ""
              }
              onChange={(value) =>
                setAddServiceTranslatedField("name", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <UploadFile
              label={t('serviceIcon')}
              onChange={(props) => setAddPackageService("iconPath", props.url)}
              defaultFileName={addPackageServiceState?.iconPath || ""}
            />

            <br />

            <Input
              id="service-comment-id"
              name="service-comment-id"
              label={t('comment')}
              type="text"
              placeholder=""
              value={
                addPackageServiceState?.translations?.[field_Language]?.comment || ""
              }
              onChange={(value) =>
                setAddServiceTranslatedField("comment", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <ButtonsBlock>
              {/* add service */}
              <Button
                fullWidth={true}
                btnStyleType="primary"
                text={packageServiceId ? t('update') : t('create')}
                onClick={
                  packageServiceId
                    ? updatePackageServiceFun
                    : AddServiceFunction
                }
              />
              {/* add service */}
              <br />
              {/* remove  service */}
              {packageServiceId && (
                <Button
                  fullWidth={true}
                  btnStyleType="transparent"
                  border={true}
                  text={t('delete')}
                  onClick={() =>
                    window.confirm(`${t('certainToDeleteIt')}`) &&
                    removePackageService({
                      category: "tpl",
                      id: packageServiceId,
                    })
                  }
                />
              )}
              {/* remove service */}
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
      {/* add new service */}
    </Wrapper>
  );
};
//
// styles
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 20px;
`;

const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.grey};
`;
const ServiceBox = styled.div`
  height: 200px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  /*  */
  &:hover {
    transform: scale(1.02);
    transition: all 200ms ease-in-out;
  }
  /*  */
  img {
    width: 60px;
  }
  /*  */
  div {
    font-size: 18px;
    margin-block: 10px;
    text-align: center;
  }
  /*  */
  p {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
  }
`;
const ServiceContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  /*  */
`;
const Wrapper = styled.div``;
//
export default AdminTplServices;
