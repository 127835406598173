import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import api from '../../../api'
import Colors from '../../../assets/styles/Colors'
import Button from '../../../components/Button'
import Loader from '../../../components/Loader'
import { useAppSelector } from '../../../store'
import AdminLangSwicher from '../../admin_components/AdminLangSwicher'
import { useTranslation } from 'react-i18next'
import { AppState } from '../../../store/appSlice'
//
//
const AdminPaymentScheduleTypes = () => {
  //
  const { t } = useTranslation()
  //
  const navigate = useNavigate()
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  //
  // get policy deactive reason
  const {
    data: paymentScheduleTypeArr,
    isLoading: isPaymentScheduleTypeArrLoading,
    refetch,
  } = api.useGetPaymentScheduleTypesQuery(2)
  //
  //
  useEffect(() => {
    refetch()
    //
    return () => {}
  }, [refetch])

  //
  // main loader
  const MainLoader = isPaymentScheduleTypeArrLoading
  //
  //
  return MainLoader ? (
    <Loader />
  ) : (
    <Wrapper>
      <HeadDiv>
        <Button
          btnStyleType="primary"
          text={t('createPaymentMultiples')}
          onClick={() => navigate(`/payment-schedule-types-detail/0`)}
          size="medium"
        />
        <AdminLangSwicher />
      </HeadDiv>

      <br />
      <ReasonBlock>
        {paymentScheduleTypeArr &&
          paymentScheduleTypeArr.map((item, index) => (
            <ScheduleTypeItem
              $isActive={item.isActive}
              key={index}
              onClick={() =>
                navigate(`/payment-schedule-types-detail/${item.id}`)
              }
            >
              <p>
                {item.translations?.[field_Language]?.title || t('textNotFound')}
              </p>
            </ScheduleTypeItem>
          ))}
      </ReasonBlock>
    </Wrapper>
  )
}
//
const HeadDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ScheduleTypeItem = styled.div<{ $isActive: boolean }>`
  border: ${({ $isActive }) =>
    $isActive
      ? `1px solid ${Colors.primaryGreen}`
      : `1px solid ${Colors.sunsetOrange}`};
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  transition: all 200ms ease;

  p {
    margin: 0;
  }

  &:hover {
    transform: scale(1.02);
    transition: all 200ms ease;
  }
`
const ReasonBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  text-align: center;
`
const Wrapper = styled.div`
  /*  */
`
//
export default AdminPaymentScheduleTypes
