import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Responsive from "../../../../../assets/config/Responsive";
import CloseIcon from "../../../../../assets/icons/JSX/CloseIcon";
import Colors from "../../../../../assets/styles/Colors";
// import Button from "../../../../../components/Button";
import Input from "../../../../admin_components/AdminInput";
import Loader from "../../../../../components/Loader";
import NotFound404 from "../../../../../components/NotFound404";
import InfoIcon from "../../../../../assets/icons/JSX/InfoIcon";
import _debounce from "lodash.debounce";
import {
  EnabledLanguage,
  Product,
  TplProduct,
} from "../../../../../domain/types";
import { useAppSelector } from "../../../../../store";
import {
  default as AdminButton,
  default as Button,
} from "../../../../admin_components/AdminButton";
import { toast } from "react-toastify";
import Col from "react-bootstrap/esm/Col";
import dayjs from "dayjs";
import CustomDropdown from "../../../../../components/CustomDropdown";
import BaseModal from "../../../../../components/modals/BaseModal";
import { useTranslation } from "react-i18next";

//
//
const EditTplProductFieldsTab: React.FC<{
  tplProduct?: TplProduct;
  language: EnabledLanguage;
  isLoading: boolean;
  setTranslatedFieldOnProduct: any;
  setTplProductField: (fieldName: keyof TplProduct, fieldValue: any) => void;
  setMainProductField: (fieldName: keyof Product, fieldValue: any) => void;
  saveTab: any;
  approveProductChanges: any;
  rejectProductChanges: any;
}> = ({
  tplProduct,
  language,
  setTranslatedFieldOnProduct,
  setTplProductField,
  setMainProductField,
  isLoading,
  saveTab,
  approveProductChanges,
  rejectProductChanges,
}) => {
  //
  const { t } = useTranslation()
  //
  const auth = useAppSelector((data) => data.auth);
  const isAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";
  //
  const [warningAlertRange, setWarningAlertRange] = useState<string | number>(
    ""
  );
  //
  const [emailsOfCompanyUsers, setEmailsOfCompanyUsers] = useState<
    string[] | []
  >(tplProduct?.mainProduct?.emails || []);

  const [enteredEmail, setEnteredEmail] = useState<string | undefined>("");

  // set exist company user

  const removeLeadingZeros = (input: string) => {
    const trimmedString = input.replace(/^0+/, "");
    const numberValue = parseInt(trimmedString, 10);

    return numberValue;
  };

  useEffect(() => {
    if (tplProduct?.mainProduct?.emails) {
      setEmailsOfCompanyUsers(tplProduct?.mainProduct?.emails);
    }
    //
    return () => {};
  }, [tplProduct?.mainProduct?.emails]);
  //
  //
  useEffect(() => {
    if (emailsOfCompanyUsers) {
      setMainProductField("emails", emailsOfCompanyUsers);
    }
    //
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsOfCompanyUsers]);

  //
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = _debounce(
      () => setScreenWidth(window.innerWidth),
      100
    );

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //
  //
  const [openWarningRangeModal, setOpenWarningRangeModal] = useState<{
    isOpen: boolean;
    index: number;
  }>({ isOpen: false, index: 0 });
  //
  //
  const _CloseWarningRangeModal = (isOpen: boolean, index: number) => {
    setOpenWarningRangeModal({ isOpen, index });
  };
  //
  //
  return isLoading ? (
    <Loader />
  ) : !tplProduct ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <ContentBox>
        <Column
          style={{ padding: "20px 10px 20px 50px" }}
          $widthPercent={screenWidth <= 1199 ? 100 : 60}
        >
          <Row>
            <Column style={{ padding: "0px" }}>
              <Block>
              <div>{t('title')}</div>
              </Block>
            </Column>
            <Column style={{ padding: "0px" }}>
              <Block>
                <Input
                  id="name-id"
                  label=""
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  value={
                    tplProduct?.mainProduct?.translations?.[language]?.title ||
                    ""
                  }
                  required={false}
                  error={false}
                  onChange={(value) =>
                    setTranslatedFieldOnProduct("title", language, value)
                  }
                />
              </Block>
            </Column>
          </Row>

          <Row>
            <Column style={{ padding: "0px" }}>
              <Block>
              <div>{t('insuranceCompany')}</div>
              </Block>
            </Column>
            <Column style={{ padding: "0px" }}>
              <Block>
                <Input
                  id="brand-name"
                  label=""
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  value={
                    tplProduct.mainProduct.company.brandName?.[language]
                      ?.title || ""
                  }
                  required={false}
                  error={false}
                  disabled={true}
                  onChange={(value) => {}}
                />
              </Block>
            </Column>
          </Row>

          <Row>
            <Column style={{ padding: "0px" }}>
              <Block>
              <div>{t('productGroup')}</div>
              </Block>
            </Column>
            <Column style={{ padding: "0px" }}>
              <Block>
                <Input
                  id="car-insurance"
                  label=""
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  value={t('motorLiabilityInsurance')}
                  required={false}
                  error={false}
                  onChange={(value) => {}}
                  disabled={true}
                />
              </Block>
            </Column>
          </Row>
          <Row>
            <Column style={{ padding: "0px" }}>
              <Block>
              <div>{t('product')}</div>
              </Block>
            </Column>
            <Column style={{ padding: "0px" }}>
              <Block>
                <Input
                  id="insuranceType"
                  label=""
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  value={"Tpl"}
                  required={false}
                  error={false}
                  onChange={(value) => {}}
                  disabled={true}
                />
              </Block>
            </Column>
          </Row>
          <Row>
            <Column style={{ padding: "0px" }}>
              <Block>
              <div>{t('status')}</div>
              </Block>
            </Column>
            <Column style={{ padding: "0px" }}>
              <Block>
                <CustomDropdown
                  title=""
                  placeholder={
                    tplProduct.mainProduct.integrated
                    ? `${t('integrated')}`
                    : `${t('nonIntegrated')}`
                  }
                  isClearable={false}
                  value={tplProduct.mainProduct.integrated}
                  onChange={(obj) =>
                    setMainProductField("integrated", obj.value)
                  }
                  options={[
                    ...[
                      { label: `${t('integrated')}`, value: true },
                      { label: `${t('nonIntegrated')}`, value: false },
                    ].map((item) => ({
                      value: item.value,
                      label: item.label,
                    })),
                  ]}
                />
              </Block>
            </Column>
          </Row>
          <Row>
            <Column style={{ padding: "0px" }}>
              <Block>
              <div>{t('productMarketingPrice')}</div>
              </Block>
            </Column>
            <Column style={{ padding: "0px" }}>
              <Block>
                <Input
                  id="marketing-price"
                  label=""
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  required={false}
                  error={false}
                  value={tplProduct.productMarketingPrice}
                  onChange={(value) =>
                    setTplProductField("productMarketingPrice", value)
                  }
                />
              </Block>
            </Column>
          </Row>
          <Row>
            <Column style={{ padding: "0px" }}>
              <Block>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                  {t('startDateRestriction')}
                  </div>
                  <InfoIcon />
                </div>
              </Block>
            </Column>
            <Column style={{ padding: "0px" }}>
              <Block>
                <Input
                  id="limitation-of-start-date"
                  label=""
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  required={false}
                  error={false}
                  value={tplProduct.limitationOfStartDate?.toString()}
                  onChange={(value) => {
                    setTplProductField("limitationOfStartDate", value);
                  }}
                />
              </Block>
            </Column>
          </Row>
          <Row>
            <Column style={{ padding: "0px" }}>
              <Block>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                  {t('productRealizationDate')}
                  </div>
                  <InfoIcon />
                </div>
              </Block>
            </Column>
            <Column style={{ padding: "0px" }}>
              <Block>
                <Input
                  id="limit-before-realization"
                  label=""
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  required={false}
                  error={false}
                  value={tplProduct.limitBeforeRealization}
                  onChange={(value) =>
                    setTplProductField("limitBeforeRealization", value)
                  }
                />
              </Block>
            </Column>
          </Row>

          <br />
          <br />
          <br />

          <Block>
          <Title>{t('policyNumber')}</Title>
            <PolicyNumberGroupDiv>
              <Row>
                <Col>
                <Text>{`(${t('prefix')})`}</Text>
                </Col>
                <Col>
                <Text>{t('from')}</Text>
                </Col>
                <Col>
                <Text>{t('to')}</Text>
                </Col>
                <Col>
                <Text>{`(${t('suffix')})`}</Text>
                </Col>
                <Col>
                <Text>{t('utilized')}</Text>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <PolicyNumberGroupScrollView>
                {tplProduct.mainProduct.policyNumberRanges.map(
                  (range, rangeIndex) => (
                    <PolicyNumberBlock key={range.id}>
                      {/*  alert range modal */}
                      <BaseModal
                        isOpen={
                          openWarningRangeModal.isOpen === true &&
                          openWarningRangeModal.index === rangeIndex
                            ? true
                            : false
                        }
                        onClose={() =>
                          _CloseWarningRangeModal(false, rangeIndex)
                        }
                      >
                        {/*  */}
                        <WarningAlertRanges>
                          {range?.warningAlertRanges?.map(
                            (warningRange, warningRangeIndex) => (
                              <WarningRangeDiv key={warningRangeIndex}>
                                <span>{warningRange}</span>
                                <button
                                  onClick={() => {
                                    const listWithUpdatedPolicyWarningAlertRange =
                                      tplProduct.mainProduct.policyNumberRanges.map(
                                        (g, i) =>
                                          i === rangeIndex
                                            ? {
                                                ...g,
                                                warningAlertRanges:
                                                  g.warningAlertRanges.filter(
                                                    (x) => x !== warningRange
                                                  ),
                                              }
                                            : g
                                      );
                                    setMainProductField(
                                      "policyNumberRanges",
                                      listWithUpdatedPolicyWarningAlertRange
                                    );
                                  }}
                                >
                                  X
                                </button>
                              </WarningRangeDiv>
                            )
                          )}
                        </WarningAlertRanges>
                        <hr />

                        <Input
                          width="fullWidth"
                          id="email-warning-range-input"
                          label={t('numbersOfEmails')}
                          value={warningAlertRange || ""}
                          onChange={(value) =>
                            setWarningAlertRange(parseInt(value))
                          }
                        />

                        <br />

                        <AdminButton
                          fullWidth={true}
                          text={t('add')}
                          onClick={() => {
                            const listWithUpdatedPolicyWarningAlertRange =
                              tplProduct.mainProduct.policyNumberRanges.map(
                                (g, i) => {
                                  //
                                  if (i === rangeIndex) {
                                    //
                                    const currentRanges =
                                      g.warningAlertRanges === null
                                        ? []
                                        : [...g.warningAlertRanges];

                                    const editedRange = [
                                      ...currentRanges,
                                      warningAlertRange,
                                    ];
                                    //
                                    return {
                                      ...g,
                                      warningAlertRanges:
                                        editedRange || g.warningAlertRanges,
                                    };
                                    //
                                  } else {
                                    //
                                    return g;
                                    //
                                  }
                                  //
                                }
                              );
                            //
                            setWarningAlertRange("");
                            //
                            setMainProductField(
                              "policyNumberRanges",
                              listWithUpdatedPolicyWarningAlertRange
                            );
                          }}
                        />
                        {/*  */}
                      </BaseModal>
                      {/*  alert range modal */}

                      <Row>
                        <Col>
                          <Input
                            id={`policy-numbers-prefix`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.policyPrefix}
                            onChange={(value) => {
                              const listWithUpdatedPolicyPrefix =
                                tplProduct.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, policyPrefix: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedPolicyPrefix
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            id={`policy-numbers-minPolicyNumber-${rangeIndex}`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.minPolicyNumber}
                            onChange={(value) => {
                              const listWithUpdatedMinPolicyNumber =
                                tplProduct.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, minPolicyNumber: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedMinPolicyNumber
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            id={`policy-numbers-maxPolicyNumber-${rangeIndex}`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.maxPolicyNumber}
                            onChange={(value) => {
                              const listWithUpdatedMaxPolicyNumber =
                                tplProduct.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, maxPolicyNumber: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedMaxPolicyNumber
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            id={`policy-numbers-suffix`}
                            className="policy-number-class"
                            width="fullWidth"
                            length={3}
                            label=""
                            value={range.policySuffix}
                            onChange={(value) => {
                              const listWithUpdatedPolicySuffix =
                                tplProduct.mainProduct.policyNumberRanges.map(
                                  (g, i) =>
                                    i === rangeIndex
                                      ? { ...g, policySuffix: value }
                                      : g
                                );
                              setMainProductField(
                                "policyNumberRanges",
                                listWithUpdatedPolicySuffix
                              );
                            }}
                          />
                        </Col>

                        <Col>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {range.usedPolicyNumber
                              ? `${range.policyPrefix ?? ""}${
                                  range.usedPolicyNumber
                                }${range.policySuffix ?? ""}`
                              : `${t('none')}`}
                          </div>
                        </Col>

                        <Col>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            <Button
                              btnStyleType="transparent"
                              size="medium"
                              border={true}
                              icon={false}
                              fullWidth={false}
                              disabled={false}
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                              }}
                              text={t('ranges')}
                              onClick={() =>
                                setOpenWarningRangeModal({
                                  isOpen: true,
                                  index: rangeIndex,
                                })
                              }
                            />

                            <Button
                              btnStyleType="secondary"
                              size="medium"
                              icon={false}
                              fullWidth={false}
                              disabled={false}
                              style={{ display: "inline-block" }}
                              onClick={() => {
                                setMainProductField(
                                  "policyNumberRanges",
                                  tplProduct.mainProduct.policyNumberRanges.filter(
                                    (g, i) => i !== rangeIndex
                                  )
                                );
                              }}
                              text={t('delete')}
                            />
                          </div>
                        </Col>
                      </Row>
                    </PolicyNumberBlock>
                  )
                )}
              </PolicyNumberGroupScrollView>
              <br />
              <Button
                btnStyleType="secondary"
                size="medium"
                icon={false}
                fullWidth={true}
                disabled={false}
                onClick={() => {
                  const maxUsedPolicyNumber =
                    tplProduct.mainProduct.policyNumberRanges.reduce(
                      (c, n) =>
                        Math.max(c, removeLeadingZeros(n.maxPolicyNumber)),
                      0
                    );
                  const lastUsedPolicyPrefix = [
                    ...tplProduct.mainProduct.policyNumberRanges,
                  ].reverse()[0]?.policyPrefix;

                  const lastUsedPolicySuffix = [
                    ...tplProduct.mainProduct.policyNumberRanges,
                  ].reverse()[0]?.policySuffix;

                  const newMinPolicyNumber = maxUsedPolicyNumber + 1;

                  setMainProductField("policyNumberRanges", [
                    ...tplProduct.mainProduct.policyNumberRanges,
                    {
                      id:
                        -1 *
                        (tplProduct.mainProduct.policyNumberRanges.length + 1),
                      minPolicyNumber: newMinPolicyNumber.toLocaleString(),
                      maxPolicyNumber: (
                        newMinPolicyNumber + 1
                      ).toLocaleString(),
                      policyPrefix: lastUsedPolicyPrefix,
                      policySuffix: lastUsedPolicySuffix,
                    },
                  ]);
                }}
                text={t('addPolicyNumber')}
              />
            </PolicyNumberGroupDiv>
          </Block>

          <div>
          <h6>{t('companyUserEmails')}</h6>
            <div
              style={{
                position: "relative",
              }}
            >
              <Input
                id="email"
                label={""}
                type="email"
                placeholder="example@gmail.com"
                value={enteredEmail}
                name="email"
                onChange={(value) => setEnteredEmail(value)}
                width={"fullWidth"}
                style={{ marginBottom: "24px" }}
              />

              <AdminButton
                style={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
                size="medium"
                text="დამატება"
                fullWidth={false}
                btnStyleType="primary"
                onClick={() => {
                  if (
                    enteredEmail &&
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      enteredEmail
                    )
                  ) {
                    setEmailsOfCompanyUsers((prevNames) => [
                      ...prevNames,
                      enteredEmail,
                    ]);
                    //
                    setEnteredEmail("");
                    //
                  } else {
                    toast.error(`${t('validEmail')}`);
                  }
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {emailsOfCompanyUsers?.map(
                (email_item: string, email_index: number) => {
                  return (
                    <div
                      style={{
                        padding: "12px",
                        margin: "10px",
                        backgroundColor: Colors.Sazerac,
                        borderRadius: "6px",
                        position: "relative",
                      }}
                      key={email_index}
                    >
                      <span>{email_item}</span>
                      <CloseIcon
                        onClick={() =>
                          emailsOfCompanyUsers && [
                            setEmailsOfCompanyUsers(
                              emailsOfCompanyUsers.filter(
                                (x) => x !== email_item
                              )
                            ),
                          ]
                        }
                        style={{
                          color: Colors.primaryRed,
                          height: "17px",
                          width: "17px",
                          position: "absolute",
                          top: "-6px",
                          right: "-6px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  );
                }
              )}
            </div>
            <br />
          </div>
          <br />
          <br />
          <br />

          <Row>
            <Column style={{ padding: "0px" }}>
              <Block>
              <div>{t('productFee')}</div>
              </Block>
            </Column>
            <Column style={{ padding: "0px" }}>
              <Block>
                <Input
                  id="tpl-commission"
                  label=""
                  placeholder=""
                  type="text"
                  width="fullWidth"
                  required={false}
                  error={false}
                  value={tplProduct.mainProduct.commission}
                  onChange={(value) => setMainProductField("commission", value)}
                />
              </Block>
            </Column>
          </Row>
        </Column>

        <Column
          $widthPercent={screenWidth <= 1199 ? 100 : 35}
          style={{ padding: "20px 100px 20px 10px" }}
        >
          <TextBlock>
          <h5>{t('createdDate')}</h5>
            <div>
              {dayjs(tplProduct.createdAt).format("DD MMMM YYYY HH:mm:ss")}
            </div>
          </TextBlock>

          <TextBlock>
          <h5>{t('lastUpdatedAt')}</h5>
            <div>
              {dayjs(tplProduct.updatedAt).format("DD MMMM YYYY HH:mm:ss")}
            </div>
          </TextBlock>

          <TextBlock>
          <h5>{t('status')}</h5>
            <div>{tplProduct.mainProduct.status}</div>
          </TextBlock>

          {tplProduct.mainProduct.activationDate && (
            <Block>
              <Text>{t('lastActivationDate')}:</Text>
              <div>
                {dayjs(tplProduct.mainProduct.activationDate).format(
                  "DD MMMM YYYY HH:mm:ss"
                )}
              </div>
            </Block>
          )}
          {tplProduct.mainProduct.deactivationDate && (
            <Block>
              <Text>{t('deactivationDate')}</Text>
              <div>
                {dayjs(tplProduct.mainProduct.deactivationDate).format(
                  "DD MMMM YYYY HH:mm:ss"
                )}
              </div>
            </Block>
          )}

          <br />

          <Button
            btnStyleType="primary"
            icon={false}
            fullWidth={true}
            style={{ marginBottom: 16 }}
            disabled={false}
            onClick={saveTab}
            text={t('save')}
          />
          {isAdmin && tplProduct.mainProduct.status === "draft" ? (
            <Button
              btnStyleType="primary"
              icon={false}
              fullWidth={true}
              style={{ marginBottom: 16 }}
              disabled={false}
              onClick={approveProductChanges}
              text={t('publish')}
            />
          ) : null}
          {isAdmin ? (
            <Button
              btnStyleType="primary"
              icon={false}
              fullWidth={true}
              style={{ marginBottom: 16 }}
              disabled={false}
              onClick={rejectProductChanges}
              text={
                tplProduct.mainProduct.status === "draft"
                ? `${t('rejectChanges')}`
                : `${t('moveToDraft')}`
              }
            />
          ) : null}
        </Column>
      </ContentBox>
    </Wrapper>
  );
};
//
//
// styles
const WarningAlertRanges = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const WarningRangeDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  width: fit-content;
  background-color: ${Colors.Sazerac};
  margin-right: 10px;
  margin-bottom: 10px;

  button {
    color: ${Colors.primaryRed};
    background-color: unset;
    margin-left: 10px;
    outline: none;
    line-height: none;
    border: none;
    padding: 2px 5px;

    :hover {
      color: ${Colors.Black};
    }
  }
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 10px;
  /*  */
  > div {
    flex-basis: 50%;
  }
`;
const Title = styled.div`
  margin-bottom: 10px;
  color: ${Colors.primaryRed};
  font-size: 16px;
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  ${Responsive.tablet} {
    flex-direction: column;
  }
  ${Responsive.mobile} {
    flex-direction: column;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const Column = styled.div<{ $widthPercent?: number }>`
  padding: 20px 15px;
  ${({ $widthPercent = 50 }) =>
    $widthPercent ? `width: calc(${$widthPercent}% - 15px);` : ""}

  ${Responsive.tablet} {
    width: 100%;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
const TextBlock = styled.div`
  margin-top: 20px;
  /*  */
  h5 {
    font-size: 16px;
    color: ${Colors.primaryBlack};
    margin-bottom: 2px;
  }
  div {
    font-size: 15px;
    color: ${Colors.primaryGrey};
  }
`;
const PolicyNumberGroupDiv = styled.div`
  input {
    text-align: center;
  }
  /* border: 1px solid red; */
  box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  -webkit-box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  -moz-box-shadow: 0px 1px 12px 1px inset ${Colors.grey};
  padding: 10px;
`;
const PolicyNumberGroupScrollView = styled.div`
  min-height: 150px;
  max-height: 290px;
  overflow-y: scroll;
  /*  */

  .policy-number-class {
    input {
      min-width: 53px !important;
      width: 70% !important;
    }
  }
`;
const PolicyNumberBlock = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 5px;
  padding-block: 5px;
`;
const Text = styled.div`
  margin-bottom: 10px;
  color: ${Colors.primaryGrey};
  font-size: 14px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
`;
//
export default EditTplProductFieldsTab;
