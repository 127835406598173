import React, { useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../../../api";
import Colors from "../../../../../assets/styles/Colors";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import Loader from "../../../../../components/Loader";
import Message from "../../../../../components/Message";
import { useAppSelector } from "../../../../../store";
import { AppState } from "../../../../../store/appSlice";
import { useTranslation } from "react-i18next";
//
//
const AdminPetPriceTabFour: React.FC<{ productId: number | undefined }> = ({
  productId,
}) => {
  //
  const {
    data: petPackagePrices,
    isLoading: isPetPackagePricesLoading,
    refetch: isRefetchPackagePrice,
  } = api.useGetPetPackagePricesQuery(productId as number, {
    skip: productId ? false : true,
  });
  //
  const { t } = useTranslation();
  //
  const { data: petCategories, isLoading: isPetCategoriesLoading } =
    api.useGetPetCategoriesQuery(undefined);
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  //
  const [prices, setPrices] = useState<
    { price: number | null; id: number }[] | undefined
  >(undefined);
  //
  //
  const updatePriceById = (id: number, newPrice: number) => {
    const updatedPrices = prices?.map((item) => {
      if (item.id === id) {
        return { ...item, price: newPrice };
      }
      return item;
    });

    setPrices(updatedPrices);
  };
  //
  //
  const [updatePackagePrice, { isLoading: isUpdatePackagePriceLoading }] =
    api.useUpdatePetPackagePriceByIdMutation();
  //
  //
  const UpdatePackagePrice = async (id: number, price: string) => {
    //
    if (id && price) {
      try {
        await updatePackagePrice({
          priceId: id,
          premium: parseFloat(price),
        }).unwrap();
        //
        // refetch price
        isRefetchPackagePrice();
        //
        toast.success(`${t('price')} ${t('updatedSuccessfully')}`);
        //
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    } else {
      toast.info(`${t('enterPrice')}`);
    }
    //
  };
  //
  //
  const setIncomePrices = useCallback(() => {
    //
    if (petPackagePrices && petPackagePrices.length >= 1) {
      setPrices(
        petPackagePrices?.map((x) => ({
          price: x.premium,
          id: x.id,
        }))
      );
    }
    //
  }, [petPackagePrices]);
  //
  //
  useEffect(() => {
    setIncomePrices();
    //
    return () => {};
  }, [setIncomePrices]);

  //
  //
  const MainLoading =
    isUpdatePackagePriceLoading ||
    isPetPackagePricesLoading ||
    isPetCategoriesLoading;
  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Message variant="light-red" text={t("petPricesMandatory")} />

      <hr />

      {petPackagePrices && petPackagePrices.length <= 0 && (
        <h4
          style={{
            textAlign: "center",
            color: Colors.primaryRed,
          }}
        >
          {t('generatedPricesNotFound')}
        </h4>
      )}

      {petPackagePrices && petPackagePrices.length >= 1 ? (
        <>
          <Row>
            <Col xs={{ span: 4 }}>
              <label htmlFor="package-name">{t('packageTitle')}</label>
            </Col>
            <Col xs={{ span: 2 }}>
              <label htmlFor="limit-name">{t('pet')}</label>
            </Col>
            <Col xs={{ span: 1 }}>
              <label htmlFor="currency-name">{t('currency')}</label>
            </Col>
            <Col xs={{ span: 2 }}>
              <label htmlFor="payment-method">{t('paymentType')}</label>
            </Col>
            <Col xs={{ span: 3 }}>
              <label htmlFor="price-to-currency">{t('priceByCurrency')}</label>
            </Col>
          </Row>

          <hr />
          {petPackagePrices?.map((item, index) => {
            if (item) {
              return (
                <Row
                  key={index}
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  <Col xs={{ span: 4 }}>
                    <Input
                      id="package-name"
                      name="package-name"
                      label={``}
                      type="text"
                      placeholder={``}
                      value={item?.petPackage?.mainPackage?.translations[field_Language]?.title || ""}
                      onChange={(value) => {}}
                      width={"fullWidth"}
                      disabled={true}
                    />
                  </Col>

                  <Col xs={{ span: 2 }}>
                    <Input
                      id="pet-name"
                      name="pet-name"
                      label={``}
                      type="text"
                      placeholder=""
                      // @ts-ignore
                      value={
                        petCategories?.find(
                          (x) => x.id === item.animalCategoryId
                        )?.translations?.[field_Language]?.name || ""
                      }
                      onChange={(value) => {}}
                      width={"fullWidth"}
                      disabled={true}
                    />
                  </Col>

                  <Col xs={{ span: 1 }}>
                    <Input
                      id="currency-name"
                      name="currency-name"
                      label={``}
                      type="text"
                      placeholder=""
                      value={item?.currency || ""}
                      onChange={(value) => {}}
                      width={"fullWidth"}
                      disabled={true}
                    />
                  </Col>

                  <Col xs={{ span: 2 }}>
                    <Input
                      id="payment-method"
                      name="payment-method"
                      label={``}
                      type="text"
                      placeholder=""
                      value={
                        item?.paymentScheduleType?.translations[field_Language]?.title || ""
                      }
                      onChange={(value) => {}}
                      width={"fullWidth"}
                      disabled={true}
                    />
                  </Col>

                  <Col xs={{ span: 1 }}>
                    <Input
                      id="price-to-currency"
                      name="price-to-currency"
                      label={``}
                      type="number"
                      placeholder=""
                      value={prices?.find((x) => x.id === item.id)?.price || ""}
                      onChange={(value) => updatePriceById(item.id, value)}
                      width={"fullWidth"}
                    />
                  </Col>

                  <Col xs={{ span: 2 }}>
                    <Button
                      style={{
                        minWidth: "unset",
                      }}
                      disabled={
                        prices?.find((x) => x.id === item.id)?.price ===
                        item.premium
                          ? true
                          : false
                      }
                      text={t('save')}
                      onClick={() => {
                        UpdatePackagePrice(
                          item.id,
                          prices?.find((x) => x.id === item.id)
                            ?.price as unknown as string
                        );
                      }}
                      btnStyleType={"primary"}
                      border={false}
                      fullWidth={true}
                    />
                  </Col>
                </Row>
              );
            } else {
              return null;
            }
          })}
        </>
      ) : null}
    </Wrapper>
  );
};
//
const Wrapper = styled.div`
  padding: 10px;
`;
//
export default AdminPetPriceTabFour;
