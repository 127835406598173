import { css } from 'styled-components'
import Colors from '../assets/styles/Colors'

const primary = css`
  background-color: ${Colors.primaryRed};
  color: ${Colors.white};
  /* hover styles */
  &:hover,
  &:active {
    background-color: ${Colors.sunsetOrange};
    color: ${Colors.white};
  }
`
const secondary = css`
  background-color: ${Colors.primaryBlack};
  color: ${Colors.white};
  /* hover styles */
  &:hover,
  &:active {
    background-color: ${Colors.sunsetOrange};
    color: ${Colors.white};
  }
`
const transparent = css`
  background-color: ${'transparent'};
  color: ${Colors.primaryBlack};
  /* hover styles */
  &:hover,
  &:active {
    background-color: ${Colors.primaryBlack};
    color: ${Colors.white};
  }
`
const white = css`
  background-color: ${Colors.white};
  color: ${Colors.primaryBlack};
  /* hover styles */
  &:hover,
  &:active {
    background-color: ${Colors.primaryBlack};
    color: ${Colors.white};
  }
`

const ButtonPresets = {
  BASE_STYLES: {},
  primary,
  secondary,
  transparent,
  white,
}

export default ButtonPresets
