import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EnabledLanguage } from "../domain/types";
import { detectUserLanguage } from "./langStorage";
import {
  getAppThemeLocalStorage,
  setAppThemeLocalStorage,
} from "./appThemeStorage";

export type AppState = {
  lang: EnabledLanguage;
  field_Language: EnabledLanguage;
  isLoading: boolean;
  appTheme: "light" | "dark";
  searchKeyword: string;
};
//
const Initial: AppState = {
  lang: detectUserLanguage(),
  field_Language: detectUserLanguage(),
  appTheme: getAppThemeLocalStorage(),
  isLoading: false,
  searchKeyword: "",
};

const slice = createSlice({
  name: "appSlice",
  initialState: Initial,
  reducers: {
    setLanguage: (
      state: AppState,
      { payload: lang }: PayloadAction<EnabledLanguage>
    ) => {
      state.lang = lang;
    },
    //
    setLoading: (
      state: AppState,
      { payload: isLoading }: PayloadAction<AppState["isLoading"]>
    ) => {
      state.isLoading = isLoading;
    },
    //
    setEditedlanguage: (
      state: AppState,
      { payload: field_Language }: PayloadAction<AppState["field_Language"]>
    ) => {
      state.field_Language = field_Language;
    },
    //
    setAppTheme: (
      state: AppState,
      { payload: appTheme }: PayloadAction<AppState["appTheme"]>
    ) => {
      state.appTheme = appTheme;
      setAppThemeLocalStorage(appTheme);
    },
    //
    setSearchKeyword: (
      // for global header search
      state: AppState,
      { payload: searchKeyword }: PayloadAction<AppState["searchKeyword"]>
    ) => {
      state.searchKeyword = searchKeyword;
    },
  },
});

export const {
  setLanguage,
  setEditedlanguage,
  setLoading,
  setAppTheme,
  setSearchKeyword,
} = slice.actions;

export default slice.reducer;
