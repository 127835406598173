import * as React from "react"

function LeftLineIconJsx(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22.006}
      height={12.001}
      viewBox="0 0 22.006 12.001"
      {...props}
    >
      <path
        data-name="back arrow hover"
        d="M6.116 12L0 6l6.117-6v2.924L3.971 5.029h18.035v2H4.03l2.088 2.047V12z"
        fill="CurrentColor"
      />
    </svg>
  )
}

export default LeftLineIconJsx
