import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../../assets/icons/JSX/Logo";
import Colors from "../../../assets/styles/Colors";
import {
  AdminMenu,
  EnabledLanguage,
  supportedLocales,
} from "../../../domain/types";
import { RootState, useAppSelector } from "../../../store";
import { AuthState } from "../../../store/authSlice";

import {
  AppstoreOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  GlobalOutlined,
  LoginOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoonOutlined,
  PaperClipOutlined,
  QrcodeOutlined,
  SunOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  FloatButton,
  Input,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Popover,
  QRCode,
  Row,
  Tooltip,
  theme,
} from "antd";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import { RiMenuSearchLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import Loader from "../../../components/Loader";
import {
  AppState,
  setAppTheme,
  setEditedlanguage,
  setLanguage,
  setLoading,
  setSearchKeyword,
} from "../../../store/appSlice";

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
//
//
// !We have this section on two pages | Admin_layout | admin_header
// ? admin menu
export const Admin_menu: AdminMenu[] = [
  {
    id: 111,
    url: "/",
    hide: true,
    permissions: [
      "Frani-Admin",
      "Frani-Product-Owner",
      "Insurance-Admin",
      "Insurance-Financial-Viewer",
    ],
    translations: {
      ge: {
        title: "მთავარი",
      },
      en: {
        title: "Dashboard",
      },
    },
    sub_menu: [],
  },
  {
    id: 1,
    url: null,
    hide: false,
    permissions: [
      "Frani-Admin",
      "Frani-Product-Owner",
      "Insurance-Admin",
      "Insurance-Financial-Viewer",
    ],
    translations: {
      ge: {
        title: "სამოგზაურო",
      },
      en: {
        title: "Travel",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 1,
        url: "/risks",
        hide: true,
        permissions: ["Frani-Admin", "Frani-Product-Owner"],
        translations: {
          ge: {
            title: "რისკები",
          },
          en: {
            title: "Risks",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/addons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "დაფარვები",
          },
          en: {
            title: "Coverages",
          },
        },
      },
      {
        sub_menu_id: 3,
        url: "/travel-franchise",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ფრანშიზები",
          },
          en: {
            title: "Deductible",
          },
        },
      },
      {
        sub_menu_id: 4,
        url: "/manage-travel-pages",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გვერდების მართვა",
          },
          en: {
            title: "Manage pages",
          },
        },
      },
      // {
      //   sub_menu_id: 5,
      //   url: '/travel-reports',
      //   hide: true,
      //   permissions: ['Frani-Admin', 'Frani-Product-Owner', 'Insurance-Admin', 'Insurance-Financial-Viewer'],
      //   translations: {
      //     ge: {
      //       title: 'რეპორტები',
      //     },
      //     en: {
      //       title: 'Reports',
      //     },
      //   },
      // },
      {
        sub_menu_id: 6,
        url: "/travel-multi-days",
        hide: false,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "მრავალჯერადი (დღეები)",
          },
          en: {
            title: "Multi (days)",
          },
        },
      },
      {
        sub_menu_id: 7,
        url: "/travel-outside-georgia/policy-deactivation-reasons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გაუქმების მიზეზები",
          },
          en: {
            title: "Deactivation reason",
          },
        },
      },
      {
        sub_menu_id: 8,
        url: "/travel-outside-georgia/information-sheet",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "საინფორმაციო ფურცელი",
          },
          en: {
            title: "Information Sheet",
          },
        },
      },
    ],
  },
  {
    id: 12,
    url: null,
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "შიდა ტური",
      },
      en: {
        title: "Local Tours",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 1,
        url: "/welcomer-risks",
        hide: true,
        permissions: ["Frani-Admin", "Frani-Product-Owner"],
        translations: {
          ge: {
            title: "რისკები",
          },
          en: {
            title: "Risks",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/welcomer-addons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "დაფარვები",
          },
          en: {
            title: "Coverages",
          },
        },
      },
      {
        sub_menu_id: 3,
        url: "/welcomer-franchise",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ფრანშიზები",
          },
          en: {
            title: "Deductible",
          },
        },
      },
      {
        sub_menu_id: 4,
        url: "/manage-welcomer-pages",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გვერდების მართვა",
          },
          en: {
            title: "Manage pages",
          },
        },
      },
      {
        sub_menu_id: 6,
        url: "/welcomer-to-georgia/policy-deactivation-reasons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გაუქმების მიზეზები",
          },
          en: {
            title: "Deactivation reason",
          },
        },
      },
      {
        sub_menu_id: 7,
        url: "/welcomer-limits",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ლიმიტები",
          },
          en: {
            title: "Limit",
          },
        },
      },
      {
        sub_menu_id: 8,
        url: "/welcomer-currencies",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ვალუტები",
          },
          en: {
            title: "Currency",
          },
        },
      },
      {
        sub_menu_id: 9,
        url: "/welcomer-to-georgia/information-sheet",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "საინფორმაციო ფურცელი",
          },
          en: {
            title: "Information Sheet",
          },
        },
      },
    ],
  },
  {
    id: 9,
    url: null,
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "ტპლ",
      },
      en: {
        title: "TPL",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 5,
        url: "/tpl-currencies",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ვალუტები",
          },
          en: {
            title: "Currency",
          },
        },
      },
      {
        sub_menu_id: 1,
        url: "/tpl-limits",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ლიმიტები",
          },
          en: {
            title: "Limit",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/tpl-manage-pages",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გვერდების მართვა",
          },
          en: {
            title: "Manage pages",
          },
        },
      },
      {
        sub_menu_id: 4,
        url: "/tpl-exception-cars",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გამონაკლისი მანქანები",
          },
          en: {
            title: "Exceptional cars",
          },
        },
      },
      {
        sub_menu_id: 6,
        url: "/tpl/policy-deactivation-reasons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გაუქმების მიზეზები",
          },
          en: {
            title: "Deactivation reason",
          },
        },
      },
      {
        sub_menu_id: 7,
        url: "/tpl-services",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "სერვისები",
          },
          en: {
            title: "Services",
          },
        },
      },
      {
        sub_menu_id: 8,
        url: "/payment-schedule-types",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გადახდის ჯერადობები",
          },
          en: {
            title: "Payment multiples",
          },
        },
      },
      {
        sub_menu_id: 9,
        url: "/tpl/information-sheet",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "საინფორმაციო ფურცელი",
          },
          en: {
            title: "Information Sheet",
          },
        },
      },
    ],
  },
  {
    id: 13,
    url: null,
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "შინაური ცხოველები",
      },
      en: {
        title: "Pet",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 1,
        url: "/pet-categories",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ცხოველის კატეგორია",
          },
          en: {
            title: "Pet category",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/pet-breed",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ცხოველის ჯიში",
          },
          en: {
            title: "Pet breed",
          },
        },
      },
      {
        sub_menu_id: 3,
        url: "/pet-services",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "სერვისები",
          },
          en: {
            title: "Services",
          },
        },
      },
      {
        sub_menu_id: 4,
        url: "/pet-package-services",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "მომსახურებები",
          },
          en: {
            title: "Coverages",
          },
        },
      },
      {
        sub_menu_id: 5,
        url: "/pet-manage-pages",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გვერდების მართვა",
          },
          en: {
            title: "Manage pages",
          },
        },
      },
      {
        sub_menu_id: 6,
        url: "/pet/policy-deactivation-reasons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გაუქმების მიზეზები",
          },
          en: {
            title: "Deactivation reason",
          },
        },
      },
      {
        sub_menu_id: 7,
        url: "/pet/information-sheet",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "საინფორმაციო ფურცელი",
          },
          en: {
            title: "Information Sheet",
          },
        },
      },
    ],
  },
  {
    id: 14,
    url: null,
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "კრიტიკული დაავადებები",
      },
      en: {
        title: "Critical Illness",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 1,
        url: "/critical-limits",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ლიმიტები",
          },
          en: {
            title: "Limit",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/critical-currencies",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ვალუტები",
          },
          en: {
            title: "Currency",
          },
        },
      },
      {
        sub_menu_id: 3,
        url: "/critical-manage-risks",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "რისკები",
          },
          en: {
            title: "Risks",
          },
        },
      },
      {
        sub_menu_id: 4,
        url: "/critical-manage-pages",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გვერდების მართვა",
          },
          en: {
            title: "Manage pages",
          },
        },
      },
      {
        sub_menu_id: 5,
        url: "/critical/policy-deactivation-reasons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გაუქმების მიზეზები",
          },
          en: {
            title: "Deactivation reason",
          },
        },
      },
      {
        sub_menu_id: 6,
        url: "/critical/information-sheet",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "საინფორმაციო ფურცელი",
          },
          en: {
            title: "Information Sheet",
          },
        },
      },
    ],
  },
  {
    id: 2,
    url: "/authorized-companies",
    hide: false,
    permissions: [
      "Frani-Admin",
      "Frani-Product-Owner",
      "Insurance-Admin",
      "Insurance-Company-Editor",
    ],
    translations: {
      ge: {
        title: "კომპანიები",
      },
      en: {
        title: "Companies",
      },
    },
    sub_menu: [],
  },
  {
    id: 3,
    url: "/product-list",
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "პროდუქტების მართვა",
      },
      en: {
        title: "Manage Products",
      },
    },
    sub_menu: [],
  },
  {
    id: 4,
    url: "/company-sold-policies",
    hide: false,
    permissions: [
      "Frani-Admin",
      "Insurance-Product-Editor",
      "Frani-Product-Owner",
      "Insurance-Admin",
      "Insurance-Financial-Viewer",
    ],
    translations: {
      ge: {
        title: "გაყიდული პოლისები",
      },
      en: {
        title: "Purchased policies",
      },
    },
    sub_menu: [],
  },
  {
    id: 5,
    url: "/company-payment-history",
    hide: false,
    permissions: [
      "Frani-Admin",
      "Frani-Product-Owner",
      "Insurance-Financial-Viewer",
      "Insurance-Admin",
    ],
    translations: {
      ge: {
        title: "გადახდების ისტორია",
      },
      en: {
        title: "Payment history",
      },
    },
    sub_menu: [],
  },
  {
    id: 6,
    url: "/web-documents",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "დოკუმენტაციები",
      },
      en: {
        title: "Documentations",
      },
    },
    sub_menu: [],
  },
  {
    id: 7,
    url: "null",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "მომხმარებლების მართვა",
      },
      en: {
        title: "Manage users",
      },
    },
    sub_menu: [      {
      sub_menu_id: 1,
      url: "/frani-users",
      hide: true,
      permissions: ["Frani-Admin"],
      translations: {
        ge: {
          title: "ადმინის მომხმარებლები",
        },
        en: {
          title: "Admin Users",
        },
      },
    },
    {
      sub_menu_id: 2,
      url: "/web-users",
      hide: true,
      permissions: ["Frani-Admin"],
      translations: {
        ge: {
          title: "ვების მომხმარებლები",
        },
        en: {
          title: "Web Users",
        },
      },
    },],
  },
  {
    id: 8,
    url: "/manage-pages",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "გვერდების მართვა",
      },
      en: {
        title: "Manage pages",
      },
    },
    sub_menu: [],
  },
  {
    id: 10,
    url: "/manage-product-emails",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "მეილების მართვა",
      },
      en: {
        title: "Manage emails",
      },
    },
    sub_menu: [],
  },
  {
    id: 11,
    url: "/business-module",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "კორპორატიული მოდული",
      },
      en: {
        title: "Corporate model",
      },
    },
    sub_menu: [],
  },
  {
    id: 15,
    url: "/promo-codes",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "პრომოკოდები",
      },
      en: {
        title: "Promo codes",
      },
    },
    sub_menu: [],
  },
];
// ? admin menu
// !We have this section on two pages | Admin_layout | admin_header
//
//
const Admin_layout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation();
  //
  const { i18n } = useTranslation();
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const { appTheme, lang, searchKeyword } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const [logout, { isLoading: logOutLoading }] = api.useLogoutMutation();
  //
  const dispatch = useDispatch();
  //
  const [openDrawer, setOpenDrawer] = useState(false);
  //
  const [openFloatBtn, setOpenFloatBtn] = useState(false);
  //
  const [searchIsOpened, setSearchIsOpened] = useState(false);
  //
  const [searchKeywordDebounce] = useDebounce(searchKeyword, 100);
  //
  //
  const _drawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };
  //
  // logout
  const AdminLogout = async () => {
    try {
      await logout({}).unwrap();
      navigate(`/`);
    } catch (error) {
      toast("logout error | " + error);
    }
  };
  //
  const changeLanguage = (lng: EnabledLanguage) => {
    // show loader
    dispatch(setLoading(true));
    // change language
    i18n.changeLanguage(lng);
    // set fields language in redux store
    dispatch(setEditedlanguage(lng));
    // set theme language in redux store
    dispatch(setLanguage(lng));
    // hide loader
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 800);
  };
  //
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  //
  // const currentUerType = auth?.user?.userType;
  //
  // const currentUerRoles = auth?.user?.companyUsers?.map((x) => x.role);
  //
  // const allRols = currentUerRoles
  //   ? [...currentUerRoles, currentUerType]
  //   : [currentUerType];
  //
  const MainLoading = logOutLoading;
  //
  //
  const Menus: MenuItem[] = Admin_menu.map((item, index) => {
    const mainMenuItem = getItem(
      <span key={item.url}>{item.translations?.[lang]?.title}</span>,
      item.url || index,
      item.sub_menu.length >= 1 ? (
        <FolderOpenOutlined />
      ) : item.url === "/" ? (
        <AppstoreOutlined />
      ) : (
        <FolderOutlined />
      ),
      item.sub_menu.length >= 1
        ? item.sub_menu.map((sub_menu) =>
            getItem(
              <span key={sub_menu.url}>
                {sub_menu?.translations?.[lang]?.title}
              </span>,
              sub_menu?.url,
              <FolderOutlined />
            )
          )
        : undefined
    );

    return mainMenuItem;
  });
  //
  //
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //
  //
  const onSearch = () => {
    const fileterMune = Admin_menu?.filter((item) =>
      item?.translations?.[lang]?.title
        ?.toLowerCase()
        .includes(searchKeywordDebounce?.toLowerCase())
    );
    //
    const filterSubmenu = Admin_menu.flatMap((menu) =>
      menu.sub_menu
        ?.filter((item) =>
          (item?.translations?.[lang]?.title || "")
            .toLowerCase()
            .includes(searchKeywordDebounce?.toLowerCase())
        )
        .map((filteredItem) => ({
          ...filteredItem,
          parentTranslation: menu.translations,
        }))
    );
    //
    const union = [...fileterMune, ...filterSubmenu];
    //
    return union;
  };
  //
  //
  const searchedItems = onSearch();
  //
  const showSearchResult =
    searchedItems && searchedItems.length >= 1
      ? // &&
        // searchKeywordDebounce &&
        // searchKeywordDebounce.length >= 2
        true
      : false;
  //
  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Layout
        style={{
          height: "100vh",
        }}
      >
        <Sider
          className="custom-side-bar-class"
          // theme={appTheme}
          trigger={null}
          collapsible
          width={300}
          collapsed={
            screenWidth <= 768 ? true : screenWidth <= 1199 ? true : collapsed
          }
          style={{
            backgroundColor: appTheme === "dark" ? "#141414" : "#ffffff",
            overflowY: "scroll",
          }}
        >
          <LogoBox
            $theme={appTheme}
            $isClosed={
              screenWidth <= 768 ? true : screenWidth <= 1199 ? true : collapsed
            }
            onClick={() => navigate("/")}
          >
            <LogoStyle />
          </LogoBox>

          <Menu
            onClick={({ key }) => {
              navigate(key);
            }}
            // theme={appTheme}
            mode="inline"
            defaultSelectedKeys={["მთავარი"]}
            items={Menus}
          />
        </Sider>

        <Layout>
          <Header
            className="header-custom-class"
            style={{
              padding: "0px 20px 0px 0px",
              background: colorBgContainer,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              className="collapsed-btn-class"
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />

            <Marquee
              pauseOnHover
              gradient={appTheme === "dark" ? false : true}
              className="custom-marquee-message"
            >
              მიმდინარეობს საიტის რეკონსტრუქცია, ხარვეზის შემთხვევაში მიაწოდეთ
              ინფორმაცია დეველოპერულ გუნდს 😇 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </Marquee>

            <Button
              className="collapsed-btn-class"
              type="text"
              icon={
                <RiMenuSearchLine
                  style={{
                    scale: "1.3",
                  }}
                />
              }
              onClick={() => setSearchIsOpened(true)}
              style={{
                fontSize: "16px",
                minWidth: "64px",
                width: 64,
                height: 64,
              }}
            />

            <div
              style={{
                display: "flex",
              }}
            >
              <Popover
                content={
                  <div>
                    {supportedLocales?.map((locale) => (
                      <Button
                        className="header-icon-btn-class"
                        key={locale}
                        style={{
                          width: "100%",
                          // background:
                          //   lang === locale ? Colors.secondaryBlack : "",
                        }}
                        type="text"
                        onClick={() => changeLanguage(locale)}
                      >
                        {locale?.toUpperCase()}
                      </Button>
                    ))}
                  </div>
                }
                placement="bottomRight"
              >
                <Button
                  className="header-icon-btn-class"
                  type="text"
                  name="app-language"
                  icon={<GlobalOutlined />}
                />
              </Popover>

              <Button
                className="header-icon-btn-class"
                type="text"
                name="app-theme"
                icon={appTheme === "light" ? <MoonOutlined /> : <SunOutlined />}
                onClick={() =>
                  dispatch(setAppTheme(appTheme === "dark" ? "light" : "dark"))
                }
              />

              <Button
                className="header-icon-btn-class"
                type="text"
                name="drawer-toggle"
                icon={<UserOutlined />}
                onClick={_drawerToggle}
              />

              <Tooltip placement="bottomRight" title={"გამოსვლა"}>
                <Button
                  className="header-icon-btn-class"
                  type="text"
                  name="logout"
                  icon={<LoginOutlined />}
                  onClick={AdminLogout}
                />
              </Tooltip>
            </div>
          </Header>
          <Content
            style={{
              overflowY: "scroll",
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </Content>
        </Layout>

        <Modal
          title="type keywords"
          centered
          open={searchIsOpened}
          onCancel={() => setSearchIsOpened(false)}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <Input
            style={{
              marginBottom: "10px",
            }}
            placeholder="Basic usage"
            size="large"
            onChange={(e) => dispatch(setSearchKeyword(e.target.value))}
          />

          {searchKeywordDebounce &&
            searchKeywordDebounce.trim().length >= 1 && (
              <Divider orientation="center" orientationMargin={0} plain>
                Found: {searchedItems?.length}
              </Divider>
            )}

          <ResultDiv>
            {showSearchResult &&
              searchedItems.map((item, index) =>
                item.url ? (
                  <SearchedItem
                    key={index}
                    onClick={() => [
                      navigate(item?.url || ""),
                      setSearchIsOpened(false),
                    ]}
                  >
                    {item && item.parentTranslation ? (
                      <span>{item?.parentTranslation?.[lang]?.title} / </span>
                    ) : null}
                    {item.translations?.[lang]?.title}
                  </SearchedItem>
                ) : null
              )}
          </ResultDiv>
        </Modal>

        <Drawer
          title={`${t("hi")} ${auth?.user?.firstName}`}
          onClose={_drawerToggle}
          open={openDrawer}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
              color: Colors.primaryGrey,
            }}
          >
            <div>
              <Divider orientation="left" orientationMargin={0} plain>
                {t("yourData")}
              </Divider>

              <Row>
                <Col span={24}>
                  <p className="site-description-item-profile-p">
                    {auth.user?.firstName}
                  </p>
                </Col>
                <Col span={24}>
                  <p className="site-description-item-profile-p">
                    {auth.user?.lastName}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <p className="site-description-item-profile-p">
                    {auth.user?.email}
                  </p>
                </Col>
              </Row>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Divider orientation="center" orientationMargin={0} plain>
                მიმდინარე გვერდის QR
              </Divider>

              <QRCode
                style={{
                  padding: "5px",
                  marginTop: "auto",
                  userSelect: "none",
                  pointerEvents: "none",
                }}
                size={200}
                value={window.location.href}
                color={Colors.primaryRed}
                bgColor={Colors.grey}
              />
            </div>
          </div>
        </Drawer>

        <FloatButton.Group
          className="custom-float-button-class"
          open={openFloatBtn}
          onClick={() => setOpenFloatBtn(!openFloatBtn)}
          trigger="click"
          style={{ right: 24 }}
          icon={<QrcodeOutlined />}
          type="primary"
        >
          <Tooltip
            placement="left"
            title={"frani.com"}
            color={Colors.primaryRed}
          >
            <FloatButton
              icon={<PaperClipOutlined />}
              onClick={() => window.open("https://frani.com/", "_blank")}
            />
          </Tooltip>

          <Tooltip
            placement="left"
            title={"dev.frani.com"}
            color={Colors.secondaryRed}
          >
            <FloatButton
              icon={<PaperClipOutlined />}
              onClick={() => window.open("https://dev.frani.com/", "_blank")}
            />
          </Tooltip>
        </FloatButton.Group>
      </Layout>

      {/* <Wrapper>
        <Content>
          <LeftSide>
            <SideBarContent>
              <SideBarUl>
                <LogoStyle onClick={() => navigate("/dashboard")} />

                {auth &&
                  auth.user &&
                  Admin_menu.map((item: AdminMenu, index) => (
                    <div key={index}>
                      {Permision(item.permissions)?.length === 0 ? null : (
                        <SideBarLi>
                          {item.sub_menu.length >= 1 ? (
                            <DropDownTitle
                              className={
                                activeMenuId === item.id ? "active" : ""
                              }
                              onClick={() =>
                                setActiveMenuId(
                                  activeMenuId === item.id ? null : item.id
                                )
                              }
                            >
                              <span>{item.translations[lang]?.title}</span>{" "}
                              <SmallArrow />
                            </DropDownTitle>
                          ) : (
                            <NavLink to={`${item.url}`}>
                              <span>{item.translations[lang]?.title}</span>
                            </NavLink>
                          )}

                          {activeMenuId === item.id && (
                            <MenuDropDown>
                              {item &&
                                item.sub_menu &&
                                item.sub_menu.length >= 1 &&
                                item.sub_menu.map(
                                  (
                                    sub_item: AdminSubMenu,
                                    sube_index: number
                                  ) => {
                                    //
                                    const filteredRols =
                                      sub_item?.permissions?.filter(function (
                                        accepted_role
                                      ) {
                                        return (
                                          allRols?.filter(function (
                                            current_user_roles
                                          ) {
                                            return (
                                              current_user_roles ===
                                              accepted_role
                                            );
                                          }).length !== 0
                                        );
                                      });
                                    //
                                    return (
                                      <div key={sube_index}>
                                        {filteredRols?.length === 0 ? null : (
                                          <>
                                            <NavLink to={`${sub_item.url}`}>
                                              <span>
                                                {
                                                  sub_item.translations[lang]
                                                    ?.title
                                                }
                                              </span>
                                            </NavLink>
                                          </>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                            </MenuDropDown>
                          )}
                        </SideBarLi>
                      )}
                    </div>
                  ))}
              </SideBarUl>
            </SideBarContent>
          </LeftSide>

          <RightSide>
            <AdminHeader />
            <MobileStyle>{children}</MobileStyle>
          </RightSide>
        </Content>
      </Wrapper> */}
    </Wrapper>
  );
};

// styles
const ResultDiv = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;
const SearchedItem = styled.div`
  border: 1px solid ${Colors.primaryGrey};
  border-radius: 8px;
  padding: 6px 10px;
  margin-block: 10px;
  user-select: none;
  cursor: pointer;

  span {
    color: ${Colors.primaryRed};
    /* margin-right: 20px; */
    width: fit-content;
  }

  &:hover {
    background-color: ${Colors.Alabaster};
  }
`;
const LogoStyle = styled(Logo)`
  height: 30px;
  width: fit-content;
  display: block;
  margin-inline: auto;
  user-select: none;
  pointer-events: none;
`;

const LogoBox = styled.div<{ $isClosed: boolean; $theme: string }>`
  background-color: ${({ $theme }) =>
    $theme === "dark" ? Colors.white : Colors.grey};
  border-radius: 8px;
  display: flex;
  padding: ${({ $isClosed }) => ($isClosed ? `5px 10px` : `10px 20px`)};
  margin: ${({ $isClosed }) => ($isClosed ? `20px 10px` : `20px 24px`)};
  cursor: pointer;

  ${LogoStyle} {
    height: ${({ $isClosed }) => ($isClosed ? `20px` : `30px`)};
  }
`;

const Wrapper = styled.div`
  .custom-float-button-class {
    .ant-float-btn-body {
      background-color: ${Colors.primaryRed};

      .ant-float-btn-icon {
        color: ${Colors.white};
      }
    }
  }

  .header-icon-btn-class {
    font-size: 16px;
    width: 64px;
    height: 64px;
  }

  ${Responsive.mobile} {
    ${LogoBox} {
      display: none;
    }

    .custom-marquee-message {
      opacity: 0;
      user-select: none;
      pointer-events: none;
    }

    .header-custom-class {
      height: 40px;
    }

    .header-icon-btn-class {
      width: 40px;
      height: 40px;

      &:last-child {
        margin-right: -10px;
      }
    }

    .collapsed-btn-class {
      display: none;
    }

    .custom-side-bar-class {
      flex: 0 0 40px !important;
      max-width: 40px Im !important;
      min-width: 40px !important;
      width: 40px !important;
    }
  }
`;
export default Admin_layout;
