import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import { MediumScreenTitle } from "../../../assets/styles/GlobalStyle";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import { AdminTravelMultiDay, EnabledLanguage } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store/appSlice";
//
//
//
type EditedDayType = {
  title: string;
  excerpt: string;
  body: string;
};
//
const Admin_travel_multi_day_edit = () => {
  //
  //
  const { dayId: dayIdStr = "0" } = useParams();
  const dayID = parseInt(dayIdStr);
  //
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
   //
  //
  const [editedDayInfo, setEditedDayInfo] = useState<
    Partial<AdminTravelMultiDay>
  >({});
  //
  //
  const {
    data: multiDayById,
    isLoading: isMultiDayByIdLoading,
    refetch: isMultiDayRefetch,
  } = api.useGetAdminTravelMultiDayByIdQuery(dayID, {
    skip: !dayID && true,
  });
  //
  // set edited day info
  useEffect(() => {
    if (multiDayById) {
      setEditedDayInfo(multiDayById);
    }
  }, [multiDayById]);
  //
  //
  const setEditedDayInfoTranstationField = (
    field: keyof EditedDayType,
    language: EnabledLanguage,
    value: any
  ) => {
    setEditedDayInfo({
      ...editedDayInfo,
      translations: {
        ...editedDayInfo.translations,
        [language]: {
          ...editedDayInfo?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  // add travel addon fields
  const setEditedDayFields = (field: keyof AdminTravelMultiDay, value: any) => {
    setEditedDayInfo({
      ...editedDayInfo,
      [field]: value,
    });
  };
  //
  //
  //
  //  remove travel multi day
  const [removeTravelMultiDay, { isLoading: isRemoveTravelMultiDayLoading }] =
    api.useRemoveTravelMultiDayMutation();
  //
  //  store frani user  & update frani user
  const [storeTravelDay, { isLoading: isStoreTravelDayLoading }] =
    api.useStoreTravelMultiDayMutation();
  //
  //
  const storeTravelMultiDaysFunction = async () => {
    //
    //
    try {
      await storeTravelDay(editedDayInfo).unwrap();
      //
      //
      toast.success(dayID === 0 ? `${t('addedSuccessfully')}` :`${t('updatedSuccessfully')}`);
      //
      if (dayID === 0) {
        navigate("/travel-multi-days");
      } else {
        isMultiDayRefetch();
      }

      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  const RemoveTravelDayFunction = () => {
    if (dayID !== 0 && dayID) {
      window.confirm(`${t('certainToDeleteIt')}`) && removeTravelMultiDay(dayID);
      //
      navigate("/travel-multi-days");
    }
  };
  //
  //
  const mainLoading =
    isMultiDayByIdLoading ||
    isStoreTravelDayLoading ||
    isRemoveTravelMultiDayLoading;
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <MediumScreenTitle>
        {dayID === 0 ? t('create') : t('update')}
      </MediumScreenTitle>
      <div>
        <ModalHead>
          <AdminLangSwicher />
        </ModalHead>
        <br />
        <div>
          <Input
            id="user-title"
            name="user-title"
            label={t('title')}
            type="text"
            placeholder=""
            value={editedDayInfo?.translations?.[field_Language]?.title || ""}
            onChange={(value) =>
              setEditedDayInfoTranstationField("title", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "24px" }}
          />

          <Input
            id="user-body"
            name="user-body"
            label={t('description')}
            type="text"
            placeholder=""
            value={editedDayInfo?.translations?.[field_Language]?.body || ""}
            onChange={(value) =>
              setEditedDayInfoTranstationField("body", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "24px" }}
          />

          <Input
            id="user-excerpt"
            name="user-excerpt"
            label={t('additionalInformation')}
            type="text"
            placeholder=""
            value={editedDayInfo?.translations?.[field_Language]?.excerpt || ""}
            onChange={(value) =>
              setEditedDayInfoTranstationField("excerpt", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "24px" }}
          />

          <Input
            id="user-day"
            name="user-day"
            label={t('day')}
            type="number"
            placeholder=""
            value={editedDayInfo.days || ""}
            onChange={(value) => setEditedDayFields("days", value)}
            width={"fullWidth"}
            style={{ marginBottom: "24px" }}
          />

          <Input
            id="user-insured-day"
            name="user-insured-day"
            label={t('insuredDaysNumber')}
            type="number"
            placeholder=""
            value={editedDayInfo.insuredDays || ""}
            onChange={(value) =>
              setEditedDayFields("insuredDays", Number(value))
            }
            width={"fullWidth"}
            style={{ marginBottom: "24px" }}
          />

          <br />
          <Button
            fullWidth={true}
            btnStyleType="primary"
            text={dayID === 0 ? t('create') : t('update')}
            onClick={storeTravelMultiDaysFunction}
          />
          {dayID !== 0 && (
            <>
              <br />
              <Button
                fullWidth={true}
                btnStyleType="transparent"
                border={true}
                text={t('delete')}
                onClick={RemoveTravelDayFunction}
              />
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
//
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.grey};
`;
const Wrapper = styled.div``;
//
export default Admin_travel_multi_day_edit;
