import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { AddWelcomerRisks, EnabledLanguage } from "../../../../domain/types";
import api from "../../../../api";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import styled from "styled-components";
import Colors from "../../../../assets/styles/Colors";
import Button from "../../../admin_components/AdminButton";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import BaseModal from "../../../../components/modals/BaseModal";
import Input from "../../../../components/Input";
import UploadFile from "../../../../components/UploadFile";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../../store/appSlice";

//
//
type AddWelcomerRiskTranslatedFields = {
  riskName: string;
  icon: string;
};
//
//
const AdminWelcomerRisks = () => {
  //
  const { t } = useTranslation();
  // 
  const { lang } = useAppSelector((root) => root.app);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );  
  //
  // select risk id
  const [welcomerRiskId, setWelcomerRiskId] = useState<number | null>(null);
  //
  // welcomer risk modal
  const [openWelcomerAddModal, setOpenWelcomerAddModal] =
    useState<boolean>(false);
  //
  // add welcomer risk state
  const [addWelcomerRiskState, setAddWelcomerRiskState] = useState<
    Partial<AddWelcomerRisks>
  >({});
  //
  // get all welcomer risks list
  const {
    data: welcomerRisks,
    isLoading: isWelcomerRisksLoading,
    refetch: refetchWelcomerRisks,
  } = api.useGetAdminWelcomerRiskListQuery(undefined);
  //
  //   add welcomer risk
  const [saveWelcomerRisk, { isLoading: isAddWelcomerRiskLoading }] =
    api.useSaveWelcomerRiskMutation();
  //
  // remove welcomer risk
  const [
    removeWelcomerRisk,
    {
      isLoading: isRemoveWelcomerRiskLoading,
      isSuccess: removeWelcomerRiskSuccess,
    },
  ] = api.useRemoveWelcomerRiskMutation();
  //
  //
  // close add welcomer risk modal
  const onWelcomerAddModalClose = () => {
    setOpenWelcomerAddModal(false);
    setWelcomerRiskId(null);
  };
  //
  // add welcomer risk fields
  const setAddWelcomerRisk = (field: keyof AddWelcomerRisks, value: any) => {
    setAddWelcomerRiskState({
      ...addWelcomerRiskState,
      [field]: value,
    });
  };
  //
  //
  // get welcomer risk by id
  const {
    data: existingWelcomerRisk,
    isLoading: isExistingWelcomerRiskLoading,
  } = api.useGetAdminWelcomerRiskByIdQuery(welcomerRiskId || 0);
  //
  //
  // add welcomer translations fields
  const setAddWelcomerRiskTranslatedFields = (
    field: keyof AddWelcomerRiskTranslatedFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddWelcomerRiskState({
      ...addWelcomerRiskState,
      translations: {
        ...addWelcomerRiskState.translations,
        [language]: {
          ...addWelcomerRiskState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  //
  // add welcomer risk function
  const AddWelcomerRiskFunction = async () => {
    //
    try {
      await saveWelcomerRisk(addWelcomerRiskState).unwrap();
      //
      // refetch welcomer risks
      refetchWelcomerRisks();
      // close modal
      onWelcomerAddModalClose();
      //
      toast.success(`${t('risk')} ${t('addedSuccessfully')}`);
      //
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  // auto refetch welcomer risks when user remove risk
  useEffect(() => {
    if (removeWelcomerRiskSuccess) {
      refetchWelcomerRisks();
      onWelcomerAddModalClose();
    }
  }, [removeWelcomerRisk, removeWelcomerRiskSuccess, refetchWelcomerRisks]);
  //
  //
  useEffect(() => {
    if (existingWelcomerRisk) {
      setAddWelcomerRiskState(existingWelcomerRisk);
    }
  }, [existingWelcomerRisk]);
  //
  //
  // main loading
  const MainLoading =
    isAddWelcomerRiskLoading ||
    isExistingWelcomerRiskLoading ||
    isRemoveWelcomerRiskLoading ||
    isWelcomerRisksLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        btnStyleType="primary"
        text={t('createRisk')}
        onClick={() => [
          setOpenWelcomerAddModal(!openWelcomerAddModal),
          setAddWelcomerRiskState({}),
        ]}
      />
      <br />
      <br />
      <RisksContent>
        {welcomerRisks &&
          welcomerRisks.map((item, index) => (
            <RiskBox
              key={index}
              onClick={() => [
                setWelcomerRiskId(item.id),
                setOpenWelcomerAddModal(!openWelcomerAddModal),
              ]}
            >
              <img src={item.icon} alt={`risk icon`} />
              <div>{item?.translations?.[lang]?.riskName}</div>
            </RiskBox>
          ))}
      </RisksContent>
      {/*  */}
      {/* add new welcomer risk */}
      <BaseModal
        onClose={onWelcomerAddModalClose}
        isOpen={openWelcomerAddModal}
      >
        <div>
          <ModalHead>
            <h4>{welcomerRiskId ? t('update') : t('create')}</h4>
            <AdminLangSwicher />
          </ModalHead>

          <FieldsContent>
            <Input
              id="risk-name"
              label={t('riskTitle')}
              type="text"
              placeholder=""
              value={
                addWelcomerRiskState?.translations?.[field_Language]?.riskName || ""
              }
              name="risk-name"
              onChange={(value) =>
                setAddWelcomerRiskTranslatedFields("riskName", field_Language, value)
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <UploadFile
              label={t('riskIcon')}
              onChange={(props) => setAddWelcomerRisk("icon", props.url)}
              defaultFileName={addWelcomerRiskState?.icon || ""}
            />

            <ButtonsBlock>
              {/* add travel risk */}
              <Button
                fullWidth={true}
                btnStyleType="primary"
                text={welcomerRiskId ? t('update') : t('create')}
                onClick={AddWelcomerRiskFunction}
              />
              {/* add travel risk */}
              <br />
              {/* remove travel risk */}
              {welcomerRiskId && (
                <Button
                  fullWidth={true}
                  btnStyleType="transparent"
                  border={true}
                  text={t('removeRisk')}
                  onClick={() =>
                    window.confirm(`${t('certainToDeleteIt')}`) &&
                    removeWelcomerRisk(welcomerRiskId)
                  }
                />
              )}
              {/* remove travel risk */}
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
      {/* add new welcomer risk */}
    </Wrapper>
  );
};

//styles
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 40px;
`;
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.grey};
`;
const RisksContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  /*  */
  img {
    width: 50px;
  }
`;
const RiskBox = styled.div`
  height: 150px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
`;
const Wrapper = styled.div``;
//
export default AdminWelcomerRisks;
