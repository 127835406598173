import React from "react";
import Select from "react-select";
import styled from "styled-components";
import Colors from "../assets/styles/Colors";
//
//
//
const CustomSelect: React.FC<{
  data: any; //{value: strin, label: string}
  defaultInputValue?: string;
  defaultMenuIsOpen?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  onChange: (value: any) => void;
  headText?: string;
  id?: string;
  isSearchable?: boolean;
}> = ({
  data,
  defaultInputValue,
  defaultMenuIsOpen,
  placeholder,
  isDisabled,
  onChange,
  headText,
  id,
  isSearchable = true,
}) => {
  return (
    <Wrapper>
      <Text id={id}>{headText}</Text>
      <Select
        id={id}
        options={data}
        defaultInputValue={defaultInputValue}
        defaultMenuIsOpen={defaultMenuIsOpen}
        className="custom-select"
        classNamePrefix="custom-select"
        placeholder={placeholder}
        isDisabled={isDisabled}
        defaultValue={"icon"}
        onChange={(event) => onChange(event)}
        isSearchable={isSearchable}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused
              ? `${Colors.primaryBlack}`
              : `${Colors.secondaryGrey}`,
            boxShadow: "unset",
            borderRadius: "4px",
            backgroundColor: `${Colors.white}`,
          }),
        }}
      />
    </Wrapper>
  );
};
//
//
const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.primaryGrey};
  padding-bottom: 12px;
`;
const Wrapper = styled.div`
  width: 100%;
  padding-top: 5px;
  margin-bottom: 24px;
  /*  */
  .custom-select__input-container {
    font-size: 14px;
    color: ${Colors.primaryBlack};
  }
  /*  */

  /*  */
  .custom-select__indicator {
    padding-right: 23px;
    cursor: pointer;
  }
  /*  */
  .custom-select__value-container {
    padding: 12px 20px;
    padding-left: 22px;
    font-size: 14px;
  }
  /*  */
  .custom-select__control {
    &:hover {
      border-color: ${Colors.primaryBlack};
    }
  }
  /*  */
  .custom-select__control--menu-is-open {
    &:hover {
      border-color: unset;
    }
  }
  /*  */
  .custom-select__option--is-selected {
    background-color: ${Colors.lightGrey};
  }
  /*  */
  span {
    display: none;
  }
  .custom-select__menu {
    background-color: ${Colors.white};
  }
  .custom-select__option {
    color: ${Colors.primaryBlack};
    font-size: 14px;
    &:hover {
      background-color: ${Colors.lightGrey};
    }
  }
`;
export default CustomSelect;
