import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";
//
//
const Admin_travel_multi_day = () => {
  //
  const { t } = useTranslation()
  //
  const navigate = useNavigate();
  //
  const {lang} = useAppSelector((root) => root.app)
  //

  const {
    data: multiDays,
    isLoading: isMultiDaysLoading,
    refetch,
  } = api.useGetTravelMultiDaysQuery(undefined);
  //
  //
  useEffect(() => {
    refetch();
  }, [refetch]);

  //
  const mainLoading = isMultiDaysLoading;
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Button
        btnStyleType="primary"
        text={t('create')}
        onClick={() => navigate("/travel-multi-day-edit/0")}
      />
      <br />
      <br />
      <Content>
        {multiDays &&
          multiDays.map((item, index) => (
            <Block
              key={index}
              onClick={() => navigate(`/travel-multi-day-edit/${item.id}`)}
            >
              <div>{item?.translations[lang]?.title}</div>
              <div>{item?.translations[lang]?.body}</div>
              <div>{item?.translations[lang]?.excerpt}</div>
              <div>{item.days}</div>
            </Block>
          ))}
      </Content>
    </Wrapper>
  );
};
//
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
`;
const Block = styled.div`
  border: 1px solid ${Colors.primaryRed};
  border-radius: 4px;
  padding: 10px;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  user-select: none;
  div {
    border-bottom: 1px solid ${Colors.primaryGrey};
    padding-block: 5px;
    margin-block: 5px;
    text-align: center;

    &:last-child {
      border: 0px;
      font-size: 20px;
    }
  }
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
`;
const Wrapper = styled.div``;
//
export default Admin_travel_multi_day;
