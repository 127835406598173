import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Responsive from '../assets/config/Responsive'
import DoubleRightIcon from '../assets/icons/JSX/DoubleRightIcon'
import LeftLineIconJsx from '../assets/icons/JSX/LeftLineIconJsx'
import Colors from '../assets/styles/Colors'

const CustomLink: React.FC<{
  to?: string
  size?: 'small' | 'medium' | 'large'
  text?: string
  rightDoubleIcon?: boolean
  rightLineIcon?: boolean
  leftLineIcon?: boolean
  underline?: boolean
  hideText?: boolean
  style?: React.CSSProperties
  onClick?: () => void
  disabled?: boolean
  params?: string
}> = ({
  to = '',
  size = 'small',
  text,
  rightDoubleIcon = false,
  rightLineIcon = false,
  leftLineIcon = false,
  underline = false,
  hideText = false,
  style,
  disabled = false,
  onClick,
  params = '',
}) => {
  return (
    <LinkStyle
      onClick={onClick}
      style={style}
      to={to}
      state={{ params: params }}
      $size={size}
      $underline={underline}
      $disabled={disabled}
    >
      {leftLineIcon === true ? (
        <IconDIv>
          <LeftIcon />{' '}
        </IconDIv>
      ) : null}
      {hideText === true ? null : <span>{text}</span>}
      {rightDoubleIcon === true ? <DoubleRightIconStyle /> : null}
      {rightLineIcon === true ? (
        <IconDIv>
          <RightLineIconStyle />
        </IconDIv>
      ) : null}
    </LinkStyle>
  )
}
//
//
// styles levan madurashvili
const IconDIv = styled.div`
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Responsive.tablet} {
    height: 16px;
  }
  ${Responsive.mobile} {
    height: 16px;
  }
`
const LeftIcon = styled(LeftLineIconJsx)``
const RightLineIconStyle = styled(LeftLineIconJsx)`
  transform: rotate(180deg);
`
const DoubleRightIconStyle = styled(DoubleRightIcon)`
  height: 15px;
  width: 10px;
  margin-bottom: -1px;
  margin-left: 5px;
`
const LinkStyle = styled(Link)<{ $size: string; $underline: boolean; $disabled: boolean }>`
  /* styles with props */
  font-size: ${({ $size }) => ($size === 'small' ? '14px' : $size === 'medium' ? '18px' : '24px')};
  line-height: ${({ $size }) => ($size === 'small' ? '14px' : $size === 'medium' ? '18px' : '24px')};
  text-decoration: ${({ $underline }) => ($underline === true ? 'underline' : 'none')};
  pointer-events: ${({ $disabled }) => ($disabled === true ? 'none' : 'all')};
  opacity: ${({ $disabled }) => ($disabled === true ? '0.5' : 'unset')};

  /* default styles */
  color: ${Colors.primaryBlack};
  transition: all 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  /* link text style */
  span {
  }

  /* right line icon */
  ${RightLineIconStyle} {
    width: ${({ $size }) => ($size === 'small' ? '25px' : $size === 'medium' ? '32px' : '40px')};
    height: ${({ $size }) => ($size === 'small' ? '18px' : $size === 'medium' ? '18px' : '27px')};
    margin-left: ${({ $size }) => ($size === 'small' ? '10px' : $size === 'medium' ? '10px' : '14px')};
  }

  /* left line icon */
  ${LeftIcon} {
    color: ${Colors.primaryGrey};
    width: ${({ $size }) => ($size === 'small' ? '25px' : $size === 'medium' ? '32px' : '40px')};
    height: ${({ $size }) => ($size === 'small' ? '18px' : $size === 'medium' ? '18px' : '27px')};
    margin-right: ${({ $size }) => ($size === 'small' ? '10px' : $size === 'medium' ? '10px' : '14px')};
  }

  /* hover styles */
  &:hover {
    color: inherit;
    /* transform: scale(1.1); */
    transition: all 200ms ease-in-out;

    /* double right icon */
    ${DoubleRightIconStyle} {
      color: ${Colors.primaryRed};
    }

    /* left line icon */
    ${LeftIcon} {
      color: ${Colors.primaryBlack};
    }
  }
  /* slick styles */
  &:active {
    color: ${Colors.secondaryBlack};

    /* double right icon */
    ${DoubleRightIconStyle} {
      color: inherit;
    }

    /* left line icon */
    ${LeftIcon} {
      color: inherit;
    }
  }

  /* Responsive */

  ${Responsive.tablet} {
    /* styles with props */
    font-size: ${({ $size }) => ($size === 'small' ? '14px' : $size === 'medium' ? '14px' : '14px')};
    line-height: ${({ $size }) => ($size === 'small' ? '10px' : $size === 'medium' ? '14px' : '14px')};

    /* right line icon */
    ${RightLineIconStyle} {
      width: ${({ $size }) => ($size === 'small' ? '18px' : $size === 'medium' ? '18px' : '18px')};
      height: ${({ $size }) => ($size === 'small' ? '9px' : $size === 'medium' ? '9px' : '9px')};
      margin-left: ${({ $size }) => ($size === 'small' ? '8px' : $size === 'medium' ? '8px' : '8px')};
    }

    /* left line icon */
    ${LeftIcon} {
      width: ${({ $size }) => ($size === 'small' ? '18px' : $size === 'medium' ? '18px' : '18px')};
      height: ${({ $size }) => ($size === 'small' ? '9px' : $size === 'medium' ? '9px' : '9px')};
      margin-right: ${({ $size }) => ($size === 'small' ? '8px' : $size === 'medium' ? '8px' : '8px')};
    }
  }
  /* #####################################3 */
  ${Responsive.mobile} {
    /* styles with props */
    font-size: ${({ $size }) => ($size === 'small' ? '14px' : $size === 'medium' ? '14px' : '14px')};
    line-height: ${({ $size }) => ($size === 'small' ? '14px' : $size === 'medium' ? '14px' : '14px')};

    /* right line icon */
    ${RightLineIconStyle} {
      width: ${({ $size }) => ($size === 'small' ? '18px' : $size === 'medium' ? '18px' : '18px')};
      height: ${({ $size }) => ($size === 'small' ? '9px' : $size === 'medium' ? '9px' : '9px')};
      margin-left: ${({ $size }) => ($size === 'small' ? '8px' : $size === 'medium' ? '8px' : '8px')};
    }

    /* left line icon */
    ${LeftIcon} {
      width: ${({ $size }) => ($size === 'small' ? '18px' : $size === 'medium' ? '18px' : '18px')};
      height: ${({ $size }) => ($size === 'small' ? '9px' : $size === 'medium' ? '9px' : '9px')};
      margin-right: ${({ $size }) => ($size === 'small' ? '8px' : $size === 'medium' ? '8px' : '8px')};
    }
  }
`

export default CustomLink
