import { DownloadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Empty, Input, Select, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import DoubleRightIcon from "../../../assets/icons/JSX/DoubleRightIcon";
import Colors from "../../../assets/styles/Colors";
import { RootState, useAppSelector } from "../../../store";
import { AuthState } from "../../../store/authSlice";
//
//
type FilterFieldsType = {
  paymentDateFrom: string | null;
  paymentDateTo: string | null;
  privateNumber: string | null;
  orderId: string | null;
  productCategoryId: string | null;
};
//
const AdminCompanyPaymentList = () => {
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const { t } = useTranslation();
  //
  const [filterFieldsState, setFilterFieldsState] = useState<FilterFieldsType>({
    paymentDateFrom: null,
    paymentDateTo: null,
    privateNumber: null,
    orderId: null,
    productCategoryId: null,
  });
  //
  const setFilterField = (
    fieldName: keyof FilterFieldsType,
    fieldValue: any
  ) => {
    setFilterFieldsState({
      ...filterFieldsState,
      [fieldName]: fieldValue,
    });
  };
  //
  const {
    data: AdminProductCategories,
    isLoading: isAdminProductCategoriesLoading,
  } = api.useGetAdminProductCategoriesListQuery(undefined);
  //
  const [pageForAllPayment, setPageForAllPayments] = useState<number>(1);
  // user current company
  const { data: companyList, isLoading: isCompanyListLoading } =
    api.useCompanyListQuery(undefined);
  //
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >(undefined);

  const [privateNumberDebounce] = useDebounce(
    filterFieldsState?.privateNumber,
    1000
  );
  const [orderIdDebounce] = useDebounce(filterFieldsState?.orderId, 1000);
  //
  // company payment list
  const {
    data: allCompanyPaymentList,
    isLoading: isAllCompanyPaymentListLoading,
    refetch: refetchPayments,
  } = api.useGetAllCompanyPaymentListQuery({
    paymentDateFrom: filterFieldsState?.paymentDateFrom,
    paymentDateTo: filterFieldsState?.paymentDateTo,
    privateNumber: privateNumberDebounce,
    orderId: orderIdDebounce,
    productCategoryId: filterFieldsState.productCategoryId,
    companyId: selectedCompanyId,
    page: pageForAllPayment,
    limit: 20,
  });
  //
  const [
    sendAllPaymentsReport,
    {
      isLoading: isSendAllPaymentsReportLoading,
      data: filteredAllPaymentsReport,
    },
  ] = api.useGetDownloadAllCompanyPaymentListMutation();
  //
  // watch and download excel
  useEffect(() => {
    if (filteredAllPaymentsReport) {
      document.location.href = filteredAllPaymentsReport?.url || "";
    }
    //
    return () => {};
  }, [filteredAllPaymentsReport]);
  //
  const downloadPdfReport = async () => {
    //
    try {
      await sendAllPaymentsReport({
        paymentDateFrom: filterFieldsState.paymentDateFrom,
        paymentDateTo: filterFieldsState.paymentDateTo,
        companyId: selectedCompanyId,
        productCategoryId: filterFieldsState.productCategoryId,
        privateNumber: filterFieldsState.privateNumber,
        orderId: filterFieldsState.orderId,
      }).unwrap();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  useEffect(() => {
    if (auth.user?.companyUsers?.[0]?.companyId) {
      setSelectedCompanyId(auth.user?.companyUsers?.[0]?.companyId);
    }
    //
    return () => {};
  }, [auth.user?.companyUsers]);
  //
  const pageCountForAllPayments =
    allCompanyPaymentList &&
    allCompanyPaymentList.meta &&
    allCompanyPaymentList.meta.total &&
    Math.ceil(allCompanyPaymentList?.meta?.total / 20);
  //
  const _clearProps = async () => {
    await setSelectedCompanyId(undefined);
    await setFilterFieldsState({
      paymentDateFrom: null,
      paymentDateTo: null,
      privateNumber: null,
      orderId: null,
      productCategoryId: null,
    });
    refetchPayments();
  };
  //
  const columns = [
    {
      name: t("payer"),
      key: "fullName",
      dataIndex: "fullName",
    },
    {
      name: t("personalNum"),
      key: "personalNumber",
      dataIndex: "personalNumber",
    },
    {
      name: t("totalCommission"),
      key: "totalCommission",
      dataIndex: "totalCommission",
    },
    {
      name: t("franiCommission"),
      key: "currentCommission",
      dataIndex: "currentCommission",
    },
    {
      name: t("franiRate"),
      key: "franiPercent",
      dataIndex: "franiPercent",
    },
    {
      name: t("paymentDate"),
      key: "paymentDate",
      dataIndex: "paymentDate",
    },
    {
      name: t("scheduledPaymentDate"),
      key: "scheduledPaymentDate",
      dataIndex: "scheduledPaymentDate",
    },
    {
      name: t("scheduledPayment"),
      key: "scheduledPayment",
      dataIndex: "scheduledPayment",
    },
    {
      name: t("policyPrice"),
      key: "policyPrice",
      dataIndex: "policyPrice",
    },
    {
      name: t("policyNumber"),
      key: "policyNumber",
      dataIndex: "policyNumber",
    },
    {
      name: t("orderId"),
      key: "orderId",
      dataIndex: "orderId",
    },
    {
      name: t("amount"),
      key: "paymentAmount",
      dataIndex: "paymentAmount",
    },
    {
      name: t("paymentStatus"),
      key: "paymentStatus",
      dataIndex: "paymentStatus",
    },
  ];
  //
  return (
    <>
      <FilterBlock>
        <Select
          loading={isCompanyListLoading}
          size="large"
          showSearch
          value={selectedCompanyId || null}
          placeholder={t("chooseCompany")}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(value) => [
            setPageForAllPayments(1),
            setSelectedCompanyId(value),
          ]}
          options={
            companyList
              ? [
                  ...companyList.map((item) => ({
                    value: item.id,
                    label: item.brandName
                      ? item.brandName?.[lang]?.title
                      : item.slug,
                  })),
                ]
              : undefined
          }
        />

        <Select
          loading={isAdminProductCategoriesLoading}
          size="large"
          value={filterFieldsState.productCategoryId || null}
          placeholder={t("productCategory")}
          optionFilterProp="label"
          onChange={(value) => setFilterField("productCategoryId", value)}
          options={
            AdminProductCategories
              ? [
                  ...AdminProductCategories.map((item) => ({
                    value: item.id,
                    label: item.slug?.replace(/-/g, " "),
                  })),
                ]
              : undefined
          }
        />

        <DatePicker
          size="large"
          placeholder={t("fromPaymentsDate")}
          style={{ width: "100%" }}
          value={
            filterFieldsState?.paymentDateFrom
              ? dayjs(filterFieldsState?.paymentDateFrom)
              : null
          }
          onChange={(date) => {
            const dateString = dayjs(date).format("YYYY-MM-DD");
            if (dateString && date) {
              setFilterField(
                "paymentDateFrom",
                // @ts-ignore
                dayjs.utc(dateString).format("YYYY-MM-DD")
              );
            } else {
              setFilterField("paymentDateFrom", null);
            }
          }}
        />

        <DatePicker
          size="large"
          placeholder={t("uptoPaymentDate")}
          style={{ width: "100%" }}
          value={
            filterFieldsState?.paymentDateTo
              ? dayjs(filterFieldsState?.paymentDateTo)
              : null
          }
          onChange={(date) => {
            const dateString = dayjs(date).format("YYYY-MM-DD");
            if (dateString && date) {
              // @ts-ignore
              setFilterField(
                "paymentDateTo",
                // @ts-ignore
                dayjs.utc(dateString).format("YYYY-MM-DD")
              );
            } else {
              setFilterField("paymentDateTo", null);
            }
          }}
        />

        <Input
          value={filterFieldsState?.privateNumber || ""}
          size="large"
          placeholder={`${t("personalNumber")}`}
          onChange={(e) => setFilterField("privateNumber", e.target.value)}
        />

        <Input
          value={filterFieldsState?.orderId || ""}
          size="large"
          placeholder={`${t("orderId")}`}
          onChange={(e) => setFilterField("orderId", e.target.value)}
        />

        <Button size="large" type="dashed" danger onClick={_clearProps}>
          {t("clear")}
        </Button>

        <Button
          type="primary"
          icon={<DownloadOutlined />}
          size="large"
          onClick={downloadPdfReport}
          loading={isSendAllPaymentsReportLoading}
        >
          {t("downloadReports")}
        </Button>
      </FilterBlock>

      <Table
        scroll={{ x: "max-content" }}
        locale={{
          emptyText: <Empty description={t("paymentsNotFound")} />,
        }}
        loading={isAllCompanyPaymentListLoading}
        pagination={false}
        dataSource={
          allCompanyPaymentList &&
          allCompanyPaymentList.data &&
          allCompanyPaymentList?.data?.length >= 1
            ? [
                ...allCompanyPaymentList.data?.map((item, index) => ({
                  key: index,
                  fullName: item.fullName,
                  personalNumber: item.personalNumber,
                  totalCommission: `${item.totalCommission} ₾`,
                  currentCommission: item.currentCommission
                    ? `${item.currentCommission} ₾`
                    : "",
                  franiPercent: `${item.franiPercent} %`,
                  paymentDate: dayjs(item.paymentDate).format(
                    "DD / MM / YYYY / HH : MM"
                  ),
                  scheduledPaymentDate: dayjs(item.scheduledPaymentDate).format(
                    "DD / MM / YYYY / HH : MM"
                  ),
                  scheduledPayment: `${item.scheduledPayment} ₾`,
                  policyPrice: `${item.policyPrice} ₾`,
                  policyNumber: item.policyNumber,
                  orderId: item.orderId,
                  paymentAmount: `${item.paymentAmount} ₾`,
                  paymentStatus: item.paymentStatus,
                })),
              ]
            : []
        }
        columns={[
          ...columns.map((item) => ({
            title: item.name,
            dataIndex: item.dataIndex,
          })),
        ]}
      />

      <br />
      <br />
      {selectedCompanyId === undefined &&
      allCompanyPaymentList &&
      allCompanyPaymentList?.data &&
      allCompanyPaymentList?.data?.length >= 1 ? (
        <CustomPaginationWrapper>
          <ReactPaginate
            breakLabel={"..."}
            forcePage={(pageForAllPayment as number) - 1}
            nextLabel={<RightIcon />}
            onPageChange={(event) => setPageForAllPayments(event.selected + 1)}
            pageRangeDisplayed={1}
            pageCount={pageCountForAllPayments || 0}
            previousLabel={<LeftIcon />}
            // renderOnZeroPageCount={null}
          />
        </CustomPaginationWrapper>
      ) : null}
    </>
  );
};
//
// styles
const CustomPaginationWrapper = styled.div`
  margin-block: 20px;
  display: grid;
  place-items: center;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-bottom: 0px;
    li {
      user-select: none;
      font-size: 14px;
      line-height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background-color: transparent;
      color: var(--text-color);
      height: 30px;
      width: 30px;
      cursor: pointer;
      margin-inline: 1px;

      a {
        width: 100%;
        text-align: center;
        display: grid;
        place-items: center;
      }

      &.disabled {
        opacity: 0.6;
      }

      &.break {
        a {
          height: 100%;
          margin-top: -8px;
          color: ${Colors.primaryRed};
        }
      }
      &.selected {
        background-color: ${Colors.primaryRed};
        color: ${Colors.white};
      }
    }
  }

  ${Responsive.mobile} {
    ul {
      li {
        margin: 0 5px;
        font-size: 14px;
        height: 24px;
        width: 24px;
      }
    }
  }
`;
const RightIcon = styled(DoubleRightIcon)`
  color: ${Colors.primaryRed};
  height: 15px;
`;
const LeftIcon = styled(DoubleRightIcon)`
  transform: rotate(180deg);
  color: ${Colors.primaryRed};
  height: 15px;
`;

const FilterBlock = styled.div`
  -webkit-box-shadow: 0px 0px 15px 3px #64646411;
  box-shadow: 0px 0px 15px 3px #64646411;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  padding: 20px;
  margin-bottom: 30px;
  align-items: flex-end;
`;
//

export default AdminCompanyPaymentList;
