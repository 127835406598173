import React, { useState } from "react";
import styled from "styled-components";
import Responsive from "../assets/config/Responsive";
import Colors from "../assets/styles/Colors";
import OpenEye from "../assets/icons/JSX/OpenEye";
import CloseEye from "../assets/icons/JSX/CloseEye";
import { UseFormRegisterReturn } from "react-hook-form";

const Input: React.FC<{
  id: string;
  label: string;
  type?: string;
  placeholder?: string;
  value: any;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  className?: string;
  onChange: (value: any) => void;
  style?: React.CSSProperties;
  width?: "fullWidth" | "default";
  children?: React.ReactNode;
  name?: string;
  forSpreadOperator?: boolean;
  icon?: boolean;
  onBlur?: (value: any) => void;
  disabled?: boolean;
  onKeyPress?: (value: any) => void;
  formRegistration?: UseFormRegisterReturn;
}> = ({
  id,
  label = "",
  type = "text",
  placeholder,
  value,
  required,
  error = false,
  errorText,
  className,
  onChange,
  style,
  width = "default",
  children,
  name,
  forSpreadOperator = false,
  icon = false,
  onBlur,
  disabled = false,
  onKeyPress,
  formRegistration,
}) => {
  //
  const [inputType, setInputType] = useState(type);
  //
  return (
    <Wrapper style={style} className={className} $width={width}>
      {label ? (
        <LabelStyle $error={error} htmlFor={`field-${id}`}>
          {label}
        </LabelStyle>
      ) : null}
      {forSpreadOperator === true ? (
        <InputStyle
          onWheel={() => {}}
          $error={error}
          required={required}
          value={value}
          type={inputType}
          id={`field-${id}`}
          name={name}
          placeholder={placeholder}
          onChange={(value) => onChange(value)}
          onBlur={onBlur}
          disabled={disabled}
          onKeyPress={onKeyPress}
          {...formRegistration}
        />
      ) : (
        <InputStyle
          onWheel={() => {}}
          $error={error}
          required={required}
          value={value}
          type={inputType}
          id={`field-${id}`}
          name={name}
          placeholder={placeholder}
          onChange={(event) => onChange(event.target.value)}
          onBlur={onBlur}
          disabled={disabled}
          onKeyPress={onKeyPress}
          {...formRegistration}
        />
      )}
      {icon === true ? (
        <PasswordButton
          onClick={() =>
            setInputType(inputType === "password" ? "text" : "password")
          }
        >
          {inputType === "password" ? <OpenEye /> : <CloseEye />}
        </PasswordButton>
      ) : null}
      {children}
      {error === true ? (
        <ErrorMessage $error={error} className={`field-${id}`}>
          {errorText}
        </ErrorMessage>
      ) : null}
    </Wrapper>
  );
};
//
//
// styles levan madurashvili
const PasswordButton = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  user-select: none;
  margin: 0px;
  padding: 0px;
  height: 23px;
  width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 49px;
  right: 15px;
  transition: all 200ms ease-in-out;
  /*  */
  svg {
    transition: all 200ms ease-in-out;
    width: 20px;
    height: 20px;
  }

  ${Responsive.mobile} {
    top: 47px;
  }
`;
const ErrorMessage = styled.div<{ $error: boolean }>`
  width: inherit;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: ${({ $error }) =>
    $error === true ? `${Colors.primaryRed}` : `${Colors.primaryGrey}`};
  margin-top: 5px;
  /* position: absolute;
  bottom: -25px;
  left: 0; */
  /* z-index: 1; */
`;
const LabelStyle = styled.label<{ $error: boolean }>`
  width: inherit;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  text-align: left;
  padding-right: 25px;
  color: ${({ $error }) =>
    $error === true ? `${Colors.primaryRed}` : `${Colors.primaryGrey}`};
`;
const InputStyle = styled.input<{ $error: boolean }>`
  width: inherit;
  outline: none;
  padding: 18px 22px;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid ${Colors.secondaryGrey};
  border-color: ${({ $error }) =>
    $error === true ? `${Colors.primaryRed}` : `${Colors.secondaryGrey}`};
  border-radius: 4px;
  background-color: ${Colors.white};
  color: ${Colors.primaryBlack};
  -webkit-appearance: none;
  -moz-appearance: textfield;

  /* focus stlyes */
  &:focus {
    border-color: ${({ $error }) =>
      $error === true ? `${Colors.primaryRed}` : `${Colors.rollingStone}`};
  }

  /* responsive */

  ${Responsive.mobile} {
    padding: 15px 18px;
  }
`;
const Wrapper = styled.div<{ $width: string }>`
  /* styles from props */
  width: ${({ $width }) => ($width === "fullWidth" ? "100%" : "400px")};
  user-select: none;
  position: relative;
`;

export default Input;
