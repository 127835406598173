import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../../api";
import Colors from "../../../../assets/styles/Colors";
import CustomDropdown from "../../../../components/CustomDropdown";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import BaseModal from "../../../../components/modals/BaseModal";
import { EnabledLanguage, PetService } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState, setEditedlanguage } from "../../../../store/appSlice";
import AdminButton from "../../../admin_components/AdminButton";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import { useTranslation } from "react-i18next";

const AdminPetServices = () => {
  //
  const { t } = useTranslation()
  // 
  const dispatch = useDispatch();
  //
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  //
  const [existingService, setExistingService] = useState<
    Partial<PetService> | undefined
  >(undefined);
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const {
    data: petServices,
    isLoading: isPetServicesLoading,
    refetch: refetchServices,
  } = api.useGetPetServicesQuery(undefined);
  //
  const [createService, { isLoading: isCreateServiceLoading }] =
    api.useCreatePetServiceMutation();
  //
  const [updateService, { isLoading: isUpdateServiceLoading }] =
    api.useUpdatePetServiceMutation();
  //
  const [
    deletePetService,
    {
      isLoading: isDeletePetServiceLoading,
      isSuccess: isDeletePetServiceSuccess,
    },
  ] = api.useDeletePetServiceMutation();
  //
  const _createAndUpdateService = async () => {
    //
    if (existingService) {
      try {
        if (existingService && existingService.id) {
          // update
          await updateService(existingService).unwrap();
        } else {
          // create
          await createService(existingService).unwrap();
        }
        //
        refetchServices();
        //
        setModalIsOpen(false);
        //
        toast.success(
          existingService?.id
            ? `${t('service')} ${t('updatedSuccessfully')}`
            : `${t('service')} ${t('createdSuccessfully')}`
        );
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t('errorOccurred')}`);
        }
      }
    }
    //
  };
  //
  //
  const _closeModal = () => {
    setModalIsOpen(false);
  };
  //
  const deleteService = (id: number) => {
    if (id) {
      return [
        window.confirm(`${t('certainToDeleteIt')}`) &&
          deletePetService(id),
        refetchServices(),
        _closeModal(),
      ];
    }
  };
  //
  const setServiceFields = (field: keyof PetService, value: any) => {
    setExistingService({
      ...existingService,
      [field]: value,
    });
  };
  //
  const setServiceTranslationFields = (
    field: keyof { name: string },
    language: EnabledLanguage,
    value: any
  ) => {
    setExistingService({
      ...existingService,
      translations: {
        ...existingService?.translations,
        [language]: {
          ...existingService?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  console.log("existingService", existingService);

  useEffect(() => {
    if (isDeletePetServiceSuccess) {
      refetchServices();
      _closeModal();
    }

    return () => {};
  }, [isDeletePetServiceSuccess, refetchServices]);

  //
  const MainLoading =
    isPetServicesLoading ||
    isCreateServiceLoading ||
    isDeletePetServiceLoading ||
    isUpdateServiceLoading;
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <AdminButton
        btnStyleType="primary"
        text={t('createService')}
        onClick={() => [setExistingService(undefined), setModalIsOpen(true)]}
      />
      <br />
      <br />
      <Content>
        <Block>
          {petServices?.map((item, index) => (
            <ServiceBox
              key={index}
              onClick={() => [setExistingService(item), setModalIsOpen(true)]}
            >
              <div>{item.translations?.[field_Language]?.name}</div>
              <div>{item.status === 'active' ? t('active') : t('inActive')}</div>
              <div>{item.priority}</div>
            </ServiceBox>
          ))}
        </Block>
      </Content>

      <BaseModal onClose={_closeModal} isOpen={modalIsOpen}>
        <div>
          <ModalHead>
            <h4>{existingService?.id ? t('update') : t('create')}</h4>
            <AdminLangSwicher
              onChange={(value) => dispatch(setEditedlanguage(value))}
            />
          </ModalHead>

          <Input
            id="service-name"
            name="service-name"
            label={t('title')}
            type="text"
            placeholder=""
            value={existingService?.translations?.[field_Language]?.name || ""}
            onChange={(value) =>
              setServiceTranslationFields("name", field_Language, value)
            }
            width={"fullWidth"}
            style={{ marginBottom: "24px" }}
          />

          <CustomDropdown
            isMulti={false}
            placeholder=""
            isSearchable={false}
            value={existingService?.status || ""}
            title={t('status')}
            onChange={(event) => setServiceFields("status", event.value)}
            options={[
              ...["active", "inactive"].map((item) => ({
                value: item,
                label: item === 'active' ? t('active') : t('inActive'),
              })),
            ]}
          />

          <FieldsContent>
            <Input
              id="service-priority"
              name="service-priority"
              label={t('priority')}
              type="number"
              placeholder=""
              value={existingService?.priority || ""}
              onChange={(value) =>
                setServiceFields("priority", parseInt(value))
              }
              width={"fullWidth"}
              style={{ marginBottom: "24px" }}
            />

            <ButtonsBlock>
              <AdminButton
                fullWidth={true}
                btnStyleType="primary"
                disabled={
                  modalIsOpen &&
                  existingService?.priority &&
                  existingService.status
                    ? false
                    : true
                }
                text={existingService?.id ? t('update') : t('create')}
                onClick={_createAndUpdateService}
              />

              <br />
              {existingService && existingService.id && (
                <AdminButton
                  fullWidth={true}
                  btnStyleType="transparent"
                  border={true}
                  text={t('delete')}
                  onClick={() => {
                    if (existingService && existingService.id) {
                      deleteService(existingService.id);
                    }
                  }}
                />
              )}
            </ButtonsBlock>
          </FieldsContent>
        </div>
      </BaseModal>
    </Wrapper>
  );
};
//
const FieldsContent = styled.div`
  margin-top: 20px;
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 20px;
`;
const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Colors.grey};
`;
const Block = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
`;
const ServiceBox = styled.div`
  height: 200px;
  width: 100%;
  border: 1px solid ${Colors.primaryBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
`;
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
`;
const Wrapper = styled.div`
  /*  */
`;
//
export default AdminPetServices;
