import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import api from "../../../api";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import { AuthState } from "../../../store/authSlice";
//
//
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
//
//
import MuiButton from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Responsive from "../../../assets/config/Responsive";
import { RootState } from "../../../store";
import { useTranslation } from "react-i18next";
//
//
const Admin_frani_users = () => {
  //
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const { t } = useTranslation()
  //
  const navigate = useNavigate();
  //
  // frani users list
  const {
    data: franiUsersList,
    isLoading: isFraniUsersListLoading,
    refetch: isFraniUsersListRefetch,
  } = api.useGetFraniUsersListQuery(undefined);
  //

  //
  // frani user deactivation
  const [franiuserDeactivation, { isLoading: isFraniUserDeactivationLoading }] =
    api.useFraniUserDeactivationMutation();
  // frani user activation
  const [franiuserActivation, { isLoading: isFraniUserActivationLoading }] =
    api.useFraniUserActivationMutation();
  //
  // frani user deactivation function
  const FraniUserDeactivationFunction = async (userID: number) => {
    try {
      await franiuserDeactivation(userID).unwrap();
      //
      //
      toast.success(`${t('user')} ${t('deactivated')}`);
      //
      // refetch
      isFraniUsersListRefetch();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  // frani user activation function
  const FraniUserActivationFunction = async (userID: number) => {
    try {
      await franiuserActivation(userID).unwrap();
      //

      //
      toast.success(`${t('user')} ${t('activated')}`);
      //
      // refetch
      isFraniUsersListRefetch();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t('errorOccurred')}`);
      }
    }
  };
  //
  //
  const mainLoading =
    isFraniUsersListLoading ||
    isFraniUserDeactivationLoading ||
    isFraniUserActivationLoading;
  //
  //
  return mainLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      {auth && auth?.user?.userType === "Frani-Product-Owner" ? null : (
        <Button
          btnStyleType="primary"
          text={t('createUser')}
          onClick={() => navigate("/frani-user-edit/0")}
        />
      )}
      <br />
      <br />
      <UsersBlock>
        {franiUsersList &&
          franiUsersList.length >= 1 &&
          franiUsersList?.map((item, index) => (
            <Card
              sx={{
                cursor: "default",
                userSelect:
                  auth?.user?.userType === "Frani-Product-Owner"
                    ? "none"
                    : "unset",
                pointerEvents:
                  auth?.user?.userType === "Frani-Product-Owner"
                    ? "none"
                    : "unset",
              }}
              key={index}
            >
              <CardMedia
                component="img"
                height="140"
                image={
                  "https://cdn.pixabay.com/photo/2018/04/18/18/56/user-3331257__340.png"
                }
                alt="company cover"
              />
              <CardContent>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <span>
                    {item?.firstName} {item.lastName}
                  </span>{" "}
                  <small>
                    {item?.activated === true ? "Active" : "Not active"}
                  </small>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item?.roles?.map((r) => (
                    <div key={r?.id}>{r?.role}</div>
                  ))}
                </Typography>
              </CardContent>
              {auth?.user?.userType === "Frani-Product-Owner" ? null : (
                <CardActions>
                  <Row style={{ margin: "0 auto", width: "100%" }}>
                    <Col>
                      <MuiButton
                        color={"info"}
                        fullWidth={true}
                        size="small"
                        onClick={() => navigate(`/frani-user-edit/${item.id}`)}
                      >
                        {t('update')}
                      </MuiButton>
                    </Col>
                    <Col>
                      {(auth?.user?.companyUsers?.[0]?.role ||
                        auth?.user?.userType) !== "Frani-Admin" ? null : (
                        <>
                          <MuiButton
                            color={"error"}
                            fullWidth={true}
                            size="small"
                            onClick={() =>
                              FraniUserDeactivationFunction(item.id)
                            }
                          >
                            {t('deactivate')}
                          </MuiButton>
                        </>
                      )}
                    </Col>
                    <Col>
                      <MuiButton
                        color={"success"}
                        fullWidth={true}
                        size="small"
                        onClick={() => FraniUserActivationFunction(item.id)}
                      >
                        {t('activate')}
                      </MuiButton>
                    </Col>
                  </Row>
                </CardActions>
              )}
            </Card>
          ))}
      </UsersBlock>
    </Wrapper>
  );
};
//
const UsersBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  div,
  button,
  p {
    font-family: inherit;
  }
  /*  */
  ${Responsive.mobile} {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
`;
const Wrapper = styled.div``;
//
export default Admin_frani_users;
