import { AppThemeType } from "../domain/types";

export const getAppThemeLocalStorage = () => {
  const theme = localStorage.getItem("appTheme");
  //
  if (theme) {
    return theme as AppThemeType;
  } else {
    return "light";
  }
};

export const setAppThemeLocalStorage = (theme: AppThemeType) => {
  if (theme) {
    localStorage.setItem("appTheme", theme);
  } else {
    localStorage.setItem("appTheme", "light");
  }
};
