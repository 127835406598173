import { Currency, SingleMultiOption, TravelAbroadArea, TravelAbroadPriceCombination, TravelPackage, TravelProduct } from './types'
import lodash from 'lodash'

export type TravelAbroadEnabledCurrencies = 'USD' | 'EUR'
export const TravelAbroadEnabledCurrencyOptions: Currency[] = ['USD', 'EUR']

export const explainTravelCombination = ({
  travelProduct,
  priceCombinationToExplain,
}: {
  travelProduct: TravelProduct
  priceCombinationToExplain: TravelAbroadPriceCombination
}) => {
  const basePrice = travelProduct.priceCoefficients?.basePrice
  const { travelType, area, currency, franchise, minAge, maxAge, minDay, maxDay } = priceCombinationToExplain
  const KForTravelType = travelProduct.priceCoefficients
  const KArea = travelProduct.priceCoefficients?.areaCoefficients.find(
    (ka) => ka.area === priceCombinationToExplain.area,
  )?.coefficient
  const KCurrency = travelProduct.priceCoefficients?.currencyCoefficients.find(
    (ka) => ka.currency === priceCombinationToExplain.currency,
  )?.coefficient
  const KFranchise = travelProduct.priceCoefficients?.franchiseCoefficients.find(
    (ka) => ka.franchise.toString() === priceCombinationToExplain.franchise.toString(),
  )?.coefficient
  const KAgeGroup = (travelProduct.minTotalPremiumInfos || [])
    .find((dg) => dg.minAge! === priceCombinationToExplain.minAge! && dg.maxAge! === priceCombinationToExplain.maxAge!)
    ?.coefficientsByTravelArea?.find((cAgeArea) => cAgeArea.area === priceCombinationToExplain.area)?.coefficient
  const KDayGroup = travelProduct.dayGroupInfos.find(
    (dg) => dg.minDay === priceCombinationToExplain.minDay && dg.maxDay === priceCombinationToExplain.maxDay,
  )?.coefficient
  const calculatedPremium = priceCombinationToExplain.premium
  return (
    [
      `basePrice: ${basePrice}`,
      `single/Multi (${travelType}): ${KForTravelType}`,
      `area (${area}): ${KArea}`,
      `currency (${currency}): ${KCurrency}`,
      `franchise (${franchise}): ${KFranchise}`,
      `ageGroup (${minAge}-${maxAge}): ${KAgeGroup}`,
      `dayGroup (${minDay}-${maxDay}): ${KDayGroup}`,
    ].join(' * ') + ` = ${calculatedPremium}`
  )
}

export const regeneratePriceCombinations = (travelProduct: TravelProduct): TravelProduct => {
  const existingManualPriceCombinations = travelProduct.priceCombinations.filter((pc) => pc.isAutoGenerated === false)
  let regeneratedPriceCombinations: TravelProduct['priceCombinations'] = []

  for (const travelPackage of travelProduct.mainProduct.packages.map((p) => p.target as TravelPackage)) {
    if (!travelPackage) {
      console.log(`travelPackage relationship not found on travelProduct package`, { travelProduct })
      continue
    }
    const singleTravelTypeEnabled: SingleMultiOption[] = travelPackage.enableSingle ? ['SINGLE'] : []
    const multiTravelTypeEnabled: SingleMultiOption[] = travelPackage.enableMulti ? ['MULTI'] : []
    const neededTravelTypes: SingleMultiOption[] = [
      ...singleTravelTypeEnabled,
      ...multiTravelTypeEnabled,
      //
    ]
    for (const travelType of neededTravelTypes) {
      // for (const area of TravelAbroadAreaOptions) {
      for (const area of travelPackage.areas.map((a) => a.area)) {
        // for (const currency of TravelAbroadEnabledCurrencies) {
        for (const currency of travelPackage.mainPackage.compensationCurrencies.map(
          (c) => c.currency as TravelAbroadEnabledCurrencies,
        )) {
          for (const franchise of travelPackage.mainPackage.franchises || []) {
            for (const ageGroup of travelProduct.minTotalPremiumInfos) {
              for (const dayGroup of travelProduct.dayGroupInfos) {
                // skip generation if there's a manual price (exception with same parameters)
                if (
                  existingManualPriceCombinations.some(
                    (ex) =>
                      ex.travelPackageId.toString() === travelPackage.id.toString() &&
                      ex.travelType === travelType &&
                      ex.area === area &&
                      ex.currency === currency &&
                      ex.franchise.toString() === franchise.toString() &&
                      ex.minAge.toString() === ageGroup.minAge.toString() &&
                      ex.maxAge.toString() === ageGroup.maxAge.toString() &&
                      ex.minDay.toString() === dayGroup.minDay.toString() &&
                      ex.maxDay.toString() === dayGroup.maxDay.toString(),
                  )
                ) {
                  continue
                }
                let KForTravelType =
                  travelType === 'MULTI'
                    ? travelProduct.priceCoefficients?.multiPolicyCoefficient
                    : travelProduct.priceCoefficients?.singlePolicyCoefficient
                let KForPackage = travelProduct.priceCoefficients?.packageCoefficients?.find(
                  (pk) => pk.packageId === travelPackage.id,
                )?.coefficient
                let KArea = travelProduct.priceCoefficients?.areaCoefficients?.find(
                  (ak) => ak.area === area,
                )?.coefficient
                let KCurrency = travelProduct.priceCoefficients?.currencyCoefficients?.find(
                  (ck) => ck.currency === currency,
                )?.coefficient
                let KFranchise = travelProduct.priceCoefficients?.franchiseCoefficients?.find(
                  (fk) => fk.franchise?.toString() === franchise?.toString(),
                )?.coefficient
                let KAgeGroup = ageGroup.coefficientsByTravelArea?.find((ca) => ca.area === area)?.coefficient
                let KDayGroup = dayGroup.coefficient
                // IMPORTANT:
                // instead of erroring, assume 1 – these fields have required validation and prices won't be saved/[will be regenerated] when they are filled before saving
                if (!KForPackage) {
                  // throw new Error(`missing coefficient for travel package ${travelPackage.id}`)
                  KForPackage = 1
                }
                if (!KArea) {
                  // throw new Error(`missing coefficient for area ${area}`)
                  KArea = 1
                }
                if (!KCurrency) {
                  // throw new Error(`missing coefficient for currency ${currency}`)
                  KCurrency = 1
                }
                if (!KFranchise) {
                  // throw new Error(`missing coefficient for franchise ${franchise}`)
                  KFranchise = 1
                }
                if (!KAgeGroup) {
                  // throw new Error(`missing coefficient for age group ${ageGroup.minAge}-${ageGroup.maxAge}`)
                  KAgeGroup = 1
                }
                if (!KDayGroup) {
                  // throw new Error(`missing coefficient for day group ${dayGroup.minDay}-${dayGroup.maxDay}`)
                  KDayGroup = 1
                }
                const calculatedPremium =
                  travelProduct.priceCoefficients.basePrice *
                  KForTravelType *
                  KArea *
                  KCurrency *
                  KFranchise *
                  KAgeGroup *
                  KDayGroup
                let combination: TravelAbroadPriceCombination = {
                  travelProductId: travelProduct.id,
                  isAutoGenerated: true,
                  travelPackageId: travelPackage.id,
                  currency: currency,
                  franchise: franchise,
                  minDay: dayGroup.minDay,
                  maxDay: dayGroup.maxDay,
                  minAge: ageGroup.minAge,
                  maxAge: ageGroup.maxAge,
                  area: area,
                  travelType: travelType,
                  premium: calculatedPremium,

                  id: -1 * (regeneratedPriceCombinations.length + 1),
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                }
                // combination.explanation = explainTravelCombination({
                //   travelProduct,
                //   priceCombinationToExplain: combination,
                // })
                // TODO_1 for existing ID, find existing combination in older generated combinations (which we don't have here)
                // TODO_2 for overriding generated with manual combinations, find manual combination with same parameters and if found, ignore the generated `combination` one here.
                regeneratedPriceCombinations.push(combination)
              }
            }
          }
        }
      }
    }
  }

  const fullPriceCombinations: TravelProduct['priceCombinations'] = [
    ...existingManualPriceCombinations,
    ...regeneratedPriceCombinations,
  ]
  return {
    ...travelProduct,
    priceCombinations: fullPriceCombinations,
  }
}

export const allAreasOfTravelProduct = (travelProduct: TravelProduct): TravelAbroadArea[] => {
  return lodash.uniq(
    travelProduct.mainProduct.packages
      .map((pkg) => pkg.target as TravelPackage)
      .map((pkg) => pkg?.areas.map((area) => area.area))
      .reduce((carry, next) => (carry ?? []).concat(next ?? []), []),
  ) as TravelAbroadArea[]
}

export const allCurrenciesOfTravelProduct = (travelProduct: TravelProduct): Currency[] => {
  return lodash.uniq(
    travelProduct.mainProduct.packages
      .map((pkg) => pkg?.compensationCurrencies.map((currencyHolder) => currencyHolder.currency))
      .reduce((carry, next) => (carry ?? []).concat(next ?? []), []),
  ) as Currency[]
}

export const allFranchisesOfTravelProduct = (travelProduct: TravelProduct): number[] => {
  return lodash.uniq(
    travelProduct.mainProduct.packages
      .map((pkg) => pkg?.franchises)
      .reduce((carry, next) => (carry ?? []).concat(next ?? []), []),
  )
}

/** fill missing package coefficients, set default 1 (missing package coefficient was a frequent occurence) */
export const decorateTravelProductWithDefaultPackageCoefficients = (existingTravelProduct: TravelProduct) => {
  const packageIdsMissingInCoefficients = existingTravelProduct.mainProduct.packages
    .map((pkg) => pkg.targetId)
    .filter(
      (travelPackageId) =>
        !existingTravelProduct.priceCoefficients?.packageCoefficients.some(
          (pkgK) => pkgK.packageId === travelPackageId,
        ),
    )
  const travelProductWithDefaultPackageCoefficients: TravelProduct = {
    //
    ...existingTravelProduct,
    priceCoefficients: {
      ...(existingTravelProduct.priceCoefficients ?? {}),
      packageCoefficients: [
        ...(existingTravelProduct.priceCoefficients?.packageCoefficients ?? []),
        ...packageIdsMissingInCoefficients.map((travelPackageId) => ({
          packageId: travelPackageId,
          coefficient: 1,
        })),
      ],
    },
  }
  return travelProductWithDefaultPackageCoefficients
}
