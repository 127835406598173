import React from "react";
import { useNavigate } from "react-router-dom";

const AdminTplProductList = () => {
  //
  const navigate = useNavigate();
  //
  return (
    <div>
      AdminTplProductList
      <button onClick={() => navigate("/tpl-product-edit")}>product</button>
    </div>
  );
};

export default AdminTplProductList;
