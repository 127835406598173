import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from ".";
import api from "../api";
import { User } from "../domain/types";
import { getAuthToken, setAuthToken } from "./tokenStorage";

export type AuthState = {
  user: User | null;
  userLoading: boolean;
  token: string | null;
  //
  searchKeyword: string;
};

const authTokenFromStorage = getAuthToken();
const slice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: authTokenFromStorage,
    userLoading: !!authTokenFromStorage,
    searchKeyword: "",
  } as AuthState,
  reducers: {
    setCredentials: (
      // for manually setting logged-in credentials
      state: AuthState,
      { payload: { user, token } }: PayloadAction<{ user: User; token: string }>
    ) => {
      state.user = user;
      state.userLoading = false;
      state.token = token;
      setAuthToken(token);
    },
    setSearchKeyword: (
      // for global header search
      state: AuthState,
      { payload: searchKeyword }: PayloadAction<AuthState["searchKeyword"]>
    ) => {
      state.searchKeyword = searchKeyword;
    },
    clearLoginData: (state: AuthState) => {
      state.token = null;
      state.user = null;
      state.userLoading = false;
      // console.log('fetching user data failed, auto logging out ')
      setAuthToken("");
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        // auto-login after registration
        api.endpoints.signUp.matchFulfilled,
        (state, { payload }) => {
          state.token = payload.token;
          state.user = payload.user;
          state.userLoading = false;
          setAuthToken(payload.token);
        }
      )
      .addMatcher(
        // mark user as loading while logging in
        api.endpoints.login.matchPending,
        (state, { payload }) => {
          state.userLoading = true;
        }
      )
      .addMatcher(
        // remember token in localStorage after successful login
        api.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.token = payload.token;
          state.user = payload.user;
          state.userLoading = false;
          setAuthToken(payload.token);
        }
      )
      .addMatcher(
        // clear logged-in user and localStorage after logout
        api.endpoints.logout.matchFulfilled,
        (state, { payload }) => {
          state.token = null;
          state.user = null;
          state.userLoading = false;
          setAuthToken("");
        }
      )
      .addMatcher(
        // mark user as loading while logging in
        api.endpoints.profile.matchPending,
        (state, { payload }) => {
          state.userLoading = true;
        }
      )
      .addMatcher(
        // set user in redux after auto-login from localStorage.token
        api.endpoints.profile.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
          state.userLoading = false;
        }
      )
      .addMatcher(
        // if auto-login (localStorage.token => dispatch(profile)) failed
        api.endpoints.profile.matchRejected,
        (state, { payload }) => {
          // Problem: for some reason, /auth/profile 200 OK, returns fulfilled Promise, but still runs this matchRejected handler
          // probably mismatch between RTK Query usages
          // Workaround: moved to clearLoginData called by App.tsx, where correct status is detected
          // state.token = null
          // state.user = null
          // state.userLoading = false
          // console.log('fetching user data failed, auto logging out ')
          // setAuthToken('')
        }
      )
      .addMatcher(
        api.endpoints.updateProfile.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
          state.userLoading = false;
        }
      );
  },
});

export const { setCredentials, setSearchKeyword, clearLoginData } =
  slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
