import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import {
  FullScreen,
  MediumScreenTitle,
} from "../../../assets/styles/GlobalStyle";
import Loader from "./../../../components/Loader";

const Admin_create_notification = () => {
  // send notification mutation
  const [createNotification, { isLoading: CreateNotificationLoading }] =
    api.useCreateNotificationMutation();

  const [notificationType, setNotificationType] = useState<string>("");
  const [technical, setTechnical] = useState<boolean>(false);
  // GE
  const [notificationTitleGe, setNotificationTitleGe] = useState<string>("");
  const [notificationMessageGe, setNotificationMessageGe] =
    useState<string>("");
  // EN
  const [notificationTitleEn, setNotificationTitleEn] = useState<string>("");
  const [notificationMessageEn, setNotificationMessageEn] =
    useState<string>("");

  // send notification function
  const sendNotification = async () => {
    try {
      await createNotification({
        notificationType: notificationType,
        technical: technical,
        translations: {
          ge: {
            title: notificationTitleGe,
            message: notificationMessageGe,
          },
          en: {
            title: notificationTitleEn,
            message: notificationMessageEn,
          },
        },
      }).unwrap();
      toast.success("Notification received successfully");
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error("Მოხდა შეცდომა. Გთხოვთ სცადოთ მოგვიანებით...");
      }
      console.log(error);
    }
  };
  const MainLoading = CreateNotificationLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <FullScreen className="container">
      <Wrapper>
        <div className="mb-3">
          <MediumScreenTitle>შეიყვანეთ ინფორმაცია ქართულად</MediumScreenTitle>
          <Form.Label>
            <b>Choose who you want to receive the notification</b>
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setNotificationType(e.target.value)}
          >
            <option>Choose from here</option>
            <option value="All">All</option>
            <option value="Insurance">Insurance</option>
            <option value="User">User</option>
          </Form.Select>
        </div>

        <div className="mb-3">
          <Form.Group className="mb-3" controlId="notificationTitle">
            <Form.Label>
              <b>Enter notification title (in Georgian)</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter notification title (in Georgian)"
              value={notificationTitleGe}
              onChange={(e) => setNotificationTitleGe(e.target.value)}
            />
          </Form.Group>
        </div>

        <div className="mb-3">
          <Form.Group className="mb-3" controlId="notificationMessage">
            <Form.Label>
              <b>Enter notification message (in Georgian)</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter notification message (in Georgian)"
              value={notificationMessageGe}
              onChange={(e) => setNotificationMessageGe(e.target.value)}
            />
          </Form.Group>
        </div>
        {/* ############################################################################## */}

        {/* ############################################################################## */}
        <div className="mb-3 mt-5">
          <MediumScreenTitle>შეიყვანეთ ინფორმაცია ინგლისურად</MediumScreenTitle>
          <Form.Group className="mb-3" controlId="notificationTitle">
            <Form.Label>
              <b>Enter notification title (in English)</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter notification title (in English)"
              value={notificationTitleEn}
              onChange={(e) => setNotificationTitleEn(e.target.value)}
            />
          </Form.Group>
        </div>

        <div className="mb-5">
          <Form.Group className="mb-3" controlId="notificationMessage">
            <Form.Label>
              <b>Enter notification message (in English)</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter notification message (in English)"
              value={notificationMessageEn}
              onChange={(e) => setNotificationMessageEn(e.target.value)}
            />
          </Form.Group>
        </div>

        <div className="mb-4">
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              style={{ userSelect: "none" }}
              type="checkbox"
              label="Technical messages ( Sending to everyone )"
              onChange={(e) => setTechnical(e.target.checked)}
            />
          </Form.Group>
        </div>

        <Button
          variant="primary"
          className="w-100 mt-4"
          onClick={sendNotification}
        >
          Submit
        </Button>
      </Wrapper>
    </FullScreen>
  );
};

const Wrapper = styled.div``;

export default Admin_create_notification;
